import React from 'react';
import moment from 'moment';
import * as _ from 'lodash';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocationState } from 'react-router-use-location-state';

// project imports
import { ProductImage } from 'components/v2';
import { getCoBuyingList } from 'services/ProductService';
import { Calculator } from 'utils/bourgogne-tools';
import { useAuth, useScriptRef } from 'hooks';
import { CLO_CODE } from 'config';
import { httpsCallable } from 'firebase/functions';
import { getFirebaseFunctions } from 'utils/firebase-tools';

// material-ui
import { Box, Button, CircularProgress, Container, Divider, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import { styled } from '@mui/material/styles';

// assets
import TasteDivider from '../../../../mall/wine/WineInfo/components/InfoView/components/assets/ico_taste_level_division.svg';
import { PromotionClockIcon } from '../SelfPromotionSection/components/icon';
import { WoAlert } from '../../../../../utils/kmwine-alerts';

// 공동구매 카드 커버 가로 길이 (여러개일때)
const CARD_COVER_WIDTH = 299;
// 공동구매 카드 커버 가로 길이 (하나일때)
const CARD_COVER_ONE_WIDTH = 335;
// 공동구매  카드 커버 세로 길이
const CARD_COVER_HEIGHT = 251;

const initState = Object.freeze({
  loaded: false,
  error: false,
  errorLoading: false,
  show: false,
  list: [],
  swiperIndex: 0
});

/**
 * 공동 구매
 * @constructor
 *
 * @authors 최효근<hkchoi@winone.io>, 조현권<hkcho@wineone.io>
 */
const CoBuying = () => {
  const scriptedRef = useScriptRef();
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  // 로그인 사용자 정보
  const { user } = useAuth();

  // Swiper ref
  const cardSwiperRef = React.useRef(null);

  // 공동구매 목록
  const [rows, setRows] = useLocationState('co-buying', initState);

  React.useEffect(() => {
    fetchCoBuyingList();
  }, []);

  // 진행 중인 공동구매 목록 불러오기
  const fetchCoBuyingList: Promise<any> = React.useCallback(async () => {
    setRows({ ...rows, errorLoading: true });

    const body = {
      show: 'true',
      sort: [{ sale_start_dt: 'desc' }]
    };

    // 공동구매 목록 호출결과
    const result = await getCoBuyingList(body).catch((error) => ({ error }));

    if (!scriptedRef.current) {
      console.warn('[CoBuying][fetchCoBuyingList] Unmounted component.');
      return undefined;
    }

    // 공동구매 조회 실패
    if (result.error) {
      console.error('[CoBuying][fetchCoBuyingList] ', result.error);
      // error clo 에러 표현 제거
      // httpsCallable(
      //   getFirebaseFunctions(),
      //   'call-cdm-clo-error'
      // )({
      //   code: CLO_CODE.UNEXPECTED_ERROR,
      //   title: `공동구매 조회 중 오류`,
      //   msg: `[uid=${user._id}] ${result.error.message}`,
      //   which: `${location.pathname}${location.search}`,
      //   param: {
      //     uid: user._id
      //   }
      // })
      //   .then(console.log)
      //   .catch(console.error);

      setRows({ loaded: true, error: true, errorLoading: false, show: false, list: [] });
      return undefined;
    }

    const { success, cobuyings } = result;

    // 공동구매 조회 실패
    if (!success) {
      console.error('[CoBuying][fetchCoBuyingList] 잘못된 Response 값이 반환됨.');
      httpsCallable(
        getFirebaseFunctions(),
        'call-cdm-clo-error'
      )({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `공동구매 조회 중 오류`,
        msg: `[uid=${user._id}]`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user._id
        }
      })
        .then(console.log)
        .catch(console.error);
      setRows({ loaded: true, error: true, errorLoading: false, show: false, list: [], swiperIndex: 0 });
    }

    // 이미 조회된 공동구매 목록이 있음
    if (rows.loaded && rows.list?.length > 0) {
      const diff = _.differenceWith(rows.list, cobuyings, _.isEqual).length > 0;

      // 공동구매 목록 변경됨 - 초기화
      if (diff) {
        setRows({ loaded: true, error: false, errorLoading: false, show: true, list: cobuyings, swiperIndex: 0 });
        cardSwiperRef.current?.swiper?.slideTo?.(0);
      } else {
        console.log('[공동구매] 변경된 정보가 없음. 기존 state로 렌더링');
      }
    } else {
      setRows({ loaded: true, error: false, errorLoading: false, show: true, list: cobuyings, swiperIndex: 0 });
    }
  }, []);

  // 공동구매 상세페이지로 이동
  const goDetailPage = async (product) => {
    const { _id: id, /* name__ko, */ sale_end_dt } = product;

    const now = moment();
    const saleEndMmt = moment(sale_end_dt);

    if (now.isBefore(saleEndMmt)) {
      navigate(`/co-buying/${id}`);
    } else {
      // 공동구매가 종료되었습니다.
      await WoAlert.fire('공동구매', '공동 구매 주문기간이 종료되었습니다.', 'info');
      fetchCoBuyingList();
    }
  };

  // 공동구매 종료까지 남은 시간 계산
  const coBuyingCountdown = (endDt) => {
    const today = moment().format('YYYY-MM-DD HH:mm:ss');
    const coBuyingEndDt = moment(moment(endDt).locale('ko').format('YYYY-MM-DD HH:mm:ss'));
    const duration = moment.duration(coBuyingEndDt.diff(today));

    const days = duration.days(); // 프로모션 남은 종료기간을 일(day)로 계산
    const hours = duration.hours(); // 프로모션 남은 종료기간이 24시간 이전일 경우 사용
    const minutes = duration.minutes(); // 프로모션 남은 종료기간이 60분 이전일 경우 사용
    const seconds = duration.seconds(); // 프로모션 남은 종료기간이 60초 이전일 경우 사용

    let result;

    // 공동구매 종료 기간이 24시간 이상일 경우
    if (days > 0) {
      result = `${days}일`;
    }
    // 공동구매 종료 기간이 24시간 이하, 60분 이상일 경우
    else if (hours > 0) {
      result = `${hours}시간${minutes > 0 ? ` ${minutes}분` : ''}`;
    }
    // 공동구매 종료 기간이 60분 이전일 경우
    else if (minutes > 0) {
      result = `${minutes}분`;
    }
    // 공동구매 종료 기간이 60초 이전일 경우
    else if (seconds > 0) {
      result = '1분';
    }
    // 공동구매 종료됨
    else {
      result = 0;
    }

    return result;
  };

  return (
    <>
      {rows.error && (
        <Box mt="42px" height="376px" bgcolor="#FFFFFF" pt="24px">
          <Container aria-label="공동 구매" component="article" position="relative">
            <Typography variant="subtitle1" fontWeight={800} fontSize={21} letterSpacing="0.4px" lineHeight="normal" noWrap>
              공동 구매
            </Typography>
            <Typography variant="subtitle1" mt={1} fontWeight={400} fontSize={16} letterSpacing="0.4px" lineHeight="normal" noWrap>
              1KMWINE과 함께하는 다다익선 공동구매
            </Typography>
          </Container>

          <Box mt="20px" display="flex" justifyContent="center">
            <Box
              sx={{
                position: 'relative',
                width: CARD_COVER_ONE_WIDTH,
                height: CARD_COVER_HEIGHT,
                background: 'rgba(0, 0, 0, 0.4)',
                borderRadius: '20px'
              }}
            >
              <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    textShadow: '0 3px 3px #00000029',
                    textAlign: 'center',
                    fontWeight: 400,
                    color: '#FFF',
                    letterSpacing: '-0.32px',
                    lineHeight: 'normal'
                  }}
                >
                  상품을 가져오지 못 했어요
                </Typography>
                {rows.errorLoading ? (
                  <Box mt={4}>
                    <CircularProgress size="32px" color="lime" />
                  </Box>
                ) : (
                  <Button
                    color="white"
                    variant="contained"
                    startIcon={<ReplayOutlinedIcon />}
                    sx={{ mt: 4 }}
                    onClick={() => fetchCoBuyingList()}
                  >
                    재시도
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {rows.loaded && !rows.error && rows.show && rows.list.length > 0 && (
        <Box mt="42px" height="376px" bgcolor="#FFFFFF" pt="24px">
          <Container aria-label="공동 구매" component="article" position="relative">
            <Typography variant="subtitle1" fontWeight={800} fontSize={21} letterSpacing="0.4px" lineHeight="normal" noWrap>
              공동 구매
            </Typography>
            <Typography variant="subtitle1" mt={1} fontWeight={400} fontSize={16} letterSpacing="0.4px" lineHeight="normal" noWrap>
              1KMWINE과 함께하는 다다익선 공동구매
            </Typography>
          </Container>
          <Swiper
            ref={cardSwiperRef}
            className={`Cobuying-Swiper ${classes.contentSwiper}`}
            slidesPerView="auto"
            centeredSlides
            initialSlide={rows.swiperIndex}
            centeredSlidesBounds={rows.list.length > 1}
            spaceBetween={12}
            onSlideChange={(e) => {
              const { activeIndex } = e;
              setRows((prev) => ({ ...prev, swiperIndex: activeIndex }));
            }}
          >
            {rows.list.map((product, index) => {
              const successRate = Number(product.success_rate) ?? 0;
              let successRateLabel;

              // 달성률 포맷 (99 ~ 100 : 소수점 버림, 그 외 : 올림)
              if (successRate >= 99 && successRate <= 100) {
                successRateLabel = Math.floor(successRate);
              } else {
                successRateLabel = Math.ceil(successRate);
              }

              return (
                <SwiperSlide
                  key={`co-buying-${index}-card`}
                  className={rows.list.length === 1 ? classes.oneSlide : classes.severalSlide}
                  onClick={() => {
                    goDetailPage(product);
                  }}
                  style={{ border: '1px solid #EEECF1' }}
                >
                  <Box width={rows.list.length === 1 ? CARD_COVER_ONE_WIDTH : CARD_COVER_WIDTH} className={classes.infoBox}>
                    {/* 공동구매 정보영역 */}
                    <Box paddingY="22px" display="flex">
                      <ProductImage
                        width="120px"
                        height="120px"
                        bgcolor="transparent"
                        src={product?.bottle_img?.thumb}
                        alt="상품 이미지"
                        padding={0}
                      />

                      <Box width="100%" pr="24px">
                        <Typography paragraph className={classes.highlightBgText}>
                          {product.min_quantity.toLocaleString()}병 달성 시
                        </Typography>
                        <Typography fontSize="14px" color="#2D154D" sx={{ opacity: '.5', textDecoration: 'line-through' }}>
                          {product.price__sale.toLocaleString()}원
                        </Typography>
                        <Box display="flex" alignItems="flex-end" flexDirection="row">
                          <Typography fontWeight="800" color="#9357E5" fontSize="16px">
                            {parseInt(product.discount_percent, 10)}%
                          </Typography>
                          <Typography letterSpacing="-.3px" fontWeight="900" color="#2D154D" fontSize="17px" sx={{ marginLeft: '2px' }}>
                            {product.price__original.toLocaleString()}원
                          </Typography>
                        </Box>

                        <Typography letterSpacing="-.6px" fontSize="11px" sx={{ opacity: '.6' }}>
                          {moment(product.sale_start_dt).isSame(product.sale_end_dt, 'day')
                            ? `기간 : ${moment(product.sale_start_dt).locale('ko').format('MM.DD(ddd)')}`
                            : `기간 : ${moment(product.sale_start_dt).locale('ko').format('MM.DD(ddd)')} -
                          ${moment(product.sale_end_dt).locale('ko').format('MM.DD (ddd)')}`}
                        </Typography>
                        <Box
                          height="22px"
                          display="flex"
                          alignItems="center"
                          marginTop="9px"
                          width="100%"
                          borderRadius="20px"
                          sx={{
                            background: `linear-gradient(to right, #9357E5 ${
                              product.success_rate === null ? 0 : successRateLabel
                            }%, rgba(147, 87, 229, 0.5) 0)`
                          }}
                        >
                          <Typography color="#ffffff" fontSize="11px" fontWeight="400" sx={{ marginLeft: '12px' }}>
                            {product.success_rate === null ? 0 : successRateLabel}% 달성
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Divider sx={{ borderStyle: 'dashed', marginX: '22px' }} />
                    {/* 상품 정보영역 */}
                    <Box px="24px" pt="17px" pb="20px">
                      <Box display="flex">
                        <ProductName>
                          {product.max_quantity <= Number(product.order_cnt) && (
                            <Box
                              width="30px"
                              height="13px"
                              component="span"
                              display="inline-flex"
                              justifyContent="center"
                              alignItems="center"
                              marginRight="4px"
                              sx={{ backgroundColor: '#EEECF1' }}
                            >
                              <Typography color="#220648" component="span" fontWeight="bold" fontSize="12px" sx={{ opacity: 1 }}>
                                품절
                              </Typography>
                            </Box>
                          )}
                          <Typography
                            lineHeight="1.1"
                            fontSize="12px"
                            component="span"
                            sx={{ opacity: `${product.max_quantity <= Number(product.order_cnt) ? 0.4 : 1}` }}
                          >
                            {product.name__ko}
                          </Typography>
                        </ProductName>
                      </Box>

                      <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Stack direction="row" divider={<CbDivider />} spacing="6px" display="flex" alignItems="center">
                          {product.vintage !== 'NV' && (
                            <Typography fontSize="11px" sx={{ opacity: 0.6 }}>
                              {product.vintage}년
                            </Typography>
                          )}

                          <Typography fontSize="11px" sx={{ opacity: 0.6 }}>
                            {Calculator.formatCapacity(product.capacity)}
                          </Typography>
                        </Stack>
                        <Box display="flex" alignItems="center" justifyContent="center">
                          <PromotionClockIcon color="#E55B49" />
                          <Typography color="#E55B49" fontSize="11px" sx={{ marginTop: '2px', marginLeft: '4px' }}>
                            {coBuyingCountdown(product.sale_end_dt)} 남음
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
      )}
    </>
  );
};

export default CoBuying;

const useStyles = makeStyles(() => ({
  contentSwiper: {
    paddingBottom: '32px',
    paddingTop: '20px',
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  // 공동구매 카드 슬라이드 (하나일 때)
  oneSlide: {
    width: CARD_COVER_ONE_WIDTH,
    maxWidth: CARD_COVER_ONE_WIDTH,
    height: CARD_COVER_HEIGHT,
    maxHeight: CARD_COVER_HEIGHT,

    borderRadius: '20px',
    position: 'relative'
  },
  // 공동구매 카드 슬라이드 (여러개일 때)
  severalSlide: {
    width: CARD_COVER_WIDTH,
    maxWidth: CARD_COVER_WIDTH,
    height: CARD_COVER_HEIGHT,
    maxHeight: CARD_COVER_HEIGHT,
    borderRadius: '20px',
    position: 'relative'
  },

  // 공동구매 정보박스
  infoBox: {
    backgroundColor: '#FFFFFF',
    height: 251,
    maxHeight: 251,
    borderRadius: '20px',
    position: 'relative'
  },

  // 텍스트 형광배경
  highlightBgText: {
    fontSize: '17px',
    fontWeight: 700,
    letterSpacing: '-0.18px',
    opacity: 0.7,
    background: 'linear-gradient(to top, rgba(147, 87, 229, 0.2) 70%, transparent 10%)',
    boxDecorationBreak: 'clone',
    color: '#220348',
    wordBreak: 'keep-all',
    display: 'inline',
    lineHeight: 1.3,
    marginBottom: '10px',
    padding: '0 2px'
  }
}));

// 상품명
const ProductName = styled((props) => <Typography className={'product-name'} {...props} />)(({ noWrap = true }) => ({
  textAlign: 'left',
  fontSize: '13px',
  lineHeight: 'normal',
  minHeight: noWrap ? '2rem' : 'auto',
  height: noWrap ? '2rem' : 'auto',
  display: noWrap ? '-webkit-box' : 'block',
  overflow: noWrap ? 'hidden' : 'inherit',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  wordBreak: 'break-all',
  opacity: 0.7
}));

const CbDivider = React.memo(() => (
  <Box width="1px" height="11px" component="img" src={TasteDivider} alt="divider" sx={{ objectFit: 'cover', opacity: '.6' }} />
));
