import React from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';
import PropTypes from 'prop-types';
import { httpsCallable } from 'firebase/functions';

// project imports
import { CLO_CODE, DEV, USER_LOCATION_TYPE } from 'config';
import { useAuth, useKakaoMap, useScriptRef, useWineOne } from 'hooks';
import { WoAlert } from 'utils/kmwine-alerts';
import { useDispatch, useSelector } from 'store';
import { updateUserLocation } from 'store/slices/user-location';
import { getFirebaseFunctions } from 'utils/firebase-tools';
import { openSnackbar } from 'store/slices/snackbar';
import { CurrentLocationIcon } from 'components/icons';
import { WineBottleLoadingLottie } from '../../../../spinner';
import { getPinMapList, getVendorMapList } from '../../../../../services/VendorService';

// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import { Backdrop, Box, Button, Chip, CircularProgress, Fab, Typography, Zoom } from '@mui/material';

// assets
import userMarkerImg from 'assets/images/map_user_marker.png';
import hereMarkerImg from 'assets/images/map/btn_location_now_sel@3x.webp';
import hereMarkerNorImg from 'assets/images/map/btn_location_now_nor@3x.webp';
import thereMarkerImg from 'assets/images/map/btn_location_there_nor@3x.webp';
import thereSelMarkerImg from 'assets/images/map/btn_location_there_sel@3x.webp';
import locationIconImg from 'assets/images/ic_location@3x.webp';
import vendorOnIconImg from 'assets/images/map/img_store_ongoing@2x.webp';

/* global kakao */

const BOTTOM_COMPONENT_HEIGHT = 159;

/**
 * 위치 핀을 선택할 수 있는 지도 영역
 *
 * @constructor
 * @authors 조현권<hkcho@wineone.io>, 김지현<jhkim@wineone.io>, 최효근<hkchoi@wineone.io>
 */
function PinMap({ height = '100%', width = '100%', onPinConfirmed }) {
  const kakaoMap = useKakaoMap();
  const scriptedRef = useScriptRef();
  const theme = useTheme();
  const location = useLocation();
  const navigationType = useNavigationType();

  const { geolocation, refreshUserGeolocation } = useWineOne(); // wineone context
  const { user, refresh } = useAuth(); // 1kmwine auth
  const globalDispatch = useDispatch(); // global dispatch

  // 사용자 위치정보
  const { userLocation } = useSelector((state) => state);

  // 유저 현재위치 마커
  const CURRENT_USER_MARKER = React.useMemo(() => {
    if (kakaoMap.loaded && !kakaoMap.error) return new kakao.maps.MarkerImage(userMarkerImg, new kakao.maps.Size(20, 20));
    return null;
  }, [kakaoMap.loaded, kakaoMap.error]);

  // 유저의 현재 핀 마커(focus)
  const CURRENT_PIN_MARKER = React.useMemo(() => {
    if (kakaoMap.loaded && !kakaoMap.error) return new kakao.maps.MarkerImage(hereMarkerImg, new kakao.maps.Size(97, 49));
    return null;
  }, [kakaoMap.loaded, kakaoMap.error]);
  // 유저의 현재 핀 마커(blurred)
  const CURRENT_PIN_NOR_MARKER = React.useMemo(() => {
    if (kakaoMap.loaded && !kakaoMap.error) return new kakao.maps.MarkerImage(hereMarkerNorImg, new kakao.maps.Size(97, 49));
    return null;
  }, [kakaoMap.loaded, kakaoMap.error]);

  // 핀(미선택) 마커
  const OTHER_PIN_MARKER = React.useMemo(() => {
    if (kakaoMap.loaded && !kakaoMap.error) return new kakao.maps.MarkerImage(thereMarkerImg, new kakao.maps.Size(59, 49));
    return null;
  }, [kakaoMap.loaded, kakaoMap.error]);
  // 핀(선택)
  const SELECT_PIN_MARKER = React.useMemo(() => {
    if (kakaoMap.loaded && !kakaoMap.error) return new kakao.maps.MarkerImage(thereSelMarkerImg, new kakao.maps.Size(59, 49));
    return null;
  }, [kakaoMap.loaded, kakaoMap.error]);

  // 카카오 맵이 들어갈 div
  const pinMapDivRef = React.useRef(null);
  // 지도
  const [pinMap, setPinMap] = React.useState(null);
  // 지도 중심좌표
  const [coordinate, setCoordinate] = React.useState(userLocation.coord);

  // 지도를 움직였습니까???
  const [moved, setMoved] = React.useState(false);

  // 위치핀 핀 마커 객체
  const pinMarkerObj = React.useRef({});
  // 입점사 마커 객체
  const [vndMarkerObj, setVndMarkerObj] = React.useState({});
  // 반경원
  const circle = React.useRef(null);

  React.useEffect(() => {
    try {
      refreshUserGeolocation(); // 사용자 현재위치 최신화
    } catch (e) {
      console.warn('사용자 현재위치 최신화 오류', e);
    }
  }, []);

  // 지도 중심좌표 변경 이벤트 callback
  const mapBoundChanged = () => {
    if (scriptedRef.current && pinMap !== null) {
      setMoved(true); // 지도가 움직였습니다.

      // 지도 중심좌표를 얻어옵니다
      const latlng = pinMap.getCenter();
      setCoordinate({
        lat: latlng.getLat(),
        lng: latlng.getLng()
      });
    }
  };

  // 카카오지도 초기화[start]
  const initializeKakaoMap = React.useCallback(
    (lat, lng) => {
      if (kakaoMap.loaded && !kakaoMap.error) {
        // 지도 옵션
        const _map = new kakao.maps.Map(pinMapDivRef.current, {
          center: new kakao.maps.LatLng(lat, lng),
          level: 7, // 기본 줌 레벨
          minLevel: 2,
          maxLevel: 10 // 최대 줌 레벨
        });

        if (scriptedRef.current) setPinMap(_map);
      }
    },
    [kakaoMap.loaded, kakaoMap.error]
  );
  // 카카오지도 초기화[end]

  // 사용자 위치마커
  const userMarker = React.useRef(null);

  // 선택된 위치핀
  const [selectedPin, setSelectedPin] = React.useState(userLocation.currentPin);

  // 선택핀 변경 감지
  React.useEffect(() => {
    // todo
    if (!kakaoMap.loaded || kakaoMap.error) return;

    if (!pinMap) {
      console.warn('[핀선택지도][선택핀변경] 지도가 아직 초기화되지 않았습니다.');
      if (circle.current) circle.current.setMap(null);
      return undefined;
    }

    // 선택된 핀의 좌표정보
    const {
      location: { latitude, longitude }
    } = selectedPin;

    // console.log('- 선택된 핀 좌표: ', latitude, longitude);
    const pinPosition = new kakao.maps.LatLng(latitude, longitude);

    // 그려진 반경원(circle)이 없을 경우 생성
    if (circle.current === null) {
      circle.current = new kakao.maps.Circle({
        map: pinMap,
        strokeWeight: 0, // 선의 두께입니다
        strokeColor: '#8629ff', // 선의 색깔입니다
        strokeOpacity: 0.0, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
        strokeStyle: 'solid', // 선의 스타일입니다
        fillColor: '#8629ff', // 채우기 색깔입니다
        fillOpacity: 0.15, // 채우기 불투명도입니다
        // center: pinPosition,
        radius: 1000
      });
    }

    // 반경원 위치 지정
    circle.current.setPosition(pinPosition);
  }, [kakaoMap.loaded, kakaoMap.error, selectedPin, pinMap]);

  React.useEffect(() => {
    // todo
    if (!kakaoMap.loaded || kakaoMap.error) return;

    if (pinMapDivRef.current) {
      if (pinMap == null) {
        if (userLocation.coord) {
          console.debug(`%cwineone`, DEV.CONSOLE.LABEL_STYLE, `카카오지도 초기화 시작`);
          // 지도 초기화
          initializeKakaoMap(userLocation.coord.lat, userLocation.coord.lng);
        } else {
          console.debug('현재 위치가 아직 파악되지 않았습니다.', geolocation);
          if (geolocation.initialized && typeof geolocation.latitude !== 'undefined') {
            console.debug('현재 실제 위치를 중심으로 지도를 그립니다.', geolocation);
            setCoordinate({ lat: geolocation.latitude, lng: geolocation.longitude });
            initializeKakaoMap(geolocation.latitude, geolocation.longitude);
          }
        }
      } else {
        console.debug(`%cwineone`, DEV.CONSOLE.LABEL_STYLE, `이미 초기화된 카카오지도가 있습니다.`);
      }
    }
  }, [kakaoMap.loaded, kakaoMap.error, pinMapDivRef, geolocation, userLocation]);

  React.useEffect(() => {
    if (!kakaoMap.loaded || kakaoMap.error) return;

    if (pinMap) {
      // 맵 중심/줌 변경 이벤트 리스너 등록
      kakao.maps.event.addListener(pinMap, 'idle', mapBoundChanged);

      requestPinList(); // 위치핀 목록 조회
      requestVendorList(); // 입점사 목록 조회
    }
    return () => {
      try {
        if (pinMap) window.kakao?.maps?.event?.removeListener(pinMap, 'idle', mapBoundChanged);
      } catch (e) {
        /* DO NOTHING */
      }
    };
  }, [kakaoMap.loaded, kakaoMap.error, pinMap]);

  // 사용자 위치 변경됨
  React.useEffect(() => {
    // todo 에러처리
    if (!kakaoMap.loaded || kakaoMap.error) return;

    const { access, initialized, latitude, longitude } = geolocation;
    if (!initialized) console.debug('[핀선택지도] 사용자의 위치를 확인하는 중...');

    if (access) {
      // 사용자 현 위치마커 표현
      if (userMarker.current === null) {
        userMarker.current = new kakao.maps.Marker({
          image: CURRENT_USER_MARKER
        });
      }

      // 마커 위치지정
      userMarker.current.setPosition(new kakao.maps.LatLng(latitude, longitude));
      if (pinMap) {
        userMarker.current.setMap(pinMap);
      }
    } else {
      console.warn('[핀선택지도] 사용자의 위치정보에 접근할 수 없습니다.');
    }

    return () => {
      if (userMarker.current) {
        userMarker.current.setMap(null);
        userMarker.current = null;
      }
    };
  }, [kakaoMap.loaded, kakaoMap.error, pinMap, geolocation]);

  // 맵 중심이 변경될 때마다 주변 핀과 입점샵 정보를 불러옴
  React.useEffect(() => {
    if (pinMap !== null) {
      requestVendorList();
      requestPinList();
    }
  }, [coordinate, selectedPin]);

  const requestPinList = async () => {
    console.debug(`%cwineone`, DEV.CONSOLE.LABEL_STYLE, `핀 목록조회를 시작합니다...`);
    // 지도영역 확인
    const bounds = pinMap.getBounds();
    // 지도영역의 대각선 거리 구하기
    const coordNE = bounds.getNorthEast();
    const coordSW = bounds.getSouthWest();

    // 지도 꼭지점 위도경도
    const squarePoint = [
      `${coordSW.getLng()} ${coordNE.getLat()}`,
      `${coordNE.getLng()} ${coordNE.getLat()}`,
      `${coordNE.getLng()} ${coordSW.getLat()}`,
      `${coordSW.getLng()} ${coordSW.getLat()}`
    ];

    // console.log('#options: ', options);
    await getPinMapList(squarePoint).then((results) => {
      console.debug(`%cwineone`, DEV.CONSOLE.LABEL_STYLE, `주변 위치핀 검색결과 => `, results);
      const { result, data } = results;

      if (result.code !== 0) {
        const { msg } = result;
        console.error('주변 핀 검색중 에러.', msg);

        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `주변 핀 검색중 에러`,
          msg: `[uid=${user?._id ?? 'anonymous'}] ${JSON.stringify(msg)}`,
          which: `${location.pathname}${location.search}`,
          param: {
            uid: user._id,
            msg
          }
        })
          .then(console.log)
          .catch(console.error);

        return undefined;
      }

      data.forEach((result) => {
        // 핀 아이디
        const pin_id = result.id;

        // 카카오 마커 객체
        let pinMarker = pinMarkerObj.current[pin_id];

        // 기존 마커가 없을 경우
        let isNewMarker = false;

        if (!pinMarker) {
          isNewMarker = true;
          pinMarker = new kakao.maps.Marker({
            clickable: true,
            zIndex: 2
          });
        }

        // 핀 위치
        // Vendor marker location
        const lat = result.location.y;
        const lng = result.location.x;
        const pinPosition = new kakao.maps.LatLng(lat, lng);

        // 조회된 핀이 현재 사용자가 사용하는 핀
        if (userLocation.currentPin && userLocation?.currentPin?.pin_id === pin_id) {
          // console.log('- 현재 사용자의 핀입니다.', pin_id, selectedPin.pin_id);

          // 사용자의 현재 위치핀이면서 선택된 상태

          if (pin_id === selectedPin.pin_id) {
            pinMarker.setImage(CURRENT_PIN_MARKER);
          } else {
            pinMarker.setImage(CURRENT_PIN_NOR_MARKER);
          }
        } else {
          const markerImg = pin_id === selectedPin?.pin_id ? SELECT_PIN_MARKER : OTHER_PIN_MARKER;
          pinMarker.setImage(markerImg);
        } // end of 현재 사용자가 사용하는 핀

        // 핀 이름
        const { name } = result;

        pinMarker.setTitle(name);

        pinMarker.setPosition(pinPosition); // 핀 좌표
        pinMarker.setMap(pinMap);
        pinMarker.setClickable(true);

        // 위치핀 마커 클릭시
        const markerClicked = () => {
          console.debug('[1KMWINE] 위치 핀이 선택되었습니다.');
          if (scriptedRef.current) {
            // 다른 핀들 미선택, 현재위치 상태로 만들기
            Object.keys(pinMarkerObj.current).forEach((pinId) => {
              const oldMarker = pinMarkerObj.current[pinId];

              if (userLocation?.currentPin?.pin_id === pinId) {
                // oldMarker.setImage(CURRENT_PIN_MARKER);
                if (pinId === pin_id) {
                  oldMarker.setImage(CURRENT_PIN_MARKER);
                } else {
                  oldMarker.setImage(CURRENT_PIN_NOR_MARKER);
                }
              } else {
                oldMarker.setImage(OTHER_PIN_MARKER);
              }
            });

            pinMap.panTo(pinPosition);

            if (userLocation?.currentPin?.pin_id === pin_id) {
              pinMarker.setImage(CURRENT_PIN_MARKER);
            } else {
              pinMarker.setImage(SELECT_PIN_MARKER);
            }

            setSelectedPin(() => ({
              address: result.address,
              location: { lat, lng, latitude: Number(lat), longitude: Number(lng) },
              name,
              pin_id
            }));
          }
        };

        // try {
        //   console.log('--- 기존 클릭이벤트 제거');
        //   kakao.maps.event.removeListener(pinMarker, 'click', markerClicked);
        // } catch (err) {
        //   console.warn(pin_id, '기존 클릭이벤트 제거 실패', err);
        // }

        if (scriptedRef.current) {
          if (isNewMarker) {
            kakao.maps.event.addListener(pinMarker, 'click', markerClicked);
          }
          // pinMarkerObj[pin_id] = pinMarker;
          // setPinMarkerObj({ ...pinMarkerObj });
          pinMarkerObj.current[pin_id] = pinMarker;
          pinMarkerObj.current = { ...pinMarkerObj.current };
        }
      });
    });
  };

  const requestVendorList = async () => {
    console.debug(`%cwineone`, DEV.CONSOLE.LABEL_STYLE, `벤더 목록조회를 시작합니다...`);
    // 지도영역 확인
    const bounds = pinMap.getBounds();
    // 지도영역의 대각선 거리 구하기
    const coordNE = bounds.getNorthEast();
    const coordSW = bounds.getSouthWest();

    // 지도 꼭지점 위도경도
    const squarePoint = [
      `${coordSW.getLng()} ${coordNE.getLat()}`,
      `${coordNE.getLng()} ${coordNE.getLat()}`,
      `${coordNE.getLng()} ${coordSW.getLat()}`,
      `${coordSW.getLng()} ${coordSW.getLat()}`
    ];

    await getVendorMapList(squarePoint).then((resultList) => {
      console.debug(`%cwineone`, DEV.CONSOLE.LABEL_STYLE, `주변 입점사 검색결과 => `, resultList);
      const { result, data } = resultList;

      if (result.code !== 0) {
        const { msg } = result;
        console.error('주변 입점샵 검색중 에러.', msg);

        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `주변 입점샵 검색중 에러`,
          msg: `[uid=${user?._id ?? 'anonymous'}] ${JSON.stringify(msg)}`,
          which: `${location.pathname}${location.search}`,
          param: {
            uid: user._id,
            msg
          }
        })
          .then(console.log)
          .catch(console.error);

        return undefined;
      }

      data.forEach((result) => {
        // vendor id
        const vendorId = result._id;

        // vendor marker object
        let vndMarker = vndMarkerObj[vendorId];
        if (!vndMarker) {
          vndMarker = new kakao.maps.Marker({
            clickable: false,
            zIndex: 1
          });
        } else {
          console.debug(`[marker] 기존 vendor marker[id=${vendorId}]를 제거.`);
          vndMarker.setMap(null);
        }

        // Vendor marker location
        const lat = result.biz__location.y;
        const lng = result.biz__location.x;
        const pinPosition = new kakao.maps.LatLng(lat, lng);

        vndMarker.setImage(new kakao.maps.MarkerImage(vendorOnIconImg, new kakao.maps.Size(32, 38)));

        // 입점샵 이름
        const name = result.biz__name;

        vndMarker.setTitle(name);

        vndMarker.setPosition(pinPosition);
        vndMarker.setMap(pinMap);
        vndMarker.setClickable(false);

        if (scriptedRef.current) {
          vndMarkerObj[vendorId] = vndMarker;
          setVndMarkerObj({ ...vndMarkerObj });
        }
      });
    });
  };

  // 위치 설정 완료 콜백
  const locationUpdateSuccess = React.useCallback(
    (code) => {
      refresh(); // 로그인 사용자 정보 갱신

      // console.log('selectedPin: ', selectedPin);

      onPinConfirmed({ success: true, code });

      globalDispatch(
        openSnackbar({
          open: true,
          message: `위치 설정이 완료되었습니다.`,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          // close: true,
          autoHideDuration: 6000
        })
      );
    },
    [selectedPin]
  );

  // '이 위치로 설정' 버튼 클릭
  const confirmPin = () => {
    // 이미 지정된 핀
    if (selectedPin?.pin_id === userLocation.currentPin?.pin_id) {
      WoAlert.fire('', `현재 지정된 위치핀 입니다.`, 'warning');
      return false;
    }

    // console.log('------- changeLeftCount: ', changeLeftCount);

    // 핀변경 보조 안내 메시지
    // let chagneLeftCountMessage;
    // if (changeLocationLimit === changeLeftCount) {
    //   chagneLeftCountMessage = `핀은 하루 ${changeLocationLimit.toLocaleString()}번 변경 가능해요.`;
    // } else if (changeLeftCount === 1) {
    //   chagneLeftCountMessage = `오늘 마지막 변경이에요 (오늘 밤 12시 해제)`;
    // } else {
    //   chagneLeftCountMessage = `하루 ${changeLocationLimit.toLocaleString()}번 중
    //   ${(pinLogSize + 1).toLocaleString()}번째 변경이에요
    //   (${changeLeftCount}번 남음)`;
    // }

    if (userLocation.currentPin) {
      WoAlert.fire({
        title: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={locationIconImg} width="35px" height="35px" alt="" style={{ marginRight: '5px' }} />
            위치 변경
          </div>
        ),
        // html: `<div style='text-align: left'>위치 재설정 시 기존 위치에서 장바구니에 담은 상품이 삭제됩니다.<br />위치를 변경할까요?</div>`,
        // html: `<div style='text-align: left'>선택하신 핀으로 위치를 변경할까요?<br />${chagneLeftCountMessage}</div>`,
        html: `<div style='text-align: left'>선택하신 핀으로 위치를 변경할까요?</div>`,
        showCancelButton: true,
        customClass: {
          confirmButton: 'max-50',
          cancelButton: 'max-50'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          console.debug('[1KMWINE] 사용자의 위치핀정보 업데이트 시작', selectedPin);
          globalDispatch(updateUserLocation(USER_LOCATION_TYPE.PIN, selectedPin.pin_id))
            .then(({ success, code, error }) => {
              console.debug('사용자의 위치핀정보 업데이트 완료', success, code, error);
              if (success) {
                locationUpdateSuccess(code);
              } else {
                // todo 발생가능한 오류 처리
                // console.debug('##------------ code: ', code);
                // console.debug('##------------ code: ', error);
                WoAlert.fire(`핀정보 변경 중 오류가 발생했습니다.\n${error}`, '', 'error');
              }
            })
            .catch((error) => {
              console.debug('사용자의 위치핀정보 업데이트 에러');
              // todo onPinConfirmed 사용하지말고 에러 동작 처리 및 CLO
              onPinConfirmed({ success: false, error });
            });
        }
      });
    } else {
      console.debug('[최초등록] 사용자의 위치핀정보 업데이트 시작', selectedPin);
      globalDispatch(updateUserLocation(USER_LOCATION_TYPE.PIN, selectedPin.pin_id))
        .then(({ success, code, error }) => {
          console.debug('사용자의 위치핀정보 업데이트 완료', success, code, error);
          if (success) {
            onPinConfirmed({ success: true, code });
          } else {
            // todo 발생가능한 오류 처리
            // console.debug('##------------ code: ', code);
            // console.debug('##------------ code: ', error);
            WoAlert.fire('', `핀정보 변경 중 오류가 발생했습니다.<br/>${error}`, 'error');
          }
        })
        .catch((error) => {
          console.debug('사용자의 위치핀정보 업데이트 에러');
          onPinConfirmed({ success: false, error });
        });
    }
  };

  // 핀 위치변경 가능여부
  const changeAvailable = React.useMemo(() => {
    // 선택한 핀이 없을 경우
    if (selectedPin == null) {
      return false;
    }

    // 선택한 핀이 현재 사용자의 핀일 경우
    if (selectedPin?.pin_id === userLocation.currentPin?.pin_id) {
      return false;
    }

    return true;
  }, [userLocation, selectedPin]);

  if (kakaoMap.loaded && kakaoMap.error) {
    return (
      <Box id="pin-map-container" width={width} height={height} position={'relative'}>
        {/* 지도영역 */}
        <div style={{ width, height }}>
          <Box width={'100%'} height={'100%'} display={'flex'} flexDirection="column" justifyContent={'center'} alignItems={'center'}>
            <Typography variant={'caption'} textAlign="center">
              지도를 불러오는 중 오류가 발생했습니다.
              <br />
              <span>[Kakao map SDK error]</span>
            </Typography>
          </Box>
        </div>
      </Box>
    );
  }

  // render PinMap
  return (
    <Box id="pin-map-container" width={width} height={height} position={'relative'}>
      {/* 지도영역 */}
      <div id="pin-map" ref={pinMapDivRef} style={{ width, height }}>
        {pinMap == null && (
          <Box width={'100%'} height={'100%'} display={'flex'} flexDirection="column" justifyContent={'center'} alignItems={'center'}>
            <WineBottleLoadingLottie />
            <Typography variant={'caption'}>지도를 불러오는 중 입니다</Typography>
          </Box>
        )}
      </div>

      {/* 하단 액션영역 */}
      {pinMap != null && (
        <BottomContainer>
          <BottomContents>
            <Box position="absolute" right={0} top="-64px">
              <Zoom in={geolocation.access} appear={navigationType === 'PUSH'}>
                <Fab
                  color="white"
                  onClick={() => {
                    console.debug('[핀지도] 사용자의 위치로 이동합니다.', geolocation.latitude, geolocation.longitude);
                    const pinPosition = new kakao.maps.LatLng(geolocation.latitude, geolocation.longitude);
                    pinMap.panTo(pinPosition);
                  }}
                  aria-label="위치설정 닫기"
                  sx={{ boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.16)' }}
                >
                  <CurrentLocationIcon color={theme.palette.text.primary} />
                </Fab>
              </Zoom>
            </Box>
            {selectedPin === null ? (
              <Typography color={'text.secondary'}>위치 핀을 선택해주세요</Typography>
            ) : (
              <Box>
                <Typography component="span" fontSize="18px" fontWeight={800} lineHeight={1}>
                  {selectedPin.name}
                </Typography>
                <Box display={'flex'} alignItems={'center'} height="24px" mt="7px">
                  <AddrChip />
                  <Typography component="span" fontSize="13px">
                    {selectedPin.address}
                  </Typography>
                </Box>
                {/* <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}> */}
                {/*   <CheckIcon width={'15px'} height={'15px'} color="#e66352" /> */}
                {/*   <Typography component="span" fontSize="12px" color="#e66352"> */}
                {/*     위치 핀은 하루에 {changeLocationLimit.toLocaleString()}회 변경할 수 있어요. (남은 횟수 {changeLeftCount}회) */}
                {/*   </Typography> */}
                {/* </Box> */}
              </Box>
            )}
            {onPinConfirmed && (
              <Box mt="14px">
                <PinConfirmButton onClick={confirmPin} disabled={!changeAvailable}>
                  {changeAvailable ? (
                    // <span style={{ fontWeight: 800 }}>현재 위치로 설정하기</span>
                    <Box fontWeight={800} width="100%" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                      선택한 곳으로 위치를 설정하기
                    </Box>
                  ) : (
                    '현재 설정된 핀'
                  )}
                </PinConfirmButton>
              </Box>
            )}
          </BottomContents>
        </BottomContainer>
      )}

      {/* 지도를 움직여 위치를 선택해보세요. */}
      {pinMap != null && !moved && (
        <Box
          className="blink_80"
          position="absolute"
          top="50%"
          left="50%"
          p="10px 20px"
          // width={280}
          width="calc(100% - 40px)"
          maxWidth={335}
          zIndex={2000}
          sx={{ transform: 'translate(-50%, 5px)' }}
          bgcolor="#43187bcc"
          borderRadius="22px"
        >
          <Typography width="100%" textAlign="center" color="#fff">
            지도를 움직이거나, 핀을 선택해 위치를 설정해주세요.
          </Typography>
        </Box>
      )}

      <Backdrop open={userLocation.loading} invisible sx={{ zIndex: 2000, height }}>
        <Box
          color="text.secondary"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bgcolor={alpha(theme.palette.background.paper, 0.75)}
          p={4}
          borderRadius={theme.shape.borderRadius}
        >
          <CircularProgress size={40} />
          <Box mt={2}>
            <Typography variant="body1" color="brand.main">
              위치정보를 처리 중 입니다
            </Typography>
          </Box>
        </Box>
      </Backdrop>
    </Box>
  );
}

PinMap.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onPinConfirmed: PropTypes.func
};

export default PinMap;

/* eslint-disable react/prop-types */
const BottomContainer = ({ children }) => (
  <Box position="fixed" bottom="34px" left={0} width="100vw" height={BOTTOM_COMPONENT_HEIGHT} zIndex={1}>
    {children}
  </Box>
);

/* eslint-disable react/prop-types */
const BottomContents = ({ children }) => (
  <Box
    display="flex"
    position="relative"
    flexDirection="column"
    justifyContent="space-between"
    className="map-bottom-component"
    bgcolor="background.paper"
    borderRadius="14px"
    width="100%"
    maxWidth={335}
    height="auto"
    p="24px 14px 14px"
    mx="auto"
    boxShadow="0 4px 10px 0 rgba(0, 0, 0, 0.16)"
  >
    {children}
  </Box>
);

const AddrChip = () => (
  <Chip
    label="지번"
    sx={{
      fontSize: '13px',
      bgcolor: 'rgba(20,2,41,0.07)',
      borderRadius: '3px',
      height: '24px',
      mr: 1,
      px: '5px',
      '& .MuiChip-label': {
        p: 0,
        lineHeight: 1,
        color: '#140229'
      }
    }}
  />
);

/* eslint-disable react/prop-types */
const PinConfirmButton = ({ children, onClick, disabled }) => (
  <Button
    fullWidth
    variant="contained"
    color="brand"
    onClick={onClick}
    disabled={disabled}
    sx={{ height: '50px', borderRadius: '5px', fontSize: '17px' }}
  >
    {children}
  </Button>
);
