import React from 'react';
import { useNavigate, useNavigationType } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import { useLocationState } from 'react-router-use-location-state';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { getTypeByValue, PDATA_CATEGORY } from 'config';
import { useAuth, useCode, useScriptRef } from 'hooks';
import { WoAlert } from 'utils/kmwine-alerts';
import { openSnackbar } from 'store/slices/snackbar';
import { getPdataInfoData } from 'services/PdataService';
import { blockUser } from 'services/UserService';
import { getReviewThreadList } from 'services/ReviewThreadService';
import { ProductImage } from '../product';
import TasteOn from '../../views/mall/wine/WineInfo/components/InfoView/components/assets/ico_taste_on.svg';
import TasteOff from '../../views/mall/wine/WineInfo/components/InfoView/components/assets/ico_taste_off.svg';
import TasteDivision from '../../views/mall/wine/WineInfo/components/InfoView/components/assets/ico_taste_level_division.svg';
import { AromaChip } from '../widgets/wine';
import {
  ReviewImageViewer,
  ReviewReportDrawer,
  ReviewThreadCard,
  ReviewThreadEmpty,
  ReviewThreadError,
  ReviewUpdateDrawer
} from 'views/home/HomeIndex/components/ReviewThread/components';
import ReviewReportDialog from 'views/mall/wine/WineInfo/components/ReviewView/components/ReviewReportDialog';
import { ReviewWriteBtn, ReviewWritePurpleBtn, UserBlockIcon } from '../icons';
import { WineBottleLoadingLottie } from '../spinner';

// material-ui
import {
  Box,
  ButtonBase,
  Collapse,
  Container,
  Grid,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
  Fade,
  CircularProgress,
  AlertTitle,
  Dialog,
  Slide,
  IconButton,
  AppBar,
  Toolbar
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/styles';
import { alpha, styled } from '@mui/material/styles';

// assets
import QuoteLeft from 'assets/images/one_line_review_left _double quotation mark.svg';
import QuoteRight from 'assets/images/one_line_review_right_double quotation mark.svg';

// pdata 상세 정보 dialog border radius
const DIALOG_BORDER_RADIUS = '24px';

const { WINE, WHISKEY } = PDATA_CATEGORY;

// 리뷰 정렬 옵션
const REVIEW_SORT_OPTIONS = [
  {
    label: '최신순',
    value: 'new'
  },
  {
    label: '높은 평점순',
    value: 'high'
  },
  {
    label: '낮은 평점순',
    value: 'low'
  }
];

// pdata 메인 이미지 세로 크기
const MAIN_IMG_HEIGHT = 289;
// pdata 리뷰 이미지 가로 크기
const REVIEW_IMG_WIDTH = 44;
// pdata 리뷰 이미지 세로 크기
const REVIEW_IMG_HEIGHT = 60;

// 페이지네이트 기본정보
const initialPage = Object.freeze({
  current: 1,
  total_pages: 0,
  total_results: 0,
  size: 10 // 한번에 호출할 상품 개수
});

const initialRowState = Object.freeze({
  loaded: false,
  page: initialPage,
  items: [],
  empty: false
});

/**
 * pdata 상세보기
 * @returns {null}
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
const PdataInfo = ({ show, onClose, pdataId, moveToReview = false }) => {
  // 로그인 사용자 정보
  const { user } = useAuth();
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const theme = useTheme();

  const scriptedRef = useScriptRef();
  const globalDispatch = useDispatch();

  const { getCodeNameByValue } = useCode(); // woConstants.code에 담겨있는 값을 조회한다.

  // pdata info ref
  const pdataInfoRef = React.useRef(null);
  const [pdataInfoRefCombined, setPdataInfoRefCombined] = React.useState(false);
  // container ref
  const containerRef = React.useRef(null);
  // review section ref
  const reviewSectionRef = React.useRef(null);

  // pdata 상세 다이어로그 열림 닫힘
  const [open, setOpen] = React.useState(show);

  // 조회된 pdata
  const [pdata, setPdata] = React.useState(null);
  // pdata 상세보기 더보기 여부
  const [pdataMore, setPdataMore] = React.useState(false);

  // 리뷰 정렬 옵션
  const [reviewSort, setReviewSort] = React.useState(REVIEW_SORT_OPTIONS[0].value);
  // 조회된 리뷰 목록
  const [rows, setRows] = useLocationState('pdata_info', initialRowState);
  // 검색상태
  const [searchState, setSearchState] = React.useState({
    searched: rows.loaded,
    searching: false,
    error: false
  });

  // 수정/신고/차단할 리뷰
  const [selectReview, setSelectReview] = React.useState({ type: '', reviewId: '' });
  // 리뷰 수정 drawer
  const [reviewUpdateDrawer, setReviewUpdateDrawer] = React.useState(false);
  // 리뷰 신고/차단 drawer
  const [reviewReportDrawer, setReviewReportDrawer] = React.useState(false);
  // 리뷰 신고 modal
  const [reviewReportModal, setReviewReportModal] = React.useState(false);

  // 이미지 미리보기 인덱스 번호
  const [currentImage, setCurrentImage] = React.useState(0);
  // 이미지 뷰어 오픈 여부
  const [viewerOpen, setViewerOpen] = useLocationState('pdata_info_reviewOpen', false);
  // 이미지 미리보기 배열
  const [images, setImages] = React.useState([]);

  React.useEffect(() => {
    setOpen(() => show);
  }, [show]);

  React.useLayoutEffect(() => {
    if (!pdataId) {
      handleClose();
      return;
    }
    fetchPdata();
  }, [pdataId]);

  React.useEffect(() => {
    if (!show) return;
    setRows(initialRowState);
    fetchReviewList(1);
  }, [show, reviewSort, pdataId]);

  // 리뷰 영역으로 바로가기
  React.useEffect(() => {
    if (!open) return;
    if (reviewSectionRef?.current && moveToReview) {
      moveToReviewSection();
    } else {
      containerRef?.current?.scrollIntoView({
        inline: 'start',
        block: 'start'
      });
    }
  }, [open, moveToReview]);

  // pdata 조회
  const fetchPdata = async () => {
    setPdata(null);
    if (pdataId === null) return;
    const result = await getPdataInfoData(pdataId).catch((error) => ({ error }));

    if (!scriptedRef.current) {
      console.warn('[PdataInfo][fetchPdata] Unmounted component.');
      return undefined;
    }

    // pdata 조회 중 오류 발생
    if (result.error) {
      console.error('[PdataInfo][fetchPdata] pdata 조회 중 오류 발생', result.error);
      WoAlert.fire({
        title: '상품조회 오류',
        html: `<div style='line-height: 1.5rem; text-align: center'>상품정보 조회 중 오류가 발생했습니다.<br/>잠시 후 다시 시도해주십시오.</div>`,
        customClass: {
          confirmButton: 'max-100'
        },
        confirmButtonText: '확인'
      }).then(() => {
        navigate(-1);
      });
      return undefined;
    }

    const { code, msg, data } = result.result;

    // 잘못된 Response값이 반환됨
    if (code !== 0) {
      console.error('[PdataInfo][fetchPdata] 잘못된 Response값이 반환됨', msg);
      WoAlert.fire({
        title: '상품조회 오류',
        html: `<div style='line-height: 1.5rem; text-align: center'>상품정보 조회 중 오류가 발생했습니다.<br/>잠시 후 다시 시도해주십시오.</div>`,
        customClass: {
          confirmButton: 'max-100'
        },
        confirmButtonText: '확인'
      }).then(() => {
        navigate(-1);
      });
      return undefined;
    }

    const { review_star_avg } = data;
    // 리뷰 평균 평점 형변환
    if (typeof review_star_avg === 'string') {
      data.review_star_avg = parseFloat(review_star_avg);
    }

    setPdata(data);
  };

  // pdata 이미지 정보
  const pdataImgBox = React.useMemo(() => {
    if (!pdata) return <Skeleton width="100%" height={MAIN_IMG_HEIGHT} variant="rectangular" />;

    // vivino점수
    const vivino = pdata?.pdata_score?.vivino?.toFixed(1);

    return (
      <Box
        component="section"
        position="relative"
        className="pdata-img-wrapper"
        height={MAIN_IMG_HEIGHT}
        pb="12px"
        aria-label="상품 이미지"
      >
        <Box
          className="gradient-bg"
          width="100%"
          height={173}
          position="absolute"
          left={0}
          bottom={0}
          sx={{ backgroundImage: `linear-gradient(to bottom, #fff 33%, #f4f0f8)` }}
        />

        {/* vivino */}
        {pdata?.pdata_score?.vivino && vivino ? (
          <Box
            sx={{
              position: 'absolute',
              width: 76,
              height: 24,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1.5px solid #B92240',
              bgcolor: '#FFF',
              boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.16) inset',
              borderRadius: '12px',
              fontSize: 12,
              color: '#B92240',
              fontFamily: 'D-DIN',
              fontWeight: 700,
              lineHeight: 'normal',
              top: 20,
              left: 24
            }}
          >
            VIVINO {vivino}
          </Box>
        ) : null}

        <ProductImage
          width="100%"
          height="100%"
          visibleByDefault={navigationType !== 'PUSH'}
          src={pdata.bottle_img?.removebg}
          wrapperProps={{ sx: { backgroundColor: 'transparent', borderRadius: 'none' } }}
          alt="와인 이미지"
        />
      </Box>
    );
  }, [pdata]);

  // pdata 리뷰 조회
  const fetchReviewList = async (current) => {
    // 이미 요청 중
    if (current === 1 && searchState.searching) {
      console.warn('[ReviewThread][getReviewThread] 중복 요청입니다. rows: ', rows);
      return false;
    }

    if (!pdataId) return;

    // 검색 상태로 변경
    setSearchState({ searching: true, error: false });

    const body = {
      page: {
        current,
        size: rows.page.size
      },
      sort: reviewSort,
      type: 'pdataInfo',
      pdataId
    };

    const result = await getReviewThreadList(body).catch((error) => ({ error }));

    if (!scriptedRef.current) {
      console.warn('[PdataInfo][fetchReviewList] Unmounted component.');
      return undefined;
    }

    // 리뷰 쓰레드 목록 조회 중 오류 발생
    if (result.error) {
      setSearchState({ searched: true, searching: false, error: true });
      console.error('[PdataInfo][fetchReviewList] error: ', result.error);
      return undefined;
    }

    const { code, msg } = result.result;

    // 잘못된 Response 값이 반환됨.
    if (code !== 0) {
      setRows({ ...rows, error: true, loading: false });
      console.error('[PdataInfo][fetchReviewList] code: ', code, ', msg: ', msg);
      return undefined;
    }

    // 1페이지 요청 시 리스트 초기화
    const reviewThreads = current === 1 ? [...result.data] : [...rows.items, ...result.data];
    // 목록이 비어있을 경우
    const empty = current === 1 && result.data.length === 0;

    setRows({
      loaded: true,
      page: result.page,
      items: reviewThreads,
      empty
    });

    setSearchState({ searched: true, searching: false, error: false });
  };

  // 리뷰 섹션으로 이동
  const moveToReviewSection = () => {
    reviewSectionRef?.current?.scrollIntoView({
      behavior: 'smooth'
    });
  };

  // pdata에 작성된 리뷰 정보
  const pdataTotalReview = React.useMemo(() => {
    if (!pdata) return <Skeleton width={200} height={20} variant="rectangular" />;

    const { review_star_avg, writer_count } = pdata;

    return (
      <ButtonBase onClick={moveToReviewSection}>
        <Stack
          direction="row"
          spacing="4px"
          divider={
            <Box display="flex" justifyContent="center" alignItems="center" width="4px" height="20px">
              <Box height="13px" width="1.3px" bgcolor={alpha(theme.palette.text.primary, 0.1)} />
            </Box>
          }
        >
          <Stack direction="row" spacing="4px" alignItems="center">
            <Star20 />
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 700,
                lineHeight: 'normal',
                fontFamily: 'D-DIN'
              }}
            >
              {/* 리뷰 작성 정보가 있는 경우 평균 별점을 표시 */}
              {review_star_avg && writer_count ? review_star_avg?.toFixed(1) : '0.0'}
            </Typography>
          </Stack>
          <Box display="flex" alignItems="center" pl="2px">
            {/* 리뷰 작성 정보가 있는 경우 참여자 표시 */}
            {review_star_avg && writer_count ? (
              <Box display="flex" alignItems="center">
                <Typography sx={{ opacity: 0.7, lineHeight: 'normal' }}>리뷰&nbsp;</Typography>
                <Typography sx={{ opacity: 0.7, fontWeight: 700, lineHeight: 'normal' }}>{writer_count}</Typography>
                <Typography sx={{ opacity: 0.7, lineHeight: 'normal' }}>개</Typography>
              </Box>
            ) : (
              <Typography sx={{ opacity: 0.7, lineHeight: 'normal' }}>첫 리뷰를 작성 해보세요!</Typography>
            )}
            <ReviewWriteBtn />
          </Box>
        </Stack>
      </ButtonBase>
    );
  }, [pdata]);

  // 을/를 구분 함수
  const isEndWithConsonant = (korStr) => {
    const finalChrCode = korStr.charCodeAt(korStr.length - 1);
    // 0 = 받침 없음, 그 외 = 받침 있음
    const finalConsonantCode = (finalChrCode - 44032) % 28;
    return finalConsonantCode !== 0;
  };

  // 이 pdata를 마셔보셨나요?
  const pdataHaveYouTried = React.useMemo(() => {
    if (!pdata) return;
    const { pdata_category, myreview_star } = pdata;
    const { nickname } = user;

    let pdataType = PDATA_CATEGORY[pdata_category?.toUpperCase()]?.label?.ko;

    if (pdataType !== '와인' && pdataType !== '위스키' && pdataType !== '일본주') pdataType = '상품';

    pdataType = isEndWithConsonant(pdataType) ? `${pdataType}을` : `${pdataType}를`;

    // 내가 작성한 리뷰가 있는 경우
    if (myreview_star) {
      return (
        <Box>
          <Typography sx={{ fontSize: 14, fontWeight: 800, lineHeight: 'normal', mb: 1 }}>
            {nickname}님의 평점 {myreview_star?.toFixed(1)}
          </Typography>
          <Rating
            readonly
            allowFraction
            initialValue={myreview_star}
            emptyIcon={<PurpleEmptyStarIcon />}
            fillIcon={<PurpleFilledStarIcon />}
          />
          <Box display="flex" justifyContent="center" alignItems="center" mt="12px">
            <Typography sx={{ fontSize: 12, fontWeight: 700, lineHeight: 'normal' }}>리뷰 한번 더 작성하기</Typography>
            <ReviewWriteBtn width={12} height={12} />
          </Box>
        </Box>
      );
    }

    return (
      <Box>
        <Typography sx={{ fontSize: 14, fontWeight: 800, lineHeight: 'normal', mb: 1 }}>이 {pdataType} 마셔보셨나요?</Typography>
        <Rating readonly initialValue={0} emptyIcon={<PurpleEmptyStarIcon />} />
        <Box display="flex" justifyContent="center" alignItems="center" mt="12px">
          <Typography sx={{ fontSize: 12, fontWeight: 700, lineHeight: 'normal' }}>리뷰 작성하기</Typography>
          <ReviewWriteBtn width={12} height={12} />
        </Box>
      </Box>
    );
  }, [pdata]);

  // 와인 종류
  const wineTypeData = React.useMemo(() => {
    if (!scriptedRef.current) return null;

    return pdata?.pdata_type
      ? `${getTypeByValue(pdata.pdata_category, pdata.pdata_type)} (${getTypeByValue(pdata.pdata_category, pdata.pdata_type, 'en')})`
      : `알 수 없음`;
  }, [pdata?.pdata_category, pdata?.pdata_type]);

  // 생산지 단어 추출
  const pdataCountryPlaceStr = React.useCallback(
    (pdata) => {
      if (!pdata) return;
      let countryPlaceStrKo = getCodeNameByValue('country', pdata?.pdata_country?.id);

      if (pdata?.pdata_place1?.ko) countryPlaceStrKo += ` ${pdata?.pdata_place1.ko}`;
      if (pdata?.pdata_place2?.ko) countryPlaceStrKo += ` ${pdata?.pdata_place2.ko}`;

      const enCountries = [];
      if (pdata?.pdata_place2?.en) enCountries.push(pdata?.pdata_place2.en);
      if (pdata?.pdata_place1?.en) enCountries.push(pdata?.pdata_place1.en);
      enCountries.push(getCodeNameByValue('country', pdata?.pdata_country.id, 'en'));

      return { ko: countryPlaceStrKo, en: enCountries.join(', ') };
    },
    [getCodeNameByValue]
  );

  // 주요 품종
  const varietyData = React.useMemo(() => {
    if (!scriptedRef.current) return null;

    if (!pdata) return;
    const codeName = getCodeNameByValue('variety', pdata.pdata_variety?.id);

    return codeName ? `${codeName} (${getCodeNameByValue('variety', pdata.pdata_variety?.id, 'en')})` : null;
  }, [pdata?.pdata_variety, getCodeNameByValue]);

  // pdata review 합계 정보
  const pdataReviewTotalCard = React.useMemo(() => {
    if (!pdata) return null;

    const { review_star_avg, writer_count } = pdata;

    // 리뷰 작성자 수 포맷
    let writerCount;
    if (!writer_count) writerCount = 0;
    else if (writer_count > 99999) writerCount = '99,999+';
    else writerCount = writer_count?.toLocaleString();

    return (
      <Box mt="8px">
        <Stack direction="row" spacing="2px" alignItems="center">
          <Star20 width={16} height={16} />
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 700,
              lineHeight: 'normal',
              fontFamily: 'D-DIN',
              pl: '2px'
            }}
          >
            {/* 리뷰 작성 정보가 있는 경우 평균 별점을 표시 */}
            {review_star_avg && writer_count ? review_star_avg?.toFixed(1) : '0.0'}
          </Typography>
          {/* 리뷰 작성자 수 */}
          <Typography sx={{ fontSize: 12, fontWeight: 400, fontFamily: 'D-DIN', lineHeight: 'normal', color: 'rgba(20, 2, 41, 0.5)' }}>
            ({writerCount})
          </Typography>
        </Stack>

        {/* 리뷰 작성하러가기 */}
        <Box mt="2px" display="flex" justifyContent="flex-end" alignItems="center">
          <Typography sx={{ fontSize: 12, fontWeight: 700, lineHeight: 'normal', color: 'rgba(147, 87, 229, 0.7)' }}>
            {writer_count > 0 ? '리뷰 작성하기' : '첫 리뷰를 작성해 보세요!'}
          </Typography>
          <ReviewWritePurpleBtn />
        </Box>
      </Box>
    );
  }, [pdata]);

  // 선택한 리뷰가 수정 또는 신고/차단인지 확인
  const reviewAction = (type, reviewId) => {
    setSelectReview(reviewId);
    if (type === 'update') {
      setReviewUpdateDrawer(true);
    } else {
      setReviewReportDrawer(true);
    }
  };

  // 리뷰 신고 및 차단
  const reviewReport = (type) => {
    // 리뷰 차단
    if (type === 'block') {
      WoAlert.fire({
        title: (
          <Box display="flex" alignItems="center">
            <UserBlockIcon sx={{ mr: '5px' }} />
            <Typography component="div" fontSize={18} fontWeight="bold" textAlign="left">
              사용자 차단
            </Typography>
          </Box>
        ),
        html: `<div style='line-height: 1.5rem; text-align: left'>이 사용자를 차단 하시겠습니까?<br />사용자의 모든 콘텐츠가 숨김처리 됩니다.</div>`,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: '차단하기',
        cancelButtonText: '취소',
        customClass: {
          confirmButton: 'max-50',
          cancelButton: 'max-50'
        },
        preConfirm: () => {
          WoAlert.showLoading();
          return blockUser(selectReview.uid)
            .then((response) => {
              const { code, msg } = response.result;
              return { code, msg };
            })
            .catch((error) => {
              console.error('[1kmwine] 사용자 차단요청 실패', error);
              WoAlert.fire(`사용자 차단요청 중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.`, '', 'error');
              return { code: 99, msg: '사용자 차단요청 중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.' };
            });
        }
      }).then((result) => {
        if (!result.isConfirmed) return undefined;
        const { code, msg } = result.value;

        // 차단 성공
        if (code === 0 || code === 3) {
          // 차단 성공 후 리뷰 쓰레드 배열 재정렬
          setRowItems('block');
          globalDispatch(
            openSnackbar({
              open: true,
              message: (
                <>
                  <AlertTitle>사용자 차단 완료</AlertTitle>
                  <Typography variant="caption">
                    사용자가 차단 되었습니다. 차단 해제는 '마이 페이지' > '앱 설정'에서 설정 가능합니다.
                  </Typography>
                </>
              ),
              variant: 'alert',
              alert: {
                color: 'success'
              },
              close: true,
              autoHideDuration: 6000
            })
          );
        }

        // 작성자 차단 실패
        else {
          globalDispatch(
            openSnackbar({
              open: true,
              message: (
                <>
                  <AlertTitle>사용자 차단 오류</AlertTitle>
                  <Typography variant="caption">
                    {/* 사용자가 차단 되었습니다. 차단 해제는 '마이 페이지' > '앱 설정'에서 설정 가능합니다. */}
                    {msg}
                  </Typography>
                </>
              ),
              variant: 'alert',
              alert: {
                color: 'error'
              },
              close: true,
              autoHideDuration: 4000
            })
          );
        }
      });
    }

    // 리뷰 신고
    else if (type === 'report') {
      setReviewReportModal(true);
    }
  };

  // 리뷰 신고 및 차단 후 리뷰 쓰레드 배열 재정렬 (신고/차단 데이터 제외 후 표현)
  const setRowItems = async (type = 'report') => {
    // 표시 안할 리뷰 id
    const { review_id, uid } = selectReview;

    let tempItems = [];
    // 신고 (단일 블러 처리)
    if (type === 'report') {
      tempItems = rows.items.map((item) => {
        if (item.review_id === review_id) {
          item.blur = true;
          item.blur_type = 'report';
        }
        return item;
      });
    }
    // 차단 (복수 블러 처리)
    else if (type === 'block') {
      tempItems = rows.items.map((item) => {
        if (item.uid === uid) {
          item.blur = true;
          item.blur_type = 'block';
        }
        return item;
      });
    }

    await setRows({ ...rows, items: tempItems });
    await setReviewReportDrawer(false);
  };

  const handleThumbnailClick = (images, index) => {
    setImages(images);
    setCurrentImage(index);
    setViewerOpen(true, { method: 'push' });
  };

  // 이미지 뷰어 닫기
  const closeImageViewer = () => {
    setCurrentImage(0);
    setViewerOpen(false);
  };

  // pdata 상세 정보 다이어로그 닫기
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    if (onClose) {
      setSearchState({ searched: false, searching: false, error: false });
      try {
        onClose();
      } catch (e) {
        console.error('[PdataInfo.jsx] 다이어로그 닫기 콜백실행 오류.', e);
      }
    } else {
      setOpen(() => false);
      navigate(-1);
    }
  };

  // 스크롤 디렉션
  const [y, setY] = React.useState(window.scrollY);

  // 스크롤이벤트
  const handleScrollEvent = React.useCallback(
    (e) => {
      const { scrollTop, offsetHeight } = e.currentTarget;

      const containerOffsetHeight = pdataInfoRef.current.children[1].offsetHeight - 180;

      if (y < scrollTop) {
        if (scrollTop + offsetHeight >= containerOffsetHeight) {
          if (!searchState.searched) return; // 최초 조회시 중복요청 제외
          if (searchState.searching) return; // 페이지 요청 중일 경우 pass
          // 다음 페이지 호출
          if (rows.page.current < rows.page.total_pages) {
            console.log(`[PdataInfo][handleScrollEvent] 다음 리뷰 쓰레드 페이지 호출 (${rows.page.current + 1})`, rows.page);
            fetchReviewList(rows.page.current + 1);
          }
          // 이미 검색결과를 모두 보여준 상태
          else {
            console.log('[1KMWINE] 모든 리뷰 쓰레드를 출력했습니다.');
          }
        }
      }

      setY(window.scrollY);
    },
    [y, rows.loaded, searchState]
  );

  // 스크롤 감시
  React.useEffect(() => {
    if (pdataInfoRefCombined) {
      setY(pdataInfoRef.current.scrollTop);
      pdataInfoRef.current.addEventListener('scroll', handleScrollEvent);
    }

    return () => {
      try {
        pdataInfoRef.current.removeEventListener('scroll', handleScrollEvent);
      } catch (e) {
        /* DO NOTHING */
      }
    };
  }, [handleScrollEvent]);

  // render
  return (
    <Dialog
      fullScreen
      keepMounted
      scroll="paper"
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      sx={{ top: '53px' }}
      PaperProps={{
        style: { borderTopLeftRadius: DIALOG_BORDER_RADIUS, borderTopRightRadius: DIALOG_BORDER_RADIUS },
        ref: pdataInfoRef
      }}
    >
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          borderTopLeftRadius: DIALOG_BORDER_RADIUS,
          borderTopRightRadius: DIALOG_BORDER_RADIUS,
          overflow: 'hidden',
          height: 'auto',
          minHeight: 'auto'
        }}
      >
        <Toolbar sx={{ height: 72 }}>
          <Box position="absolute" width="100%" left={0} display="flex" justifyContent="center" alignItems="center">
            <Typography component="div" fontSize={18} fontWeight={800} maxWidth={'150px'} noWrap>
              상품 상세 정보
            </Typography>
          </Box>
          <IconButton className="DialogTitleCloseBtn" onClick={handleClose} sx={{ width: 34, height: 34 }}>
            <CloseIcon />
          </IconButton>
          <Box flexGrow={1} />
        </Toolbar>
      </AppBar>
      <Container
        sx={{ pt: 1, scrollMarginTop: '72px', px: '20px' }}
        ref={(ref) => {
          setPdataInfoRefCombined(true);
          containerRef.current = ref;
        }}
      >
        {/* 상단 ~ 한줄 설명 영역 */}
        <Box component="section" aria-label="상품 기본 정보">
          {/* 청소년 유의 안내 */}
          <BuyNotice />

          {/* pdata 이미지 */}
          <Box mt="12px" mx="-20px">
            {pdataImgBox}
          </Box>
          <Box width="100%" textAlign="center" py="12px">
            <Typography sx={{ color: 'rgba(20, 2, 41, 0.7)', fontSize: 10, fontWeight: 400, lineHeight: 'normal' }}>
              ※ 본 상품의 이미지는 실제와 다를 수 있습니다.
            </Typography>
          </Box>

          {/* 와인명 */}
          <Box mt="20px">
            <Box component="section" aria-label="상품명">
              <Typography fontSize="20px" fontWeight="900" component="h1" sx={{ color: theme.palette.text.primary, lineHeight: '1.4' }}>
                {pdata?.name_ko ?? <Skeleton />}
              </Typography>
              <Typography
                marginTop="8px"
                textAlign="left"
                fontSize="12px"
                fontWeight="400"
                sx={{ color: theme.palette.text.primary, opacity: '.4', lineHeight: '1.4' }}
              >
                {pdata?.name_en ?? <Skeleton />}
              </Typography>
            </Box>
          </Box>

          {/* 작성된 리뷰 정보 */}
          <Box mt="20px">{pdataTotalReview}</Box>

          {/* 한줄리뷰 */}
          <Box component="section" display="flex" justifyContent="left" minHeight={45} mt="32px" mb="20px" aria-label="한줄 리뷰">
            <Box display="flex" width="100%" bgcolor="#F3EFF7" px="20px" borderRadius="12px">
              <Box width="20px" marginTop="12px" height="15px" component="img" src={QuoteLeft} />
              <Box px="16px" py="12px" width="calc(100% - 40px)" display="flex" justifyContent="center">
                <Typography fontSize="14px" color={theme.palette.text.primary} fontWeight="450">
                  {pdata?.desc1}
                </Typography>
              </Box>
              <Box alignSelf="flex-end" marginBottom="12px" width="20px" height="15px" component="img" src={QuoteRight} />
            </Box>
          </Box>
        </Box>

        <SectionArea />

        {/* 이 pdata를 마셔보셨나요? */}
        <Box mx="-16px" aria-label="리뷰 유도">
          <ButtonBase
            component="section"
            sx={{ width: '100%', py: '24px', textAlign: 'center' }}
            onClick={() => navigate(`/my/review/${pdataId}/write`)}
          >
            {pdataHaveYouTried}
          </ButtonBase>
        </Box>

        <SectionArea />

        {/* pdata 정보 */}
        <Box component="section" mt="36px" aria-label="상품 정보">
          <InfoSectionTitle>Information</InfoSectionTitle>

          {/* pdata Information */}
          <Box marginY="20px" marginLeft="4px">
            {/* 종류 */}
            {pdata?.pdata_category === WINE.value && wineTypeData ? (
              <Box display="flex" marginBottom="12px">
                <InfoSectionSubTitle width="70px" minWidth="70px" fontSize="14px" fontWeight="600">
                  종류
                </InfoSectionSubTitle>
                <InfoSectionSubTitle>{wineTypeData}</InfoSectionSubTitle>
              </Box>
            ) : null}

            {/* 생산자 */}
            {pdata?.pdata_producer?.ko ? (
              <Box display="flex" marginBottom="12px">
                <InfoSectionSubTitle width="70px" minWidth="70px" fontSize="14px" fontWeight="600">
                  생산자
                </InfoSectionSubTitle>
                <Grid container columnSpacing="0.25rem">
                  <Grid item xs="auto" maxWidth="100%">
                    <InfoSectionSubTitle fontSize="14px">{pdata.pdata_producer.ko}</InfoSectionSubTitle>
                  </Grid>
                  {pdata.pdata_producer?.en && (
                    <Grid item xs="auto" maxWidth="100%">
                      <InfoSectionSubTitle fontSize="14px">({pdata.pdata_producer.en})</InfoSectionSubTitle>
                    </Grid>
                  )}
                </Grid>
              </Box>
            ) : null}

            {/* 생산 국가 */}
            <Box display="flex" mb="12px">
              <InfoSectionSubTitle width="70px" minWidth="70px" fontSize="14px" fontWeight="600">
                생산 국가
              </InfoSectionSubTitle>
              <Grid container columnSpacing="0.25rem">
                <Grid item xs="auto" maxWidth="100%">
                  <InfoSectionSubTitle>{pdataCountryPlaceStr(pdata)?.ko}</InfoSectionSubTitle>
                </Grid>
                {pdataCountryPlaceStr(pdata)?.en ? (
                  <Grid item xs="auto" maxWidth="100%">
                    <InfoSectionSubTitle>({pdataCountryPlaceStr(pdata)?.en})</InfoSectionSubTitle>
                  </Grid>
                ) : null}
              </Grid>
            </Box>

            {/* 품종 */}
            {pdata?.pdata_category === WINE.value && varietyData ? (
              <Box display="flex">
                <InfoSectionSubTitle width="70px" minWidth="70px" fontSize="14px" fontWeight="600">
                  품종
                </InfoSectionSubTitle>
                <InfoSectionSubTitle>{varietyData}</InfoSectionSubTitle>
              </Box>
            ) : null}
          </Box>

          {/* pdata Taste */}
          {(pdata?.pdata_category === WINE.value || pdata?.pdata_category === WHISKEY.value) && pdata?.pdata_taste ? (
            <Box mt="40px">
              <InfoSectionTitle>Taste</InfoSectionTitle>
              <Box my="20px" pl="8px">
                <Stack spacing="12px">
                  <TasteItem label="당도" score={pdata?.pdata_taste?.sweet} />
                  <TasteItem label="산도" score={pdata?.pdata_taste?.acidity} />
                  <TasteItem label="바디" score={pdata?.pdata_taste?.body} />
                  <TasteItem label="탄닌" score={pdata?.pdata_taste?.tannin} />
                </Stack>
              </Box>
            </Box>
          ) : null}

          {/* pdata aroma */}
          <Box minWidth="330px" marginTop="40px">
            <Box display="flex" alignItems="center" justifyContent="center">
              <InfoSectionTitle>Aroma</InfoSectionTitle>
            </Box>

            <Box display="flex" flexWrap="wrap" rowGap="10px" marginTop="19px" marginLeft="8px">
              {(pdata?.aroma ?? []).map((pAroma, i) => (
                <AromaChip pAroma={pAroma} isLastIndex={pdata.aroma.length - 1 === i} index={i} key={`aromachip-${i}`} />
              ))}
            </Box>
          </Box>

          {/* pdata 더보기 */}
          <Collapse in={pdataMore}>
            {/* 전문가 평점 */}
            {pdata?.pdata_category === WINE.value ? (
              <Box component="section" mt="40px" aria-label="전문가 평점">
                <InfoSectionTitle>전문가 평점</InfoSectionTitle>
                <Box mt="14px" marginLeft="8px">
                  <Grid container spacing={0}>
                    <Grid item xs={2.5}>
                      <ScoreBox>
                        <ScoreTitle>RP</ScoreTitle>
                        <ScoreContent>
                          {pdata?.pdata_score?.rp ? (
                            <>
                              <Typography fontSize={13} fontWeight="bold" letterSpacing="-0.13px">
                                {`${pdata?.pdata_score?.rp}점`}
                              </Typography>
                              <Typography component="div" fontSize={11} letterSpacing="-0.13px" sx={{ opacity: 0.4 }}>
                                {pdata?.pdata_score?.rp_v}
                              </Typography>
                            </>
                          ) : (
                            <span style={{ color: theme.palette.text.secondary }}>-</span>
                          )}
                        </ScoreContent>
                      </ScoreBox>
                    </Grid>
                    <Grid item xs={2.5}>
                      <ScoreBox>
                        <ScoreTitle>WS</ScoreTitle>
                        <ScoreContent>
                          {pdata?.pdata_score?.ws ? (
                            <>
                              <Typography fontSize={13} fontWeight="bold" letterSpacing="-0.13px">
                                {`${pdata?.pdata_score?.ws}점`}
                              </Typography>
                              <Typography component="div" fontSize={11} letterSpacing="-0.13px" sx={{ opacity: 0.4 }}>
                                {pdata?.pdata_score?.ws_v}
                              </Typography>
                            </>
                          ) : (
                            <span style={{ color: theme.palette.text.secondary }}>-</span>
                          )}
                        </ScoreContent>
                      </ScoreBox>
                    </Grid>
                    <Grid item xs={2.5}>
                      <ScoreBox>
                        <ScoreTitle>JS</ScoreTitle>
                        <ScoreContent>
                          {pdata?.pdata_score?.js ? (
                            <Box>
                              <Typography fontSize={13} fontWeight="bold" letterSpacing="-0.13px">
                                {`${pdata?.pdata_score?.js}점`}
                              </Typography>
                              <Typography component="div" fontSize={11} letterSpacing="-0.13px" sx={{ opacity: 0.4 }}>
                                {pdata?.pdata_score?.js_v}
                              </Typography>
                            </Box>
                          ) : (
                            <span style={{ color: theme.palette.text.secondary }}>-</span>
                          )}
                        </ScoreContent>
                      </ScoreBox>
                    </Grid>
                    <Grid item xs={2.5}>
                      <ScoreBox>
                        <ScoreTitle>기타</ScoreTitle>
                        <ScoreContent>
                          {pdata?.pdata_score?.etc ? (
                            <Box>
                              <Typography fontSize={13} fontWeight="bold" letterSpacing="-0.13px">
                                {`${pdata?.pdata_score?.etc}점`}
                              </Typography>
                              <Typography component="div" fontSize={11} letterSpacing="-0.13px" sx={{ opacity: 0.4 }}>
                                {pdata?.pdata_score?.etc_v}
                              </Typography>
                            </Box>
                          ) : (
                            <span style={{ color: theme.palette.text.secondary }}>-</span>
                          )}
                        </ScoreContent>
                      </ScoreBox>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            ) : null}

            {/* 테이스팅 노트 */}
            <Box minWidth="330px" marginTop="40px">
              <InfoSectionTitle>Tasting notes</InfoSectionTitle>
              <Box mt="20px" px="8px">
                <Box fontSize={14} fontWeight="normal" lineHeight={1.57} letterSpacing="-0.14px" whiteSpace="pre-line">
                  {pdata?.desc2}
                </Box>
              </Box>
            </Box>
          </Collapse>

          <Box pt="32px" pb="40px">
            <ButtonBase
              sx={{
                width: '100%',
                py: '13px',
                border: '1px solid rgba(34, 6, 72, 0.7)',
                borderRadius: '6px',
                fontSize: 16,
                fontWeight: 700,
                lineHeight: 1,
                color: 'rgba(34, 6, 72, 0.8)'
              }}
              onClick={() => setPdataMore(!pdataMore)}
            >
              상품정보 {pdataMore ? '접기' : '더보기'}
              {pdataMore ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </ButtonBase>
          </Box>
        </Box>

        <SectionArea />

        {/* pdata 리뷰 통계 */}
        <Box component="section" py="36px" aria-label="상품 리뷰 통계">
          <InfoSectionTitle>리뷰</InfoSectionTitle>

          <Typography sx={{ fontSize: 12, fontWeight: 400, lineHeight: 'normal', mt: 2 }}>
            동일한 상품에 대해 작성된 리뷰로, 판매샵은 다를 수 있습니다.
          </Typography>

          <ButtonBase sx={{ width: '100%', mt: '28px', borderRadius: '8px' }} onClick={() => navigate(`/my/review/${pdataId}/write`)}>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                width: '100%',
                border: '1px solid #E8E0F0',
                borderRadius: '8px',
                p: '12px'
              }}
            >
              {/* pdata 이미지 */}
              <Box sx={{ width: REVIEW_IMG_WIDTH, height: REVIEW_IMG_HEIGHT, maxHeight: REVIEW_IMG_HEIGHT }}>
                <ProductImage src={pdata?.bottle_img?.thumb} width="100%" height="100%" alt="review-pdata-image" />
              </Box>

              <Box
                sx={{
                  width: `calc(100% - ${REVIEW_IMG_WIDTH}px - 8px)`,
                  maxWidth: `calc(100% - ${REVIEW_IMG_WIDTH}px - 8px)`,
                  height: REVIEW_IMG_HEIGHT,
                  maxHeight: REVIEW_IMG_HEIGHT,
                  pt: '4px'
                }}
              >
                {/* pdata 이름 */}
                <Typography sx={{ fontSize: 12, fontWeight: 400, lineHeight: 'normal', opacity: 0.7, textAlign: 'left' }} noWrap>
                  {pdata?.name_ko}
                </Typography>
                {pdataReviewTotalCard}
              </Box>
            </Stack>
          </ButtonBase>
        </Box>

        {/* pdata 리뷰 목록 */}
        <Box ref={reviewSectionRef} component="section" aria-label="상품 리뷰 목록" pb="36px" sx={{ scrollMarginTop: '72px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 1, pb: 2, borderBottom: '1px solid #EAE8ED' }}>
            <Typography sx={{ fontSize: 14, fontWeight: 800, lineHeight: 'normal' }}>전체 리뷰</Typography>
            {/* 리뷰 정렬 옵션 */}
            <Stack direction="row" spacing="12px">
              {REVIEW_SORT_OPTIONS.map((option, index) => {
                const { label, value } = option;
                // 선택된 리뷰 정렬 옵션
                const selected = reviewSort === value;

                return (
                  <ButtonBase disabled={searchState.searching} key={`review-sort-${index}-options}`} onClick={() => setReviewSort(value)}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: selected ? '#9357E5' : 'rgba(20, 2, 41, 0.50)',
                        fontWeight: selected ? 700 : 400,
                        lineHeight: 'normal'
                      }}
                    >
                      {label}
                    </Typography>
                  </ButtonBase>
                );
              })}
            </Stack>
          </Box>

          {/* 리뷰 목록 영역 */}
          <Box sx={{ mx: '-4px' }}>
            {/* 리뷰 목록 조회 중 오류 발생 */}
            {searchState.searched && searchState.error && (
              <ReviewThreadError reloadList={() => fetchReviewList(1)} contentHeight="auto" contentPd="44px" type="pdataInfo" />
            )}

            {/* 리뷰 목록이 비어있음 */}
            {rows.loaded && rows.empty && <ReviewThreadEmpty showHeader={false} contentHeight="auto" bgcolor="#FFF" contentPd="44px" />}

            {/* 리뷰 목록이 존재함 */}
            {!rows.empty && (
              <Stack spacing={1}>
                {rows.items.map((item, index) => (
                  <ReviewThreadCard
                    key={`review-${index}-card`}
                    review={item}
                    selectReview={(type, review) => reviewAction(type, review)}
                    handleThumbnailClickEvent={handleThumbnailClick}
                    showBuyVendor={false}
                    type="pdataInfo"
                  />
                ))}
              </Stack>
            )}

            {rows.page.current === 1 && searchState.searching && (
              <Box
                sx={{
                  width: '100%',
                  height: 307,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Box textAlign="center">
                  <WineBottleLoadingLottie />
                  <Typography variant="caption" color="#000">
                    리뷰를 불러오고 있습니다.
                  </Typography>
                </Box>
              </Box>
            )}

            {/* 리뷰 목록 조회 중 */}
            {rows.page.current > 1 && searchState.searching && (
              <Fade in>
                <Box textAlign="center" mt={2}>
                  <CircularProgress size={30} color="brand" />
                </Box>
              </Fade>
            )}
          </Box>
        </Box>
      </Container>
      {/* 리뷰 수정 drawer */}
      <ReviewUpdateDrawer
        open={reviewUpdateDrawer}
        onClose={() => setReviewUpdateDrawer(false)}
        action={() => navigate(`/my/review/${selectReview?.review_id}/modify`)}
      />
      {/* 리뷰 신고/차단 하기 drawer */}
      <ReviewReportDrawer open={reviewReportDrawer} onClose={() => setReviewReportDrawer(false)} action={(type) => reviewReport(type)} />
      {/* 리뷰 신고 modal */}
      <ReviewReportDialog
        pdata={{ id: selectReview.pdata_id }}
        review={selectReview}
        onClose={() => setReviewReportModal(false)}
        open={reviewReportModal}
        value={''}
        keepMounted={false}
        setRowItems={setRowItems}
      />
      {/* 이미지 상세보기 */}
      <ReviewImageViewer open={viewerOpen} onClose={closeImageViewer} images={images} currentIndex={currentImage} />
    </Dialog>
  );
};

export default PdataInfo;

PdataInfo.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  pdataId: PropTypes.string,
  moveToReview: PropTypes.bool
};

// 청소년 유의사항 안내
const BuyNotice = React.memo(() => {
  return (
    <Box bgcolor="#F4F0F8" borderRadius="18px" p="8px 12px">
      <Stack direction="row" spacing={1} alignItems="center">
        <Box
          sx={{
            width: 20,
            height: 20,
            border: '1px solid #B6AAC3',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 10,
            fontWeight: 800,
            lineHeight: 'normal',
            bgcolor: '#FFF',
            p: '4px',
            mx: '2px'
          }}
        >
          19
        </Box>
        <Typography
          sx={{
            fontSize: 10,
            fontWeight: 400,
            lineHeight: 'normal',
            opacity: 0.5
          }}
        >
          청소년은 구매할 수 없으며, 구매를 위해 본인인증이 필요합니다.
        </Typography>
      </Stack>
    </Box>
  );
});

const CbDivider = React.memo(() => (
  <Box width="2px" height="11px" component="img" src={TasteDivision} alt="divider" sx={{ objectFit: 'cover', opacity: '.6' }} />
));

/* eslint-disable react/prop-types */
// 20px yellow star
const Star20 = React.memo(({ width = 20, height = 20 }) => (
  <SvgIcon sx={{ width, height, fill: 'none' }} viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0038 14.5782L5.69856 17.7946C5.60352 17.8655 5.49544 17.917 5.38049 17.9461C5.26554 17.9753 5.14598 17.9815 5.02862 17.9645C4.91126 17.9474 4.79842 17.9074 4.69652 17.8467C4.59463 17.7861 4.50568 17.7059 4.43475 17.6109C4.34711 17.4934 4.28936 17.3564 4.26651 17.2117C4.24365 17.0669 4.25637 16.9188 4.30356 16.78L6.03092 11.6898L1.63382 8.58929C1.53668 8.52103 1.45395 8.43427 1.39037 8.334C1.3268 8.23372 1.28362 8.1219 1.26331 8.00492C1.243 7.88794 1.24596 7.7681 1.27202 7.65226C1.29807 7.53643 1.34672 7.42687 1.41517 7.32985C1.49969 7.20995 1.61216 7.11245 1.74283 7.04578C1.87351 6.97911 2.01846 6.94528 2.16514 6.94721L7.53743 7.01937L9.14015 1.88541C9.17531 1.77212 9.23244 1.66687 9.30828 1.57566C9.38412 1.48445 9.47718 1.40908 9.58216 1.35386C9.68714 1.29863 9.80197 1.26462 9.92009 1.25378C10.0382 1.24294 10.1573 1.25548 10.2706 1.29068C10.4105 1.33412 10.5378 1.41096 10.6414 1.51457C10.745 1.61819 10.8219 1.74546 10.8653 1.88541L12.4593 7.01937L17.8338 6.9494C17.9525 6.94766 18.0705 6.96938 18.1809 7.0133C18.2912 7.05722 18.3918 7.12249 18.4769 7.20536C18.562 7.28823 18.63 7.38707 18.6768 7.49623C18.7237 7.60539 18.7485 7.72272 18.7499 7.8415C18.7519 7.98819 18.718 8.13314 18.6514 8.26381C18.5847 8.39449 18.4872 8.50695 18.3673 8.59148L13.9789 11.6898L15.7063 16.78C15.7445 16.8923 15.7602 17.011 15.7525 17.1293C15.7448 17.2477 15.7139 17.3633 15.6615 17.4697C15.6091 17.5761 15.5363 17.6711 15.4472 17.7494C15.3581 17.8276 15.2544 17.8876 15.1422 17.9257C15.0034 17.9729 14.8553 17.9857 14.7105 17.9628C14.5658 17.9399 14.4287 17.8822 14.3113 17.7946L12.1576 16.1864L10.0038 14.5782Z"
      fill="#FEC466"
    />
  </SvgIcon>
));

// pdata 섹션 나눔 area
const SectionArea = React.memo(() => (
  <Box sx={{ height: '8px', mx: '-20px' }}>
    <Box sx={{ bgcolor: '#F3F2F5', width: '100%', height: '8px' }} />
  </Box>
));

// purple filled star
const PurpleFilledStarIcon = React.memo(() => (
  <SvgIcon sx={{ width: 30, height: 30, fill: 'none' }} viewBox="0 0 30 30">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0057 21.8673L8.54785 26.6918C8.40528 26.7982 8.24316 26.8755 8.07074 26.9192C7.89831 26.9629 7.71896 26.9723 7.54293 26.9467C7.3669 26.9211 7.19763 26.8611 7.04478 26.7701C6.89194 26.6791 6.75852 26.5589 6.65213 26.4163C6.52066 26.2402 6.43405 26.0346 6.39976 25.8175C6.36548 25.6003 6.38455 25.3781 6.45534 25.17L9.04638 17.5347L2.45073 12.8839C2.30501 12.7815 2.18092 12.6514 2.08556 12.501C1.99019 12.3506 1.92543 12.1828 1.89496 12.0074C1.8645 11.8319 1.86894 11.6521 1.90802 11.4784C1.94711 11.3046 2.02008 11.1403 2.12275 10.9948C2.24954 10.8149 2.41824 10.6687 2.61425 10.5687C2.81026 10.4687 3.02768 10.4179 3.24772 10.4208L11.3061 10.529L13.7102 2.82811C13.763 2.65818 13.8487 2.5003 13.9624 2.36349C14.0762 2.22668 14.2158 2.11363 14.3732 2.03078C14.5307 1.94794 14.703 1.89693 14.8801 1.88067C15.0573 1.86441 15.236 1.88321 15.4059 1.93601C15.6158 2.00117 15.8067 2.11644 15.9621 2.27186C16.1176 2.42728 16.2328 2.61819 16.298 2.82811L18.6889 10.529L26.7507 10.4241C26.9288 10.4215 27.1057 10.4541 27.2713 10.52C27.4368 10.5858 27.5878 10.6837 27.7154 10.808C27.8431 10.9323 27.945 11.0806 28.0152 11.2444C28.0855 11.4081 28.1227 11.5841 28.1249 11.7622C28.1278 11.9823 28.077 12.1997 27.977 12.3957C27.877 12.5917 27.7308 12.7604 27.5509 12.8872L20.9684 17.5347L23.5594 25.17C23.6167 25.3384 23.6403 25.5165 23.6287 25.694C23.6172 25.8715 23.5708 26.045 23.4923 26.2046C23.4137 26.3642 23.3045 26.5067 23.1708 26.6241C23.0371 26.7414 22.8816 26.8313 22.7132 26.8886C22.5051 26.9594 22.2829 26.9785 22.0658 26.9442C21.8486 26.9099 21.6431 26.8233 21.4669 26.6918L18.2363 24.2795L15.0057 21.8673Z"
      fill="#9357E5"
      fillOpacity="0.7"
    />
  </SvgIcon>
));

// purple empty star
const PurpleEmptyStarIcon = React.memo(() => (
  <SvgIcon sx={{ width: 30, height: 30, fill: 'none' }} viewBox="0 0 30 30">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0057 21.8673L8.54785 26.6918C8.40528 26.7982 8.24316 26.8755 8.07074 26.9192C7.89831 26.9629 7.71896 26.9723 7.54293 26.9467C7.3669 26.9211 7.19763 26.8611 7.04478 26.7701C6.89194 26.6791 6.75852 26.5589 6.65213 26.4163C6.52066 26.2402 6.43404 26.0346 6.39976 25.8175C6.36548 25.6003 6.38455 25.3781 6.45534 25.17L9.04637 17.5347L2.45073 12.8839C2.30501 12.7815 2.18092 12.6514 2.08556 12.501C1.99019 12.3506 1.92543 12.1828 1.89496 12.0074C1.8645 11.8319 1.86894 11.6521 1.90802 11.4784C1.94711 11.3046 2.02008 11.1403 2.12275 10.9948C2.24954 10.8149 2.41823 10.6687 2.61425 10.5687C2.81026 10.4687 3.02768 10.4179 3.24771 10.4208L11.3061 10.529L13.7102 2.82811C13.763 2.65818 13.8487 2.5003 13.9624 2.36349C14.0762 2.22668 14.2158 2.11363 14.3732 2.03078C14.5307 1.94794 14.7029 1.89693 14.8801 1.88067C15.0573 1.86441 15.236 1.88321 15.4059 1.93601C15.6158 2.00117 15.8067 2.11644 15.9621 2.27186C16.1176 2.42728 16.2328 2.61819 16.298 2.82811L18.6889 10.529L26.7507 10.4241C26.9288 10.4215 27.1057 10.4541 27.2713 10.52C27.4368 10.5858 27.5878 10.6837 27.7154 10.808C27.8431 10.9323 27.945 11.0806 28.0152 11.2444C28.0855 11.4081 28.1227 11.5841 28.1249 11.7622C28.1278 11.9823 28.077 12.1997 27.977 12.3957C27.877 12.5917 27.7308 12.7604 27.5509 12.8872L20.9684 17.5347L23.5594 25.17C23.6167 25.3384 23.6403 25.5165 23.6287 25.694C23.6172 25.8715 23.5708 26.045 23.4923 26.2046C23.4137 26.3642 23.3045 26.5067 23.1708 26.6241C23.0371 26.7414 22.8816 26.8313 22.7132 26.8886C22.5051 26.9594 22.2829 26.9785 22.0658 26.9442C21.8486 26.9099 21.6431 26.8233 21.4669 26.6918L18.2363 24.2795L15.0057 21.8673Z"
      fill="#EDE7F4"
    />
  </SvgIcon>
));

// eslint-disable-next-line react/prop-types
const InfoSectionTitle = React.memo(({ children }) => (
  <Grid container spacing="8px">
    <Grid item xs="auto">
      <Typography fontSize={16} fontWeight="800">
        {children}
      </Typography>
    </Grid>
    <Grid item xs sx={{ display: 'inline-flex' }}>
      <Box width="100%" height="1px" bgcolor="#2D154D" sx={{ opacity: '.1', alignSelf: 'center' }} />
    </Grid>
  </Grid>
));

const InfoSectionSubTitle = styled((props) => <Typography {...props} />)(({ fontWeight = 400 }) => ({
  fontSize: 14,
  lineHeight: 'normal',
  fontWeight,
  color: 'rgba(20, 2,41, 0.8)'
}));

// eslint-disable-next-line react/prop-types
const TasteItem = React.memo(({ label, score = 1 }) => {
  const rateIcons = React.useMemo(() => {
    const icons = [];
    for (let i = 1; i <= 5; i++) {
      icons.push(
        <Box height="16px" display="flex" alignItems="center" key={`rate-icon-${i}`}>
          <Box width="30px" height="10px" sx={{ backgroundImage: `url(${score >= i ? TasteOn : TasteOff})` }} />
        </Box>
      );
    }
    return icons;
  }, [score]);

  return (
    <Stack direction="row" spacing="45px" aria-label={`${label} 점수 = ${score}`}>
      <Typography fontSize="14px" fontWeight="600" whiteSpace="nowrap">
        {label}
      </Typography>
      <Box display="flex">
        <Stack
          direction="row"
          divider={
            <Box height="100%" display="flex" alignItems="center">
              <Box width="1px" height="4px" component="img" src={TasteDivision} alt="Taste icon divider" />
            </Box>
          }
          spacing="8px"
        >
          {rateIcons}
        </Stack>
      </Box>
    </Stack>
  );
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ScoreBox = styled(Box)({
  height: 88,
  display: 'flex',
  flexDirection: 'column'
});

const ScoreTitle = styled(Box)({
  height: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 13,
  fontWeight: 300
});

const ScoreContent = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '10px'
});
