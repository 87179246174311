import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { logEvent } from 'firebase/analytics';
import { useDispatch } from 'react-redux';

// project imports
import { CLO_CODE } from 'config';
import { useAuth, useScriptRef, useWineOne } from 'hooks';
import { openSnackbar } from 'store/slices/snackbar';
import { WoAlert } from 'utils/kmwine-alerts';
import OrderTool from 'utils/bourgogne-tools/OrderTool';
import { getFirebaseFunctions, getFirestore } from 'utils/firebase-tools';
import { ToolbarTitle } from 'components/toolbar';
import { CloseThickIcon } from 'components/icons';
import { SpaceBetweenBox, WoDivider } from 'components';
import { addFvrVendor, getOneVendor } from 'services/VendorService';
import { getCoBuyingDetail } from '../../../../services/ProductService';
import { analytics } from '../../../../index';

// material-ui
import {
  AppBar,
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Collapse,
  Container,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  SvgIcon,
  Toolbar,
  Typography,
  useTheme
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

// assets
import EmptyStartIcon from './assets/ico_pay_end_star_off.png';
import FilledStartIcon from './assets/ico_pay_end_star_on.png';

/**
 * 결제완료화면
 * @returns {JSX.Element}
 * @constructor
 *
 * @authors 조현권<hkcho@wineone.io>,이재일<leeji@wineone.io>
 * @jira PROD-223
 */
function OrderComplete() {
  // 로그인 사용자 정보
  const { user } = useAuth();

  const scriptedRef = useScriptRef();

  const theme = useTheme();
  const navigate = useNavigate();
  const globalDispatch = useDispatch();

  const location = useLocation();
  const { onNativeBackPressed } = useWineOne();

  const [searchParams] = useSearchParams();
  const oid = searchParams.get('oid'); // 주문번호
  const tid = searchParams.get('tid'); // 결제 승인번호

  const sendCloError = httpsCallable(getFirebaseFunctions(), 'call-cdm-clo-error');

  // 주문정보
  const [order, setOrder] = React.useState({ loaded: false });

  // 입점사
  const [vendor, setVendor] = React.useState({ loaded: false, data: null });

  // 공동구매 상품 정보
  const [coBuying, setCoBuying] = React.useState(null);

  // 프로모션 상세정보
  const [promotion, setPromotion] = React.useState(null);

  // 티켓상품인지 찾기
  const isTicket = React.useMemo(() => {
    if (order.product_categories && order.product_categories[0] === 'ticket') return true;
    return false;
  }, [order]);

  const fetchOrder = async () => {
    // 주문정보 조회
    const orderSnapshot = await getDoc(doc(getFirestore(), 'order', oid)).catch((error) => ({ error }));

    if (!scriptedRef.current) {
      console.warn('[OrderComplete][fetchOrder] Unmounted component.');
      return;
    }

    // 주문정보 조회 실패
    if (orderSnapshot.error) {
      console.error('주문정보 조회 실패, ', orderSnapshot.error);
      WoAlert.fire(`주문정보 조회 중 오류가 발생했습니다.\n주문목록 화면으로 이동합니다.`, '', 'error').then(() => {
        navigate('/my/order/list');
      });
      return;
    }

    // 존재하지 않는 주문
    if (!orderSnapshot.exists()) {
      WoAlert.fire(`존재하지 않는 주문입니다.\n주문목록 화면으로 이동합니다.`, '', 'error').then(() => {
        navigate('/my/order/list');
      });
      return;
    }

    // 주문정보
    const order = orderSnapshot.data();
    console.log('# 조회된 주문정보: ', order);

    getDocs(collection(orderSnapshot.ref, 'order_products'))
      .then((snapshot) => {
        const items = [];
        snapshot.forEach((doc) => {
          const { id, name, quantity, price } = doc.data();
          items.push({ item_id: id, item_name: name.ko, price: price.sale, quantity });
        });

        const eventParam: any = {
          currency: 'KRW',
          transaction_id: order.oid,
          value: order.pay_origin?.amount,
          items
        };
        logEvent(analytics, 'purchase', eventParam);
        console.log('Google Analytics - purchase');
      })
      .catch((e) => {
        console.error('구글 애널리틱스 이벤트 로그 실패', e);
      });

    // 프로모션 주문일경우 프로모션 정보 조회
    if (order.promotion_id) {
      const promotionSnapshot = await getDoc(doc(getFirestore(), 'promotion', order.promotion_id)).catch((error) => ({ error }));

      if (!scriptedRef.current) {
        console.warn('[OrderComplete][fetchOrder] Unmounted component.');
        return;
      }

      // 프로모션 정보 조회 실패
      if (promotionSnapshot.error) {
        console.error('프로모션 정보 조회 실패, ', promotionSnapshot.error);
        WoAlert.fire(`프로모션 정보 조회 중 오류가 발생했습니다.\n주문목록 화면으로 이동합니다.`, '', 'error').then(() => {
          navigate('/my/order/list');
        });
        return;
      }

      // 존재하지 않는 주문
      if (!promotionSnapshot.exists()) {
        WoAlert.fire(`존재하지 않는 프로모션입니다.\n주문목록 화면으로 이동합니다.`, '', 'error').then(() => {
          navigate('/my/order/list');
        });
        return;
      }

      // 프로모션정보
      const promotion = promotionSnapshot.data();

      // console.log('457474747', promotion);
      setPromotion(promotion);
    }

    // 공동구매정보
    if (order.cobuy_id) {
      const result = await getCoBuyingDetail(order.cobuy_id).catch((error) => ({ error }));

      // 공동구매 조회 실패
      if (result.error) {
        sendCloError({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `[주문완료] 공동구매 상세정보 조회 중 오류`,
          msg: `[uid=${user._id}] ${JSON.stringify(result.error)}`,
          which: `${location.pathname}${location.search}`,
          param: {
            uid: user._id
          }
        })
          .then(console.log)
          .catch(console.error);

        console.error('[fetchCoBuyingDetail][fetchCoBuyingDetail] ', result.error);

        return undefined;
      }

      // 잘못된 Response 값이 반환됨
      if (!result.success) {
        sendCloError({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `공동구매 조회 중 오류`,
          msg: `[uid=${user._id}]`,
          which: `${location.pathname}${location.search}`,
          param: {
            uid: user._id
          }
        })
          .then(console.log)
          .catch(console.error);

        console.error('[fetchCoBuyingDetail][fetchCoBuyingDetail] 잘못된 Response 값이 반환됨');

        return undefined;
      }

      setCoBuying(result.cobuying);
    }

    setOrder({ ...order, loaded: true });

    // 입점사 고유 아이디
    const vendorId = order.vendor.id;

    // 입점샵 조회
    const {
      data: { result, data: _vendor }
    } = await getOneVendor(vendorId).catch((error) => ({ error }));

    // 조회 실패
    if (result.code !== 0) {
      setVendor({ loaded: true, data: null });
      return undefined;
    }

    console.log('# 조회된 입점샵 정보: ', _vendor);
    // 관심샵으로 설정된 샵이 아니라면 관심샵 등록 영역 활성화
    setFvrBox(!_vendor.my_favorite);
    setFvr(_vendor.my_favorite);
    setVendor({ loaded: true, data: _vendor });
  };

  // 결제완료
  React.useLayoutEffect(() => {
    if (oid && tid) {
      fetchOrder().then(() => {
        console.log(`[1KMWINE] 결제완료 주문번호[${oid}], 결제승인번호[${tid}]`);
      });
    } else {
      WoAlert.fire(`올바르지 않은 접근입니다.\n잠시 후 다시 시도해주세요.`, '', 'error').then(() => {
        if (process.env.NODE_ENV === 'development') {
          console.info('[development] 개발환경입니다. 실제 서비스에서는 마이페이지로 리다이렉트 됩니다.');
        } else {
          navigate('/my');
        }
      });
    }
  }, [oid]);

  // 뒤로가기
  const pageBackFunc = React.useCallback(() => {
    console.debug(`주문완료 화면에서 기기의 '뒤로가기' 버튼이 감지되었습니다.`);
    navigate('/my/order/list');
  }, [location]);

  React.useEffect(() => {
    // 기기에서 뒤로가기 버튼을 눌렀을 때
    onNativeBackPressed(pageBackFunc);
  }, [location]);

  // 카드결제정보 뱃지 스트링
  const paymentBadgeStr = React.useMemo(() => {
    let str = null;
    if (order?.payment) {
      str = OrderTool.paymentBadgeStr(order.payment);
    }

    return str;
  }, [order?.payment]);

  // 관심샵 여부
  const [fvr, setFvr] = React.useState(0);
  // 관심샵 등록 영역 표현 여부
  const [fvrBox, setFvrBox] = React.useState(false);
  // 관심샵 등록 로딩
  const [fvrLoading, setFvrLoading] = React.useState(false);

  // 관심샵 추가
  const addVendorFvr = async () => {
    setFvrLoading(true);

    const result = await addFvrVendor(vendor.data._id)
      .catch((error) => ({ error }))
      .finally(() => {
        setFvrLoading(false);
      });

    // 관심샵 추가 중 오류 발생
    if (result.error) {
      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `주문완료 후 관심샵 추가 중 오류`,
        msg: `[uid=${user._id}] ${JSON.stringify(result.error)}`,
        which: `${location.pathname}${location.search}`,
        param: { uid: user._id, vendor_id: vendor._id }
      })
        .then(console.log)
        .catch(console.error);

      if (scriptedRef.current) {
        console.error('[주문완료] 입점샵 관심샵 추가 중 오류 발생 > ', result.error);
        WoAlert.fire(`관심샵 추가 중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.`, '', 'error');
      }
      return undefined;
    }

    const { code, msg } = result.data.result;

    if (code !== 0) {
      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `주문완료 후 관심샵 추가 중 오류`,
        msg: `[uid=${user._id}] ${JSON.stringify(msg)}`,
        which: `${location.pathname}${location.search}`,
        param: { uid: user._id, vendor_id: vendor._id }
      })
        .then(console.log)
        .catch(console.error);

      if (scriptedRef.current) {
        console.error('[주문완료] 입점샵 관심샵 추가 중 오류 발생 > ', msg);
        WoAlert.fire(`관심샵 추가 중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.`, '', 'error');
      }
      return undefined;
    }

    if (scriptedRef.current) {
      setFvr(1);
    }

    globalDispatch(
      openSnackbar({
        open: true,
        message: '관심샵이 추가 되었습니다.',
        variant: 'alert',
        alert: { color: 'success' },
        close: true,
        autoHideDuration: 2800
      })
    );
  };

  // [임시] pdata.category가 'ticket'인 pdata 대응
  const isOnlyTicket = React.useMemo(() => {
    if (!order.loaded) return false;

    // product_categories 있는 주문 - 2023년 7월부터
    if (order.product_categories?.length > 0) {
      const { product_categories } = order;

      let ticketOnly = true;
      for (let i = 0; i < product_categories.length; i++) {
        const orderProductCategory = product_categories[i];
        if (orderProductCategory !== 'ticket') ticketOnly = false;
      }

      // 티켓만 구매했다 == 픽업정보 감추기
      return ticketOnly;
    }

    return false;
  }, [order.loaded]);

  const orderCompleteType = React.useMemo(() => {
    if (!order.loaded) {
      return;
    }
    if (promotion !== null && promotion.confirm === 'auto' && promotion.pickup.state !== 'none') {
      return (
        <Box>
          <Typography>주문이 완료되었습니다.</Typography>
          <Typography>픽업일에 샵으로 방문해주세요.</Typography>
          <Typography
            component="span"
            sx={{
              fontSize: '18px',
              fontWeight: 800,
              position: 'relative',
              display: 'inline-block',
              '&:before': {
                content: '""',
                position: 'absolute',
                top: '10px',
                left: '-5px',
                right: '-5px',
                height: '65%',
                background: '#d4f788',
                zIndex: -1
              }
            }}
          >
            {order?.vendor?.name ?? <Skeleton width={45} />}
          </Typography>
        </Box>
      );
    }

    if (promotion !== null && promotion.confirm === 'auto' && promotion.pickup.state === 'none') {
      return (
        <Box>
          <Typography component="div" fontSize={18} fontWeight="800" textAlign="center">
            예약 주문이 완료되었습니다.
          </Typography>
          <Box maxWidth="278px" textAlign="center">
            <Typography fontSize={18} fontWeight="300">
              프로모션 종료 후 &nbsp;
              <Typography
                fontSize={18}
                fontWeight="300"
                component="span"
                sx={{ background: 'linear-gradient(transparent 35%, #d4f788 0%)', display: 'inline' }}
              >
                {moment(promotion.period.end_dt.toDate()).add(1, 'd').format('MM월 DD일')}부터 샵으로 상품 배송이 시작
              </Typography>
              됩니다.
            </Typography>
          </Box>
        </Box>
      );
    }

    if (coBuying === null) {
      return (
        <Box>
          <Typography component="div" fontSize={18} fontWeight={300} textAlign="center">
            <Box display="inline-block" position="relative" px="4px" mr="8px" height={21} zIndex={1}>
              <Typography
                component="span"
                sx={{
                  fontSize: '18px',
                  fontWeight: 800,
                  position: 'relative',
                  display: 'inline-block',
                  '&:before': {
                    content: '""',
                    position: 'absolute',
                    top: '10px',
                    left: '-5px',
                    right: '-5px',
                    height: '65%',
                    background: '#d4f788',
                    zIndex: -1
                  }
                }}
              >
                {order?.vendor?.name ?? <Skeleton width={45} />}
              </Typography>
            </Box>
            에서
          </Typography>
          <Box mt="12px" textAlign="center">
            <Typography component="span" fontSize={18}>
              주문을 확인 중 입니다.
            </Typography>
          </Box>
        </Box>
      );
    }

    if (coBuying) {
      return (
        <Box>
          <Typography component="div" fontSize={18} fontWeight="800" textAlign="center">
            공동구매 주문이 완료되었습니다.
          </Typography>
          <Box maxWidth="278px" textAlign="center">
            <Typography fontSize={18} fontWeight="300">
              최소 주문 수량 달성 시 &nbsp;
              <Typography
                fontSize={18}
                fontWeight="300"
                component="span"
                sx={{ background: 'linear-gradient(transparent 35%, #d4f788 0%)', display: 'inline' }}
              >
                {moment(coBuying?.delivery_start_dt).locale('ko').format('MMM Do')}부터 샵으로 상품 배송이 시작
              </Typography>
              됩니다.
            </Typography>
          </Box>
        </Box>
      );
    }
  }, [order, promotion, coBuying]);

  // render
  return (
    <Box bgcolor="background.paper">
      <AppBar position="sticky">
        <Toolbar>
          <IconButton size="large" edge="start" color={'inherit'} onClick={pageBackFunc} aria-label="close">
            <CloseThickIcon />
          </IconButton>
          <ToolbarTitle>주문완료</ToolbarTitle>
        </Toolbar>
        <WoDivider />
      </AppBar>

      <Container
        maxWidth="xs"
        sx={{ display: 'flex', flexDirection: 'column', minHeight: 'calc(var(--vh, 1vh) * 100 - 60px)', paddingBottom: '106px' }}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box my="16px">{shiningWineAndBottleSvg}</Box>
          {orderCompleteType}
        </Box>

        {/* 관심샵으로 등록한 입점샵이 아닐 경우 */}
        {!isTicket && coBuying === null && (
          <Collapse in={fvrBox}>
            <Box mt="32px" textAlign="center">
              <Typography component="span" fontSize="18px" fontWeight={700} lineHeight={1}>
                관심샵으로 등록하고
              </Typography>
              <Box marginTop="8px" fontSize="18px" fontWeight={700} lineHeight={1}>
                할인 정보를 받아보세요!
              </Box>
              <Box mt="32px">
                <ButtonBase sx={{ borderRadius: '26px' }} onClick={() => addVendorFvr()} disabled={fvrLoading || fvr === 1}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '2px solid #9357E5',
                      borderRadius: '26px',
                      padding: '11px 18px'
                    }}
                  >
                    {fvrLoading && <CircularProgress size="18px" sx={{ color: '#F9B546' }} />}
                    {!fvrLoading && fvr === 1 && <Box component="img" src={FilledStartIcon} width="18px" height="18px" mr="2px" />}
                    {!fvrLoading && fvr === 0 && <Box component="img" src={EmptyStartIcon} width="18px" height="18px" mr="2px" />}
                    <Typography sx={{ color: '#9357E5', fontWeight: 700, fontSize: '16px', lineHeight: 1 }}>관심샵 등록</Typography>
                  </Box>
                </ButtonBase>
              </Box>
            </Box>
          </Collapse>
        )}
        {/* 추석연휴 공지사항 */}

        {/* !isTicket && (
          <Alert icon={false} color="error" sx={{ mt: '24px', borderRadius: '14px' }}>
            <AlertTitle
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'text.primary',
                fontSize: 14,
                fontWeight: 900,
                mb: '11px'
              }}
            >
              <InfoIcon sx={{ color: '#E37462', width: '15px', height: '18px', marginRight: '4px' }} />
              추석 연휴 픽업 안내
            </AlertTitle>
            <Typography variant="body2" fontSize={14} color="text.primary" sx={{ wordBreak: 'keep-all' }}>
              추석 연휴에는 샵 영업 시간이 변동될 수 있습니다. <br />
              반드시&nbsp;<b style={{ color: '#F46C5B', fontWeight: 'bold' }}>주문 확정 메시지를 받으신 후</b>
              &nbsp;샵에 방문해 주세요.
            </Typography>
          </Alert>
        ) */}

        {/* 주문 정보 카드 */}
        <Box mt="23px" px="14px" py="30px" borderRadius="14px" bgcolor="#f4f0f8">
          {/* 주문번호 & 주문날짜 */}
          <SpaceBetweenBox>
            <Stack
              direction="row"
              spacing={1}
              divider={
                <Box display="flex" alignItems="center" pb="1px">
                  <Box height={'9px'} width={'1px'} bgcolor={alpha(theme.palette.text.primary, 0.1)} />
                </Box>
              }
            >
              <Typography fontSize="14px" fontWeight={300} sx={{ opacity: 0.7 }}>
                주문번호
              </Typography>
              <Typography fontSize="14px" fontWeight={700}>
                {oid}
              </Typography>
            </Stack>
            <Typography variant="caption" fontSize="12px" sx={{ opacity: 0.5 }}>
              {order?.payment?.p_auth_dt ? moment(order.payment.p_auth_dt, 'YYYYMMDDHHmmss').format('YYYY.MM.DD') : <Skeleton width={15} />}
            </Typography>
          </SpaceBetweenBox>

          <Divider sx={{ my: '28px' }} />

          {/* 주문한 상품 */}
          <Typography fontSize="14px" fontWeight="normal" lineHeight={1.43}>
            {order?.goods_name ?? <Skeleton width="100%" />}
          </Typography>

          <Divider sx={{ my: '28px' }} />

          {/* 픽업정보 */}
          {order.loaded && !isOnlyTicket && (
            <>
              <Box>
                <Typography variant="subtitle1" fontSize={11} fontWeight={800} lineHeight="normal">
                  픽업 정보
                </Typography>

                <Stack direction="column" spacing="6px" sx={{ mt: '12px' }}>
                  {/* 매장이름 */}
                  {(coBuying !== null || promotion?.type === 'reserve') && (
                    <PickupStack title="매장명">
                      <PickupContent>{vendor.loaded ? `${vendor.data?.biz.name}` : <Skeleton width={140} />}</PickupContent>
                    </PickupStack>
                  )}

                  {/* 매장주소 */}
                  <PickupStack title="매장 주소">
                    <PickupContent>
                      {vendor.loaded ? `${vendor.data?.biz?.address1} ${vendor.data?.biz?.address2}` : <Skeleton width={140} />}
                    </PickupContent>
                  </PickupStack>

                  {/* 픽업 일시 */}
                  <PickupStack title="픽업 일시">
                    <PickupContent>
                      {coBuying !== null || promotion?.pickup.state === 'none' ? (
                        <span>주문 마감 후 별도 안내</span>
                      ) : order?.pickup_date_time ? (
                        <span>{moment(order.pickup_date_time.toMillis()).format('YYYY.MM.DD (ddd) A hh:mm')}</span>
                      ) : (
                        <Skeleton width={140} />
                      )}
                    </PickupContent>
                  </PickupStack>

                  {/* 구매자 */}
                  <PickupStack title="수령인">
                    <PickupContent>
                      {order?.user?.uname ?? <Skeleton width={40} />}
                      {order?.user?.email ? ` (${order.user.email})` : null}
                    </PickupContent>
                  </PickupStack>
                </Stack>
              </Box>
              <Divider sx={{ my: '28px' }} />
            </>
          )}

          {/* 결제 정보 */}
          <Box>
            <Typography variant="subtitle1" fontSize={11} fontWeight={800} lineHeight="normal">
              결제 정보
            </Typography>
            <SpaceBetweenBox sx={{ mt: '12px' }}>
              <Typography variant="subtitle1" fontSize={14} fontWeight={300} lineHeight="normal" sx={{ opacity: 0.7 }}>
                결제 정보
              </Typography>

              {order.loaded ? (
                <Box display="inline-flex" alignItems="center">
                  <Box component="span" px="3px" mr="10px" borderRadius="2px">
                    <Typography fontSize={11} color={alpha(theme.palette.brand.main, 0.3)}>
                      {paymentBadgeStr}
                    </Typography>
                  </Box>

                  <Typography variant="body1" component="span" fontSize={22} lineHeight="normal" fontFamily="D-DIN">
                    {order.pay.amount.toLocaleString()}
                  </Typography>
                  <Typography variant="body1" component="span" fontSize={16} fontWeight={300} sx={{ ml: '2px' }}>
                    원
                  </Typography>
                </Box>
              ) : (
                <Skeleton width={140} />
              )}
            </SpaceBetweenBox>
          </Box>
        </Box>
      </Container>

      <BottomBox>
        <Stack direction="row" spacing="8px">
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              navigate('/my/order/list', { state: { redirect: `/my/order/${oid}/detail` } });
            }}
            sx={{ fontSize: 17, fontWeight: 'bold', height: 46 }}
          >
            주문 상세보기
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="brand"
            onClick={() => {
              if (coBuying) {
                navigate(`/`);
                return;
              }
              navigate(`/mall`);
            }}
            sx={{ fontSize: 17, fontWeight: 'bold', height: 46 }}
          >
            쇼핑 계속하기
          </Button>
        </Stack>
      </BottomBox>
    </Box>
  );
}

export default OrderComplete;

const shiningWineAndBottleSvg = (
  <SvgIcon viewBox="0 0 123 100" sx={{ width: 123, height: 100 }}>
    <path style={{ fill: 'none' }} d="M0 0h123v100H0z" />
    <path
      d="M-8853.1-6682h-3.457l4.871-8.493a3.011 3.011 0 0 1 2.6-1.506h81.161a3.011 3.011 0 0 1 2.6 1.506l4.873 8.492h-3.459l-4.018-7h-81.161l-4.018 7z"
      transform="translate(8869.555 6780)"
      style={{ fill: '#220348' }}
    />
    <g>
      <g>
        <g style={{ fill: '#fff' }}>
          <path
            d="M-10179.5-6639.501h-20.001v-38.998c0-3.664 2-7.03 5.219-8.786l.782-.426v-17.79h8.001v17.79l.781.427a10.004 10.004 0 0 1 5.218 8.785v38.998z"
            style={{ stroke: 'none' }}
            transform="translate(10240.002 6729)"
          />
          <path
            d="M-10181-6641.001v-37.498c0-3.113-1.7-5.975-4.437-7.468l-1.562-.853v-17.181h-5.001v17.18l-1.563.853a8.504 8.504 0 0 0-4.438 7.469v37.498h17.001m3 3h-23.001v-40.498c0-4.36 2.426-8.153 6.001-10.103V-6707h11.001v18.4a11.498 11.498 0 0 1 5.999 10.102v40.498z"
            style={{ fill: '#220348', stroke: 'none' }}
            transform="translate(10240.002 6729)"
          />
        </g>
        <path transform="translate(42.002 56)" style={{ fill: '#220648' }} d="M0 0h11v3H0z" />
        <path transform="translate(42.002 73)" style={{ fill: '#220648' }} d="M0 0h11v3H0z" />
      </g>
      <g style={{ stroke: '#220348', strokeWidth: '3px', fill: '#fff' }}>
        <path d="M0 0h17v13.5A8.5 8.5 0 0 1 8.5 22 8.5 8.5 0 0 1 0 13.5V0z" style={{ stroke: 'none' }} transform="translate(67.002 49)" />
        <path d="M1.5 1.5h14v12a7 7 0 0 1-7 7 7 7 0 0 1-7-7v-12z" style={{ fill: 'none' }} transform="translate(67.002 49)" />
      </g>
      <path transform="translate(74.002 69)" style={{ fill: '#220648' }} d="M0 0h3v19H0z" />
      <path transform="translate(48.002 25)" style={{ fill: '#defd9a' }} d="M0 0h5v5H0z" />
      <path transform="translate(70.002 52)" style={{ fill: '#defd9a' }} d="M0 0h11v4H0z" />
      <path transform="translate(50.002 56)" style={{ fill: '#220648' }} d="M0 0h3v20H0z" />
      <path transform="translate(42.002 59)" style={{ fill: '#eadff5' }} d="M0 0h8v14H0z" />
    </g>
    <path d="M-10197-6692v-6h-4v-3h4v3h3v6zm3-6v-3h4v3zm-3-3v-6h3v6z" transform="translate(10212 6715)" style={{ fill: '#220648' }} />
    <path d="M-10197-6694v-4h-3v-3h3v3h3v4zm3-4v-3h3v3zm-3-3v-4h3v4z" transform="translate(10301 6724)" style={{ fill: '#220648' }} />
    <g transform="translate(92 40)" style={{ stroke: '#220348', strokeWidth: '3px', fill: 'none' }}>
      <circle cx="4.5" cy="4.5" r="4.5" style={{ stroke: 'none' }} />
      <circle cx="4.5" cy="4.5" r="3" style={{ fill: 'none' }} />
    </g>
  </SvgIcon>
);

// eslint-disable-next-line react/prop-types
const PickupStack = ({ children, title }) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      spacing="8px"
      divider={
        <Box display="flex" alignItems="center" py="3px">
          <Box height={1} width={'1px'} bgcolor={alpha(theme.palette.text.primary, 0.1)} />
        </Box>
      }
    >
      <PickupTitle>{title}</PickupTitle>
      {children}
    </Stack>
  );
};

const PickupTitle = styled(Typography)({
  fontSize: 14,
  fontWeight: 300,
  lineHeight: 'normal',
  minWidth: 54,
  opacity: 0.7
});

const PickupContent = styled(Typography)({
  fontSize: '14px',
  lineHeight: 'normal'
});

const BottomBox = styled(Box)({
  width: '100%',
  bottom: 0,
  padding: '20px 16px',
  position: 'fixed',
  backgroundColor: '#fff',
  boxShadow: '0 -2px 10px 0 #00000029'
});

// eslint-disable-next-line react/prop-types
export function StarOffIcon({ width = 20, height = 20, color = '#9357E5' }) {
  return (
    <SvgIcon viewBox="0 0 20 20" sx={{ width, height }}>
      <g transform="translate(-274 -58)">
        <g transform="translate(-2448 -6977)">
          <g transform="translate(2722 7035)">
            <rect style={{ width, height, fill: 'none' }} />
          </g>
        </g>
        <path
          d="M22.464,11.017a1.647,1.647,0,0,0-.913-2.809L15.991,7.4a.726.726,0,0,1-.547-.4L12.958,1.965a1.647,1.647,0,0,0-2.953,0L7.519,7a.727.727,0,0,1-.548.4l-5.56.808A1.647,1.647,0,0,0,.5,11.017l4.023,3.921a.728.728,0,0,1,.21.644L3.782,21.12a1.647,1.647,0,0,0,2.389,1.736l4.972-2.614a.728.728,0,0,1,.677,0l4.973,2.614a1.647,1.647,0,0,0,2.389-1.736l-.95-5.537a.728.728,0,0,1,.21-.644Zm-5.138,4.721.949,5.537a.727.727,0,0,1-1.055.767l-4.973-2.615a1.647,1.647,0,0,0-1.533,0L5.743,22.042a.727.727,0,0,1-1.055-.767l.949-5.537a1.647,1.647,0,0,0-.474-1.458L1.14,10.359a.727.727,0,0,1,.4-1.241L7.1,8.311a1.644,1.644,0,0,0,1.24-.9l2.486-5.038a.728.728,0,0,1,1.3,0L14.62,7.41a1.644,1.644,0,0,0,1.24.9l5.56.808a.727.727,0,0,1,.4,1.241L17.8,14.28A1.646,1.646,0,0,0,17.326,15.738Z"
          transform="translate(279.5 61.453)"
          style={{ fill: color, stroke: color, strokeWidth: 0.5 }}
        />
      </g>
    </SvgIcon>
  );
}
