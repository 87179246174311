import React from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';
import { deleteDoc, doc, getDoc } from 'firebase/firestore';

import { useAuth, useScriptRef } from 'hooks';
import { getFirestore } from 'utils/firebase-tools';
import { addWishItem } from 'services/UserService';
import { HeartIcon } from 'components/icons';
import LikeButtonLottie from 'components/spinner/LikeButtonLottie';

// material-ui
import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

// assets
import heartbeatLoaderJson from 'assets/lottie/heartbeat-loader.json';

/**
 * 찜하기 버튼
 * @param pdataId
 * @param pdataName
 * @param onWishChange
 * @param other
 * @returns {JSX.Element}
 * @constructor
 */
function WishButton({ pdataId, pdataName, onWishChange = () => false, ...other }) {
  const scriptedRef = useScriptRef();
  const containerRef = React.useRef(null);
  const [isWish, setIsWish] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const { user } = useAuth();
  // const globalDispatch = useDispatch();

  React.useEffect(() => {
    try {
      containerRef.current.innerHTML = '';
    } catch (e) {
      /* DO NOTHING */
    }
    lottie.loadAnimation({
      container: containerRef.current,
      loop: true,
      autoplay: true,
      animationData: heartbeatLoaderJson,
      rendererSettings: { clearCanvas: true }
    });
  }, []);

  React.useLayoutEffect(() => {
    getDoc(doc(getFirestore(), `member/${user._id}/wishes`, `${pdataId}-${user._id}`))
      .then((docSnapshot) => {
        if (scriptedRef.current) {
          setIsWish(docSnapshot.exists());
        }
      })
      .catch((e) => {
        console.error(`와인[pdata.id=${pdataId}]의 wish 여부 조회 중 오류가 발생했습니다.`, e);
      });
  }, [pdataId]);

  const toggleWish = () => {
    // console.log(`현재 wish 여부 : ${isWish}`);
    if (isWish) {
      console.debug(`와인[pdata.id=${pdataId}]을 찜목록에서 제외.`);
      setIsWish(false);

      deleteDoc(doc(getFirestore(), `member/${user._id}/wishes`, `${pdataId}-${user._id}`))
        .then((r) => {
          console.debug('찜목록 제외 완료', r);

          if (scriptedRef.current) {
            setIsWish(false);
            onWishChange(false);
          }
        })
        .finally(() => {
          if (scriptedRef.current) {
            setLoading(false);
          }
        });
    } else {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
      console.debug(`와인[pdata.id=${pdataId}]을 찜목록에 추가.`);

      setIsWish(true);
      addWishItem(pdataId).then((r) => {
        if (scriptedRef.current) {
          console.debug('찜목록 추가 완료. response=', r);

          setIsWish(true);
          onWishChange(true);
        }
      });
    }
  };

  return isWish == null ? (
    <Box ref={containerRef} width={30} height={30} sx={{ ...(other.sx ?? {}) }} />
  ) : (
    <IconButtonBase aria-label="와인 찜하기" onClick={toggleWish} {...other}>
      {loading && <LikeButtonLottie />}
      <HeartIcon filled={isWish} />
    </IconButtonBase>
  );
}

WishButton.propTypes = {
  pdataId: PropTypes.string.isRequired,
  pdataName: PropTypes.string.isRequired,
  onWishChange: PropTypes.func
};

export default WishButton;

const IconButtonBase = styled(IconButton)({
  color: '#fb6464',
  padding: 0
});
