import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment/moment';

// project imports
import { useSelector } from 'store';
import { BackButton, StarOffIcon, StarOnIcon } from 'components';
import VendorLocationMap from './VendorLocationMap';

// material-ui
import { AppBar, Box, ButtonBase, Container, Dialog, List, Skeleton, Stack, SvgIcon, Toolbar, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

// 오늘 요일
const todayStr = moment().locale('en').format('ddd').toUpperCase();

// 샵 이미지가 여러장일 경우 가로 크기
const VENDOR_IMAGE_WIDTH = 148;
// 샵 이미지가 여러장일 경우 세로 크기
const VENDOR_IMAGE_HEIGHT = 148;

/**
 * 샵 상세 다이얼로그 (depth 2)
 *
 * @authors 최효근<hkchoi@wineone.io>, 이재일<leeji@wineone.io>
 */
function VendorDetailDialog({ openVendorDetailDialog, vendor, imgs, vendorAddr, toggleVendorFvr, vendorClickState }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { code } = useSelector((state) => state.woConstants);

  // 관심샵 등록/삭제 로딩
  const [fvrToggleLoading, setFvrToggleLoading] = React.useState(false);

  // 샵 이미지 정보
  const [vendorImages, setVendorImages] = React.useState({ loaded: false, empty: true, imageList: [] });

  React.useEffect(() => {
    if (!vendor.loaded) return;
    if (imgs === undefined) {
      setVendorImages({ loaded: true, empty: true, imageList: [] });
    } else {
      setVendorImages({ loaded: true, empty: false, imageList: imgs });
    }
  }, [vendor.loaded]);

  // 관심샵 추가/삭제
  const vendorFvrChange = async () => {
    await setFvrToggleLoading(true);
    await toggleVendorFvr(vendor.my_favorite);
    await setFvrToggleLoading(false);
  };

  // 매장 태그 목록
  const vendorTagArray = React.useMemo(() => vendor?.shop?.service_tag, [vendor?.shop?.service_tag]);

  // 전체 서비스 태그 목록
  const serviceTagArray = React.useMemo(() => {
    if (code.service_tag) {
      return Object.entries(code.service_tag);
    }
    return [];
  }, [code?.service_tag]);

  // 매장 연락처
  const vendorContact = React.useMemo(() => {
    if (!vendor.loaded || vendor.deleted) return null;
    return vendor?.shop?.tel ?? null;
  }, [vendor.loaded, vendor.deleted, vendor?.shop?.tel]);

  // 매장 영업시간
  const bizHours = React.useMemo(() => {
    return vendor?.shop?.biz_hours ?? null;
  }, [vendor]);

  // 서비스 태그 포맷
  const formatedServiceTag = React.useMemo(() => {
    if (vendorTagArray.length === 0 || serviceTagArray.length === 0) return;

    const formattedTags = serviceTagArray
      .filter((tag) => vendorTagArray.includes(tag[0]))
      .map((tag) => tag[1].ko)
      .join(', ');

    return (
      <Typography component="span" fontSize="12px">
        {formattedTags}
      </Typography>
    );
  }, [vendorTagArray, serviceTagArray]);

  return (
    vendor.loaded && (
      <Dialog
        open={openVendorDetailDialog}
        onTransitionEnter={() => {
          if (vendorClickState === 'time') {
            document.getElementById('vendor-biz-time')?.scrollIntoView?.();
          }
        }}
        fullScreen
      >
        {/* header */}
        <AppBar position="sticky">
          <Toolbar>
            {/* 페이지 제목 */}
            <Box position="absolute" width="100%" left={0} display="flex" justifyContent="center" alignItems="center">
              <Typography component="div" fontSize="18px" fontWeight={800} maxWidth="150px" noWrap>
                {vendor.biz.name}
              </Typography>
            </Box>

            {/* 뒤로가기 버튼 */}
            <BackButton color="inherit" onClick={() => navigate(-1)} />
            <Box flexGrow={1} />
            {/* 관심샵 버튼 */}
            <ButtonBase sx={{ borderRadius: '50%' }} onClick={vendorFvrChange} disabled={fvrToggleLoading}>
              {vendor.my_favorite ? <StarOnIcon /> : <StarOffIcon color="#140229" />}
            </ButtonBase>
          </Toolbar>
        </AppBar>

        {/* contents */}
        <Container sx={{ px: '20px', pb: '40px' }}>
          {/* 샵 이미지 */}
          {/* 샵 이미지가 1장일 경우 */}
          {imgs.length === 1 && (
            <Box
              sx={{
                width: '100%',
                height: '200px',
                background: `url("${imgs[0].thumb}")`,
                backgroundSize: 'cover',
                backgroundPosition: 'center top',
                borderRadius: '8px'
              }}
            />
          )}
          {/* 샵 이미지가 2장 이상일 경우 */}
          {imgs.length > 1 && (
            <List
              component={Stack}
              direction="row"
              disablePadding
              className="none-scroll-bar"
              spacing="10px"
              sx={{
                overflow: 'auto',
                mt: 1,
                mx: '-20px',
                px: 2,
                scrollSnapType: 'x',
                scrollPadding: theme.spacing(2)
              }}
            >
              {vendorImages.imageList.map((img, i) => (
                <Box
                  key={`vendor-detail-${i}-row`}
                  sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    minWidth: VENDOR_IMAGE_WIDTH,
                    minHeight: VENDOR_IMAGE_HEIGHT,
                    width: VENDOR_IMAGE_WIDTH,
                    height: VENDOR_IMAGE_HEIGHT,
                    background: `url("${img.thumb}")`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center top',
                    borderRadius: '8px'
                  }}
                />
              ))}
            </List>
          )}
          {/* 샵 소개 */}
          <Box mt={4} px="4px">
            <Typography fontSize="14px" fontWeight="800" letterSpacing="1px">
              소개
            </Typography>

            {/* 샵 이름 */}
            <Typography mt={2} fontSize="12px" fontWeight="800">
              {vendor?.biz?.name ?? <Skeleton />}
            </Typography>

            {/* 샵 소개글 */}
            <Typography fontSize="12px" mt={1}>
              {vendor?.shop?.comment ?? <Skeleton />}
            </Typography>
          </Box>

          {/* 샵 주소 & 지도 */}
          <Box mt={4}>
            <Typography fontSize="14px" fontWeight="800" px="4px" letterSpacing="1px">
              주소
            </Typography>
            <Typography fontSize="12px" mt={1} px="4px" sx={{ userSelect: 'text' }}>
              {vendorAddr}
            </Typography>
            <Box mt={2}>
              <VendorLocationMap vendor={vendor} />
            </Box>
          </Box>

          {vendorContact && (
            <Box mt={4}>
              <Typography fontSize="14px" fontWeight="800" px="4px" letterSpacing="1px">
                전화번호
              </Typography>
              <Typography fontSize="12px" mt={1} px="4px" sx={{ userSelect: 'text' }}>
                {vendorContact}
              </Typography>
            </Box>
          )}

          {/* 영업시간 */}
          <Box component="section" mt={4} px="4px" id="vendor-biz-time">
            <Typography fontSize="14px" fontWeight="800" letterSpacing="1px">
              영업시간
            </Typography>
            <Stack spacing="8px" marginTop="14px">
              <BizHourOnDay label="월요일" bizHourInfo={bizHours.MON} isToday={todayStr === 'MON'} />
              <BizHourOnDay label="화요일" bizHourInfo={bizHours.TUE} isToday={todayStr === 'TUE'} />
              <BizHourOnDay label="수요일" bizHourInfo={bizHours.WED} isToday={todayStr === 'WED'} />
              <BizHourOnDay label="목요일" bizHourInfo={bizHours.THU} isToday={todayStr === 'THU'} />
              <BizHourOnDay label="금요일" bizHourInfo={bizHours.FRI} isToday={todayStr === 'FRI'} />

              <BizHourOnDay
                label="토요일"
                color={(theme) => theme.palette.error.main}
                bizHourInfo={bizHours.SAT}
                isToday={todayStr === 'SAT'}
              />
              <BizHourOnDay
                label="일요일"
                color={(theme) => theme.palette.error.main}
                bizHourInfo={bizHours.SUN}
                isToday={todayStr === 'SUN'}
              />
            </Stack>
          </Box>

          {/* 추가정보 */}
          {vendorTagArray.length > 0 && (
            <Box mt={4} px="4px">
              <Typography fontSize="14px" fontWeight="800" letterSpacing="1px">
                추가정보
              </Typography>
              <Box mt="14px">{formatedServiceTag}</Box>
            </Box>
          )}
        </Container>
      </Dialog>
    )
  );
}

export default VendorDetailDialog;

VendorDetailDialog.propTypes = {
  openVendorDetailDialog: PropTypes.bool.isRequired,
  vendor: PropTypes.object.isRequired,
  imgs: PropTypes.array,
  vendorAddr: PropTypes.string.isRequired,
  toggleVendorFvr: PropTypes.func.isRequired,
  vendorClickState: PropTypes.string
};

/* eslint-disable react/prop-types */
const BizHourOnDay = ({ label, color = '#140229', bizHourInfo, isToday = false }) => {
  const theme = useTheme();
  const { available, open_hour: openHour, open_min: openMin, close_hour: closeHour, close_min: closeMin } = bizHourInfo;
  let bizHourStr = '휴무';
  if (available) {
    const startDt = moment(`${(openHour + '').padStart(2, '0')}${(openMin + '').padStart(2, '0')}`, 'hhmm').format('A hh:mm');
    const closeDt = moment(`${(closeHour + '').padStart(2, '0')}${(closeMin + '').padStart(2, '0')}`, 'hhmm').format('A hh:mm');
    bizHourStr = `${startDt} ~ ${closeDt}`;
  }

  // render
  return (
    <Stack
      direction="row"
      spacing={1}
      divider={
        <Box display="flex" alignItems="center" pb="1px">
          <Box height="9px" width="1px" bgcolor={alpha(theme.palette.text.primary, 0.1)} />
        </Box>
      }
    >
      <Typography fontSize="12px" color={color} sx={{ fontWeight: `${isToday && '700'}` }}>
        {label}
      </Typography>
      <Box
        fontSize="12px"
        fontWeight="normal"
        letterSpacing="-0.16px"
        sx={{ position: 'relative', pr: '50px', minWidth: 215, fontWeight: `${isToday && '700'}` }}
      >
        {bizHourStr}
        {isToday && (
          <Box position="absolute" height="100%" display="flex" alignItems="center" top={0} right={45}>
            <TodayTagIcon />
          </Box>
        )}
      </Box>
    </Stack>
  );
};

const TodayTagIcon = () => (
  <SvgIcon width="38px" height="19px" viewBox="0 0 38 21" sx={{ width: '38px', height: '19px' }}>
    <rect width="38" height="21" rx="10.5" style={{ fill: '#e7e4eb', opacity: 0.8 }} />
    <text
      transform="translate(19 14)"
      style={{ fill: '#140229', fontSize: '10px', fontFamily: 'D-DIN-Bold,D-DIN', fontWeight: 700, letterSpacing: '-0.01em' }}
    >
      <tspan x="-13.265" y="0">
        TODAY
      </tspan>
    </text>
  </SvgIcon>
);
