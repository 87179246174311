import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Badge, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

// assets
import { useSelector } from '../../store';
import { CartIcon } from '../icons';

/**
 * 장바구니 버튼
 *
 * @param {string} color
 * @param {boolean} showBadge
 * @param {'end'|'start'|false} edge
 * @param other
 */
function CartButton({ color: colorProp, showBadge = false, edge = false, ...other }) {
  const navigate = useNavigate();

  const openCartDrawer = () => {
    navigate('/my/cart');
  };

  // 장바구니 정보
  const cart = useSelector((state) => state.cart);

  // render
  return (
    <ButtonBase
      aria-label="장바구니"
      edge={edge}
      onClick={() => {
        openCartDrawer();
      }}
      {...other}
    >
      <Badge
        color="secondary"
        invisible={!showBadge || !cart?.totalItems}
        badgeContent={cart.totalItems}
        overlap="circular"
        max={99}
        sx={{
          '& .BaseBadge-badge': {
            top: '11px',
            right: cart.totalItems < 99 ? '9px' : '5px',
            fontFamily: 'D-DIN-Bold,D-DIN',
            fontSize: '11px',
            minWidth: cart.totalItems < 99 ? '14px' : '22px',
            maxWidth: cart.totalItems < 99 ? '14px' : '22px',
            minHeight: '14px',
            maxHeight: '14px'
          }
        }}
      >
        <CartIcon color={colorProp ?? '#140229'} />
      </Badge>
    </ButtonBase>
  );
}

CartButton.propTypes = {
  color: PropTypes.string,
  count: PropTypes.number,
  showBadge: PropTypes.bool,
  edge: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

export default CartButton;

const ButtonBase = styled((props) => <IconButton {...props} />)`
  padding: 0;
  margin-left: 13px;
`;
