import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useNavigationType } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { doc, getDoc, Timestamp } from 'firebase/firestore';
import moment from 'moment';

// project imports
import { useScriptRef } from 'hooks';
import { Numbers } from 'utils/bourgogne-tools';
import { getFirestore } from 'utils/firebase-tools';
import { getMember } from 'services/UserService';
import { HomeContentHeader } from '../index';

// material-ui
import { Box, ButtonBase, Card, CardHeader, Container, Grid, List, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// assets
import StarIcon from './assets/ico_home_review_star.png';
import WishIcon from './assets/ico_home_review_wish.png';

const ITEM_WIDTH = 222;
const ITEM_HEIGHT = 270;

let lastScrollLeft = 0;

/**
 * 홈 화면 -> 대표 리뷰 목록 (주문자들의 솔직한 후기)
 *
 * @param {string} title - 제목
 * @param {boolean} loading - 로딩여부
 * @param {boolean} error - 에러여부
 * @param {boolean} showMoreButton - '전체보기' 버튼 표현여부
 * @param {function} onMoreButtonClick - '전체보기' 버튼 클릭 이벤트 콜백
 * @param {Array} reviews - '리뷰' 목록
 * @returns {ReviewList}
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function ReviewList({
  title,
  loading = true,
  error, // todo error 처리
  showMoreButton = false,
  onMoreButtonClick = () => false,
  reviews = []
}) {
  const navigationType = useNavigationType();
  const listRef = React.useRef(null);

  // 뒤로가기로 접근 시 스크롤 위치 복구
  React.useEffect(() => {
    if (listRef.current && lastScrollLeft && navigationType === 'POP') {
      listRef.current.scrollLeft = lastScrollLeft;
    }
  }, [listRef]);

  // render
  return (
    !loading &&
    !error && (
      <Box mt="56px" mb="28px" sx={{ overflowX: 'hidden' }}>
        <Paper square component="section" elevation={0}>
          <Container sx={{ pt: 0 }}>
            <Box component="article" position="relative">
              <Box pl="2px">
                <HomeContentHeader title={title} noWrap showMoreButton={showMoreButton} onMoreButtonClick={onMoreButtonClick} />
              </Box>

              {/* 리뷰 리스트 */}
              <List
                component={Stack}
                direction="row"
                disablePadding
                className="none-scroll-bar"
                spacing="8px"
                ref={listRef}
                onScroll={(e) => {
                  lastScrollLeft = e.target.scrollLeft;
                }}
                sx={{
                  overflow: loading ? 'hidden' : 'auto',
                  mt: '28px',
                  scrollSnapType: 'x',
                  scrollPadding: '20px',
                  mx: '-20px',
                  px: '20px'
                }}
              >
                {loading ? (
                  <>
                    <ReviewSkeleton />
                    <ReviewSkeleton />
                    <ReviewSkeleton />
                  </>
                ) : (
                  reviews.map((review, i) => {
                    return <MainReview key={`pdata-main-review-${i}`} review={review} />;
                  })
                )}
              </List>
            </Box>
          </Container>
        </Paper>
      </Box>
    )
  );
}

ReviewList.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  showMoreButton: PropTypes.bool,
  onMoreButtonClick: PropTypes.func,
  reviews: PropTypes.array
};

export default ReviewList;

const ReviewSkeleton = () => (
  <ReviewCard>
    <Skeleton variant="rectangular" height={ITEM_HEIGHT} />
  </ReviewCard>
);

const ReviewCard = styled((props) => <Card elevation={0} {...props} />)`
  min-width: ${ITEM_WIDTH}px;
  width: ${ITEM_WIDTH}px;
  height: ${ITEM_HEIGHT}px;
  overflow: hidden;
  position: relative;
  border-radius: 12px;
  z-index: 0;
  display: inline-flex;
  flex-flow: column;
  justify-content: space-between;
  scroll-snap-align: start;
`;

/* eslint-disable react/prop-types */
const ReviewCardBg = ({ src, ...props }) => {
  const navigationType = useNavigationType();

  return (
    <CardBgBase className="review-bg" {...props}>
      {src ? (
        <>
          <LazyLoadImage
            placeholder={<Skeleton variant="rectangular" width="222px" height="222px" />}
            visibleByDefault={navigationType !== 'PUSH'}
            width="222px"
            height="222px"
            effect="blur"
            style={{
              objectFit: 'cover',
              objectPosition: 'center center'
            }}
            src={src}
            alt={'리뷰 배경 이미지'}
          />
          <Box
            width="222px"
            height="222px"
            bgcolor="black"
            sx={{ opacity: 0.35, position: 'absolute', inset: 0, top: 0, left: 0, right: 0, bottom: 0 }}
          />
        </>
      ) : (
        <Skeleton variant="rectangular" width="100%" height="100%" />
      )}
    </CardBgBase>
  );
};

const CardBgBase = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0;
  z-index: -1;
  * {
    user-select: none;
  }
`;

/**
 * 리뷰 카드 해더
 * @param displayName - 사용자 닉네임
 * @param createdAt - 리뷰작성일

 * @returns {ReviewCardHeader}
 *
 * @authors 조현권<hkcho@wineone.io>, 이재일<leeji@wineone.io>
 */
const ReviewCardHeader = ({ displayName, createdAt }) => {
  const reviewDate = React.useMemo(() => {
    let dateStr = '...';
    if (createdAt) {
      const timestamp = createdAt.toDate ? createdAt : new Timestamp(createdAt.seconds, createdAt.nanoseconds);
      dateStr = moment(timestamp.toDate()).fromNow();
    }
    return dateStr;
  }, [createdAt]);

  // render
  return (
    <CardHeader
      disableTypography
      // 작성자 이름
      title={
        <Typography textAlign="right" fontSize={10} fontWeight={700} lineHeight="normal" noWrap>
          {displayName}
        </Typography>
      }
      subheader={
        <Typography textAlign="right" fontSize={10} lineHeight="normal" marginTop="4px">
          {reviewDate}
        </Typography>
      }
      sx={{
        bgcolor: 'transparent',
        p: 0,
        pt: '16px',
        px: '16px',
        color: 'text.white',
        '& .MuiCardHeader-content': {
          lineHeight: 1
        },
        '& .MuiCardHeader-title': {
          lineHeight: 1
        },
        '& .MuiCardHeader-avatar': {
          mr: '4px'
        },
        '& .ProductDetailButton': {
          margin: 0
        }
      }}
    />
  );
};

/**
 * 메인 리뷰
 * @param review
 * @returns {JSX.Element}
 * @authors 조현권<hkcho@wineone.io>, 이재일<leeji@wineone.io>
 */
function MainReview({ review }) {
  const navigate = useNavigate();
  const scriptedRef = useScriptRef();

  // 리뷰 작성자
  const [writer, setWriter] = React.useState({ loaded: false });
  // 리뷰 pdata
  const [pdata, setPdata] = React.useState(null);

  React.useEffect(() => {
    fetchWriter();
  }, [review.uid]);

  React.useLayoutEffect(() => {
    fetchPdata();
  }, [review.pdata_id]);

  // review 작성자 정보
  const fetchWriter = React.useCallback(async () => {
    const snapshot = await getMember(review.uid).catch((error) => {
      console.error('[1KMWINE] 작성자 조회 중 오류가 발생했습니다.', error);
      return { error };
    });

    if (!scriptedRef.current) {
      console.warn('[ReviewList][MainReview] Unmounted component.');
      return undefined;
    }

    if (snapshot.error) {
      console.error('작성자 정보조회 오류: ', snapshot.error);
      return;
    }

    // console.debug(`[${review.uid}] Writer snapshot exists: `, snapshot.exists());
    if (snapshot.exists()) {
      setWriter({ loaded: true, exists: true, ...snapshot.data() });
    } else {
      console.warn('[MainReview] 작성자 정보가 존재하지 않습니다. (uid: ', review.uid, ')');
      setWriter({ loaded: true, exists: false });
    }
  }, [review.uid]);

  // pdata 정보 가져오기
  const fetchPdata = async () => {
    const snapshot = await getDoc(doc(getFirestore(), 'pdata', review.pdata_id));

    if (!scriptedRef.current) {
      console.warn('[MainReview][fetchPdata] Unmounted component.');
      return;
    }

    if (snapshot.error) {
      console.error('pdata 정보조회 오류: ', snapshot.error);
    }

    const pdata = snapshot.data();
    setPdata({ loaded: true, ...pdata });
  };

  return (
    <ReviewCard>
      <Box
        width="100%"
        height={ITEM_WIDTH}
        position="relative"
        onClick={() => {
          console.log('리뷰 상세화면으로 이동: ', `/home/review/${review.id}`);
          if (pdata) {
            navigate(`/home/main-review/${review.id}`, { state: { review, pdata } });
          }
        }}
      >
        <ReviewCardBg src={review.main_image.thumb.url} />
        <ReviewCardHeader
          displayName={writer.loaded ? (writer.exists ? writer?.nickname : '[탈퇴한 회원]') : null} // 작성자 이름
          createdAt={review.created_at}
        />
        <Box
          sx={{
            bgcolor: 'transparent',
            color: '#ffffff',
            zIndex: 1,
            position: 'absolute',
            bottom: '16px',
            left: '16px',
            maxWidth: '190px'
          }}
        >
          <Typography component="div" fontSize={12} fontWeight={900} lineHeight="normal" letterSpacing="-0.12px" marginBottom="10px">
            {pdata?.name?.ko ?? null}
          </Typography>

          <Typography fontSize={16} fontWeight={900} sx={{ fontStretch: 'normal', lineHeight: 'normal', letterSpacing: '-0.21px' }}>
            {review.summary}
          </Typography>
          <Box mt="4px">
            <Grid container display="flex" alignItems="center" spacing={1}>
              <Grid item xs="auto">
                {/* 리뷰 점수 */}
                <ReviewPointSimple color="#ffffff73" point={pdata?.star ?? 0} count={pdata?.reviews ?? 0} />
              </Grid>
              <Grid item xs="auto">
                {/* 좋아요 개수 */}
                <WishCountSimple color="#ffffff73" count={pdata?.wishes ?? 0} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <ButtonBase
        sx={{ height: '48px', border: '1px solid #e7e4eb', borderTop: 0, borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px' }}
        onClick={() => {
          navigate(`/mall/wine/${review.pdata_id}`);
        }}
      >
        <Box>
          <Typography>주문 가능 와인샵 보기</Typography>
        </Box>
      </ButtonBase>
    </ReviewCard>
  );
}

function ReviewPointSimple({ point = 0, count = 0, ...other }) {
  return (
    <Box height="16px" display="flex" justifyContent="center" alignItems="center" {...other}>
      <Box component="img" src={StarIcon} width="12px" height="12px" />

      {/* 구매자 평점 */}
      <Typography color="#FFFFFF" fontWeight={100} fontSize={14} sx={{ ml: '4px', mt: '1px', opacity: '.6' }}>
        {point}
      </Typography>

      {/* 상품리뷰 개수 */}
      <Typography color="#FFFFFF" fontWeight={100} fontSize={14} sx={{ opacity: '.6' }}>
        &nbsp;(
        {count > 9999 ? Numbers.nFormatter(count, 0) : count.toLocaleString()})
      </Typography>
    </Box>
  );
}

ReviewPointSimple.propTypes = {
  point: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  count: PropTypes.number
};

function WishCountSimple({ count = 0, color = '#fec466', ...other }) {
  return (
    <Box height="16px" display="flex" justifyContent="center" alignItems="center" {...other}>
      <Box component="img" src={WishIcon} width="12px" height="12px" />
      <Typography color="#FFFFFF" fontWeight={100} fontSize={14} sx={{ ml: '4px', opacity: '.6' }}>
        {count > 9999 ? Numbers.nFormatter(count, 0) : count.toLocaleString()}
      </Typography>
    </Box>
  );
}

WishCountSimple.propTypes = {
  count: PropTypes.number,
  color: PropTypes.string
};
