import React from 'react';
import { useLocation, useNavigate, useNavigationType, useParams } from 'react-router-dom';
import { useLocationState } from 'react-router-use-location-state';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import moment from 'moment/moment';
import { httpsCallable } from 'firebase/functions';

// project imports
import { useAuth, useScriptRef } from 'hooks';
import { getFirebaseFunctions } from 'utils/firebase-tools';
import { closeBackdrop, openBackdrop } from 'store/slices/backdrop';
import { useDispatch, useSelector } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { CLO_CODE } from 'config';
import { WoAlert } from 'utils/kmwine-alerts';
import { getTodayOneBottleCheck, getTodayOneBottleDetail } from 'services/ProductService';
import ProductImage from '../../ThemeSection/components';
import { AddressIcon, BizTimeIcon } from 'components';
import { EndOrderModal, EndSaleModal, SoldOutModal } from '../components';
import { TOB } from '../index';
import { PdataInfo } from 'components/pdata';

// material-ui
import { Badge, Box, Button, ButtonBase, Container, Skeleton, Stack, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// assets
import wineDefaultImg from 'assets/images/default_wine.webp';
import vendorDefaultImg from 'assets/images/default_vendor_rep.png';

// 입점샵 대표 이미지 세로 높이
const VENDOR_IMG_HEIGHT = 210;

// 상품 카드 세로 높이
const PRODUCT_CARD_HEIGHT = 274;

/**
 * 오늘의 한 병 상세페이지
 * 접근 가능한 상태 -> 오픈(관심샵이 아닌 경우), 오픈 예정, 품절, 종료
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
const TodayOneBottleDetail = () => {
  const scriptedRef = useScriptRef();
  const navigate = useNavigate();
  const globalDispatch = useDispatch();

  const location = useLocation();

  const navigationType = useNavigationType();

  const sendCloError = httpsCallable(getFirebaseFunctions(), 'call-cdm-clo-error');

  // 로그인 사용자 정보
  const { user } = useAuth();

  const { userLocation } = useSelector((state) => state);

  // 오늘의 한 병 id
  const { tobId } = useParams();

  // 오늘의 한 병 정보
  const [todayOneBottle, setTodayOneBottle] = React.useState({ loaded: false, data: null });

  // 판매 시간 종료 모달
  const [endSaleModal, setEndSaleModal] = React.useState(false);
  // 주문 완료 안내 모달
  const [orderCompleteModal, setOrderCompleteModal] = React.useState(false);
  // 품절 모달
  const [soldOutModal, setSoldOutModal] = React.useState(false);

  // 상세보기할 pdata Id
  const [detailPdataId, setDetailPdataId] = useLocationState('tob_pdataId', null);
  // pdata 상세보기
  const [showPdataInfo, setShowPdataInfo] = useLocationState('tob_showPdataInfo', false);

  /*
    오늘의 한 병 체크 상태
    END_SALE -> 판매시간 종료
    SALE_OVER -> 인당 구매수량 초과
    SOLD_OUT -> 매진
    NOT_FVR -> 관심샵 미등록
   */
  const [tobState, setTobState] = React.useState(null);

  // 입점샵 대표 이미지 정보
  const [vendorSrc, setVendorSrc] = React.useState(null);

  // 해당 페이지는 뒤로가기로 접근 x -> 홈화면으로 이동
  React.useEffect(() => {
    if (navigationType === 'POP' && showPdataInfo) {
      navigate('/', { replace: true });
    }
  }, [navigationType]);

  // 입점샵 아이디
  const vendorId = React.useMemo(() => {
    if (todayOneBottle.loaded) return todayOneBottle.data.vendor_id;
    return '';
  }, [todayOneBottle.loaded]);

  React.useLayoutEffect(() => {
    if (!tobId) return;
    fetchTodayOneBottleCheck(tobId);
    fetchTodayOneBottleDetail(tobId);
  }, []);

  // 오늘의 한 병 체크
  const fetchTodayOneBottleCheck: Promise<any> = React.useCallback(async (tobId) => {
    const body = {
      bottleId: tobId,
      requestFvr: false
    };

    const result = await getTodayOneBottleCheck(body).catch((error) => ({ error }));

    if (!scriptedRef.current) {
      console.warn('[TodayOneBottleDetail][getTodayOneBottleCheck] Unmounted component.');
      return undefined;
    }

    // 오늘의 한 병 체크 조회 실패
    if (result.error) {
      console.error('[TodayOneBottleDetail][getTodayOneBottleCheck] ', result.error);
      WoAlert.fire('', `오늘의 한 병 상품 조회 중 오류가 발생했습니다.<br/>잠시 후 다시 시도해주십시오.`, 'error').then(() => {
        navigate(-1);
      });
      return undefined;
    }

    setTobState(result);
  }, []);

  // 오늘의 한 병 상세 조회
  const fetchTodayOneBottleDetail: Promise<any> = React.useCallback(async (tobId) => {
    const result = await getTodayOneBottleDetail(tobId).catch((error) => ({ error }));

    if (!scriptedRef.current) {
      console.warn('[TodayOneBottleDetail][fetchTodayOneBottleDetail] Unmounted component.');
      return undefined;
    }

    // 오늘의 한 병 조회 실패
    if (result.error) {
      console.error('[TodayOneBottleDetail][fetchTodayOneBottleDetail] ', result.error);
      return undefined;
    }

    const { todayBottle, success } = result;

    // 잘못된 Response 값이 반환됨
    if (!success) {
      console.error('[TodayOneBottleDetail][fetchTodayOneBottleDetail] 잘못된 Response 값이 반환됨');
      return undefined;
    }

    // show가 false 인 경우
    if (!todayBottle.show || todayBottle.show === 'false') {
      WoAlert.fire('오늘의 한 병', '상품 전시 기간이 종료되었습니다', 'warning').then(() => {
        navigate('/', { replace: true });
      });
      return false;
    }

    setTodayOneBottle({ loaded: true, data: todayBottle });
  }, []);

  // 입점샵 대표 이미지 세팅
  React.useEffect(() => {
    if (!todayOneBottle.data) return;

    const { vendor_img } = todayOneBottle.data;

    setVendorSrc(vendor_img[0]?.thumb ?? vendorDefaultImg);
  }, [todayOneBottle]);

  // 이미지 조회 실패시 기본 상품 이미지로 교체
  const onError = React.useCallback(() => {
    setVendorSrc(vendorDefaultImg); // Replace to default vendor rep image.
  }, []);

  // 매장 주소
  const vendorAddr = React.useMemo(() => {
    if (!todayOneBottle.data) return null;

    let addr;
    if (todayOneBottle.data?.biz__address1) {
      addr = todayOneBottle.data?.biz__address1;
      if (todayOneBottle.data?.biz__address2) addr += `\r\n${todayOneBottle.data?.biz__address2}`;
    }
    return addr;
  }, [todayOneBottle.data]);

  // 매장 영업시간
  const bizHourStr = React.useMemo(() => {
    if (!todayOneBottle.data) return null;

    const day = moment().locale('en').format('ddd').toUpperCase();
    const bizHour = todayOneBottle.data.biz_hours[day];
    let bizHourStr;
    if (bizHour?.available) {
      const startTime = moment(`${bizHour.open_hour}`.padStart(2, '0') + `${bizHour.open_min}`.padStart(2, '0'), 'HHmm').format('A hh:mm');
      const closeTime = moment(`${bizHour.close_hour}`.padStart(2, '0') + `${bizHour.close_min}`.padStart(2, '0'), 'HHmm').format(
        'A hh:mm'
      );
      bizHourStr = `${startTime} ~ ${closeTime}`;
    } else {
      bizHourStr = '휴일';
    }
    return bizHourStr;
  }, [todayOneBottle.data]);

  // 오늘의 한 병 상품 카드
  const todayBottleCard = React.useMemo(() => {
    // 오늘의 한 병 데이터가 준비 안되있을시 skeleton 영억 return
    if (!todayOneBottle.data)
      return (
        <Skeleton
          variant="rectangular"
          animation="wave"
          sx={{ marginTop: '12px', width: '100%', height: PRODUCT_CARD_HEIGHT, borderRadius: '12px' }}
        />
      );

    const { sale_state, quantity, bottle_img, introduction, pdata___id, price__original } = todayOneBottle.data;

    // 오늘의 한 병 상태
    let tobState = sale_state;

    // 품절 여부
    if (sale_state === TOB.SALE.ING && quantity === 0) {
      tobState = TOB.CODE.SOLD_OUT;
    }

    // pdata 이미지
    const pdataSrc = bottle_img?.thumb ?? wineDefaultImg;

    return (
      <Box
        sx={{
          marginTop: '12px',
          textAlign: 'center',
          width: '100%',
          height: PRODUCT_CARD_HEIGHT,
          backgroundColor: '#FFF',
          borderRadius: '12px',
          padding: '24px 42px 20px 42px',
          boxShadow: '0px 2px 6px #00000029',
          position: 'relative'
        }}
      >
        {/* dim 처리 -> 상태가 ING가 아닌 경우 */}
        {tobState !== TOB.SALE.ING && (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              margin: '-24px -42px -20px -42px',
              background: 'rgba(0, 0, 0, 0.5)',
              borderRadius: '12px',
              zIndex: 1
            }}
          >
            <Box mt={tobState === 'WAIT' ? '0px' : '79px'}>
              {/* 품절 안내 */}
              {tobState === TOB.CODE.SOLD_OUT && (
                <Typography
                  sx={{
                    fontSize: '24px',
                    fontWeight: 900,
                    color: '#FFF',
                    letterSpacing: '-0.24px',
                    lineHeight: 'normal',
                    textShadow: '0 3px 6px #00000029'
                  }}
                >
                  SOLD OUT
                </Typography>
              )}

              {/* 종료 안내 */}
              {tobState === TOB.SALE.END && (
                <Typography
                  sx={{
                    fontSize: '24px',
                    fontWeight: 900,
                    color: '#FFF',
                    letterSpacing: '-0.24px',
                    lineHeight: 'normal',
                    textShadow: '0 3px 6px #00000029'
                  }}
                >
                  CLOSED
                </Typography>
              )}
              <ButtonBase
                sx={{
                  mt: tobState === 'WAIT' ? '109px' : '8px',
                  py: '8px',
                  width: '123px',
                  background: 'rgba(0, 0, 0, 0.6)',
                  color: '#FFF',
                  lineHeight: 'normal',
                  fontSize: '12px',
                  letterSpacing: '-0.12px',
                  borderRadius: '16px'
                }}
                onClick={() => moveToWineInfo(pdata___id)}
              >
                상품 상세 보기
                <KeyboardArrowRightIcon sx={{ ml: '4px', width: '13px', height: '13px' }} />
              </ButtonBase>
            </Box>
          </Box>
        )}
        {/* 상품 이미지 */}
        <ProductImage
          width="100%"
          bgcolor="#FFF"
          height="130px"
          src={pdataSrc}
          alt="today-one-bottle-pdata-image"
          visibleByDefault={false}
        />

        {/* 상품 설명 */}
        <Box mt="8px" mb="16px">
          {tobState === TOB.SALE.WAIT ? (
            <Typography
              paragraph
              sx={{
                fontSize: '18px',
                fontWeight: 900,
                background: '#fff',
                boxDecorationBreak: 'clone',
                color: '#000',
                display: 'inline',
                lineHeight: 1.3,
                marginBottom: '10px',
                padding: '0 4px',
                boxShadow: '0px 3px 6px #00000029',
                position: 'relative',
                zIndex: 1
              }}
            >
              {introduction}
            </Typography>
          ) : (
            <Typography
              paragraph
              sx={{
                fontSize: '18px',
                fontWeight: 900,
                letterSpacing: '-0.18px',
                background: 'linear-gradient(to top, #F3E1F2 60%, transparent 50%)',
                boxDecorationBreak: 'clone',
                color: '#140229',
                display: 'inline',
                lineHeight: 1.3,
                marginBottom: '10px',
                padding: '0 2px'
              }}
            >
              {introduction}
            </Typography>
          )}
        </Box>

        {/* 상품 가격 */}
        <Stack direction="row" justifyContent="center" alignItems="center" spacing="2px" mt="16px" position="relative" zIndex={1}>
          <Typography fontSize="22px" color={tobState === TOB.SALE.WAIT ? '#fff' : '#140229'} fontFamily="D-DIN" lineHeight={1}>
            {price__original.toLocaleString()}
          </Typography>
          <Typography fontSize="16px" color={tobState === TOB.SALE.WAIT ? '#fff' : '#140229'} fontWeight={200} lineHeight={1}>
            원
          </Typography>
        </Stack>
      </Box>
    );
  }, [todayOneBottle.data]);

  // 와인 상세정보 보기
  const moveToWineInfo = async (pdataId) => {
    if (showPdataInfo) return false; // 이미 여려있을 때 또는 pdataId가 올바르지 않을 경우

    if (!pdataId) {
      console.error('[장바구니] 와인 상세 정보 조회 중 오류 발생. 와인 상세 정보가 존재하지 않음.');
      return undefined;
    }

    setDetailPdataId(pdataId);
    setShowPdataInfo(true, { method: 'push' });
  };

  // 오늘의 한 병 액션 버튼 이벤트
  const tobActionBtnClickEvent = React.useCallback(async (id, saleState, quantity, product_id, vendor_id, myFvr) => {
    // 오늘의 한 병 판매 상태가 오픈(ING)인 경우 주문하기 페이지로 이동 여부 확인
    if (saleState === TOB.SALE.ING) {
      globalDispatch(openBackdrop({ open: true, text: '상품 정보를 불러오는 중입니다.' }));

      const body = {
        bottleId: id,
        requestFvr: false
      };

      const result = await getTodayOneBottleCheck(body).catch((error) => ({ error }));

      if (!scriptedRef.current) {
        console.warn('[TodayOneBottleDetail][getTodayOneBottleCheck] Unmounted component.');
        return undefined;
      }

      // 오늘의 한 병 체크 조회 실패
      if (result.error) {
        console.error('[TodayOneBottleDetail][getTodayOneBottleCheck] ', result.error);
        return undefined;
      }

      const { code, todayBottle } = result;
      const { display_state, remove: _remove, show: _show, quantity: todayBottleQuantity } = todayBottle;

      // 삭제 여부
      const remove = _remove === true || _remove === 'true';
      // 숨김 여부
      const show = _show === true || _show === 'true';

      // 삭제 또는 숨김 처리 되었을 시
      if (remove || !show) {
        globalDispatch(closeBackdrop());
        WoAlert.fire('오늘의 한 병', '상품 전시 기간이 종료되었습니다.', 'warning').then(() => {
          navigate('/', { replace: true });
        });
        return undefined;
      }

      // 판매 시간이 종료된 경우
      if (display_state === TOB.SALE.END) {
        globalDispatch(closeBackdrop());
        setEndSaleModal(true);
        return undefined;
      }

      // 오늘의 한병을 이미 주문한 경우
      if (code === TOB.CODE.SALE_OVER) {
        globalDispatch(closeBackdrop());
        setOrderCompleteModal(true);
        return undefined;
      }

      // 이미 오늘의 한 병이 품절됨
      if (quantity !== 0 && todayBottleQuantity === 0) {
        globalDispatch(closeBackdrop());
        setSoldOutModal(true);
        return undefined;
      }

      // 품절이고 관심샵으로 등록된 경우
      if (code === TOB.CODE.SOLD_OUT && myFvr) {
        navigate(`/vendor/d/${vendor_id}`, { replace: true });
        globalDispatch(closeBackdrop());
        return;
      }

      // 품절이고 관십샵으로 등록되지 않은 경우
      if (code === TOB.CODE.SOLD_OUT && !myFvr) {
        navigate(`/vendor/d/${vendor_id}`, { replace: true, state: { requestFvr: true } });
        globalDispatch(closeBackdrop());
        return;
      }

      // 이미 관심샵으로 등록된 경우
      if (myFvr) {
        navigate(`/order/today-bottle/confirm?pid=${product_id}&tid=${id}`, { replace: true });
        globalDispatch(closeBackdrop());
      }
      // 관심샵 등록 후 주문페이지로 이동
      else {
        await addFvrAndOrder(id, vendor_id);
        await globalDispatch(closeBackdrop());
      }
    }
    // 오늘의 한 병 판매 상태가 오픈(ING)이 아닌 경우 샵 상세 페이지로 이동
    else {
      if (myFvr) {
        navigate(`/vendor/d/${vendor_id}`, { replace: true });
        return undefined;
      }
      navigate(`/vendor/d/${vendor_id}`, { replace: true, state: { requestFvr: true } });
    }
  }, []);

  // 관심샵 등록하고 주문하기
  const addFvrAndOrder = React.useCallback(async (id, vendor_id) => {
    const body = {
      bottleId: id,
      requestFvr: true
    };

    const result = await getTodayOneBottleCheck(body).catch((error) => ({ error }));

    if (result.error) {
      console.error('[TodayOneBottleDetail][getTodayOneBottleCheck] ', result.error);

      // send clo
      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `관심샵 변경 중 오류`,
        msg: `[uid=${user._id}] ${JSON.stringify(result.error)}`,
        which: `${location.pathname}${location.search}`,
        param: { uid: user._id, vendor_id }
      })
        .then(console.log)
        .catch(console.error);

      WoAlert.fire('오늘의 한 병', '관심샵 등록 중 오류가 발생했어요.', 'warning');

      return undefined;
    }

    const { success, code, todayBottle } = result;
    const { product_id } = todayBottle;

    // 관심샵 등록이 성공했을 시
    if (success && code === TOB.CODE.ADD_FVR) {
      globalDispatch(
        openSnackbar({
          open: true,
          message: '관심샵이 추가 되었습니다.',
          variant: 'alert',
          alert: { color: 'success' },
          close: true,
          autoHideDuration: 2800
        })
      );

      navigate(`/order/today-bottle/confirm?pid=${product_id}&tid=${id}`, { replace: true });
    } else {
      WoAlert.fire('오늘의 한 병', '관심샵 등록 중 오류가 발생했어요.', 'warning').then(() => {
        navigate(0);
      });
    }
  }, []);

  // render
  return (
    <Box>
      {/* 오늘의 한 병 판매 시간 종료 모달 */}
      <EndSaleModal open={endSaleModal} vendor_id={vendorId} />

      {/* 오늘의 한 병을 이미 주문한 경우의 모달 */}
      <EndOrderModal open={orderCompleteModal} vendor_id={vendorId} />

      {/* 오늘의 한 병이 재고소진인 경우 */}
      <SoldOutModal open={soldOutModal} vendor_id={vendorId} />

      {/* 샵 대표 이미지 영역 */}
      <Box sx={{ width: '100%', height: VENDOR_IMG_HEIGHT, position: 'relative', bgcolor: '#FFF' }}>
        {/* 샵 이미지 하단 50% 영역 그라데이션 처리 */}
        <Box
          sx={{
            width: '100%',
            height: '100%',
            bottom: 0,
            position: 'absolute',
            zIndex: 1,
            background: `linear-gradient(180deg, #FFFFFF00, #00000070)`
          }}
        />

        {/* 샵 대표 이미지 */}
        <LazyLoadImage
          placeholder={
            <img
              src={vendorDefaultImg}
              alt="기본 샵 대표 이미지"
              draggable="false"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                objectPosition: 'center center'
              }}
            />
          }
          style={{ objectFit: 'cover', objectPosition: 'center top' }}
          width="100%"
          height="100%"
          effect="blur"
          draggable={false}
          src={vendorSrc}
          alt="입점샵 대표 이미지"
          onError={onError}
        />

        {/* 샵 소개글 */}
        {todayOneBottle.data?.shop__comment && (
          <Box position="absolute" zIndex={2} bottom="56px" mx="28px">
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 700,
                color: '#FFF',
                textShadow: '0px 3px 6px #00000029',
                letterSpacing: '-0.14px',
                wordBreak: 'keep-all',
                lineHeight: '1.2',
                maxHeight: '2.4em',
                display: '-webkit-box',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical'
              }}
            >
              {todayOneBottle.data?.shop__comment}
            </Typography>
          </Box>
        )}
      </Box>

      <Container sx={{ width: '100%', minHeight: 'calc(100vh - 170px)', px: '20px', pb: '40px', backgroundColor: '#F1F0F2' }}>
        {/* 오늘의 한 병 샵 정보 카드 */}
        <Box
          sx={{
            width: '100%',
            position: 'relative',
            backgroundColor: '#FFF',
            borderRadius: '12px',
            padding: '20px 24px 20px 24px',
            marginTop: '-40px',
            boxShadow: '0px -2px 6px #00000029',
            zIndex: 2
          }}
        >
          {/* 샵 이름 */}
          <Typography fontSize="18px" fontWeight={900} lineHeight="normal" letterSpacing="-0.18px" noWrap>
            {todayOneBottle.data?.vendor_name ?? <Skeleton width="130px" />}
          </Typography>

          {/* 주문 가능 와인 / 관심팬 */}
          <Box mt="12px" display="flex">
            {todayOneBottle.data ? (
              <>
                <Typography color="#9357E5">주문 가능 와인 {(todayOneBottle.data?.product_cnt ?? 0).toLocaleString()}</Typography>
                <Badge
                  variant="dot"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    px: '7px',
                    '& .MuiBadge-dot': { backgroundColor: '#E1E1E1', height: '2px', minWidth: '2px', position: 'relative' }
                  }}
                />
                <Typography color="#9357E5">관심팬 {(todayOneBottle.data?.fvr_cnt ?? 0).toLocaleString()}</Typography>
              </>
            ) : (
              <Skeleton width="175px" />
            )}
          </Box>

          {/* 매장 주소 */}
          <Box mt="14px">
            <Stack direction="row" spacing={1}>
              <AddressIcon />
              <Typography fontSize="13px" lineHeight="1.4">
                {!vendorAddr ? <Skeleton width="130px" /> : vendorAddr}
              </Typography>
            </Stack>
          </Box>

          {/* 매장 영업시간 */}
          <Box mt="8px">
            <Stack direction="row" alignItems="center" spacing={1}>
              <BizTimeIcon />
              <Typography fontSize="13px" lineHeight="normal">
                {bizHourStr ?? <Skeleton width="130px" />}
              </Typography>
            </Stack>
          </Box>
        </Box>

        {/* 오늘의 한 병 상품 카드 */}
        {todayBottleCard}

        {/* 오늘의 한 병 액션 버튼 */}
        <TOBActionButtons tobState={tobState} todayOneBottle={todayOneBottle} onClick={tobActionBtnClickEvent} />
      </Container>

      {/* pdata 상세정보 */}
      <PdataInfo show={showPdataInfo} pdataId={detailPdataId} />
    </Box>
  );
};

export default TodayOneBottleDetail;

/* eslint-disable react/prop-types */
const TOBActionButtons = React.memo(({ tobState, todayOneBottle, onClick }) => {
  const navigate = useNavigate();

  // 데이터가 조회 되지 않은 경우
  if (!tobState || !todayOneBottle.loaded || !todayOneBottle.data) {
    return (
      <Box mt="24px">
        <Skeleton variant="rectangular" sx={{ width: '100%', height: '51px', borderRadius: '26px' }} />
      </Box>
    );
  }

  // 오늘의 한병 정보
  const { _id, sale_state, quantity, product_id, vendor_id } = todayOneBottle.data;

  // 오늘의 한 병 상태
  const { code } = tobState;

  // 품절 여부
  const soldOut = quantity === 0;

  // 관심샵 등록 여부
  const { my_fvr: myFvr } = tobState.todayBottle;

  // 예정/품절/종료 플래그 (진행 중이 아님)
  const isNotIng = sale_state !== TOB.SALE.ING;

  // 버튼 내용
  let content = '샵 둘러보기';
  let subContent = '이전으로 돌아가기';

  // 1. 오픈 -> 관심샵 등록 안한 경우
  if (sale_state === TOB.SALE.ING && code === TOB.CODE.NOT_FVR) {
    content = '관심샵 등록하고 주문하기';
    subContent = '다음에 주문하기';
  }
  // 2. 예정/종료/품절 -> 관심샵 등록 안한 경우
  else if ((isNotIng && myFvr === 0) || (soldOut && myFvr === 0)) {
    content = '관심샵 등록하고 샵 둘러보기';
  }
  // 3. 예정/종료/품절 -> 관심샵 등록 한 경우
  else if ((isNotIng && myFvr > 0) || (soldOut && myFvr > 0)) {
    content = '샵 둘러보기';
  }

  return (
    <Box mt="24px">
      <Button
        fullWidth
        variant="contained"
        color="brand"
        onClick={() => onClick(_id, sale_state, quantity, product_id, vendor_id, myFvr > 0)}
        sx={{
          py: '16px',
          borderRadius: '26px',
          fontSize: 17,
          fontWeight: 800,
          lineHeight: 'normal',
          letterSpacing: '-0.17px'
        }}
      >
        {content}
      </Button>
      <Box textAlign="center">
        <ButtonBase
          onClick={() => navigate(-1)}
          sx={{ p: '2px', mt: '24px', fontSize: 12, lineHeight: 'normal', letterSpacing: '-0.12px' }}
        >
          {subContent}
        </ButtonBase>
      </Box>
    </Box>
  );
});
