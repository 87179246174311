import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocationState } from 'react-router-use-location-state';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

// project imports
import { useAuth, useLocalStorage, useScriptRef, useWineOne } from 'hooks';
import { dispatch, useSelector } from 'store';
import { CLO_CODE } from 'config';

import VendorLayout from './components/VendorLayout';
import SearchDialog from './components/SearchDialog';
import { SearchField } from '../../../components/search';
import { getFvrVendorList, getRecentVendorList, getVendorList, getVendorsCnt } from '../../../services/VendorService';

import VendorCard from './components/VendorCard';
import { SearchEmptyIcon } from '../../../components';
import { httpsCallable } from 'firebase/functions';
import { getFirebaseFunctions } from 'utils/firebase-tools';

import { WoAlert } from 'utils/kmwine-alerts';
import { fetchCodes } from 'store/slices/wo-constants';

// material-ui
import { AppBar, Box, CircularProgress, Container, Fade, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { VendorQrDialog } from '../../promotion';

const QUERY_HISTORY_MAX_LEN = 10;

const PAGE_SIZE = 8;

/**
 * '와인샵' 인덱스 페이지
 *
 * @returns {JSX.Element}
 * @authors 최효근<hkchoi@wineone.io>, 조현권<hkcho@wineone.io>
 *
 */
function VendorListIndex() {
  const navigate = useNavigate();
  const location = useLocation();
  const scriptedRef = useScriptRef();

  // 로그인 사용자 정보
  const { user } = useAuth();

  // 사용자 위치정보, 서비스 상수
  const { userLocation } = useSelector((state) => state);

  const listRef = React.useRef(null);

  // 검색어
  const [searchQuery, setSearchQuery] = useLocationState('vendor-search-query', '');
  // 최근 검색어
  const [queryHistory, setQueryHistory] = useLocalStorage('vendor-query-history', []);
  // '검색' 영역 활성화 여부
  const [searchOpen, setSearchOpen] = React.useState(false);
  // 샵리스트 숫자
  const [vendorCnt, setVendorCnt] = React.useState({ loaded: false });
  // 사용자 위치정보, 상수 관련 서비스
  const { woConstants } = useSelector((state) => state);

  const sendCloError = httpsCallable(getFirebaseFunctions(), 'call-cdm-clo-error');

  React.useLayoutEffect(() => {
    // 서비스 코드 조회
    dispatch(fetchCodes(['aroma', 'service_tag', 'wine_type']));
  }, [woConstants]);

  // '검색' 다이어로그 열기
  const openSearchArea = React.useCallback(() => {
    setSearchOpen(true);
  }, []);

  // '검색' 다이어로그 닫기
  const closeSearchArea = React.useCallback(() => {
    setSearchOpen(false);
  }, []);

  // 샵 검색결과 리스트
  const [vendorList, setVendorList] = React.useState({ loaded: false, loading: false, page: { current: 1, size: PAGE_SIZE }, items: [] });

  // 샵검색
  const searchVendor = (_searchQuery = '') => {
    // 최근 검색어에 추가
    if (_searchQuery.trim().length > 0) {
      try {
        let _oldQueryHistory = [...queryHistory];
        if (_oldQueryHistory.indexOf(_searchQuery) >= 0) {
          _oldQueryHistory.splice(_oldQueryHistory.indexOf(_searchQuery), 1);
        }

        // 기록하고 있을 최대 개수를 넘어갈 경우
        if (_oldQueryHistory.length > QUERY_HISTORY_MAX_LEN - 1) {
          _oldQueryHistory = _oldQueryHistory.splice(0, QUERY_HISTORY_MAX_LEN - 1);
        }

        setQueryHistory(() => [_searchQuery, ..._oldQueryHistory]);
      } catch (e) {
        /* DO NOTHING */
      }
    }
    setSearchQuery(() => _searchQuery.trim()); // 검색어 세팅
    setSearchOpen(false); // 검색 Dialog 닫는 역할
  };

  // 뒤로가기
  const pageBackFunc = React.useCallback(() => {
    console.debug(`'와인샵' 화면에서 '뒤로가기' 버튼이 감지되었습니다.`);
    // 검색 영역이 열려있었을 경우
    if (searchOpen) {
      closeSearchArea();
    } else if (window.history.length > 0) {
      navigate(-1);
    } else {
      navigate('/', { replace: true });
    }
  }, [location, searchOpen]);

  // 탭 네비게이션 state
  const [tabIndex, setTabIndex] = useLocationState('tabIndex', 'all');
  // 탭 변경
  const handleTabNavChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  /**
   * 입점샵 전체리스트 가져오기
   * @returns {Promise<undefined>}
   */
  const fetchVendorList: Promise<void> = async (current, searchQuery) => {
    setVendorList((vendorList) => ({ ...vendorList, items: current === 1 ? [] : vendorList.items, loading: true }));

    // 현재 핀에서 가까운 순으로 입점샵 리스트를 가져옵니다.
    const response = await getVendorList(userLocation.currentPin.pin_id, searchQuery, { current, size: PAGE_SIZE }).catch((error) => {
      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `와인샵 전체리스트 조회 오류#1`,
        msg: `[uid=${user._id}] ${JSON.stringify(response.error)}`,
        which: `${location.pathname}${location.search}`,
        param: { uid: user._id, error: response.error }
      })
        .then(console.log)
        .catch(console.error);
      return { error };
    });

    // Unmounted component
    if (!scriptedRef.current) {
      console.warn('[VendorListIndex][fetchVendorList] Unmounted component.');
      return;
    }

    // 입점샵 리스트조회 오류
    if (response.error) {
      setVendorList((vendorList) => ({ ...vendorList, loading: false }));
      await WoAlert.fire(`와인샵 리스트 조회 중\n오류가 발생했어요.`, '', 'warning').then(() => {
        navigate(-1);
      });
      return;
    }

    const {
      data, // 와인샵 리스트
      page, // 페이지 정보
      result // 처리결과
    } = response;

    // 리스트 조회 성공
    if (result.code === 0) {
      const vendors = parseVendor(data);
      setVendorList((oldList) => {
        const items = current === 1 ? vendors : [...oldList.items, ...vendors];
        return { loaded: true, loading: false, page, items };
      });
    } else {
      console.error(`와인샵 리스트 조회 중 오류`, result);
      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `와인샵 리스트 조회 오류#2`,
        msg: `[uid=${user._id}] ${JSON.stringify(result)}`,
        which: `${location.pathname}${location.search}`,
        param: { uid: user._id, error: result }
      })
        .then(console.log)
        .catch(console.error);
    }
  };

  // 최근 픽업한 샵 리스트 가지고 오기
  const fetchRecentVendorList: Promise<any> = async (current, searchQuery) => {
    setVendorList((vendorList) => ({ ...vendorList, items: current === 1 ? [] : vendorList.items, loading: true }));

    if (current > 1) {
      console.warn('최근 픽업한 샵은 페이징 처리 없음');
      return;
    }

    // 최근 픽업한샵 리스트 조회 (15개만 조회, 페이징 처리 없음)
    const response = await getRecentVendorList(searchQuery, { current, size: 15 }).catch((error) => {
      console.error('최근 픽업한 샵 리스트 조회 중 오류가 발생했습니다.', response.error);

      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `최근 픽업한 샵 리스트 조회 중 오류`,
        msg: `[uid=${user._id}] ${JSON.stringify(response.error)}`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user._id
        }
      })
        .then(console.log)
        .catch(console.error);
      return { error };
    });

    // Unmounted component
    if (!scriptedRef.current) {
      console.warn('[VendorListIndex][fetchRecentVendorList] Unmounted component.');
      return undefined;
    }

    // 최근 픽업한 샵 리스트 조회 오류
    if (response.error) {
      await WoAlert.fire(`최근 픽업한 샵 리스트 조회 중 오류가 발생했어요.`, '', 'warning').then(() => {
        setTabIndex('all');
      });
      return;
    }

    const {
      data, // 최근 픽업한 샵 리스트
      page, // 페이지 정보
      result // 처리결과
    } = response;

    // 최근 픽업한 샵 리스트 조회성공
    if (result.code === 0) {
      const vendors = parseVendor(data); // 최근 픽업한 샵 리스트

      setVendorList((oldList) => {
        const items = current === 1 ? vendors : [...oldList.items, ...vendors];
        return { loaded: true, loading: false, page, items };
      });
    } else {
      console.error(`최근 픽업한 샵 리스트 조회 중 오류.`, result);
      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `최근 픽업한 샵 리스트 조회 중 오류`,
        msg: `[uid=${user._id}] ${JSON.stringify(response.data.result)}`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user._id
        }
      })
        .then(console.log)
        .catch(console.error);
    }
    return undefined;
  };

  // 관심샵 리스트 가지고 오기
  const fetchFvrVendorList: Promise<void> = async (current, searchQuery) => {
    setVendorList((vendorList) => ({ ...vendorList, items: current === 1 ? [] : vendorList.items, loading: true }));

    // 관심샵 리스트 요청
    const response = await getFvrVendorList(searchQuery, { current, size: PAGE_SIZE }).catch((error) => {
      console.error('관심샵 리스트 조회 중 오류', error);

      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `관심샵 리스트 조회 중 오류#1`,
        msg: `[uid=${user._id}] ${JSON.stringify(error)}`,
        which: `${location.pathname}${location.search}`,
        param: { uid: user._id }
      })
        .then(console.log)
        .catch(console.error);
      return { error };
    });

    // Unmounted component
    if (!scriptedRef.current) {
      console.warn('[VendorListIndex][fetchRecentVendorList] Unmounted component.');
      return undefined;
    }

    // 관심 샵 리스트 조회 오류
    if (response.error) {
      await WoAlert.fire(`관심샵 리스트 조회 중 오류가 발생했어요.`, '', 'warning').then(() => {
        setTabIndex('all');
      });
      return;
    }

    const {
      data, // 관심샵 리스트
      page, // 페이지 정보
      result // 처리결과
    } = response;

    // 관심샵 리스트조회 성공
    if (result.code === 0) {
      const vendors = parseVendor(data); // 관심샵 리스트

      setVendorList((oldList) => {
        const items = current === 1 ? vendors : [...oldList.items, ...vendors];
        return { loaded: true, loading: false, page, items };
      });
    } else {
      console.error(`관심샵 리스트 조회 중 오류.`, data.result);
      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `관심 샵 리스트 조회 중 오류`,
        msg: `[uid=${user._id}] ${JSON.stringify(data.result)}`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user._id
        }
      })
        .then(console.log)
        .catch(console.error);
    }
    return undefined;
  };

  // 샵 리스트 숫자 가지고오기
  const fetchVendorsCnt: Promise<void> = async (): Promise<void> => {
    const response = await getVendorsCnt(searchQuery).catch((error) => {
      return { error };
    });

    if (!scriptedRef.current) {
      console.warn('[VendorListIndex][fetchVendorsCnt] Unmounted component.');
      return;
    }

    if (response.error) {
      await WoAlert.fire(`샵 리스트 카운트 조회 중\n오류가 발생했어요.`, '', 'warning');
      return;
    }

    const vendorCnt = response.data;
    setVendorCnt({ loaded: true, ...vendorCnt });
  };

  React.useLayoutEffect(() => {
    fetchVendorsCnt();
  }, [searchQuery]);

  const requestVendorList = (current, searchQuery) => {
    if (vendorList.loading) return;

    if (current === 1) {
      // 검색결과 초기화
      setVendorList({ loaded: false, loading: false, page: { current: 1, size: PAGE_SIZE }, items: [] });
    }

    if (tabIndex === 'all') {
      fetchVendorList(current, searchQuery);
    } else if (tabIndex === 'recentPickUp') {
      fetchRecentVendorList(current, searchQuery);
    } else if (tabIndex === 'fvrVendor') {
      fetchFvrVendorList(current, searchQuery);
    }
  };

  React.useLayoutEffect(() => {
    // console.log('------------ 1페이지 조회: ', tabIndex, searchQuery);
    requestVendorList(1, searchQuery);
  }, [userLocation, searchQuery, tabIndex]);

  // 스크롤 디렉션
  const [y, setY] = React.useState(window.scrollY);

  const handleScrollEvent = React.useCallback(
    (e) => {
      const window = e.currentTarget;
      // 스크롤이 아래방향으로 ( trigger 쓰지말고 y로 판단 )
      if (y < window.scrollY) {
        // 스크롤이 바닥에 거의(-100) 닿았을 때
        if (window.scrollY + window.innerHeight >= document.body.offsetHeight - 100) {
          if (!vendorList.loaded) return; // 최초 조회시 중복요청 제외
          if (vendorList.loading) return; // 페이지 요청 중일 경우 pass
          // 다음 페이지 호출
          if (vendorList.page.current < vendorList.page.total_pages) {
            console.log(`[VendorListIndex] 다음 페이지 호출 (${vendorList.page.current + 1}) [searchQuery=${searchQuery}]`);
            requestVendorList(vendorList.page.current + 1, searchQuery);
          }
          // 이미 검색결과를 모두 보여준 상태
          else {
            console.log('[1KMWINE] 모든 와인샵 리스트를 출력했습니다.', tabIndex, searchQuery);
          }
        }
      }
      setY(window.scrollY);
    },
    [y, searchQuery, vendorList.loading]
  );

  // 스크롤 감시
  React.useEffect(() => {
    setY(window.scrollY);
    window.addEventListener('scroll', handleScrollEvent);

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, [handleScrollEvent]);

  // 모바일에서 뒤로가기 눌렀을시
  const { onNativeBackPressed } = useWineOne();

  React.useEffect(() => {
    // 기기에서 뒤로가기 버튼을 눌렀을 때

    onNativeBackPressed(pageBackFunc);
  }, [pageBackFunc]);

  // v1.1.3: 입점샵 QR 쿠폰 발급안내 레이어
  const [vendorQrCouponTarget, setVendorQrCouponTarget] = React.useState(null);
  const [showVendorQrCouponDialog, setShowVendorQrCouponDialog] = React.useState(false);
  const [vendorQrCouponId, setVendorQrCouponId] = React.useState(null);

  /**
   * 관심샵 등록 쿠폰발급 안내 레이어 띄우기
   *
   * @param vendor
   * @param couponId
   */
  const handleVendorQrCouponDownload = (vendor, couponId) => {
    setVendorQrCouponTarget(vendor); // 관심샵 이벤트 입점샵
    setVendorQrCouponId(couponId); // 발급된 쿠폰 아이디
    setShowVendorQrCouponDialog(true); // 쿠폰발급안내 레이어 열기
  };

  /**
   * 관심샵 등록 쿠폰발급 안내 레이어 닫기
   */
  const handleCloseVendorQrCouponDialog = () => {
    setShowVendorQrCouponDialog(false); // 쿠폰발급안내 레이어 닫기
    setVendorQrCouponId(null); // 발급된 쿠폰 아이디
    setVendorQrCouponTarget(null); // 관심샵 이벤트 입점샵
  };

  // render
  return (
    <VendorLayout title="와인샵">
      <Container sx={{ mb: 1.5 }}>
        <SearchField readOnly value={searchQuery} onClick={openSearchArea} placeholder="주소/와인샵 이름으로 검색" />
      </Container>

      {/* 입점샵 검색영역 다이얼로그[start] */}
      <SearchDialog
        fullScreen
        keepMounted
        open={searchOpen}
        onClose={() => {
          pageBackFunc();
        }}
        onSearch={searchVendor}
        queryHistory={queryHistory}
        value={searchQuery}
      />
      {/* 입점샵 검색영역 다이얼로그[end] */}

      <AppBar id="vendor-sticky-header" position="sticky" sx={{ top: 0, minHeight: '0px' }}>
        <Container position="relative" maxWidth="md" sx={{ pb: 0 }}>
          <CategoryTabs
            value={tabIndex}
            onChange={handleTabNavChange}
            variant="scrollable"
            indicatorColor="primary"
            textColor="inherit"
            aria-label="샵구분 탭"
          >
            <Tab
              label={
                <Box>
                  전체&nbsp;
                  <Box component="span" minWidth="30px" display="inline-block">
                    {vendorCnt.vendor_cnt}
                  </Box>
                </Box>
              }
              id="vendor-tab-all"
              value="all"
            />
            <Tab
              label={`최근 픽업한 샵 ${vendorCnt.loaded ? vendorCnt.recent_vendor_cnt : ''}`}
              id="vendor-tab-recent-pick-up"
              value="recentPickUp"
            />
            <Tab label={`관심샵 ${vendorCnt.loaded ? vendorCnt.fvr_vendor_cnt : ''}`} id="vendor-tab-fvr" value="fvrVendor" />
          </CategoryTabs>
        </Container>
      </AppBar>

      <Container ref={listRef} maxWidth="md" sx={{ paddingBottom: '10px', marginTop: '20px' }}>
        {vendorList.loaded &&
          woConstants?.code?.service_tag &&
          vendorList.items.map((vendor, i) => (
            <LazyLoadComponent key={`v-list-item-${vendor._id}-${i}`}>
              <VendorCard
                vendor={vendor}
                fetchVendorCnt={fetchVendorsCnt}
                onVendorQrCouponDownloaded={(couponId) => {
                  handleVendorQrCouponDownload(vendor, couponId);
                }}
                tabIndex={tabIndex}
              />
            </LazyLoadComponent>
          ))}
        {/* vendorList.page.current < vendorList.page.total_pages */}
        {/* 리스트 로딩 표현 */}
        <Fade in={vendorList.loading}>
          <Box display="flex" justifyContent="center" py={2}>
            <CircularProgress size={24} color="brand" />
          </Box>
        </Fade>

        {/* 검색결과가 없을 경우 */}
        {vendorList.loaded && vendorList.page.total_results === 0 && searchQuery.length > 0 && (
          <Box paddingY={5} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Box display="flex" justifyContent="center" alignItems="center">
              <SearchEmptyIcon />
            </Box>
            <Typography component="div" width="100%" textAlign="center" fontSize="18px" fontWeight={300}>
              <div>
                일치하는 검색 결과가 없습니다.
                <br />'<b>{searchQuery}</b>'
              </div>
            </Typography>
          </Box>
        )}

        {/* 최근픽업한, 관심 샵이 없을 경우 */}
        {vendorList.loaded && vendorList.page.total_results === 0 && searchQuery.length === 0 && (
          <Box paddingY={5} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Typography component="div" width="100%" textAlign="center" fontSize="18px" fontWeight={300}>
              {tabIndex === 'recentPickUp' ? '최근 픽업한 ' : '관심'}샵이 없습니다.
            </Typography>
          </Box>
        )}
      </Container>

      {/* v1.1.3: 입점샵 QR 프로모션 - 쿠폰발급 안내 레이어 */}
      {vendorQrCouponTarget && (
        <VendorQrDialog
          vendorId={vendorQrCouponTarget._id}
          vendorName={vendorQrCouponTarget.biz.name}
          couponId={vendorQrCouponId}
          open={showVendorQrCouponDialog}
          onConfirm={() => {
            navigate(`/vendor/d/${vendorQrCouponTarget._id}`, {
              state: { vendor: { _id: vendorQrCouponTarget._id, ...vendorQrCouponTarget } }
            });
            handleCloseVendorQrCouponDialog();
          }}
          onClose={() => {
            handleCloseVendorQrCouponDialog();
          }}
        />
      )}
    </VendorLayout>
  );
}

export default VendorListIndex;

const CategoryTabs = styled(Tabs)(
  ({ theme }) => `
  height: auto;
  & .MuiTab-root {
    height: 52px;
    min-width: 42px;
    letter-spacing: -0.14px; 
    line-height: normal;
    font-size: 14px;    
    color: ${theme.palette.text.primary};
    opacity: .6;
    transition: all ${theme.transitions.duration.short} ${theme.transitions.easing.easeInOut};
    padding-left: ${theme.spacing(1)};
    padding-right: ${theme.spacing(1)};
    
    &.Mui-selected {
      font-weight: 800;
      opacity: 1
    }
    &.Mui-disabled {
      opacity: 0.35;
    }
  }
  
  & .MuiTabs-indicator {
    background-color: #2d154d;
  }
  
  & .MuiTabs-scroller {
    border-bottom: 1px solid ${theme.palette.divider};
  }
`
);

function parseVendor(vendorList = []) {
  return vendorList.map((vendor) => {
    return {
      _id: vendor._id,
      biz: {
        biz_type: vendor.biz__biz_type,
        name: vendor.biz__name,
        address1: vendor.biz__address1,
        address2: vendor.biz__address2,
        location: vendor.biz__location,
        location_exp: vendor.biz__location_exp,
        zip_code: vendor.biz__zip_code
      },
      shop: {
        comment: vendor.shop__comment,
        service_tag: vendor.shop__service_tag,
        tel: vendor.shop__tel
      },
      state: vendor.state,
      test: Boolean(vendor?.test),
      closed: vendor.closed === 'true',
      deleted: vendor.deleted === 'true',
      vendor_img: vendor.vendor_img && vendor.vendor_img.thumb ? vendor.vendor_img : null,
      my_fvr: vendor.my_fvr ?? 'false',
      on_promo: vendor.on_promo === 1 ?? 'false'
    };
  });
}
