import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getFirebaseFunctions } from '../../../../../../utils/firebase-tools';
import { useDispatch } from 'react-redux';

// material-ui
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Box, ButtonBase, CardContent, Chip, Fade, Grid, Skeleton, Typography } from '@mui/material';

// project imports
import { useSelector } from 'store';
import { SpaceBetweenBox } from 'components';
import { WoAlert } from '../../../../../../utils/kmwine-alerts';
import { useAuth, useScriptRef } from '../../../../../../hooks';
import { VendorImage } from '../../VendorCard/components';
import { httpsCallable } from 'firebase/functions';
import { addFvrVendor, deleteFvrVendor, getOneVendor } from '../../../../../../services/VendorService';
import { CLO_CODE } from '../../../../../../config';
import { openSnackbar } from '../../../../../../store/slices/snackbar';

// import assets
import clockIcon from 'assets/images/ic_vendor_clock.png';
import vendorFvrOn from 'assets/images/ic_vendor_fvr_on.png';
import vendorFvrOff from 'assets/images/ic_vendor_fvr_off.png';

const vendorImgWidth = 90;
const vendorImgHeight = 90;

/**
 * 입점샵 지도 > 입점샵 상세정보
 *
 * @param {Object} selectedVendor
 * @param {function} onMoveToVendor
 * @param {function} onVendorQrCouponDownloaded
 * @returns {JSX.Element}
 * @constructor
 */
function VendorMapCard({ selectedVendor, onMoveToVendor, onVendorQrCouponDownloaded }) {
  const navigate = useNavigate();
  const location = useLocation();
  const scriptedRef = useScriptRef();
  const globalDispatch = useDispatch();

  // 로그인 사용자 정보
  const { user } = useAuth();

  // tag 요소 ref
  const parentRef = React.useRef(null);
  // 보여지는 tag길이
  const [shownElementsWidth, setShownElementsWidth] = React.useState(0);
  // 숨겨진 tag
  const [hiddenElements, setHiddenElements] = React.useState([]);

  // 관심샵 toggle loading
  const [fvrToggleLoading, setFvrToggleLoading] = React.useState(false);

  // clo error
  const sendCloError = httpsCallable(getFirebaseFunctions(), 'call-cdm-clo-error');

  // 샵정보
  const [vendor, setVendor] = React.useState({ loaded: false, selectedVendor });

  const theme = useTheme();

  // tag길이, 숨겨진 값 구하기
  const findHiddenElements = () => {
    if (parentRef.current) {
      const parentRect = parentRef.current.getBoundingClientRect();
      const { childNodes } = parentRef.current;

      const hiddenElements = [];
      let shownTotalElementsWidth = 0;
      for (let i = 0; i < childNodes.length; i++) {
        const childNode = childNodes[i];

        const childRect = childNode.getBoundingClientRect();

        if (childRect.top > parentRect.top) {
          hiddenElements.push(childNode);
        } else {
          shownTotalElementsWidth += childNode.offsetWidth;
        }
      }
      setHiddenElements(hiddenElements);
      setShownElementsWidth(shownTotalElementsWidth);
    }
  };

  React.useEffect(() => {
    setShownElementsWidth(0);
    setHiddenElements([]);
    findHiddenElements();
  }, [vendor.loaded, selectedVendor]);

  // 입점샵 정보 조회
  const fetchVendor: Promise<void> = async () => {
    try {
      const {
        data: { result, data: vendor }
      } = await getOneVendor(selectedVendor.vendor_id).catch((error) => ({ error }));

      if (!scriptedRef.current) {
        console.warn('[VendorMapCard][fetchVendor] Unmounted component.');
        return undefined;
      }

      // 조회 실패
      if (result.code !== 0) throw Error(result.msg);

      // 조회된 입점샵이 없을 경우
      if (!vendor) {
        await WoAlert.fire(`영업 중인 샵이 아니에요.`, '', 'error');
        return undefined;
      }

      // 입점샵 정보 갱신
      setVendor({ ...selectedVendor, ...vendor, loaded: true });
    } catch (e) {
      await WoAlert.fire(`샵정보 조회 중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.\n[Not available]`, '', 'error');
    }
  };

  React.useLayoutEffect(() => {
    setVendor({ loaded: false, selectedVendor });
    fetchVendor();
  }, [selectedVendor]);

  // 벤더 상세정보로 가기
  const onVendorClick = () => {
    onMoveToVendor(selectedVendor);
    navigate(`/vendor/d/${selectedVendor.vendor_id}`, { state: { vendor: { ...vendor, loaded: false } } });
  };

  // 와인샵 지도에서 관심샵추가,제거
  const toggleVendorFvr = async (event) => {
    event.stopPropagation();
    setFvrToggleLoading(true);
    let result;

    const actionWord = vendor.my_favorite ? '제외' : '추가';

    // 관심샵 삭제요청
    if (vendor.my_favorite) {
      result = await deleteFvrVendor(selectedVendor?.vendor_id, 'Wine-shop map').catch((error) => ({ error }));
    }
    // 관심샵 등록요청
    else {
      result = await addFvrVendor(selectedVendor?.vendor_id).catch((error) => ({ error }));
    }

    setFvrToggleLoading(false);

    // 관심샵 등록/삭제 중 오류
    if (result.error) {
      console.error('관심샵 변경 중 오류(catched error)', result.error);

      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `관심샵 변경 중 오류`,
        msg: `[uid=${user._id}] ${JSON.stringify(result.error)}`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user._id
        }
      })
        .then(console.log)
        .catch(console.error);

      if (scriptedRef.current) {
        await WoAlert.fire(`관심샵 변경 중 오류가 발생했어요.`, '', 'warning');
      }
    }

    // 관심샵 등록/삭제 성공
    else if (result.data.result.code === 0) {
      // 별표 반대로 표현
      setVendor((prev) => ({ ...prev, my_favorite: !prev.my_favorite }));

      // 관심샵 등록 후, VENDOR_QR 쿠폰 수령시 레이어로 띄울 것
      if (!vendor.my_favorite && result.data?.VENDOR_QR) {
        const vendorQrCouponResult = result.data.VENDOR_QR;

        // console.log('#result.data: ', result.data.VENDOR_QR);

        // 쿠폰 발급 중 오류
        if (vendorQrCouponResult.error) {
          await WoAlert.fire(
            `이벤트 쿠폰오류`,
            '쿠폰 발급 중에 오류가 발생했어요.<br/>관심샵 해제 후 다시 한번 관심샵으로 설정해주세요',
            ''
          );
          return undefined;
        }

        // 쿠폰발급 성공
        if (vendorQrCouponResult.success) {
          // 쿠폰발급완료 안내레이어 표현
          onVendorQrCouponDownloaded(vendor, vendorQrCouponResult.coupon_id);
          return true;
        }

        // 기타 미발급 사유
        console.warn(`입점샵 쿠폰 미발급 사유: ${result.data.VENDOR_QR.message}`);
      }

      globalDispatch(
        openSnackbar({
          open: true,
          message: `관심샵이 ${actionWord}되었습니다.`,
          variant: 'alert',
          alert: { color: 'success' },
          close: true,
          autoHideDuration: 2800
        })
      );
    } else {
      console.error('관심샵 변경 중 오류', result.data.result);

      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `관심샵 변경 중 오류`,
        msg: `[uid=${user._id}] ${JSON.stringify(result.data.result)}`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user._id
        }
      })
        .then(console.log)
        .catch(console.error);

      if (scriptedRef.current) {
        await WoAlert.fire(`관심샵 변경 중 오류가 발생했어요.`, '', 'warning');
      }
    }

    return undefined;
  };

  // 영업시간 표현 [start]
  const day = React.useMemo(() => moment().locale('en').format('ddd').toUpperCase(), []);

  const bizHourString = (bizHour) => {
    let bizHourStr;

    if (bizHour?.available) {
      const startTime = moment(`${bizHour.open_hour}`.padStart(2, '0') + `${bizHour.open_min}`.padStart(2, '0'), 'HHmm').format('A hh:mm');
      const closeTime = moment(`${bizHour.close_hour}`.padStart(2, '0') + `${bizHour.close_min}`.padStart(2, '0'), 'HHmm').format(
        'A hh:mm'
      );
      bizHourStr = `${startTime} ~ ${closeTime}`;
    } else {
      bizHourStr = '휴일';
    }
    return bizHourStr;
  };
  // 영업시간 표현 [end]

  // render
  return (
    <Box
      component="section"
      position="relative"
      display="flex"
      width="100%"
      sx={{ flexDirection: 'row' }}
      justifyContent="center"
      alignItems="center"
    >
      {/* 샵 이미지 */}

      <ButtonBase className="vendor-image-box" onClick={onVendorClick} sx={{ borderRadius: '10px', width: vendorImgWidth }}>
        <VendorImage
          src={vendor.vendor_img && vendor.vendor_img[0]}
          width={vendorImgWidth}
          height={vendorImgHeight}
          alt={`${selectedVendor.vendor_id}_image`}
        />
      </ButtonBase>

      {/* 샵 정보 영역 */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: `calc( 100% - ${vendorImgWidth}px )`,
          height: vendorImgHeight,
          marginTop: '2px'
        }}
      >
        <CardContent
          sx={{
            pt: 0,
            pb: 0,
            flex: '1',
            width: '100%',
            position: 'relative'
          }}
        >
          {/* 매장 이름 영역 */}
          <SpaceBetweenBox sx={{ alignItems: 'center' }}>
            <Typography onClick={onVendorClick} width="85%" noWrap fontSize="16px" fontWeight={750} letterSpacing="-0.11px">
              {selectedVendor.name}
            </Typography>
            <ButtonBase
              disableRipple
              disabled={fvrToggleLoading}
              onClick={toggleVendorFvr}
              sx={{
                position: 'absolute',
                right: '0',
                borderRadius: '8px',
                width: '36px',
                height: '36px'
              }}
            >
              {/* 관심샵 버튼 */}
              {vendor.loaded && (
                <Fade in={vendor.loaded}>
                  <Box component="img" src={vendor.my_favorite ? vendorFvrOn : vendorFvrOff} width="36px" height="36px" />
                </Fade>
              )}
            </ButtonBase>
          </SpaceBetweenBox>

          {/* 매장 영업시간 */}
          <Box mt="12px" mb="15px">
            {vendor.loaded ? (
              <Fade in>
                <Box
                  display="flex"
                  alignItems="center"
                  onClick={() => {
                    if (vendor.loaded) onVendorClick();
                  }}
                >
                  <Box mr="4px" component="img" src={clockIcon} sx={{ height: '12px' }} />
                  <Typography pt="2px" color="#140229" fontSize="13px">
                    {bizHourString(vendor?.shop?.biz_hours[day])}
                  </Typography>
                </Box>
              </Fade>
            ) : (
              <Skeleton width="115px" />
            )}
          </Box>

          {/* 샵 tag chips[start] */}
          {vendor.loaded && (
            <Box
              display="flex"
              onClick={onVendorClick}
              sx={{
                maxWidth: '90%',
                height: 24,
                width: '90%',
                position: 'absolute',
                bottom: '2px'
              }}
            >
              <Grid
                width={shownElementsWidth === 0 ? '85%' : `${shownElementsWidth + 1}px`}
                overflow="hidden"
                container
                spacing="4px"
                ref={parentRef}
              >
                {vendor?.shop?.service_tag.map((tag, i) => {
                  return (
                    <Grid item xs="auto" key={`v-svc-tag-${selectedVendor?.vendor_id}-${i}`}>
                      <ServiceTag tagCode={tag} />
                    </Grid>
                  );
                })}
              </Grid>
              {shownElementsWidth !== 0 && hiddenElements.length > 0 && (
                <Box marginLeft="4px">
                  <ServiceChip
                    label={`+${hiddenElements.length}`}
                    color="default"
                    size="small"
                    sx={{ bgcolor: '#ffffff', border: `1px solid ${alpha(theme.palette.brand.main, 0.04)}` }}
                  />
                </Box>
              )}
            </Box>
          )}
        </CardContent>
      </Box>
    </Box>
  );
}

export default VendorMapCard;

VendorMapCard.propTypes = {
  selectedVendor: PropTypes.object,
  onMoveToVendor: PropTypes.func,
  onVendorQrCouponDownloaded: PropTypes.func.isRequired
};

/* eslint-disable react/prop-types */
const ServiceTag = ({ tagCode, ref, ...other }) => {
  const theme = useTheme();
  const { code } = useSelector((state) => state.woConstants);

  if (!code.service_tag) {
    console.warn('서비스태그(service_tag) 상수가 아직 로드되지 않았습니다.');
    return null;
  }

  const tag = (
    <ServiceChip
      label={code.service_tag[tagCode].ko}
      color="default"
      size="small"
      sx={{ bgcolor: alpha(theme.palette.brand.main, 0.04) }}
      {...other}
    />
  );

  return tag;
};

const ServiceChip = styled(Chip)`
  height: 18px;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: -0.41px;
  line-height: 1.3;
  border-radius: 2px;
  padding: 4px 6px;
  & .MuiChip-label {
    padding: 0;
  }
  & .MuiSvgIcon-root {
    margin-right: 2px;
  }
`;
