import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

// project imports
import { useAuth } from 'hooks';
import useMobileNative from 'hooks/useMobileNative';
import { WoAlert } from 'utils/kmwine-alerts';
import BackHeader from 'layouts/Main/BackHeader';

import { AppSettingMenu } from './components';

// material-ui
import { Box, Chip, Collapse, Container, Divider, Stack, Typography } from '@mui/material';

/**
 * 앱 설정 화면
 * @returns {JSX.Element}
 * @authors 조현권<hkcho@wineone.io>, 최효근<hkchoi@wineone.io>
 * @history
 *  - v1.0.10:
 *    - Web Version 확인 기능 추가
 *    - 웹 강제 새로고침 기능 추가
 */
function AppSetting() {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { copyText } = useMobileNative();

  // 1kmwine user
  const { user } = useAuth();

  // god user 여부
  const god = React.useMemo(() => {
    return user.god ?? false;
  }, [user.god]);

  // firebase user
  const auth = getAuth();
  // firebase current user's access token.
  const jwtString = React.useMemo(() => (god ? auth?.currentUser?.accessToken ?? 'Loading...' : ''), [god, auth?.currentUser?.accessToken]);

  /**
   * 로그아웃
   */
  const handleLogoutButtonClick = async () => {
    const result = await WoAlert.fire({
      title: (
        <Typography component="div" fontSize={18} fontWeight="bold" textAlign="left">
          로그아웃
        </Typography>
      ),
      html: `<div style='line-height: 1.5rem; text-align: left'>로그아웃 하시겠습니까?</div>`,
      showCancelButton: true,
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      customClass: {
        confirmButton: 'max-50',
        cancelButton: 'max-50'
      }
    });

    // 확인버튼 클릭시 로그아웃 처리
    if (result.isConfirmed) logout();
  };

  /**
   * 웹 강제 새로고침 기능
   * @returns {Promise<void>}
   * @authors 조현권<hkcho@wineone.io>
   * @since v1.0.10
   */
  const refreshWeb = async () => {
    const result = await WoAlert.fire({
      icon: 'question',
      title: '화면 새로고침',
      html: `<div style='line-height: 1.5rem; text-align: center'>강제 새로고침 하시겠습니까?<br/>새로고침 후, <b>홈</b> 화면으로 이동됩니다.</div>`,
      showCancelButton: true,
      confirmButtonText: '새로고침',
      cancelButtonText: '취소',
      customClass: {
        confirmButton: 'max-50',
        cancelButton: 'max-50'
      }
    });

    if (result.isConfirmed) {
      // navigate(`/home?rf=${new Date().getTime()}`);
      window.location.href = `/home?rf=${new Date().getTime()}`;
    }
  };

  const [jwtFull, setJwtFull] = React.useState(false);

  // render
  return (
    <BackHeader title="앱 설정" showBottomNav={false} showFooter={false}>
      <Container maxWidth="xs" sx={{ pb: '56px' }}>
        <Stack divider={<Divider />}>
          {/* 알림 설정[start] */}
          <AppSettingMenu
            label="알림 설정"
            onClick={() => {
              navigate('/my/app-setting/alarm-setting');
            }}
          />
          {/* 알림 설정[end] */}
          {/* 추천정보 관리[start] */}
          {god ? (
            <AppSettingMenu
              label="추천정보"
              onClick={() => {
                navigate('/my/app-setting/social');
              }}
            />
          ) : null}

          {/* GPT [start] */}
          {/* {god ? ( */}
          {/*   <AppSettingMenu */}
          {/*     label="GPT" */}
          {/*     onClick={() => { */}
          {/*       navigate('/my/gpt'); */}
          {/*     }} */}
          {/*   /> */}
          {/* ) : null} */}
          {/* GPT [end] */}

          {/* 추천정보 관리[end] */}
          {/* 콘텐츠 차단 사용자 관리[start] */}
          <AppSettingMenu
            label="콘텐츠 차단 사용자 관리"
            onClick={() => {
              navigate('/my/app-setting/block-users');
            }}
          />
          {/* 콘텐츠 차단 사용자 관리[end] */}

          {/* 오픈소스 라이선스[start] */}
          <AppSettingMenu
            label="오픈소스 라이선스"
            onClick={() => {
              navigate('/my/app-setting/opensource-license');
            }}
          />
          {/* 오픈소스 라이선스[end] */}

          {/* 내용 새로고침 [start] */}
          <AppSettingMenu label="Web Version" endDecorator={<Chip label={process.env.REACT_APP_VERSION} />} onClick={refreshWeb} />
          {/* 내용 새로고침 [end] */}

          {/* 로그아웃 [S] */}
          <AppSettingMenu label="로그아웃" onClick={handleLogoutButtonClick} />
          {/* 로그아웃 [E] */}
          <AppSettingMenu
            label="회원탈퇴"
            onClick={() => {
              navigate('/my/withdraw');
            }}
          />

          {/* 로그인 사용자 JWT 토근 */}
          {god && (
            <AppSettingMenu
              label="JWT"
              endDecorator={
                <Stack direction="row" spacing={1} sx={{ zIndex: 1 }}>
                  <Chip
                    size="small"
                    label="Copy"
                    onClick={() => {
                      copyText(jwtString, '사용자 토큰');
                    }}
                  />
                  <Chip
                    size="small"
                    label="전체보기"
                    onClick={() => {
                      setJwtFull((jwtFull) => !jwtFull);
                    }}
                  />
                </Stack>
              }
            >
              <Collapse in={jwtFull} collapsedSize="2rem">
                <Typography paragraph variant="caption" color="text.secondary" sx={{ wordBreak: 'break-all', userSelect: 'all' }}>
                  {jwtString}
                </Typography>
              </Collapse>
            </AppSettingMenu>
          )}

          {/* Invite vendor */}
          {god && (
            <AppSettingMenu
              label="Invite vendor id"
              endDecorator={
                <Typography component="span" variant="caption" color="text.secondary" sx={{ userSelect: 'all' }}>
                  {localStorage.getItem('wo-invite-vendor') ?? 'none'}
                </Typography>
              }
            />
          )}

          {god && (
            <Box py={3}>
              <Typography>cs 메일주소 테스트</Typography>
              <Box mt={2}>
                <Typography component="a" href="mailto:cs@wineone.io" sx={{ userSelect: 'text' }}>
                  cs@wineone.io
                </Typography>
                <br />
                <br />
                <a href="mailto:cs@wineone.io" style={{ userSelect: 'text' }}>
                  cs@wineone.io
                </a>
              </Box>
            </Box>
          )}
        </Stack>
      </Container>

      {/* <BottomBox height="50px"> */}
      {/*   <Stack */}
      {/*     direction="row" */}
      {/*     divider={ */}
      {/*       <Box py="8px" height="28px" display="flex"> */}
      {/*         <Divider orientation="vertical" flexItem /> */}
      {/*       </Box> */}
      {/*     } */}
      {/*     spacing={2} */}
      {/*     justifyContent="center" */}
      {/*   > */}
      {/*     <SmallActionButton label="로그아웃" onClick={handleLogoutButtonClick} /> */}
      {/*     <SmallActionButton */}
      {/*       label="회원탈퇴" */}
      {/*       onClick={() => { */}
      {/*         navigate('/my/withdraw'); */}
      {/*       }} */}
      {/*     /> */}
      {/*   </Stack> */}
      {/* </BottomBox> */}
    </BackHeader>
  );
}

export default AppSetting;
