import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ko';
import { doc, getDoc } from 'firebase/firestore';
import { logEvent } from 'firebase/analytics';
import { Helmet } from 'react-helmet-async';
import { httpsCallable } from 'firebase/functions';
import { useLocationState } from 'react-router-use-location-state';

// project imports
import { analytics } from '../../../../index';
import { OrderLayout } from '../components';
import { WoAlert } from 'utils/kmwine-alerts';
import * as OrderService from 'services/OrderService';
import { fetchMyCouponList } from 'services/UserService';
import { useAuth, useScriptRef, useWineOne } from 'hooks';
import { CLO_CODE, COUPON_TYPE, IS_PRODUCTION, ORDER } from 'config';
import { getLatestTerm, TERM_TYPE } from 'services/AppService';
import { ErrorIcon } from 'components/icons';
import { Checkbox, SpaceBetweenBox } from 'components';
import { getFirebaseFunctions, getFirestore } from 'utils/firebase-tools';
import { dispatch, useSelector } from 'store';
import { fetchCodes } from 'store/slices/wo-constants';
import { SmallButton } from 'components/buttons';
import OrderProduct from './components/OrderProduct';
import { CouponDialog, OrderSection, RefundInfo, TitleBox } from '../OrderConfirm/components';

// material-ui
import {
  AppBar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Container,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  IconButton,
  Skeleton,
  Slide,
  Toolbar,
  Typography
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

// assets
import waveBorderImg from '../OrderConfirm/components/wave_border.png';
import CloseIcon from '@mui/icons-material/Close';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

/**
 * 설이벤트 주문 최종 페이지 (이름은 ticket이지만 설이벤트 용으로 사용한다)
 * @constructor
 *
 * @authors 최효근<hkchoi@wineone.io>
 */
function OrderTicketConfirm() {
  const theme = useTheme();
  const location = useLocation();
  const scriptedRef = useScriptRef();

  // 로그인 사용자 정보
  const { user } = useAuth();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { onNativeBackPressed } = useWineOne();

  // 어디서 왔는가??
  const from = searchParams.get('from') || 'normal';

  // 사용자 위치정보, 상수 관련 서비스
  const { woConstants } = useSelector((state) => state);

  React.useLayoutEffect(() => {
    dispatch(fetchCodes(['service_tag', 'wine_type']));
  }, [woConstants]);

  React.useEffect(() => {
    const { mainProduct = null, orderItems = [], vendor = null } = location.state;
    if (!mainProduct || orderItems.length < 1 || !vendor) {
      WoAlert.fire(`올바르지 않은 접근입니다.\n잠시 후 다시 시도해주세요.`, '', 'error').then(() => {
        navigate('/', { replace: true });
      });
    }
  }, [location]);

  const {
    mainProduct, // 주문상품 (대표)
    orderItems, // 주문상품 array
    vendor: defaultVendor // 입점샵(판매사) 기본정보
  } = React.useMemo(() => location.state, [location.state]);

  const vendorId = React.useMemo(() => defaultVendor._id || defaultVendor.id, [defaultVendor._id, defaultVendor.id]);

  // 입점샵(판매사)
  const [vendor, setVendor] = useLocationState('vendor', { ...defaultVendor, loaded: false });

  React.useEffect(() => {
    if (!vendor.loaded) {
      console.debug(`[1KMWINE] 입점샵(${vendorId}) 정보조회 시작`);
      fetchVendor(vendorId); // 입점샵(판매사) 정보조회
    } else {
      console.debug(`[1KMWINE] 이미 로드된 입점샵(${vendorId}) 정보가 있습니다.`);
    }
  }, [vendorId]);

  // 주문상품 옵션 (기본값)
  const initialOrdItemOpts = React.useMemo(() => {
    const _map = {};
    location.state.orderItems.forEach((oi) => {
      _map[oi.product._id] = { id: oi.product._id, quantity: oi.quantity, packing: '', comment: '' };
    });

    return _map;
  }, [location]);

  // 주문상품 옵션
  const [ordItemOpts, setOrdItemOpts] = useLocationState('ordItemOpts', initialOrdItemOpts);

  // AppBar ref
  let appBarRef = React.useRef(null);
  // AppBar 높이
  const [appBarHeight, setAppBarHeight] = React.useState(0);

  // AppBar 높이 구하기
  React.useEffect(() => {
    // AppBar 높이 구하기
    if (appBarRef.current) {
      setAppBarHeight(appBarRef.current.offsetHeight);
    }
  }, [appBarRef]);

  // 총 상품 금액
  const goodsTotalAmount = React.useMemo(() => {
    return orderItems.reduce((total, orderItem) => {
      const { product } = orderItem;
      const productId = product.id ?? product._id;
      const opt = ordItemOpts[productId];
      return total + orderItem.product.price.original * opt.quantity;
    }, 0);
  }, [ordItemOpts]);

  // 주문확인 및 이용약관 동의여부
  const [agree, setAgree] = React.useState(false);

  // 주문정보
  const [order, setOrder] = React.useState({
    request: false, // 요청 여부
    data: null // 주문 데이터
  });

  // '결제하기' 버튼 클릭
  const startPurchase = () => {
    setOrder({ ...order, request: true });
    if (orderItems.length === 0) {
      console.warn('[wineone] 올바르지 않은 스크립트 호출. (orderItems.length === 0)');
      return false;
    }

    // 선택된 쿠폰아이디 array
    const coupon = selectedCoupons.map(({ id }) => id);

    // 주문(order)생성 요청 파라미터
    const orderRequestParam = {
      vendor_id: vendor.id, // 판매사(입점샵) ID
      products: [], // 주문상품
      coupon,
      pickup_date: `2024-01-27`,
      pickup_time: `11:00`,
      pay: {
        type: 'CARD', // 결제방식 'CARD'
        // amount: amount + userCommission.data.commission // 결제 총액
        amount // 결제 총액
      },
      state: ORDER.STATE.ING, // 최초상태 : 결제 중
      paid: false,
      from // 이전 페이지 type. 'cart', 'etc'
    };

    const logItems = [];

    // 주문상품 확인
    orderItems.forEach((orderItem, i) => {
      const { product: orderProduct } = orderItem;
      const { _id: id } = orderProduct;

      const { quantity, packing, comment } = ordItemOpts[id];
      // 주문상품정보
      orderRequestParam.products.push({
        id, // product.id
        quantity, // quantity
        packing, // 포장옵션
        comment // 추가 요청사항
      });

      // Google Analytics정보
      logItems.push({
        item_id: id,
        item_name: orderProduct.pdata.name.ko,
        currency: 'KRW',
        index: i,
        item_category: 'wine',
        item_category2: orderProduct.pdata.type,
        price: orderProduct.price.original,
        quantity
      });
    });

    console.debug('결제 요청정보: ', { ...orderRequestParam });

    // 주문정보 생성요청
    OrderService.createOrder(orderRequestParam)
      .then((res) => {
        console.log('order create response: ', res);
        setOrder({ ...order, request: false });

        const { status, data } = res;
        if (status !== 200) return errorCallback();

        const { data: orderCreated, result } = data;
        console.debug('생성된 주문정보: ', orderCreated);
        if (result.code !== 0) {
          const errorMsg =
            result.code >= 100 ? result.msg : `주문정보 생성 중 오류가 발생했습니다.\n[${result.msg}]\n잠시 후 다시 시도해주세요.`;

          WoAlert.fire(errorMsg, '', 'error').then(() => {
            console.warn(`[wineone] 주문정보 생성 오류 [code: ${result.code}, msg: ${result.msg}]`);

            // 주문금액 오류일 경우 뒤로가기
            if (result.code === 1) navigate(-1);
          });

          httpsCallable(
            getFirebaseFunctions(),
            'call-cdm-clo-error'
          )({
            code: CLO_CODE.UNEXPECTED_ERROR,
            title: `주문정보 생성실패`,
            msg: `[uid=${user._id}] ${result.msg}`,
            which: `${location.pathname}${location.search}`,
            param: { uid: user._id, result }
          })
            .then(console.log)
            .catch(console.error);

          return false;
        }

        if (IS_PRODUCTION) {
          try {
            // Google analytics: https://developers.google.com/analytics/devguides/collection/ga4/reference/events#begin_checkout
            logEvent(analytics, 'begin_checkout', {
              currency: 'KRW',
              value: orderCreated.pay.amount,
              items: logItems
            });
          } catch (e) {
            /* DO NOTHING */
          }
        }

        navigate(`/payment/inicis/request`, { state: { order: { ...orderCreated } } }); // 이니시스 카드결제요청
      })
      .catch((err) => {
        console.error('[1KMWINE] 주문정보 생성실패.', err);

        setOrder({ ...order, request: false });
        errorCallback();
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `주문정보 생성실패 [${err.message}]`,
          msg: `[uid=${user._id}] ${err.message}`,
          which: `${location.pathname}${location.search}`,
          param: { uid: user._id, error: err.stack }
        })
          .then(console.log)
          .catch(console.error);
      });

    function errorCallback() {
      WoAlert.fire('주문정보 생성 중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.', '', 'error').then(() => {
        console.warn('[1KMWINE] 주문정보 생성 오류 [OrderConfirm.createOrder$error]');
      });
      return false;
    }
  };

  // 약관/정책 초기값
  const initialTermState = Object.freeze({
    loaded: false,
    error: false,
    type: null,
    term: null
  });

  // 약관/정책
  const [termState, setTermState] = React.useState(initialTermState);

  // 약관 가져오기 -> todo 코드 개선
  const fetchTerm = (type) => {
    if (termState.loaded && termState.type === type) {
      console.debug(`[1KMWINE] 이미 로드된 문서입니다. [type=${type}]`);
      return;
    }

    setTermState({ ...termState, type });

    if (!termState.loaded || termState.type !== type) {
      getLatestTerm(type.toUpperCase())
        .then((snapshot) => {
          if (scriptedRef.current) {
            if (!snapshot.empty) {
              setTermState({ ...termState, type, loaded: true, error: false, term: snapshot.docs[0].data() });
            } else {
              console.error(`[1KMWINE] 문서가 존재하지 않습니다. [type=${type}]`);
              setTermState({ ...termState, type, loaded: true, error: false, term: null });
            }
          }
        })
        .catch((e) => {
          console.error(`[1KMWINE] 문서 조회 중 오류가 발생했습니다. [type=${type}]`, e);
          if (scriptedRef.current) {
            setTermState({ ...termState, type, loaded: true, error: true, term: null });
          }
        });
    } else {
      console.debug(`[1KMWINE] 이미 로드된 문서입니다. [type=${type}]`);
    }
  };

  // 약관보기
  const [showTerms, setShowTerms] = React.useState(false);

  const handleShowTerms = (show = false, agreeTerms) => {
    if (typeof agreeTerms === 'boolean') setAgree(agreeTerms);
    if (show) fetchTerm(TERM_TYPE.SERVICE);
    setShowTerms(show);
  };

  // 매장 주소
  const vendorAddr = React.useMemo(() => {
    let addr;
    if (vendor?.biz?.address1) {
      addr = vendor.biz.address1;
      if (vendor.biz.address2) addr += ` ${vendor.biz.address2}`;
    } else {
      addr = <Skeleton width={80} />;
    }
    return addr;
  }, [vendor]);

  // 내가 보유한 쿠폰 다이어로그 열림
  const [openCouponDialog, setOpenCouponDialog] = React.useState(false);

  // 사용자 보유쿠폰 정보
  const [myCoupons, setMyCoupons] = useLocationState('myCoupons', {
    loaded: false,
    error: false,
    coupons: []
  });

  // 적용가능한 보유쿠폰 정보
  const [availableCoupons, setAvailableCoupons] = React.useState([]);

  React.useEffect(() => {
    if (goodsTotalAmount <= 0) return;
    if (!myCoupons.loaded) {
      // console.warn('[OrderConfirm] 보유 쿠폰목록이 아직 조회되지 않았습니다. myCoupons=', myCoupons);
      return;
    }

    if (!myCoupons.error && goodsTotalAmount > 0) {
      const availables = [];

      myCoupons.coupons.forEach((c) => {
        const { coupon } = c;
        // 1) 해당 매장에서 사용할 수 있는지? ( VENDOR, ADVEN )
        if (coupon.type !== COUPON_TYPE.ADMIN) {
          // 다른 입점샵 쿠폰일 경우 pass
          if (vendor.id !== coupon.vendor.id) {
            console.debug(
              `해당 매장에서는 사용이 불가능한 쿠폰. [id=${c.id}, couponVendor=${coupon.vendor.id}, currentVendor=${vendor.id}]`
            );
            return false;
          }
        }

        // 2) 쿠폰 사용가능한 최소주문금액에 도달하지 않았을 경우
        if (coupon.min_order_amount > goodsTotalAmount) {
          console.debug(`사용가능 주문금액이 충족하지 않는 쿠폰. [id=${c.id}, min_order_amount=${coupon.min_order_amount}]`);
          return false;
        }

        // 3) 사용가능 기간확인
        const { start_dt: startDt, end_dt: endDt } = coupon.use_period;
        const startMmt = moment(startDt._seconds * 1000); // 쿠폰 사용가능 시작일시
        const endMmt = moment(endDt._seconds * 1000); // 쿠폰 사용가능 종료일시
        const now = moment(); // 현재

        // 3-1) 쿠폰 사용가능 일자가 안됐을 경우
        if (startMmt.isAfter(now)) return false;
        // 3-2) 쿠폰 사용가능 일자를 지났을 경우
        if (endMmt.isBefore(now)) return false;

        availables.push(c);
      });

      // console.log('[주문상세] 사용가능한 쿠폰들', availables);

      // 사용가능한 쿠폰들
      setAvailableCoupons(availables);
    }
  }, [myCoupons, goodsTotalAmount]);

  // 쿠폰 상태정보(선택 등)
  const [selectedCoupons, setSelectedCoupons] = useLocationState('selectedCoupons', []);

  // 최대할인금액
  const [couponMaxAmount, setCouponMaxAmount] = React.useState(null);

  // 쿠폰 할인금액
  const discountByCoupons = React.useMemo(() => {
    return selectedCoupons.reduce((dcTotal, selected) => {
      return dcTotal + (selected.coupon.amount >> 0);
    }, 0);
  }, [selectedCoupons]);

  // 결제 금액 (총 상품 금액 - 쿠폰 할인 )
  const amount = goodsTotalAmount - discountByCoupons;

  // 쿠폰금액이 상품금액보다 많은 경우
  React.useEffect(() => {
    if (discountByCoupons > goodsTotalAmount) {
      if (selectedCoupons.length > 0) {
        WoAlert.fire(`잘못된 쿠폰입니다.  \n쿠폰을 다시 선택해주세요.`, '', 'error').then(() => {
          setSelectedCoupons([]);
        });
      }
    }
  }, [selectedCoupons]);

  // 사용자 보유쿠폰 목록 조회
  const fetchMyCoupons: Promise<boolean> = React.useCallback(async () => {
    // 입점샵가 로드되었고, 내 쿠폰목록이 조회완료됨
    if (!myCoupons.loaded) {
      console.log('[1KMWINE] 사용자 보유쿠폰 조회 시작...');

      // todo OrderService.getAvailableCouponList로 교체할 것
      const response = await fetchMyCouponList(vendorId, orderItems).catch((error) => {
        console.error('[1KMWINE] 보유쿠폰 목록 조회 중 오류.', error);
        if (process.env.NODE_ENV === 'production') {
          httpsCallable(
            getFirebaseFunctions(),
            'call-cdm-clo-error'
          )({
            code: CLO_CODE.UNEXPECTED_ERROR,
            title: `[1KMWINE] 보유쿠폰 목록 조회 중 오류`,
            msg: `[uid=${user._id}] ${error}`,
            which: `${location.pathname}${location.search}`,
            param: {
              uid: user._id
            }
          })
            .then(console.log)
            .catch(console.error);
        }
        return { error };
      });

      if (!scriptedRef.current) {
        console.warn('[OrderConfirm][fetchMyCoupons] Unmounted component.');
        return;
      }

      // 사용자 보유쿠폰 목록조회 실패
      if (response.error) {
        await WoAlert.fire('', `보유쿠폰 목록 조회 중 오류가 발생했어요.<br/>잠시 후 다시 시도해주세요.`, 'error').then(() => {
          navigate(-1);
        });
        return false;
      }

      // 조회된 쿠폰 정보
      const { result, data } = response.data;

      // 보유쿠폰 조회 성공
      if (result.code === 0) {
        console.debug('사용가능한 쿠폰목록 조회결과', response.data);
        setMyCoupons((oldMyCoupons) => ({
          ...oldMyCoupons,
          loaded: true,
          error: false,
          coupons: data
        }));

        // 쿠폰 자동적용
        const autoApplyCoupon = [];

        // 쿠폰 최대할인금액
        let maxAmount = 0;

        data.map((el) => {
          if (response.data.autoApplyCouponId.includes(el.coupon.id)) {
            autoApplyCoupon.push(el);
            maxAmount += el.coupon.amount;
          }
          return undefined;
        });
        setSelectedCoupons(autoApplyCoupon);
        setCouponMaxAmount(maxAmount);
        return true; // 목록 조회 성공
      }

      console.error('[1KMWINE] 보유쿠폰 목록 조회 중 오류. code=', result.code, result.msg);
      httpsCallable(
        getFirebaseFunctions(),
        'call-cdm-clo-error'
      )({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `[1KMWINE] 보유쿠폰 목록 조회 중 오류 [code=${result.code}]`,
        msg: `[uid=${user._id}][확인 필요] ${JSON.stringify(result.error)}`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user._id,
          result
        }
      })
        .then(console.log)
        .catch(console.error);

      await WoAlert.fire('', `보유쿠폰 목록 조회 중 오류가 발생했어요.<br/>잠시 후 다시 시도해주세요.`, 'error').then(() => {
        navigate(-1);
      });
      return false;
    }
    // console.warn('입점샵 아이디(vendor.id)를 아직 확인할 수 없습니다.', vendor);
    return false;
  }, [vendorId, myCoupons]);

  // '쿠폰 선택' 라벨
  const couponSectionLabel = React.useMemo(() => {
    if (!myCoupons.loaded) {
      return <span style={{ fontSize: '11px' }}>보유 쿠폰을 확인 중 입니다...</span>;
    }

    if (selectedCoupons.length > 0) {
      return `쿠폰 적용 (${selectedCoupons.length}개)`;
    }
    return availableCoupons.length > 0 ? (
      `사용 가능 쿠폰 ${availableCoupons.length}개`
    ) : (
      <span style={{ fontSize: '11px', color: theme.palette.text.disabled }}>사용 가능한 쿠폰이 없습니다.</span>
    );
  }, [myCoupons, selectedCoupons, availableCoupons]);

  // 기기의 뒤로가기 버튼 이벤트 콜백
  const pageBackFunc = React.useCallback(() => {
    // SweetAlert이 띄워져 있는 경우
    if (WoAlert.isVisible()) {
      WoAlert.close();
    }
    // 쿠폰선택 dialog 열려있는 상태
    else if (openCouponDialog) {
      setOpenCouponDialog(() => false);
    }
    // 동의하기 레이어 열려있는 상태
    else if (showTerms) {
      setShowTerms(() => false);
    }
    // 주문정보 생성 중
    else if (order.request) {
      console.warn('[wineone] 주문정보 생성 중에는 화면을 벗어날 수 없습니다.');
    }
    // 쿠폰목록 조회 중
    // else if (!myCoupons.loaded) {
    //   console.warn('[wineone] 쿠폰목록 조회 중에는 화면을 벗어날 수 없습니다.');
    // }
    // 뒤로가기
    else {
      navigate(-1);
    }
  }, [openCouponDialog, location, showTerms, order.request, myCoupons.loaded]);

  React.useEffect(() => {
    if (!location) return false;
    // 기기에서 뒤로가기 버튼을 눌렀을 때
    // window.dispatchEvent(new CustomEvent('wo.hardware', {detail: {action: 'backpressed'}}));
    onNativeBackPressed(pageBackFunc);
  }, [pageBackFunc]);

  // render
  return (
    <OrderLayout
      title="주문하기"
      appBarRef={(ref) => {
        appBarRef = ref;
      }}
      showBackDrop={!vendor.loaded}
      backDrop={
        <Box>
          <Typography variant={'body2'} color={'#ffffff'}>
            상품정보를 확인 중 입니다
          </Typography>
        </Box>
      }
      onBackButtonClick={pageBackFunc}
    >
      <Helmet title={`${process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE} | 주문하기`} />
      <Backdrop open={order.request} sx={{ zIndex: theme.zIndex.appBar + 5 }}>
        <Box p={3} sx={{ backdropFilter: 'blur(33px)', background: 'rgba(0,0,0,0.4)', borderRadius: '8px', overflow: 'hidden' }}>
          <Typography variant={'body2'} color="#ffffff">
            주문정보를 생성하고 있습니다.
          </Typography>
        </Box>
      </Backdrop>
      <Container maxWidth="xs" pt={0} sx={{ minHeight: `calc(var(--vh, 1vh) * 100 - ${appBarHeight})` }}>
        <OrderSection>
          <Box py={3}>
            {/* 사용자와의 거리 220922 기획변경
            <Typography fontSize={36} fontFamily={theme.palette.fontFamily.currency} lineHeight={1}>
              {vendorDistanceStr ? `${vendorDistanceStr.toLocaleString()}m` : <Skeleton width="80px" />}
            </Typography>
            */}
            <Typography fontSize="20px" fontWeight="bold" lineHeight={1} letterSpacing="normal" sx={{ mr: '8px' }}>
              {vendor.loaded && vendor?.biz?.name ? `${vendor.biz.name}` : <Skeleton width={120} />}
            </Typography>
            <Box mt="12px" display="flex" alignItems="center">
              {/* 220922 기획변경으로 표현안함
              <InfoIcon />
              <Typography component="div" fontSize="13px" lineHeight="1" sx={{ ml: '4px' }}>
                매장명과 주소 정보는 주문 완료 후 공개됩니다.
              </Typography>
              */}
              <Typography component="div" fontSize="13px" lineHeight="1" color="#220348">
                {vendorAddr}
              </Typography>
            </Box>
          </Box>
          <Divider color={theme.palette.brand.main} sx={{ height: '1px' }} />
        </OrderSection>

        {/* 주문상품정보 영역[start] */}
        <OrderSection mt="14px">
          <TitleBox>
            <SpaceBetweenBox>
              <Typography variant="subtitle2" fontSize="14px" fontWeight="inherit">
                상품 정보
                {/* {orderItems.length > 1 && `(${orderItems.length.toLocaleString()}건)`} */}
              </Typography>
            </SpaceBetweenBox>
          </TitleBox>

          <Box aria-label="대표 구매상품">
            {/* 대표상품 */}
            <OrderProduct
              orderItem={mainProduct}
              orderOption={ordItemOpts[mainProduct.product._id]}
              onOptionChange={(productId, option) => {
                setOrdItemOpts((ordItemOpts) => {
                  const _tmpOrdItemOpts = { ...ordItemOpts };
                  _tmpOrdItemOpts[productId] = option;
                  return _tmpOrdItemOpts;
                });
              }}
            />
          </Box>

          {/* 상품목록[start] */}
          <Box aria-label="구매상품 목록">
            {orderItems.map((orderItem, i) => {
              // 대표상품은 제외
              if (mainProduct.product._id === orderItem.product._id) return null;
              const orderOption = ordItemOpts[orderItem.product._id];
              return (
                <React.Fragment key={`order-prod-${i}`}>
                  {i > 0 && <Divider />}
                  <OrderProduct
                    orderItem={orderItem}
                    orderOption={orderOption}
                    onOptionChange={(productId, option) => {
                      const _tmpOrdItemOpts = { ...ordItemOpts };
                      _tmpOrdItemOpts[productId] = option;
                      setOrdItemOpts(_tmpOrdItemOpts);
                    }}
                  />
                </React.Fragment>
              );
            })}
          </Box>
          {/* 상품목록[end] */}
        </OrderSection>
        {/* 주문상품정보 영역[end] */}

        {/* 쿠폰 선택[start] */}
        <OrderSection sx={{ mt: 2 }}>
          <TitleBox>
            <Typography variant="subtitle2" fontSize="14px" fontWeight="inherit">
              쿠폰
            </Typography>
          </TitleBox>
          {selectedCoupons.length > 0 && (
            <Box marginBottom="16px" marginTop="8px">
              <Typography fontSize="14px" color="#9357E5">
                {discountByCoupons.toLocaleString()}원 {couponMaxAmount === discountByCoupons && '최대'} 할인 적용 중
              </Typography>
            </Box>
          )}

          <SpaceBetweenBox height="28px">
            <Typography>{couponSectionLabel}</Typography>
            {myCoupons.loaded && (
              <SmallButton
                onClick={() => {
                  setOpenCouponDialog(true);
                }}
                sx={{ borderColor: '#60546E26', color: '#140229' }}
              >
                {selectedCoupons.length === 0 ? `보유 쿠폰 ${myCoupons.coupons.length}개` : '쿠폰 변경하기'}
              </SmallButton>
            )}
          </SpaceBetweenBox>
          <Box pt="14px" color={(theme) => alpha(theme.palette.text.primary, 0.55)} width="100%">
            {selectedCoupons.map((selected, i) => {
              const { coupon } = selected;
              return (
                <SpaceBetweenBox key={`selected-coupon-${i}`} height="28px" sx={{ pl: '1rem' }}>
                  <Typography noWrap sx={{ maxWidth: '50%' }}>
                    ㄴ&nbsp;{coupon.name}
                  </Typography>
                  <Box display="flex" justifyContent="flex-end" alignItems="center" marginRight="12px">
                    <Typography fontWeight="bold">{coupon.amount.toLocaleString()}</Typography>원
                  </Box>
                </SpaceBetweenBox>
              );
            })}
          </Box>
        </OrderSection>
        {/* 쿠폰 선택[end] */}

        {/* 결제수단[start] */}
        <OrderSection sx={{ mt: '32px' }}>
          <TitleBox>
            <Typography variant="subtitle2" fontSize="14px" fontWeight="inherit">
              결제수단
            </Typography>
          </TitleBox>
          <Box pt={1} pb={1.5}>
            <Box display="flex" justifyContent="space-between" alignItems={'center'}>
              <Typography variant="body2" fontSize="14px">
                신용/체크카드
              </Typography>
              {/* todo 다른 결제방법 기능작업 후 활성화
              <Button variant="outlined" size="small" sx={{ py: 0.25 }}>
                변경
              </Button>
               */}
            </Box>
          </Box>
        </OrderSection>
        {/* 결제수단[end] */}

        <Box sx={{ mx: '-20px', px: '20px', pt: 3.5, position: 'relative', bgcolor: '#f3f2f5' }}>
          <Box component="img" alt="" src={waveBorderImg} top="-3px" position="absolute" left={0} width="100%" height="4px" />
          {/* 결제 금액[start] */}
          <OrderSection>
            <TitleBox>
              <Typography variant="subtitle2" fontSize="14px" fontWeight="inherit">
                결제 금액
              </Typography>
            </TitleBox>
            <Box pt="17px" pb="30px">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography>총 상품 금액</Typography>
                <Typography fontWeight={900}>{goodsTotalAmount.toLocaleString()}원</Typography>
              </Box>
              {/* 쿠폰 할인 */}
              <Collapse mountOnEnter unmountOnExit in={selectedCoupons.length > 0}>
                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 1.5 }}>
                  <Typography>쿠폰 사용</Typography>
                  <Typography color="#EA3D44" fontWeight={900}>
                    -{discountByCoupons.toLocaleString()}원
                  </Typography>
                </Box>
              </Collapse>
            </Box>
          </OrderSection>
          {/* 결제 금액[end] */}

          {/* 결제 금액 표현[start] */}
          <Divider />
          <SpaceBetweenBox sx={{ py: 3 }}>
            <Typography variant="subtitle1" fontWeight={900} fontSize="14px">
              총 결제 금액
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1" fontSize="22px" color="#140229" fontWeight="bold" fontFamily="D-DIN-Bold,D-DIN">
                {/* {(amount + (userCommission.loaded ? userCommission.data.commission : 0)).toLocaleString()} */}
                {amount.toLocaleString()}
              </Typography>
              <Typography fontSize="16px" ml="1px">
                원
              </Typography>
            </Box>
          </SpaceBetweenBox>

          {/* 결제 금액 표현[start] */}

          {/* [PROD-143] 주문화면 내 (특가)세트상품 교환반품 불가 안내 문구 삽입 */}
          {/* 환불규정 */}
          <RefundInfo />

          {/* 주문내용 확인 및 약관동의[start] */}
          <Box py={1} mt={2} display="flex" justifyContent="space-between" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={agree}
                  onChange={(e) => {
                    setAgree(e.target.checked);
                  }}
                  size="small"
                  inputProps={{ 'aria-labelledby': 'order-confirm-term' }}
                />
              }
              label={
                <Typography id={'order-confirm-term'} variant="caption" lineHeight={1} fontSize="14px">
                  주문내용 확인 및 약관동의
                </Typography>
              }
            />
            <Button variant="outlined" size="small" sx={{ py: 0.25 }} onClick={() => handleShowTerms(true)}>
              약관보기
            </Button>
          </Box>
          {/* 주문내용 확인 및 약관동의[end] */}

          <Box pt="24px" pb="16px">
            {/* 결제하기 버튼 */}
            <Button
              fullWidth
              type="button"
              disabled={!myCoupons.loaded || !agree}
              variant="contained"
              color="brand"
              size="x-large"
              onClick={startPurchase}
              sx={{ height: 46 }}
            >
              <Typography fontSize={17} fontWeight={800}>
                결제하기
              </Typography>
            </Button>
          </Box>
        </Box>
      </Container>
      {/* 쿠폰선택 다이얼로그 */}
      <CouponDialog
        loaded={myCoupons.loaded}
        amount={goodsTotalAmount}
        open={openCouponDialog}
        onClose={() => setOpenCouponDialog(false)}
        onSelect={(coupons) => {
          console.debug('[주문하기] 선택된 쿠폰 정보. ', coupons);
          setSelectedCoupons(coupons);
          setOpenCouponDialog(false);
        }}
        coupons={myCoupons}
        selectedCoupons={selectedCoupons}
      />
      {/* 약관 표현영역 */}
      <Dialog fullScreen open={showTerms} onClose={() => handleShowTerms(false)} TransitionComponent={Transition}>
        <AppBar position="relative" elevation={0} sx={{ borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}` }}>
          <Toolbar color={'text.primary'}>
            <IconButton edge="start" onClick={() => handleShowTerms(false)} aria-label="close">
              <CloseIcon color={'text.primary'} />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} color={'text.primary'} variant="h6" component="div">
              약관내용
            </Typography>

            <Button
              size={'small'}
              variant={'outlined'}
              disabled={agree}
              color={agree ? 'success' : 'primary'}
              onClick={() => {
                handleShowTerms(false, true);
              }}
            >
              {agree ? '동의완료' : '동의하기'}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ px: 0 }}>
          <Container maxWidth="xs" disableGutters>
            {termState.loaded && !termState.error && <Box dangerouslySetInnerHTML={{ __html: termState.term.content }} />}
            {!termState.loaded && <CircularProgress size={16} color="brand" />}
          </Container>
          {termState.error && (
            <Container>
              <Box py="72px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <ErrorIcon />
                <Typography sx={{ mt: '12px' }}>문서 조회 중 오류가 발생 했습니다</Typography>
                <Button
                  variant="contained"
                  color="brand"
                  startIcon={<RefreshOutlinedIcon />}
                  onClick={() => fetchTerm(termState.type)}
                  sx={{ mt: '7px' }}
                >
                  다시 시도
                </Button>
              </Box>
            </Container>
          )}
        </DialogContent>
      </Dialog>
    </OrderLayout>
  );

  // 입점샵 정보조회
  async function fetchVendor(vendorId): Promise<void> {
    const vendorSnapshot = await getDoc(doc(getFirestore(), `vendor/${vendorId}`)).catch((error) => {
      console.log('입점샵 조회 실패.', error);
      WoAlert.fire(`입점샵 조회 중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.`, '', 'error').then(() => {
        navigate(-1);
      });
    });

    if (!scriptedRef.current) {
      console.warn('[fetchVendor] Unmounted component.');
      return;
    }

    console.debug('[dev] 주 표현상품: ', mainProduct);
    console.debug('[dev] 동시 주문상품: ', orderItems);
    console.debug('[dev] 입점샵: ', vendor);

    setVendor((oldVendor) => ({ ...oldVendor, ...vendorSnapshot.data(), id: vendorSnapshot.id, loaded: true }));

    // 나의 보유쿠폰 목록 불러오기
    fetchMyCoupons();
  }
}

export default OrderTicketConfirm;

// eslint-disable-next-line react/prop-types
const Transition = React.forwardRef(function Transition({ children, ...props }, ref) {
  return (
    <Slide direction="up" ref={ref} {...props}>
      {children}
    </Slide>
  );
});
