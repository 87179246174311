import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import validator from 'validator/es';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import * as _ from 'lodash';
import { useLocationState } from 'react-router-use-location-state';

// project imports
import { CATEGORY_TYPES, PDATA_CATEGORY, WINE_ORDER } from 'config';
import { useAuth, useLocalStorage, useScriptRef, useWineOne } from 'hooks';
import { useDispatch, useSelector } from 'store';
import * as WineOneSearch from 'services/WineOneSearch';
import { fetchCodes } from 'store/slices/wo-constants';
import { WoAlert } from 'utils/kmwine-alerts';
import { getFirestore } from 'utils/firebase-tools';
import { PinMap } from 'components/widgets/UserLocationSheet/components';
import { SearchEmptyIcon } from 'components/icons';
import KeywordAlarmAlert from '../../../components/widgets/KeywordAlarmAlert';

// material-ui
import { AppBar, Box, CircularProgress, Collapse, Container, Dialog, Fab, IconButton, Slide, Tab, Tabs, Typography } from '@mui/material';

// components
import { VIEW_TYPE } from './index';
import { SpaceBetweenBox } from 'components';
import { MallToolbar, OrderDialog, ProductList, SearchDialog, SearchResultContainer } from './components';
import MallLayout from './components/MallLayout';
import { PdataList } from './components/PdataList';
import { ProductInsideContainer } from './components/search-result';
import FilterDialog, {
  COUNTRY_FILTER_NAMES,
  INITIAL_FILTER_STATE,
  PRODUCT_MAX_PRICE,
  PRODUCT_PRICE_STEP,
  VARIETY_FILTER_NAMES
} from './components/FilterDialog';
import { FilterIcon } from './components/icons';
import FilterBtnTooltip from './components/search-result/FilterBtnTooltip';

// assets
import ArrowBackImg from 'assets/images/arrow-back.png';
import { searchProductWithElastic } from '../../../services/ProductService';
import { makeStyles } from '@mui/styles';

// Elastic 상품원본정보 검색 엔진 - todo MySQL에서 판매 중이지 않은 pdata의 정보 가져오기
const pdataClient = WineOneSearch.createElasticAppSearchClient(WineOneSearch.ENGINE.PDATA);

// 상품검색 페이지 사이즈
const PAGE_SIZE = 20;

// 상품검색 페이지 네이트 기본정보
const initialPage = Object.freeze({
  current: 1,
  total_pages: 0,
  total_results: 0,
  size: PAGE_SIZE, // 한번에 호출할 상품 개수
  done: false
});

// 검색상태 기본정보
const initialSearchState = Object.freeze({
  initialized: false,
  searched: false,
  searching: false,
  error: false
});

// // [1.0.9] pdata 검색 기본정보
const initialPdataSearch = Object.freeze({
  page: {
    current: 0,
    total_pages: 0,
    total_results: 0,
    size: 12, // 한번에 호출할 상품 개수
    done: false
  },
  state: {
    initialized: false,
    searching: false,
    error: false
  },
  results: []
});

const PIN_MAP_OPEN_HASH = '#pinmap-open';

const QUERY_HISTORY_MAX_LEN = 10;

/**
 * '검색' 인덱스 페이지
 *
 * @returns {JSX.Element}
 * @authors 조현권<hkcho@wineone.io>, George Murage<george@wineone.io>, 제동균<jedk@wineone.io>
 *
 * @history
 *   - v1.0.5 : 핀 반경내 상품, 반경외 상품, 상품 미등록 와인 검색 기능으로 변경
 *       - 거리(distance)별 상품 구분
 *          - type 'A': 사용자 설정핀 1,000m 반경내 상품
 *          - type 'B': 사용자 설정핀 1,000m 반경외 상품
 *          - type 'C': 'product'없이 'pdata'만 있는 상품
 *
 *   - v1.0.9: 'god'모드 사용자일 경우 테스트 상품 구매가능 하도록
 *   - v1.1.2: 코드 정리 및 화면 기능개선
 */
function MallIndex() {
  const scriptedRef = useScriptRef();
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const globalDispatch = useDispatch();

  const auth = getAuth(); // firebase authentication
  const { needPinSetting, user } = useAuth(); // 1kmwine member data

  const { onNativeBackPressed } = useWineOne();

  // 사용자 위치정보, 서비스 상수
  const { userLocation, woConstants } = useSelector((state) => state);

  React.useEffect(() => {
    globalDispatch(fetchCodes(['wine_type']));
  }, [woConstants]);

  const initialAllSearchState = React.useMemo(() => {
    const searchState = {
      viewType: VIEW_TYPE.GRID,
      orderDialogOpen: false,
      sort: WINE_ORDER[0],
      filter: INITIAL_FILTER_STATE,
      filterUsed: false,
      filterOpen: false,
      searchOpen: false,
      searchState: initialSearchState,
      pdataSearch: initialPdataSearch,
      searchQuery: ''
    };

    if (location?.state?.category) searchState.filter.pdata_category = location.state.category;
    else searchState.filter.pdata_category = 'all';
    return searchState;
  }, []);

  const [search, setSearch] = useLocationState('mip_search', initialAllSearchState);

  // 필터 선택한 순서. '스타일', '국가', '품종'의 경우
  const [filterItemSequence, setFilterItemSequence] = useLocationState('mall_fis', []);
  /** 필터 적용 */
  const applySheetFilter = (filter, _filterItemSequence) => {
    // '스타일', '국가', '품종'의 경우
    if (_filterItemSequence) {
      setFilterItemSequence(_filterItemSequence);
    }

    fetchProductList(initialPage, {
      ...search,
      filter: { ...search.filter, ...filter },
      filterUsed: !_.isEqual(INITIAL_FILTER_STATE, filter),
      filterOpen: false
    });
  };

  // 핀선택지도 열림여부
  const mapOpen = React.useMemo(() => location.hash === PIN_MAP_OPEN_HASH, [location.hash]);

  /** 핀 선택지도 닫기 */
  const handleMapDialogClose = React.useCallback(() => {
    if (location.hash === PIN_MAP_OPEN_HASH) navigate(-1); // 중복클릭 방지
  }, [location.hash]);

  /**
   * 핀선택 완료 콜백
   */
  const onPinConfirmed = React.useCallback(
    (result) => {
      if (!result.success) {
        WoAlert.fire(`위치 설정 중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.`, '', 'error').then(() => {});
      } else {
        handleMapDialogClose(); // 핀 지도 닫기
      }
    },
    [handleMapDialogClose]
  );

  // 최근 검색어 - todo 바로 갱신되지 않는 현상개선
  // 검색을 한 후 다시 검색창으로 들어갔을 때, 이전 검색어가 최근 검색어에 나타나지 않음
  const [queryHistory, setQueryHistory] = useLocalStorage('wine-query-history', []);

  // '검색' 다이어로그 열기
  const openSearchArea = React.useCallback(() => {
    setSearch((prev) => ({ ...prev, searchOpen: true }));
  }, []);

  const closeSearchArea = React.useCallback(() => {
    setSearch((prev) => ({ ...prev, searchOpen: false }));
  }, []);

  // 상품 페이징 정보
  // const [page, setPage] = React.useState(initialPage);
  const [page, setPage] = useLocationState('mip_page', initialPage);
  // 상품 검색결과 목록
  // const [list, setList] = React.useState([]);
  const [list, setList] = useLocationState('mip_list', []);

  /** 검색어를 통해 상품을 검색 */
  const searchNearbyProduct = (_searchQuery = '') => {
    console.log('-----> search.searchOpen: ', search.searchOpen);
    // 검색영역이 열려있을 경우
    // if (!search.searchOpen) {
    //   // todo 기존 검색어를 클릭했을 때 여기서 막혀서 진행이 안됨
    //   console.warn(`[wineone] 검색영역이 열려있지 않아 와인 검색을 진행하지 않습니다.`);
    //   return;
    // }

    // 최근 검색어에 추가
    if (_searchQuery.trim().length > 0) {
      try {
        let _oldQueryHistory = [...queryHistory];
        if (_oldQueryHistory.indexOf(_searchQuery) >= 0) {
          console.log('[MallIndex.jsx] 이미 검색한 적 있는 검색어: ', _searchQuery);
          _oldQueryHistory.splice(_oldQueryHistory.indexOf(_searchQuery), 1);
        }

        // 기록하고 있을 최대 개수를 넘어갈 경우
        if (_oldQueryHistory.length > QUERY_HISTORY_MAX_LEN - 1) {
          _oldQueryHistory = _oldQueryHistory.splice(0, QUERY_HISTORY_MAX_LEN - 1);
        }

        setQueryHistory(() => [_searchQuery, ..._oldQueryHistory]);
      } catch (e) {
        /* DO NOTHING */
      }
    }
    fetchProductList(initialPage, {
      ...search,
      searchQuery: _searchQuery.trim(),
      searchOpen: false,
      pdataSearch: initialPdataSearch,
      filter: {
        ...search.filter,
        pdata_category: 'all',
        tabIndex: 'all'
      }
    });
  };

  // 검색화면 컴포넌트 mount시 조회된 목록이 없을 경우 기본목록 조회
  React.useEffect(() => {
    if (!search.searchState.initialized) {
      fetchProductList(initialPage, initialAllSearchState);
    }
  }, []);

  /** 원본상품(pdata) 검색정보 초기화 */
  const resetPdataSearch = () => {
    setSearch((prev) => ({ ...prev, pdataSearch: initialPdataSearch }));
  };

  React.useEffect(() => {
    // 판매상품(product)이 모두 조회되었을 때
    if (page.done) {
      console.log('[MallIndex] 모든 판매상품(product)을 조회했습니다.');
      if (!search.pdataSearch.state.initialized) {
        fetchPdataList();
      }
    }
    // 판매상품(product)이 아직 조회 중일 때
    else {
      // 원본상품(pdata) 조회결과 초기화
      resetPdataSearch();
    }
  }, [page.done, search.pdataSearch.state.initialized]);

  // 검색중 다른곳에 갔다가 다시 왔을때 searching 초기화
  React.useEffect(() => {
    setSearch((prev) => ({ ...prev, searchState: { ...prev.searchState, searching: false } }));
  }, []);

  // 스크롤 디렉션
  const [y, setY] = React.useState(window.scrollY);

  const handleScrollEvent = (e) => {
    const window = e.currentTarget;
    // 스크롤이 아래방향으로 ( trigger 쓰지말고 y로 판단 )
    if (y < window.scrollY) {
      // 스크롤이 바닥에 거의(-350) 닿았을 때
      if (window.scrollY + window.innerHeight >= document.body.offsetHeight - 600) {
        if (!search.searchState.searched) return; // 최초 조회시 중복요청 제외
        if (search.searchState.searching) return; // 판매상품(product) 페이지 요청 중일 경우 pass
        if (search.pdataSearch.state.searching) return; // 상품(pdata) 페이지 요청 중일 경우 pass

        // 다음 페이지 호출
        // if (page.current < page.total_pages) {
        if (!page.done) {
          console.debug(`[wineone] 다음 페이지 호출 (${page.current + 1})`);
          fetchProductList({ ...page, current: page.current + 1 }, search);
        }
        // 이미 상품 검색결과를 모두 보여준 상태 -> pdata 검색
        // else if (!pdataSearch.done && !pdataSearch.page.done) {
        else if (!search.pdataSearch.page.done) {
          console.log('[MallIndex] 모든 상품(product)을 검색함. pdata 검색 시작...', search.pdataSearch.page.done);
          fetchPdataList();
        } else {
          console.log('[MallIndex] 더 이상 조회할 product 또는 pdata가 없습니다.');
        }
      }
    }
    setY(window.scrollY);
  };

  // 스크롤 감시
  React.useEffect(() => {
    setY(window.scrollY);
    window.addEventListener('scroll', handleScrollEvent);

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, [handleScrollEvent]);

  /** 판매상품(product) 목록 호출 */
  async function fetchProductList(_page, allSearchState) {
    // setCancelRequestListTokenSource
    if (_page.current !== 1 && search.searchState.searching) {
      console.warn('[MallIndex.jsx] 이미 판매상품(product) 목록을 요청 중 입니다.');
      return false;
    }

    // 1page 조회일 경우
    if (_page.current === 1) {
      // 기존 검색값 모두 초기화
      setPage(initialPage);
      setSearch({
        ...allSearchState,
        searchState: initialSearchState,
        pdataSearch: initialPdataSearch // 원본상품(pdata) 검색결과 초기화
      });
    } else {
      // 검색상태로 변경
      setSearch({ ...allSearchState, searchState: { ...allSearchState.searchState, searching: true, error: false } });
    }

    const centerCoord = userLocation.coord;
    // console.log('_search: ', allSearchState);
    // console.log('-------------> _search.searchQuery: ', allSearchState.searchQuery);
    const record_analytics = allSearchState.searchQuery.trim().length > 0;

    // 판매상품 필터
    // elastic 'product' engine filter.
    const filters = {
      all: [
        { available: 'true' }, // 판매중인 상품만
        { remove: 'false' }, // 삭제되지 않은 상품
        { vendor_closed: 'false' }, // 영업 정지된 입점샵의 product는 제외
        { vendor_deleted: 'false' }, // 삭제된 입점샵의 판매상품은 exclude
        { vendor_candidate: 'false' } // 미입점 입점샵의 판매상품은 제외
      ],
      none: [
        { category: [PDATA_CATEGORY.TICKET.value] }, // 티켓상품 조회 안함
        { quantity: { to: 1 } }, // 재고 0 제외 - v1.0.8
        { vendor_state: ['BEN'] }, // 사용 중지 입점사 제외
        { promo_state: ['WAIT'] } // 셀프 프로모션 상태가 '대기'인 상품은 검색 제외
      ]
    };

    // product 카테고리 검색
    if (allSearchState.filter.pdata_category !== 'all') {
      filters.all.push({ pdata_category: [allSearchState.filter.pdata_category] });
    }

    // 필터에서 금액범위를 직접 입력했을 경우
    if (_.difference(allSearchState.filter.price_range_custom, INITIAL_FILTER_STATE.price_range_custom).length) {
      // console.log('#1: ', allSearchState.filter.price_range_custom);
      // console.log('#2: ', INITIAL_FILTER_STATE.price_range_custom);
      // 직접 설정한 금액범위
      const [priceMin, priceMax] = allSearchState.filter.price_range_custom;

      // 금액 슬라이더가 최소, 최대범위일 경우 모든 금액을 계산합니다.
      if (priceMin === 0 && priceMax === '') {
        console.debug('모든 가격의 상품을 조회합니다.');
      } else {
        // 직접 설정한 금액범위가 있을 경우
        const option = {};
        if (priceMin > 0) {
          option.from = priceMin;
        }

        if (priceMax > 0 && priceMax !== '') {
          option.to = priceMax;
        }

        filters.all.push({ price_original: option });
      }
    }
    // 필터에서 금액범위를 직접 설정 경우
    // else if (search.filter.price_range !== INITIAL_FILTER_STATE.price_range) {
    else if (_.difference(allSearchState.filter.price_range, INITIAL_FILTER_STATE.price_range).length) {
      // Slide로 설정한 금액범위
      const [priceMin, priceMax] = allSearchState.filter.price_range;

      // 금액 슬라이더가 최소, 최대범위일 경우 모든 금액을 계산합니다.
      if (priceMin === 0 && priceMax === PRODUCT_MAX_PRICE + PRODUCT_PRICE_STEP) {
        console.debug('모든 가격의 상품을 조회합니다.');
      } else {
        const option = {};
        if (priceMin > 0) {
          option.from = priceMin;
        }

        if (priceMax < PRODUCT_MAX_PRICE + PRODUCT_PRICE_STEP) {
          option.to = priceMax;
        }

        filters.all.push({ price_original: option });
      }
    }
    // 금액범위 기본
    else {
      // 10원 이상 - v1.0.9
      filters.all.push({ price_original: { from: 10 } });
    }

    /*
     * pdata.type이 와인(wine)일 경우만 필터 적용
     *******************************************************************************************************************
     */
    if (allSearchState.filter.pdata_category === PDATA_CATEGORY.WINE.value) {
      // wine-filter - 당도 - 와인 종류: RED, WHITE, SPARKLING, ETC
      if (allSearchState.filter.tabIndex !== 'all') filters.all.push({ pdata_type: [allSearchState.filter.tabIndex] });

      // 필터에서 VIVINO 4.0 이상 평점 필터링
      if (allSearchState.filter.vivino) {
        filters.all.push({ pdata_score_vivino: { from: 4.0 } });
      }

      // wine-filter - 당도
      if (allSearchState.filter.taste_sweet > 0) {
        let scores = [];
        for (let i = 0; i < 3; i++) {
          if (allSearchState.filter.taste_sweet & (2 ** i)) scores = [...new Set([...scores, i + 1, i + 2, i + 3])];
        }
        filters.all.push({ pdata_taste_sweet: scores });
      }
      // wine-filter - 산도
      if (allSearchState.filter.taste_acidity > 0) {
        let scores = [];
        for (let i = 0; i < 3; i++) {
          if (allSearchState.filter.taste_acidity & (2 ** i)) scores = [...new Set([...scores, i + 1, i + 2, i + 3])];
        }
        filters.all.push({ pdata_taste_acidity: scores });
      }
      // wine-filter - 바디감
      if (allSearchState.filter.taste_body > 0) {
        let scores = [];
        for (let i = 0; i < 3; i++) {
          if (allSearchState.filter.taste_body & (2 ** i)) scores = [...new Set([...scores, i + 1, i + 2, i + 3])];
        }
        filters.all.push({ pdata_taste_body: scores });
      }
      // wine-filter - 바디감
      if (allSearchState.filter.taste_tannin > 0) {
        let scores = [];
        for (let i = 0; i < 3; i++) {
          if (allSearchState.filter.taste_tannin & (2 ** i)) scores = [...new Set([...scores, i + 1, i + 2, i + 3])];
        }
        filters.all.push({ pdata_taste_tannin: scores });
      }

      // wine-filter - 포도 품종
      if (allSearchState.filter.variety.length) {
        // 'etc'가 끼어있을 경우
        if (allSearchState.filter.variety.indexOf('etc') >= 0) {
          // 그 외에 선택된 품종은 검색되도록
          const notIn = VARIETY_FILTER_NAMES.filter((v) => allSearchState.filter.variety.indexOf(v) < 0);
          console.debug('[filter] 검색에서 제외할 품종: ', notIn);
          filters.none.push({
            pdata_variety_id: notIn
          });
        }
        // 선택된 포도품종 필터링
        else {
          console.debug('[filter] 검색할 품종: ', allSearchState.filter.variety);
          filters.all.push({
            pdata_variety_id: [...allSearchState.filter.variety]
          });
        }
      }

      // wine-filter - 국가
      if (allSearchState.filter.country.length) {
        // 'etc'가 끼어있을 경우
        if (allSearchState.filter.country.indexOf('etc') >= 0) {
          // 그 외에 선택된 국가는 검색되도록
          const notIn = COUNTRY_FILTER_NAMES.filter((c) => allSearchState.filter.country.indexOf(c) < 0);
          console.debug('[filter] 검색에서 제외할 국가: ', notIn);
          filters.none.push({
            pdata_country_id: notIn
          });
        }
        // 선택된 국가 필터링
        else {
          console.debug('[filter] 검색할 국가: ', allSearchState.filter.country);
          filters.all.push({
            pdata_country_id: [...allSearchState.filter.country]
          });
        }
      }
    }

    // 절.대.사.용.자 - v1.0.9
    const { god = false } = user;

    if (god) {
      /* Firestore: /member/{uid} -> god: true */
      console.info('[MallIndex] `god`사용자는 테스트 입점샵도 조회합니다.');
    } else {
      // 테스트 입점샵은 검색이 안되도록
      filters.none.push({ vendor_test: 'true' });
    }

    console.groupCollapsed('[MallIndex] 상품(product) 조회조건');
    console.log('필터: ', filters);
    console.log('정렬: ', allSearchState.sort);
    console.groupEnd();

    // 판매상품 정렬
    const _sort = [];

    // 판매상품 group by
    const group = {
      field: 'group_a', // pdata_id, capacity
      size: 10,
      sort: [],
      collapse: true
    };

    // v1.0.9: 높은 가격순
    if (allSearchState.sort.value === 'highp') {
      group.sort.push({ price_original: 'desc' });
    }
    // 그 외는 낮은 가격순이 그룹 기본정렬
    else {
      group.sort.push({ price_original: 'asc' });
    }

    // 최신순
    if (allSearchState.sort.value === 'recent') {
      _sort.push({ created_at: 'desc' });
    }
    // 추천순
    else if (allSearchState.sort.value === 'star') {
      _sort.push({ pdata_star: 'desc' });
    }
    // 리뷰 많은 순
    else if (allSearchState.sort.value === 'review') {
      _sort.push({ pdata_reviews: 'desc' });
    }
    // 가격 낮은순
    else if (allSearchState.sort.value === 'lowp') {
      _sort.push({ price_original: 'asc' });
    }
    // 가격 높은순
    else if (allSearchState.sort.value === 'highp') {
      _sort.push({ price_original: 'desc' });
    }
    // 인기순 순
    else if (allSearchState.sort.value === 'wish') {
      _sort.push({ pdata_wishes: 'desc' });
    }
    // 가까운 거리순 - v1.0.9
    else if (allSearchState.sort.value === 'distance') {
      // 1) 거리
      _sort.push({
        vendor_location: {
          center: `${centerCoord.lat}, ${centerCoord.lng}`,
          order: 'asc'
        }
      });
      // 2) 찜
      _sort.push({ pdata_wishes: 'desc' });

      // 3) 구매자 점수
      _sort.push({ pdata_star: 'desc' });
    }

    // Elastic App Search option
    // reference: https://www.elastic.co/guide/en/app-search/8.2/search.html#search-api-request-body
    const options = {
      page: { size: _page.size, current: _page.current },
      search_fields: {
        name_ko: { weight: 5 },
        name_en: { weight: 5 }
      },

      // 판매상품 필터
      filters,
      // 판매상품 정렬
      sort: _sort,
      // 판매상품 group by
      group,

      record_analytics,
      analytics: {
        tags: ['1kmwine', 'wine', 'search']
      },
      coordinate: centerCoord
    };

    // 판매상품(product) 검색
    const searchResult = await searchProductWithElastic(allSearchState.searchQuery, options);

    if (!scriptedRef.current) {
      console.warn('[MallIndex][fetchProductList] Unmounted component.');
      return;
    }

    const { page, products } = searchResult;

    // 조회된 pdata 리스트
    // 1페이지 요청시 리스트 초기화
    const productList = page.current === 1 ? [...products] : [...list, ...products];

    // 판매상품(product) 조회 완료, 조회결과가 없거나 모든 결과를 조회했을 때
    const done = page.total_pages === page.current || page.total_results === 0 || page.total_pages === 0;

    // 검색상태
    setSearch((prev) => ({
      ...prev,
      ...allSearchState,
      searchState: {
        ...allSearchState.searchState,
        initialized: true,
        searched: true,
        searching: false
      },
      pdataSearch: page.current === 1 ? { ...initialPdataSearch } : { ...prev.pdataSearch },
      filterUsed: !deepEqual(INITIAL_FILTER_STATE, allSearchState.filter)
    }));

    // 페이지번호 갱신
    setPage((prev) => ({ ...prev, ...page, done }));
    // 검색상품 목록
    setList(productList);
    if (page.current === 1) window.scrollTo(0, 0);
  }

  // 검색된 상품목록
  const productListComponent = React.useMemo(
    () => <ProductList viewType={search.viewType} totalCount={page.total_results} list={list} sort={search.sort} />,
    [list, search.viewType, search.sort, page.total_results]
  );

  // pdata 검색
  async function fetchPdataList() {
    // todo 검색어가 없을 경우 - 없는 데 없다고 안뜸
    if (validator.isEmpty(search.searchQuery)) {
      console.log('[pdata 검색] 검색어가 없습니다. pdata를 검색하지 않습니다.');
      setSearch((prev) => ({
        ...prev,
        pdataSearch: {
          ...initialPdataSearch,
          page: { ...initialPdataSearch.page, done: true }
        }
      }));
      return;
    }

    if (search.pdataSearch.page.done) {
      console.log('[pdata 검색] 이미 모든 pdata를 조회했습니다.');
      return;
    }

    if (search.pdataSearch.state.searching) {
      console.log('[pdata 검색] 이미 검색 중 입니다.');
      return;
    }

    // 조회대상 페이지
    const currentPage = search.pdataSearch.page.current + 1;

    // pdata 검색 시작
    console.log(`# pdata 검색. 검색어=${search.searchQuery}`, search.pdataSearch.page, search.pdataSearch.state);

    setSearch((prev) => ({
      ...prev,
      pdataSearch: {
        ...prev.pdataSearch,
        page: { ...prev.pdataSearch.page, done: false },
        state: { ...prev.pdataSearch.state, searching: true },
        results: prev.pdataSearch.results
      }
    }));

    // pdata 검색 필터
    // elastic 'pdata' engine filter
    const filters = {
      all: [
        { setup: 'true' }, // 검수완료된 원본상품
        { show: 'true' } // 진열된 원본상품
      ],
      none: [
        { category: [PDATA_CATEGORY.TICKET.value] }, // 티켓 제외
        { vendor_lock: 'true' } // v1.1.5: 프로모션이 종료된 특가상품(pdata)은 조회되지 않도록
      ]
    };

    // 카테고리 검색
    if (search.filter.pdata_category !== 'all') {
      filters.all.push({ category: [search.filter.pdata_category] });
    }

    // (pdata) filter - wine_type : {elastic app search}/app/enterprise_search/app_search/engines/pdata/documents
    if (search.filter.tabIndex !== 'all') filters.all.push({ type: [search.filter.tabIndex] });

    // (pdata) filter - Taste sweet
    if (search.filter.taste_sweet > 0) {
      let scores = [];
      for (let i = 0; i < 3; i++) {
        if (search.filter.taste_sweet & (2 ** i)) scores = [...new Set([...scores, i + 1, i + 2, i + 3])];
      }
      filters.all.push({ taste_sweet: scores });
    }
    // (pdata) filter - Taste acidity
    if (search.filter.taste_acidity > 0) {
      let scores = [];
      for (let i = 0; i < 3; i++) {
        if (search.filter.taste_acidity & (2 ** i)) scores = [...new Set([...scores, i + 1, i + 2, i + 3])];
      }
      filters.all.push({ taste_acidity: scores });
    }
    // (pdata) filter - Taste body
    if (search.filter.taste_body > 0) {
      let scores = [];
      for (let i = 0; i < 3; i++) {
        if (search.filter.taste_body & (2 ** i)) scores = [...new Set([...scores, i + 1, i + 2, i + 3])];
      }
      filters.all.push({ taste_body: scores });
    }
    // (pdata) filter - Taste body
    if (search.filter.taste_tannin > 0) {
      let scores = [];
      for (let i = 0; i < 3; i++) {
        if (search.filter.taste_tannin & (2 ** i)) scores = [...new Set([...scores, i + 1, i + 2, i + 3])];
      }
      filters.all.push({ taste_tannin: scores });
    }

    // (pdata) filter - Taste acidity
    if (search.filter.taste_acidity > 0) {
      let scores = [];
      for (let i = 0; i < 3; i++) {
        if (search.filter.taste_acidity & (2 ** i)) scores = [...new Set([...scores, i + 1, i + 2, i + 3])];
      }
      filters.all.push({ taste_acidity: scores });
    }

    // (pdata) filter - Taste body
    if (search.filter.taste_body > 0) {
      let scores = [];
      for (let i = 0; i < 3; i++) {
        if (search.filter.taste_body & (2 ** i)) scores = [...new Set([...scores, i + 1, i + 2, i + 3])];
      }
      filters.all.push({ taste_body: scores });
    }

    // (pdata) filter - Taste body
    if (search.filter.taste_tannin > 0) {
      let scores = [];
      for (let i = 0; i < 3; i++) {
        if (search.filter.taste_tannin & (2 ** i)) scores = [...new Set([...scores, i + 1, i + 2, i + 3])];
      }
      filters.all.push({ taste_tannin: scores });
    }

    // (pdata) filter - Grape variety
    if (search.filter.variety.length) {
      // 'etc'가 끼어있을 경우
      if (search.filter.variety.indexOf('etc') >= 0) {
        // 그 외에 선택된 품종은 검색되도록
        const notIn = VARIETY_FILTER_NAMES.filter((v) => search.filter.variety.indexOf(v) < 0);
        console.debug('[filter:pdata] 검색에서 제외할 품종: ', notIn);
        if (!filters.none) filters.none = [];
        filters.none.push({ variety_id: notIn });
      }
      // 선택된 포도품종 필터링
      else {
        console.debug('[filter:pdata] 검색할 품종: ', search.filter.variety);
        filters.all.push({ variety_id: [...search.filter.variety] });
      }
    }

    // (pdata) filter - Country
    if (search.filter.country.length) {
      // 'etc'가 끼어있을 경우
      if (search.filter.country.indexOf('etc') >= 0) {
        // 그 외에 선택된 국가는 검색되도록
        const notIn = COUNTRY_FILTER_NAMES.filter((c) => search.filter.country.indexOf(c) < 0);
        console.debug('[filter:pdata] 검색에서 제외할 국가: ', notIn);
        if (!filters.none) filters.none = [];
        filters.none.push({ country_id: notIn });
      }
      // 선택된 국가 필터링
      else {
        console.debug('[filter:pdata] 검색할 국가: ', search.filter.country);
        filters.all.push({ country_id: [...search.filter.country] });
      }
    }

    // 원본상품(pdata) 검색
    const resultList = await pdataClient
      .search(search.searchQuery, {
        page: { size: search.pdataSearch.page.size, current: currentPage },
        search_fields: {
          name_ko: { weight: 5 },
          name_en: { weight: 5 }
        },
        // 원본상품(pdata) 필터
        filters
      })
      .catch(console.error);
    console.log('pdata elastic 검색 결과: ', resultList);
    const {
      info: {
        meta: {
          alerts = [], // 에러
          page: pdataSearchPage, // 페이지 정보
          request_id // 요청 아이디
        }
      },
      results
    } = resultList; // 검색결과 정보

    if (!scriptedRef.current) {
      console.warn('[MallIndex][fetchPdataList] Unmounted component. Skip update.');
      return undefined;
    }

    console.groupCollapsed(
      `[MallIndex] 상품(pdata) elastic search results. [page=${pdataSearchPage.current.toLocaleString()}/${pdataSearchPage.total_pages.toLocaleString()}]`
    );
    console.log(' results    : ', results);
    console.log(' alerts     : ', alerts);
    console.log(' page       : ', pdataSearchPage);
    console.log(' request_id : ', request_id);
    console.groupEnd();

    // 1페이지 요청시 리스트 초기화
    const pdataResults = pdataSearchPage.current === 1 ? [] : [...search.pdataSearch.results];

    // 조회된 pdata 리스트
    results.forEach((result) => {
      pdataResults.push({
        loaded: false,
        id: result.getRaw('id'),
        _id: result.getRaw('id'),
        bottle_img: { thumb: result.getRaw('bottle_img_thumb') },
        category: result.getRaw('category'),
        country: {
          id: result.getRaw('country_id'),
          en: result.getRaw('country_en'),
          ko: result.getRaw('country_ko')
        },
        desc1: result.getRaw('desc1'),
        desc2: result.getRaw('desc2'),
        name: { en: result.getRaw('name_en'), ko: result.getRaw('name_ko') },
        producer: { en: result.getRaw('producer_en'), ko: result.getRaw('producer_ko') },
        reviews: result.getRaw('reviews'),
        star: result.getRaw('star'),
        variety: {
          id: result.getRaw('variety_id'),
          en: result.getRaw('variety_en'),
          ko: result.getRaw('variety_ko')
        },
        wishes: result.getRaw('wishes'),
        elastic: {
          query: search.searchQuery,
          documentId: result.data._meta.id,
          requestId: request_id
        }
      });
    });

    // 판매상품 조회 완료, 조회결과가 없거나 모든 결과를 조회했을 때
    const done =
      pdataSearchPage.total_results === 0 || pdataSearchPage.total_pages === 0 || pdataSearchPage.total_pages === pdataSearchPage.current;

    setSearch((prev) => ({
      ...prev,
      pdataSearch: {
        page: { ...pdataSearchPage, done },
        state: {
          initialized: true,
          searched: true,
          searching: false,
          error: alerts.length > 0 // TODO pdata 조회 에러처리
        },
        results: pdataResults
      }
    }));
  }

  const handleCategoryTabChange = (event, tabIndex) => {
    fetchProductList(initialPage, { ...search, filter: { ...search.filter, pdata_category: tabIndex } });
  };

  // 탭 변경
  const handleTabNavChange = React.useCallback(
    (event, newTabIndex) => {
      fetchProductList(initialPage, { ...search, filter: { ...search.filter, tabIndex: newTabIndex } });
    },
    [search.filter.pdata_category, search.filter.price_all, search.filterOpen]
  );

  // 상품(product) 검색결과 있음
  const hasResult = React.useMemo(() => {
    return search.searchState.searching || (search.searchState.searched && page.total_results > 0);
  }, [search.searchState.searching, search.searchState.searched, page.total_results]);

  React.useEffect(() => {}, [hasResult]);

  // 원본상품(pdata) 검색결과 있음
  const hasPdataResult = React.useMemo(() => {
    // 원본상품 출력 준비됨
    if (!search.pdataSearch.state.initialized) return false;

    // 로딩 중에는 검색결과가 있는 것으로 간주
    if (search.pdataSearch.state.searching || !search.pdataSearch.page.done) return true;

    // 검색이 완료
    return search.pdataSearch.page.done && search.pdataSearch.page.total_results > 0;
  }, [
    search.pdataSearch.state.initialized,
    search.pdataSearch.state.searching,
    search.pdataSearch.page.done,
    search.pdataSearch.page.total_results
  ]);

  /**
   * 키워드 알림등록 묻기
   *  - 해당 키워드가 알림에 등록되어 있는지 확인.
   *  - 키워드 알림이 등록되어있지 않을 경우 등록여부를 사용자에게 질문.
   */
  const askKeywordAlarm = async () => {
    if (search.searchQuery.trim().length === 0) return false;
    console.log(`검색결과가 없는 키워드에 대한 키워드 알림 등록여부 확인. searchQuery=${search.searchQuery}`);
    const snapshot = await getDocs(
      query(collection(getFirestore(), `keyword`), where('user.id', '==', auth.currentUser.uid), where('text', '==', search.searchQuery))
    ).catch((error) => error);

    console.debug('키워드 등록여부 확인: ', snapshot);
    if (!snapshot.error && snapshot.empty) {
      // 검색어 알림 등록용 화면 띄우기
      setShowKeywordAlert(true);
    }
  };

  // 키워드 알림등록 alert 보이기
  const [showKeywordAlert, setShowKeywordAlert] = React.useState(false);

  /** 키워드 알림등록 alert 닫기 */
  const handleShowKeywordAlert = () => {
    setShowKeywordAlert(false);
  };

  // 와인 종류 탭
  const wineTabList = React.useMemo(
    () =>
      CATEGORY_TYPES[PDATA_CATEGORY.WINE.value].map(({ value, label }) => (
        <Tab key={`wine-tab-key-${value.toLowerCase()}`} label={label.ko} id={`wine-tab-${value.toLowerCase()}`} value={value} />
      )),
    []
  );

  // 모든 상품이 로드되었는지 여부
  const allItemsLoaded = React.useMemo(() => page.done && search.pdataSearch.page.done, [page.done, search.pdataSearch.page.done]);

  // 검색결과가 있는지 여부
  // const hasSearchResult = React.useMemo(() => {
  //   return page.total_results + pdataSearch.page.total_results > 0;
  // }, [page, pdataSearch.page.done]);

  // v1.0.9
  React.useEffect(() => {
    if (!allItemsLoaded) return;
    console.log('[MallIndex] 모든 상품(product) 및 원본상품(pdata)가 조회완료되었습니다.');

    // 상품(product)검색 결과가 없을 경우 - 주문가능상품(product)는 없고 원본상품(pdata)만 있을 경우
    if (!validator.isEmpty(search.searchQuery) && page.total_results === 0 && search.pdataSearch.page.total_results > 0) {
      askKeywordAlarm(); // 키워드 알림등록 묻기
    }

    // validator.isEmpty(searchQuery)
    // console.log('--------------------------------------> searchQuery: ', searchQuery);
    // console.log('--------------------------------------> page: ', page);
    // console.log('--------------------------------------> hasSearchResult: ', hasSearchResult);

    return () => {
      setShowKeywordAlert(false);
    };
  }, [allItemsLoaded]);

  // 뒤로가기
  const pageBackFunc = React.useCallback(() => {
    // 상품검색 영역이 열려있었을 경우
    if (search.searchOpen) {
      closeSearchArea();
    } else if (window.history.length > 0) {
      navigate(-1);
    } else {
      navigate('/', { replace: true });
    }
  }, [location, search.searchOpen]);

  React.useEffect(() => {
    // 기기에서 뒤로가기 버튼을 눌렀을 때
    // window.dispatchEvent(new CustomEvent('wo.hardware', {detail: {action: 'backpressed'}}));

    onNativeBackPressed(pageBackFunc);
  }, [pageBackFunc]);

  /** 상품정렬 dialog 열기 */
  const handleOrderDialogOpen = () => {
    console.log('상품 정렬 dialog 열기', { ...search });
    setSearch({ ...search, orderDialogOpen: true });
  };

  /**
   * 상품정렬 순서변경 콜백
   */
  const handleOrderDialogChange = (value, label = 'unknown') => {
    console.log('[검색] 정렬순서 변경.');

    // 정렬 순서가 변경되었을 경우
    if (value) {
      fetchProductList(initialPage, { ...search, sort: { ...search.sort, label, value }, orderDialogOpen: false });
    }
    // 변경하지 않고 닫을 경우
    else fetchProductList(initialPage, { ...search, orderDialogOpen: false });
  };

  /**
   * 필터 삭제 콜백
   */
  const deleteFilter = (name, value) => {
    // 스타일 필터 삭제
    if (name === 'taste_acidity' || name === 'taste_body' || name === 'taste_sweet' || name === 'taste_tannin') {
      const remainSequence = _.reject(filterItemSequence, { item: name });

      if (name === 'taste_acidity') applySheetFilter({ ...search, taste_acidity: value }, remainSequence);
      else if (name === 'taste_body') applySheetFilter({ ...search, taste_body: value }, remainSequence);
      else if (name === 'taste_sweet') applySheetFilter({ ...search, taste_sweet: value }, remainSequence);
      else if (name === 'taste_tannin') applySheetFilter({ ...search, taste_tannin: value }, remainSequence);
    }
    // 국가, 품종 픽터 삭제
    else if (name === 'country' || name === 'variety') {
      const { country = [], variety = [] } = search.filter;
      const remainSequence = _.reject(filterItemSequence, { item: name, value });

      if (name === 'country') applySheetFilter({ ...search, country: country.filter((item) => item !== value) }, remainSequence);
      else if (name === 'variety') applySheetFilter({ ...search, variety: variety.filter((item) => item !== value) }, remainSequence);
    }
  };

  /* Sprint 23-1: 필터버튼 안내 툴팁 [start] */
  /** 툴팁 노출지연 ms */
  const TOOLTIP_SHOW_AFTER = 1200;
  /** 툴팁 노출시간 ms */
  const TOOLTIP_EXPOSURE_MILLIS = 3000;
  /** 툴팁 노출 최대횟수 */
  const TOOLTIP_EXPOSURE_COUNT_MAX = 3;
  // 툴팁 보이기 타임아웃 ID
  let tooltipShowTimeout = null;
  // 툴팁 감추기 타임아웃 ID
  let tooltipHideTimeout = null;
  // 툴팁 노출 횟수
  const [tooltipShowCount, setTooltipShowCount] = useLocalStorage('filter-tooltip', 0);

  // 와인 상세보기 안내 툴팁 보이기
  const [showTooltip, setShowTooltip] = React.useState(false);

  React.useEffect(() => {
    if (search.filter.pdata_category !== 'wine') return;

    // 와인 검색일 경우에만 뜨기
    if (showTooltip) {
      console.debug('[MallIndex] 필터버튼 안내 툴팁을 보여줍니다.');
      setTooltipShowCount((tooltipShowCount) => {
        console.debug(`[MallIndex] 필터버튼 툴팁 노출횟수. ${tooltipShowCount + 1}/${TOOLTIP_EXPOSURE_COUNT_MAX}`);
        return ++tooltipShowCount;
      });

      // #{TOOLTIP_EXPOSURE_MILLIS} millisecond 후 툴팁 감춤
      tooltipHideTimeout = setTimeout(() => {
        if (scriptedRef.current) {
          setShowTooltip(false);
        }
      }, TOOLTIP_EXPOSURE_MILLIS);
    }
  }, [showTooltip, search.filter.pdata_category]);

  // 툴팁 자동 보이기/감추기
  React.useEffect(() => {
    // 화면 진입시에 표출되도록
    // if (navigationType !== 'PUSH') return undefined;

    if (tooltipShowCount >= TOOLTIP_EXPOSURE_COUNT_MAX) {
      console.info(`[MallIndex] 이미 툴팁 노출횟수를 달성했습니다. [TOOLTIP_EXPOSURE_COUNT_MAX = ${TOOLTIP_EXPOSURE_COUNT_MAX}]`);
      return undefined;
    }

    // #{TOOLTIP_SHOW_AFTER} millisecond 후 툴팁 표현
    tooltipShowTimeout = setTimeout(() => {
      if (scriptedRef.current) setShowTooltip(true);
    }, TOOLTIP_SHOW_AFTER);

    return () => {
      hideTooltip();
    };
  }, []);

  // 툴팁 감추기
  const hideTooltip = () => {
    clearTimeout(tooltipShowTimeout);
    clearTimeout(tooltipHideTimeout);

    if (scriptedRef.current) {
      setShowTooltip(false);
    }
  };
  // Spring 23-1: 필터버튼 안내 툴팁[end]

  const hasSearchQuery = React.useMemo(
    () => typeof search.searchQuery === 'string' && search.searchQuery.trim().length > 0,
    [search.searchQuery]
  );

  const openWineFilter = React.useCallback(() => {
    setSearch((search) => ({ ...search, filterOpen: true }));
  }, [setSearch]);

  // render
  return !needPinSetting ? (
    <MallLayout
      toolbar={
        <MallToolbar
          filter={search.filter}
          searchQuery={search.searchQuery}
          onInputClick={openSearchArea}
          onChange={handleCategoryTabChange}
        />
      }
      stickyToolbar={
        <MallStickyToolBar
          hasResult={hasResult}
          hasPdataResult={hasPdataResult}
          hasSearchQuery={hasSearchQuery}
          pdataCategory={search.filter.pdata_category}
          onTypeTabChange={handleTabNavChange}
          wineTabList={wineTabList}
          tabIndex={search.filter.tabIndex}
          openWineFilter={openWineFilter}
          setSearch={setSearch}
          showTooltip={showTooltip}
        />
      }
      showFooter={false}
    >
      <Helmet title={`${process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE} | 검색`} />
      <SearchResultContainer sx={{ minHeight: hasResult ? `calc(var(--vh, 1vh) * 100)` : 'auto' }}>
        {/* 핀 위치내 상품 표현 영역 */}
        <ProductInsideContainer
          totalCount={page.total_results}
          hasSearchQuery={hasSearchQuery}
          sort={{ label: search.sort.label, value: search.sort.value }}
          onSortOpen={handleOrderDialogOpen}
          viewType={search.viewType}
          searchState={{ ...search.searchState, page }}
          onViewTypeChange={(viewType) => {
            setSearch({ ...search, viewType });
          }}
          filter={search.filter}
          filterItemSequence={filterItemSequence}
          onFilterItemClick={(name, value) => {
            if (search.searchState.initialized) deleteFilter(name, value);
          }}
          onVivinoScoreClick={(vivino) => {
            console.log(search.searchState.initialized);
            if (search.searchState.initialized) applySheetFilter({ ...search.filter, vivino });
          }}
          onPriceRangeClick={(price_range_custom, price_all) => {
            if (search.searchState.initialized) applySheetFilter({ ...search.filter, price_range_custom, price_all });
          }}
        >
          {/* 상품목록 */}
          {productListComponent}

          {!page.done && (
            <Box display="flex" justifyContent="center" py={2}>
              <CircularProgress size={24} color="brand" />
            </Box>
          )}
        </ProductInsideContainer>

        {/* 주변에 와인이 없거나, 검색된 와인이 없을 경우 */}
        {allItemsLoaded && !search.searchState.searching && !search.pdataSearch.state.searching && !hasResult && !hasPdataResult && (
          <Box paddingY={5} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Box display="flex" justifyContent="center" alignItems="center">
              <SearchEmptyIcon />
            </Box>
            <Typography component="div" width="100%" textAlign="center" fontSize="18px" fontWeight={300}>
              {validator.isEmpty(search.searchQuery) ? (
                <div>
                  주변에 해당 와인을 판매하는
                  <br />
                  매장이 없습니다.
                </div>
              ) : (
                <div>
                  일치하는 검색 결과가 없습니다.
                  <br />'<b>{search.searchQuery}</b>'
                </div>
              )}
            </Typography>
          </Box>
        )}
        {/* pdata 검색결과 영역 */}
        {page.done && search.pdataSearch.state.initialized && search.pdataSearch.page.total_results > 0 && (
          <Box
            className="pdata-search-results"
            position="relative"
            zIndex={2}
            pb="56px"
            minHeight={`calc(calc(var(--vh, 1vh) * 100) - 55px - 42px)`}
          >
            <Container className={classes.containerPdataTitle}>
              <SpaceBetweenBox alignItems="center" height="100%">
                <Typography fontSize="14px" lineHeight="normal" fontWeight={800}>
                  상품 정보{' '}
                  <Box component="span" color="#9357E5">
                    {search.pdataSearch.page.total_results.toLocaleString()}
                  </Box>
                </Typography>
              </SpaceBetweenBox>
            </Container>

            {/* 원본상품(pdata) 검색결과 */}
            <PdataList pdataSearch={search.pdataSearch} viewType={search.viewType} />

            {/* 원본상품(pdata) 로딩표현 */}
            {!search.pdataSearch.page.done && (
              <Box display="flex" justifyContent="center" py={2}>
                <CircularProgress size={24} color="brand" />
              </Box>
            )}
          </Box>
        )}
      </SearchResultContainer>

      {/* 키워드 알림 alert */}
      {showKeywordAlert && (
        <KeywordAlarmAlert
          text={search.searchQuery}
          show={showKeywordAlert}
          onClose={handleShowKeywordAlert}
          alertProps={{ sx: { bottom: 56, height: showKeywordAlert ? 'auto' : 0 } }}
        />
      )}

      {/* 검색영역 다이얼로그[start] */}
      <SearchDialog
        fullScreen
        keepMounted
        open={search.searchOpen}
        onClose={() => {
          pageBackFunc();
        }}
        onSearch={searchNearbyProduct}
        queryHistory={queryHistory}
        value={search.searchQuery}
        currentCategory={search.filter.pdata_category}
      />
      {/* 검색영역 다이얼로그[end] */}

      {/* 상품 정렬옵션 다이얼로그 */}
      <OrderDialog
        id="wine-order-menu"
        keepMounted
        open={search.orderDialogOpen}
        onClose={handleOrderDialogChange}
        value={search.sort.value}
      />

      {/* Product filter dialog */}
      <FilterDialog
        open={search.filterOpen}
        filterState={search.filter}
        filterItemSequence={filterItemSequence}
        onClose={() => {
          setSearch({ ...search, filterOpen: false });
        }}
        onConfirm={applySheetFilter}
      />

      {/* 핀선택 지도[start] */}
      <Dialog
        open={mapOpen}
        scroll={'paper'}
        fullScreen
        TransitionComponent={PinMapTransition}
        keepMounted
        onClose={handleMapDialogClose}
        aria-describedby="pin-select-map-dialog-title"
      >
        <Fab
          color="white"
          onClick={handleMapDialogClose}
          aria-label="위치설정 닫기"
          sx={{ position: 'absolute', top: '20px', left: '20px', boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.16)' }}
        >
          <Box component="img" src={ArrowBackImg} sx={{ height: '34px' }} alt="" />
        </Fab>
        {mapOpen && <PinMap height={`calc(var(--vh, 1vh) * 100`} onPinConfirmed={onPinConfirmed} />}
      </Dialog>
    </MallLayout>
  ) : null;
}

export default MallIndex;

// 직접위치설정 dialog transition.
/* eslint-disable react/prop-types */
const PinMapTransition = React.forwardRef(function Transition(props, ref) {
  const { children, ...other } = props;
  return (
    <Slide {...other} ref={ref} direction="left">
      {children}
    </Slide>
  );
});

function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }
  return true;
}

function isObject(object) {
  return object != null && typeof object === 'object';
}

const MallStickyToolBar = React.memo(
  ({ hasResult, hasPdataResult, hasSearchQuery, pdataCategory, onTypeTabChange, wineTabList, tabIndex, openWineFilter, showTooltip }) => {
    const classes = useStyles();
    const appBarContainerRef = React.useRef(null);

    return (
      <AppBar
        id="order-sticky-header"
        position={hasResult || hasPdataResult ? 'sticky' : 'relative'}
        role="searchbox"
        aria-roledescription="와인 검색박스"
        sx={{
          top: hasSearchQuery ? `${66 - 12}px` : 0,
          minHeight: pdataCategory !== PDATA_CATEGORY.WINE.value ? '0px' : '54px'
        }}
      >
        <Collapse in={pdataCategory === PDATA_CATEGORY.WINE.value}>
          <Container maxWidth="md" sx={{ py: 0 }}>
            <Box ref={appBarContainerRef} display="flex" alignItems="center" width="100%">
              <Box width="calc(100% - 32px - 8px)">
                <Tabs
                  className={classes.typeTabs}
                  value={tabIndex}
                  onChange={onTypeTabChange}
                  variant="scrollable"
                  indicatorColor="primary"
                  textColor="inherit"
                  aria-label="상품구분 탭"
                >
                  <Tab label="전체" id="type-tab-all" value="all" />
                  {wineTabList}
                </Tabs>
              </Box>

              {/* 필터 버튼 */}
              <FilterBtnTooltip
                className="mall-filter-tooltip"
                title={
                  <Typography variant="body2" fontSize="14px" fontWeight="normal" color="inherit">
                    상세 필터로 취향 와인을 찾아 보세요!
                  </Typography>
                }
                arrow
                open={showTooltip}
                PopperProps={{
                  container: appBarContainerRef.current
                }}
              >
                <Box ml="8px">
                  <IconButton className={classes.btnFilter} aria-label="상품필터" onClick={openWineFilter}>
                    <FilterIcon />
                  </IconButton>
                </Box>
              </FilterBtnTooltip>
            </Box>
          </Container>
        </Collapse>
      </AppBar>
    );
  }
);

const useStyles = makeStyles((theme) => ({
  // 와인타입 네비게이션
  typeTabs: {
    height: '54px',
    '& .MuiTab-root': {
      height: '54px',
      minWidth: '42px',
      fontSize: '14px',
      lineHeight: 'normal',
      color: theme.palette.text.primary,
      opacity: 1,
      transition: `all ${theme.transitions.duration.short} ${theme.transitions.easing.easeInOut}`,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    '& .MuiTabs-indicator': {
      // transition: 'none',
      backgroundColor: theme.palette.brand.main
    },
    '& .MuiTab-root.Mui-selected': {
      fontWeight: 800
    },
    '& .MuiTab-root.Mui-disabled': {
      opacity: 0.35
    }
  },
  // 와인 필터버튼
  btnFilter: {
    width: '32px',
    height: '32px',
    backgroundColor: '#1402290D',
    borderRadius: '13px'
  },
  // 상품정보 제목 컨테이너
  containerPdataTitle: {
    position: 'sticky',
    height: '57px',
    top: '54px',
    padding: '0 !important',
    marginTop: '-1px',
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`
  }
}));
