import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useLocationState } from 'react-router-use-location-state';
import { httpsCallable } from 'firebase/functions';
import PropTypes from 'prop-types';
import moment from 'moment/moment';

// project imports
import { CLO_CODE, COUPON_TYPE, ORDER } from 'config';
import { useAuth, useScriptRef } from 'hooks';
import { WoAlert } from 'utils/kmwine-alerts';
import { getFirebaseFunctions } from 'utils/firebase-tools';
import { Calculator } from 'utils/bourgogne-tools';
import { OrderLayout } from '../components';
import { ProductImage } from 'components/v2';
import { CouponDialog, OrderSection, RefundInfo, TitleBox } from '../OrderConfirm/components';
import { Checkbox, ErrorIcon, ProductLabel, ProductName, ProductPrice, SmallButton, SoldOutIcon, SpaceBetweenBox } from 'components';
import { fetchMyCouponList } from 'services/UserService';
import * as OrderService from 'services/OrderService';
import { getLatestTerm, TERM_TYPE } from 'services/AppService';
import { getPickupAvailableDates } from 'services/VendorService';
import WishButton from '../../../../components/buttons/WishButton';
import { getTodayOneBottleCheck, getTodayOneBottleDetail } from 'services/ProductService';
import { QuantityBox, VendorInfoCard } from './components';
import { TOB } from '../../../home/HomeIndex/components/TodayOneBottle';
import { EndOrderModal, EndSaleModal, SoldOutModal } from '../../../home/HomeIndex/components/TodayOneBottle/components';

// material-ui
import {
  AppBar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Skeleton,
  Slide,
  Stack,
  TextField as MuiTextField,
  Toolbar,
  Typography
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CheckIcon from '@mui/icons-material/Check';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';

// assets
import waveBorderImg from '../OrderConfirm/components/wave_border.png';
import { PdataInfo } from '../../../../components/pdata';

// 상품 이미지 가로 크기
const IMG_WIDTH = 107;
// 상품 이미지 세로 크기
const IMG_HEIGHT = 148;
const spacing = '12px';

// 추가 요청 사항 최대 글자수
const COMMENT_MAX_LEN = 40;

// 약관/정책 초기값
const initialTermState = Object.freeze({
  loaded: false,
  error: false,
  type: null,
  term: null
});

/**
 * 오늘의 한 병 주문확인 화면
 * @returns {JSX.Element}
 * @constructor
 *
 * @authors 조현권<hkcho@wineone.io>, 이재일<leeji@wineone.io>
 * @jira PROD-271
 */
function OrderTodayBottleConfirm() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const scriptedRef = useScriptRef();

  // AppBar ref
  let appBarRef = React.useRef(null);
  // AppBar 높이
  const [appBarHeight, setAppBarHeight] = React.useState(0);

  // AppBar 높이 구하기
  React.useEffect(() => {
    // AppBar 높이 구하기
    if (appBarRef.current) {
      setAppBarHeight(appBarRef.current.offsetHeight);
    }
  }, [appBarRef]);

  // 로그인 사용자 정보
  const { user } = useAuth();

  // URL query-strings
  const [searchParams] = useSearchParams();

  const pid = searchParams.get('pid'); // product id
  const tid = searchParams.get('tid'); // today bottle id

  // 어디서 왔는가??
  const from = React.useMemo(() => searchParams.get('from') || 'normal', [searchParams]);

  // 오늘의 한 병 정보
  const [todayOneBottle, setTodayOneBottle] = React.useState({ loaded: false, data: null });
  // 구매할 수 있는 오늘의 한 병 수량
  const [tobOrderCount, setTobOrderCount] = React.useState({ now: 1, max: 0 });

  // 입점샵 아이디
  const vendorId = React.useMemo(() => {
    if (todayOneBottle.loaded) return todayOneBottle.data.vendor_id;
    return '';
  }, [todayOneBottle.loaded]);

  // 판매 시간 종료 모달
  const [endSaleModal, setEndSaleModal] = React.useState(false);
  // 주문 완료 안내 모달
  const [orderCompleteModal, setOrderCompleteModal] = React.useState(false);
  // 품절 모달
  const [soldOutModal, setSoldOutModal] = React.useState(false);
  // 주문 수량 보다 많은 수량을 주문하려고 할 때 모달
  const [quantityOverModal, setQuantityOverModal] = React.useState({ open: false, quantity: 0 });

  // 추가 요청 사항
  const [comment, setComment] = React.useState('');

  // 최종 구매 정보 (상품 id, 가격, 수량)
  const [orderInfo, setOrderInfo] = React.useState({ product_id: '', price: 0, quantity: 1 });

  React.useEffect(() => {
    if (todayOneBottle.loaded) {
      fetchMyCoupons();
    }
  }, [todayOneBottle.loaded, orderInfo.quantity]);

  React.useLayoutEffect(() => {
    // 상품ID 또는 오늘의 한 병 ID가 올바르지 않음
    if (!pid || !tid) {
      console.warn(`올바르지 않은 접근 ['상품ID' 또는 '오늘의 한 병ID'가 올바르지 않음]`);
      if (scriptedRef.current) {
        WoAlert.fire(`올바르지 않은 접근입니다.`, `[ '상품ID' 또는 '오늘의 한 병ID'가 올바르지 않음 ]`, 'error').then(() => {
          navigate('/', { replace: true });
        });
      }
    } else {
      fetchTodayOneBottleCheck(tid);
      fetchTodayOneBottleDetail(tid);
    }
  }, [searchParams]);

  // 오늘의 한 병 상태 조회
  const fetchTodayOneBottleCheck: Promise<any> = React.useCallback(async (tobId) => {
    const body = {
      bottleId: tobId,
      requestFvr: false
    };

    const result = await getTodayOneBottleCheck(body).catch((error) => ({ error }));

    if (!scriptedRef.current) {
      console.warn('[TodayOneBottleDetail][getTodayOneBottleCheck] Unmounted component.');
      return undefined;
    }

    // 오늘의 한 병 체크 조회 실패
    if (result.error) {
      console.error('[TodayOneBottleDetail][getTodayOneBottleCheck] ', result.error);
      WoAlert.fire('', `오늘의 한 병 상품 조회 중 오류가 발생했습니다.<br/>잠시 후 다시 시도해주십시오.`, 'error').then(() => {
        navigate(-1);
      });
      return undefined;
    }

    let {
      todayBottle: { sale_cnt }
    } = result;

    // 인당 최대 구매 병수
    sale_cnt = typeof sale_cnt === 'string' ? Number(sale_cnt) : sale_cnt;

    setTobOrderCount({ now: 1, max: sale_cnt });
  }, []);

  // 오늘의 한 병 상세 조회
  const fetchTodayOneBottleDetail: Promise<any> = React.useCallback(async (tobId) => {
    const result = await getTodayOneBottleDetail(tobId).catch((error) => ({ error }));

    if (!scriptedRef.current) {
      console.warn('[TodayOneBottleDetail][fetchTodayOneBottleDetail] Unmounted component.');
      return undefined;
    }

    // 오늘의 한 병 조회 실패
    if (result.error) {
      console.error('[TodayOneBottleDetail][fetchTodayOneBottleDetail] ', result.error);
      WoAlert.fire('', `오늘의 한 병 상품 조회 중 오류가 발생했습니다.<br/>잠시 후 다시 시도해주십시오.`, 'error').then(() => {
        navigate(-1);
      });
      return undefined;
    }

    const { todayBottle, success } = result;

    // 잘못된 Response 값이 반환됨
    if (!success) {
      console.error(`[TodayOneBottleDetail][fetchTodayOneBottleDetail] 잘못된 Response 값이 반환됨 [success: ${success}]`);
      WoAlert.fire('', `오늘의 한 병 상품 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주십시오.`, 'error').then(() => {
        navigate(-1);
      });
      return undefined;
    }

    // show가 false 인 경우
    if (!todayBottle.show || todayBottle.show === 'false') {
      WoAlert.fire('오늘의 한 병', '상품 전시 기간이 종료되었습니다', 'warning').then(() => {
        navigate(-1);
      });
      return false;
    }

    // 픽업 가능 일시 조회
    fetchPickupAvailableDates(todayBottle.vendor_id);

    setTodayOneBottle({ loaded: true, data: todayBottle });
  }, []);

  // 내가 보유한 쿠폰 다이어로그 열림
  const [openCouponDialog, setOpenCouponDialog] = React.useState(false);

  // 사용자 보유쿠폰 정보
  const [myCoupons, setMyCoupons] = useLocationState('myCoupons', {
    loaded: false,
    error: false,
    coupons: []
  });

  // 적용가능한 보유쿠폰 정보
  const [availableCoupons, setAvailableCoupons] = React.useState([]);

  // 쿠폰 상태정보(선택 등)
  const [selectedCoupons, setSelectedCoupons] = useLocationState('selectedCoupons', []);

  // 최대할인금액
  const [couponMaxAmount, setCouponMaxAmount] = React.useState(null);

  // 쿠폰 할인금액
  const discountByCoupons = React.useMemo(() => {
    return selectedCoupons.reduce((dcTotal, selected) => {
      return dcTotal + (selected.coupon.amount >> 0);
    }, 0);
  }, [selectedCoupons]);

  // 총 상품 금액
  const goodsTotalAmount = React.useMemo(() => {
    const { price, quantity } = orderInfo;

    return price * quantity;
  }, [orderInfo]);

  // 결제 금액 (총 상품 금액 - 쿠폰 할인 )
  const amount = goodsTotalAmount - discountByCoupons;

  // 사용자 보유쿠폰 목록 조회
  const fetchMyCoupons: Promise<void> = async () => {
    setMyCoupons({ loaded: false, error: false, coupons: [] });

    if (!todayOneBottle.loaded) return;
    const { product_id, vendor_id } = todayOneBottle.data;
    const { quantity } = orderInfo;

    // 파라미터 정보가 모두 있을 경우 조회
    if (product_id && vendor_id && quantity && typeof quantity === 'number') {
      console.log('[1KMWINE] 사용자 보유쿠폰 조회 시작...');

      // 쿠폰 목록 조회 request Body
      const _products = [{ product: { _id: product_id, pdata: { _id: todayOneBottle.data?.pdata___id } }, quantity: orderInfo.quantity }];

      // todo OrderService.getAvailableCouponList로 교체할 것
      const response = await fetchMyCouponList(vendor_id, _products).catch((error) => {
        console.error('[1KMWINE] 보유쿠폰 목록 조회 중 오류.', error);
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `[1KMWINE] 보유쿠폰 목록 조회 중 오류`,
          msg: `[uid=${user._id}] ${error}`,
          which: `${location.pathname}${location.search}`,
          param: { uid: user._id, product_id: searchParams.get('pid') }
        })
          .then(console.log)
          .catch(console.error);
        return { error };
      });

      if (!scriptedRef.current) {
        console.warn(`[OrderTodayBottleConfirm][fetchMyCoupons] Unmounted component.`);
        return undefined;
      }

      // 보유 쿠폰목록 불러오기 오류
      if (response.error) {
        setMyCoupons((myCoupons) => ({ ...myCoupons, loaded: true, error: true }));
        await WoAlert.fire('', '보유쿠폰 목록 조회 중 오류가 발생했습니다.<br/>잠시 후 다시 시도해주십시오.', 'error').then(() => {
          navigate(-1);
        });
        return undefined;
      }

      // [오늘의한병 주문확인] 쿠폰정보 조회 성공
      const { result, data } = response.data;

      if (result.code === 0) {
        // 쿠폰 자동적용
        const autoApplyCoupon = [];

        // 쿠폰 최대할인금액
        let maxAmount = 0;

        data.map((el) => {
          if (response.data.autoApplyCouponId.includes(el.coupon.id)) {
            autoApplyCoupon.push(el);
            maxAmount += el.coupon.amount;
          }
          return undefined;
        });

        setSelectedCoupons(autoApplyCoupon);
        setCouponMaxAmount(maxAmount);

        setMyCoupons({
          loaded: true,
          error: false,
          coupons: data
        });
      } else {
        console.error('[1KMWINE] 보유쿠폰 목록 조회 중 오류. code=', result.code, result.msg);
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `[1KMWINE] 보유쿠폰 목록 조회 중 오류`,
          msg: `[uid=${user._id}] ${JSON.stringify(result.error)}`,
          which: `${location.pathname}${location.search}`,
          param: {
            uid: user._id
          }
        })
          .then(console.log)
          .catch(console.error);

        setMyCoupons((myCoupons) => ({ ...myCoupons, loaded: true, error: true, coupons: [] }));
        await WoAlert.fire(`보유쿠폰 목록 조회 중 오류가 발생했어요.`, '', 'warning');
      }
    } else {
      // console.warn('입점샵 아이디(vendor.id)를 아직 확인할 수 없습니다.', vendor);
    }
  };

  // 쿠폰 유효성 검사
  React.useEffect(() => {
    if (!todayOneBottle.loaded) {
      console.warn('[OrderTodayBottleConfirm] 오늘의 한 병이 아직 조회되지 않았습니다');
      return;
    }

    if (!myCoupons.loaded) {
      console.warn('[OrderTodayBottleConfirm] 보유 쿠폰목록이 아직 조회되지 않았습니다');
      return;
    }

    if (goodsTotalAmount <= 0) {
      setAvailableCoupons([]);
      return;
    }

    // 오늘의 한 병 판매 입점샵 id
    const { vendor_id } = todayOneBottle.data;

    if (!myCoupons.error && goodsTotalAmount > 0) {
      const availables = [];

      myCoupons.coupons.forEach((c) => {
        const { coupon } = c;
        // 1) 해당 매장에서 사용할 수 있는지? ( VENDOR, ADVEN )
        if (coupon.type !== COUPON_TYPE.ADMIN) {
          // 다른 입점샵 쿠폰일 경우 pass
          if (vendor_id !== coupon.vendor.id) {
            console.debug(
              `해당 매장에서는 사용이 불가능한 쿠폰. [id=${c.id}, couponVendor=${coupon.vendor.id}, currentVendor=${vendor_id}]`
            );
            return false;
          }
        }

        // 2) 쿠폰 사용가능한 최소주문금액에 도달하지 않았을 경우
        if (coupon.min_order_amount > goodsTotalAmount) {
          console.debug(`사용가능 주문금액이 충족하지 않는 쿠폰. [id=${c.id}, min_order_amount=${coupon.min_order_amount}]`);
          return false;
        }

        // 3) 사용가능 기간확인
        const { start_dt: startDt, end_dt: endDt } = coupon.use_period;
        const startMmt = moment(startDt._seconds * 1000); // 쿠폰 사용가능 시작일시
        const endMmt = moment(endDt._seconds * 1000); // 쿠폰 사용가능 종료일시
        const now = moment(); // 현재

        // 3-1) 쿠폰 사용가능 일자가 안됐을 경우
        if (startMmt.isAfter(now)) return false;
        // 3-2) 쿠폰 사용가능 일자를 지났을 경우
        if (endMmt.isBefore(now)) return false;

        availables.push(c);
      });

      setAvailableCoupons(availables);
    }
  }, [myCoupons.loaded, goodsTotalAmount, todayOneBottle.loaded]);

  // '쿠폰 선택' 라벨
  const couponSectionLabel = React.useMemo(() => {
    if (!myCoupons.loaded) {
      return <span style={{ fontSize: '11px' }}>보유 쿠폰을 확인 중 입니다...</span>;
    }

    if (selectedCoupons.length > 0) {
      return `쿠폰 적용 (${selectedCoupons.length}개)`;
    }
    return availableCoupons.length > 0 ? (
      `사용 가능 쿠폰 ${availableCoupons.length}개`
    ) : (
      <span style={{ fontSize: '11px', color: theme.palette.text.disabled }}>사용 가능한 쿠폰이 없습니다.</span>
    );
  }, [myCoupons.loaded, myCoupons.error, selectedCoupons, availableCoupons]);

  // 주문확인 및 이용약관 동의여부
  const [agree, setAgree] = React.useState(false);

  // 주문정보
  const [order, setOrder] = React.useState({
    request: false, // 요청 여부
    data: null // 주문 데이터
  });

  // '결제하기' 버튼 클릭
  const startPurchase = async () => {
    const { price, quantity } = orderInfo;
    const productAmount = price * quantity;

    // 쿠폰 금액이 상품 금액보다 많은 경우
    if (discountByCoupons > productAmount) {
      if (selectedCoupons.length > 0) {
        WoAlert.fire(`잘못된 쿠폰입니다.  \n쿠폰을 다시 선택해주세요.`, '', 'error').then(() => {
          setSelectedCoupons([]);
        });
        return undefined;
      }
    }

    setOrder({ ...order, request: true });

    // -------------------------------------------------------------------- 오늘의 한 병 체크 (수량, 판매시간, 재고 등) [START]
    const body = {
      bottleId: todayOneBottle.data._id,
      requestFvr: false
    };

    console.debug('[오늘의 한 병] 오늘의 한 병 상품 상태 체크');
    const tobCheckResult = await getTodayOneBottleCheck(body).catch((error) => ({ error }));

    if (!scriptedRef.current) {
      console.warn('[OrderTodayBottleConfirm][startPurchase] Unmounted component.');
      return undefined;
    }

    // 오늘의 한 병 체크 조회 실패
    if (tobCheckResult.error) {
      console.error('[OrderTodayBottleConfirm][startPurchase] ', tobCheckResult.error);
      WoAlert.fire(`주문정보 생성 중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.`, '', 'error').then(() => {
        console.warn(`[wineone] 주문정보 생성 오류 [code: ${tobCheckResult.code}, msg: ${tobCheckResult.msg}]`);
      });
      return undefined;
    }

    const { code, todayBottle } = tobCheckResult;
    const { display_state, remove: _remove, show: _show, quantity: todayBottleQuantity } = todayBottle;

    // 삭제 여부
    const remove = _remove === true || _remove === 'true';
    // 숨김 여부
    const show = _show === true || _show === 'true';

    // 삭제 또는 숨김 처리 되었을 시
    if (remove || !show) {
      setOrder({ ...order, request: false });
      WoAlert.fire('오늘의 한 병', '상품 전시 기간이 종료되었습니다.', 'warning').then(() => {
        navigate('/', { replace: true });
      });
      return undefined;
    }

    // 판매 시간이 종료된 경우
    if (display_state === TOB.SALE.END) {
      setOrder({ ...order, request: false });
      setEndSaleModal(true);
      return undefined;
    }

    // 오늘의 한 병을 이미 주문한 경우
    if (code === TOB.CODE.SALE_OVER) {
      setOrder({ ...order, request: false });
      setOrderCompleteModal(true);
      return undefined;
    }

    // 주문 상품id, 수량
    const { product_id: orderProductId, quantity: orderProductQuantity } = orderInfo;

    // 오늘의 한 병이 품절됨
    if (todayBottleQuantity === 0) {
      setOrder({ ...order, request: false });
      setSoldOutModal(true);
      return undefined;
    }

    // 주문 수량보다 남은 수량이 많은 경우
    if (orderProductQuantity > todayBottleQuantity) {
      setOrder({ ...order, request: false });
      setQuantityOverModal({ open: true, quantity: todayBottleQuantity });
      console.warn(
        `[OrderTodayBottleConfirm][startPurchase] 주문 수량보다 남은 수량이 많음 [OrderProductQuantity: ${orderProductQuantity}, TodayBottleQuantity: ${todayBottleQuantity}]`
      );
      return undefined;
    }

    // -------------------------------------------------------------------- 오늘의 한 병 체크 (수량, 판매시간, 재고 등) [END]

    // -------------------------------------------------------------------- 주문생성 [START]
    // 선택된 쿠폰아이디 array
    const coupon = selectedCoupons.map(({ id }) => id);

    // 주문(order)생성 요청 파라미터
    const orderRequestParam = {
      vendor_id: todayOneBottle.data.vendor_id, // 판매사(입점샵) ID
      products: [], // 주문상품
      coupon,
      pickup_date: `${moment(pickupYmd, 'YYYYMMDD').format('YYYY-MM-DD')}`,
      pickup_time: `${moment(pickupTime, 'HHmm').format('HH:mm')}`,
      pay: {
        type: 'CARD', // 결제방식 'CARD'
        amount // 결제 총액
      },
      state: ORDER.STATE.ING, // 최초상태 : 결제 중
      paid: false,
      from // 이전 페이지 type. 'cart', 'etc', 'today-bottle-order'
    };

    // 주문상품 정보
    orderRequestParam.products = [{ id: orderProductId, quantity: orderProductQuantity, packing: null, comment }];
    console.debug('결제 요청정보: ', { ...orderRequestParam });

    // 오늘의 한 병 주문정보 생성요청
    const res = await OrderService.createOrder(orderRequestParam).catch((error) => ({ error }));

    // 오늘의 한 병 주문정보 생성 오류
    if (res.error) {
      const { error } = res;
      console.error('[1KMWINE] 주문정보 생성실패.', error);
      setOrder({ ...order, request: false });
      errorCallback();
      return undefined;
    }

    setOrder({ ...order, request: false });

    const { status, data } = res;
    if (status !== 200) return errorCallback();

    const { data: orderCreated, result } = data;
    console.debug('생성된 주문정보: ', orderCreated);
    if (result.code !== 0) {
      WoAlert.fire(`주문정보 생성 중 오류가 발생했습니다.\n[${result.msg}]\n잠시 후 다시 시도해주세요.`, '', 'error').then(() => {
        console.warn(`[wineone] 주문정보 생성 오류 [code: ${result.code}, msg: ${result.msg}]`);

        // 주문금액 오류일 경우 뒤로가기
        if (result.code === 1) navigate(-1);
      });
      return false;
    }

    navigate(`/payment/inicis/request`, { state: { order: { ...orderCreated } } }); // 이니시스 카드결제요청

    async function errorCallback() {
      await WoAlert.fire('주문정보 생성 중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.', '', 'error').then(() => {
        console.warn('[1KMWINE] 주문정보 생성 오류 [OrderConfirm.createOrder$error]');
      });
      return false;
    }
    // -------------------------------------------------------------------- 주문생성 [END]
  };

  // 약관/정책
  const [termState, setTermState] = React.useState(initialTermState);

  // 약관 가져오기 -> todo 코드 개선
  const fetchTerm = (type) => {
    if (termState.loaded && termState.type === type) {
      console.debug(`[1KMWINE] 이미 로드된 문서입니다. [type=${type}]`);
      return;
    }

    setTermState({ ...termState, type });

    if (!termState.loaded || termState.type !== type) {
      getLatestTerm(type.toUpperCase())
        .then((snapshot) => {
          if (scriptedRef.current) {
            if (!snapshot.empty) {
              setTermState({ ...termState, type, loaded: true, error: false, term: snapshot.docs[0].data() });
            } else {
              console.error(`[1KMWINE] 문서가 존재하지 않습니다. [type=${type}]`);
              setTermState({ ...termState, type, loaded: true, error: false, term: null });
            }
          }
        })
        .catch((e) => {
          console.error(`[1KMWINE] 문서 조회 중 오류가 발생했습니다. [type=${type}]`, e);
          if (scriptedRef.current) {
            setTermState({ ...termState, type, loaded: true, error: true, term: null });
          }
        });
    } else {
      console.debug(`[1KMWINE] 이미 로드된 문서입니다. [type=${type}]`);
    }
  };

  // 약관보기
  const [showTerms, setShowTerms] = useLocationState('termModalOpen', false);

  const handleShowTerms = (show = false, agreeTerms) => {
    if (typeof agreeTerms === 'boolean') setAgree(agreeTerms);
    if (show) {
      fetchTerm(TERM_TYPE.SERVICE);
      setShowTerms(show, { method: 'true' });
    } else {
      navigate(-1);
    }
  };

  // 주문 금액 계산
  const calculateOrderPrice = React.useMemo(() => {
    if (!todayOneBottle.loaded) return;

    // 상품 정보
    const {
      data: { price__original: priceOriginal, product_id }
    } = todayOneBottle;
    if (!priceOriginal) return <Skeleton width="120px" />;

    // 선택한 상품 수량
    const { now } = tobOrderCount;
    if (!now || typeof now !== 'number') return <Skeleton width="120px" />;

    const price = priceOriginal;

    setOrderInfo({ product_id, price, quantity: now });

    return price.toLocaleString();
  }, [todayOneBottle.loaded, tobOrderCount]);

  // 상세보기할 pdata Id
  const [detailPdataId, setDetailPdataId] = useLocationState('tob_confirm_pdataId', null);
  // pdata 상세보기
  const [showPdataInfo, setShowPdataInfo] = useLocationState('tob_confirm_showPdataInfo', false);

  // pdata 상세정보 보기
  const moveToPdataInfo = async (pdataId) => {
    if (showPdataInfo) return false; // 이미 열려있을 때

    if (!pdataId) {
      console.error('[장바구니] 와인 상세 정보 조회 중 오류 발생. 와인 상세 정보가 존재하지 않음.');
      return undefined;
    }
    setDetailPdataId(pdataId);
    setShowPdataInfo(true, { method: 'push' });
  };

  // 입점샵 픽업가능날짜
  const [pickupAvailableDates, setPickupAvailableDates] = useLocationState('pickAvail', { loaded: false, error: null, data: {} });
  // 픽업시간 옵션 array
  const [pickupTimeArray, setPickupTimeArray] = React.useState([]);
  // 픽업날짜
  const [pickupYmd, setPickupYmd] = useLocationState('pickupYmd', null);
  // 픽업시간
  const [pickupTime, setPickupTime] = useLocationState('pickupTime', null);

  // 샵 픽업일시 조회
  async function fetchPickupAvailableDates(vendorId): Promise<void> {
    setPickupAvailableDates({ loaded: false, error: null, data: {} });
    const result = await getPickupAvailableDates(vendorId);

    if (!scriptedRef.current) {
      console.warn('[fetchPickupAvailableDates] Unmounted component.');
      return undefined;
    }

    // 픽업 가능일시 조회 성공
    if (result.code === 0) {
      setPickupAvailableDates({ loaded: true, error: null, data: { ...result.data } });
    } else {
      console.error('[fetchPickupAvailableDates] Fetch pickup available dates failed.', result);
      setPickupAvailableDates({ loaded: true, error: true, data: {} });
    }
  }

  // 픽업날짜 변경감시
  React.useEffect(() => {
    if (todayOneBottle.loaded && pickupAvailableDates.loaded && pickupYmd != null) {
      const { data } = pickupAvailableDates;
      setPickupTimeArray(data?.[pickupYmd] ?? []);
    } else {
      setPickupTimeArray([]);
    }
  }, [pickupAvailableDates.loaded, pickupYmd]);

  // 선택 가능한 수량
  const availableQuantity = React.useMemo(() => {
    if (!todayOneBottle.loaded) return <CircularProgress size={16} color="brand" />;

    const { data } = todayOneBottle;

    // 남은 수량
    const { quantity } = data;
    // 인당 구매 가능 수량
    const { max } = tobOrderCount;

    // 구매 가능 수량
    const available = max > 1 ? Math.min(quantity, max) : quantity;

    if (max > 1 && quantity > 1) {
      return (
        <QuantityBox value={tobOrderCount.now} max={available} onChange={(newVal) => setTobOrderCount({ ...tobOrderCount, now: newVal })} />
      );
    }

    return (
      <Typography component="span" color="text.secondary" fontSize="16px">
        수량 · 1
      </Typography>
    );
  }, [todayOneBottle]);

  // render
  return (
    <OrderLayout
      title="주문하기"
      appBarRef={(ref) => {
        appBarRef = ref;
      }}
      showBackDrop={false}
      backDrop={
        <Box>
          <Typography variant="body2" color="#ffffff">
            상품정보를 확인 중 입니다
          </Typography>
        </Box>
      }
      onBackButtonClick={() => {
        navigate('/', { replace: true });
      }}
    >
      <Helmet title={`${process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE} | 오늘의 한 병 주문하기`} />

      <Backdrop open={order.request} sx={{ zIndex: theme.zIndex.appBar + 5 }}>
        <Box p={3} sx={{ backdropFilter: 'blur(33px)', background: 'rgba(0,0,0,0.4)', borderRadius: '8px', overflow: 'hidden' }}>
          <Typography variant="body2" color="#ffffff">
            주문정보를 생성하고 있습니다.
          </Typography>
        </Box>
      </Backdrop>

      <Container maxWidth="xs" pt={0} sx={{ minHeight: `calc(var(--vh, 1vh) * 100 - ${appBarHeight})` }}>
        {/* 입점샵 정보 [START] */}
        <VendorInfoCard todayOneBottle={todayOneBottle} />
        {/* 입점샵 정보 [END] */}

        <Divider sx={{ borderColor: '#2D154D' }} />

        {/* 주문상품정보 영역 [START] */}
        <OrderSection mt="14px">
          <TitleBox>
            <SpaceBetweenBox>
              <Typography variant="subtitle2" fontSize="14px" fontWeight="inherit">
                오늘의 한 병
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                onClick={() => {
                  moveToPdataInfo(todayOneBottle.data?.pdata___id);
                }}
              >
                <Typography sx={{ fontSize: '12px', letterSpacing: '-0.12px', color: '#140229' }}>상세보기</Typography>
                <KeyboardArrowRightIcon sx={{ ml: '4px', width: 18, height: 18 }} />
              </Box>
            </SpaceBetweenBox>
          </TitleBox>

          <Box aria-label="오늘의 한 병">
            <Box py={2}>
              <Box display="flex">
                {/* 구매상품 이미지 */}
                <ProductImage
                  src={todayOneBottle.loaded ? todayOneBottle.data?.bottle_img?.thumb : ''}
                  width={IMG_WIDTH}
                  height={IMG_HEIGHT}
                  alt="todayOneBottle_Product_Image"
                >
                  {todayOneBottle.loaded && (
                    <ProductLabel height={26} fontSize={9}>
                      인당 최대 {tobOrderCount.max.toLocaleString()}병 주문 가능
                    </ProductLabel>
                  )}
                </ProductImage>

                {/* 구매상품 정보 */}
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  width={`calc( 100% - ${IMG_WIDTH}px - ${spacing} )`}
                  ml="10px"
                >
                  <Box>
                    <SpaceBetweenBox sx={{ height: 20 }}>
                      <Typography variant="caption" fontSize={11} fontWeight={800} letterSpacing="-0.11px">
                        {todayOneBottle.data?.producer?.ko?.trim() ? (
                          todayOneBottle.data?.producer?.ko
                        ) : (
                          <span style={{ color: 'rgba(0,0,0,.3)' }}>1KMWINE</span>
                        )}
                      </Typography>
                      {todayOneBottle?.data?.pdata___id ? (
                        <WishButton
                          pdataId={todayOneBottle.data?.pdata___id}
                          pdataName={todayOneBottle.data?.product_name}
                          onWishChange={(isWish) => {
                            console.log('오늘의 한 병 상품 찜하기 여부: ', isWish);
                          }}
                        />
                      ) : null}
                    </SpaceBetweenBox>
                    {/* 상품명 */}
                    <ProductName sx={{ pt: 0.25 }}>{todayOneBottle.data?.product_name ?? <Skeleton width="80%" />}</ProductName>

                    {/* 빈티지 / 용량 */}
                    <Box>
                      <Stack
                        direction="row"
                        spacing="6px"
                        divider={
                          <Box display="flex" alignItems="center" pb="1px">
                            <Box height="9px" width="1px" bgcolor={alpha(theme.palette.text.primary, 0.1)} />
                          </Box>
                        }
                        sx={{ fontSize: 11, fontWeight: 300, color: 'text.secondary' }}
                      >
                        {todayOneBottle.data?.vintage && todayOneBottle.data.vintage !== 'NV' && (
                          <Typography variant="caption">{`${todayOneBottle.data.vintage}년`}</Typography>
                        )}
                        <Typography variant="caption">{Calculator.formatCapacity(todayOneBottle.data?.capacity)}</Typography>
                      </Stack>
                    </Box>
                  </Box>
                  <Box>
                    <Box display="flex" alignItems="center">
                      <ProductPrice>{calculateOrderPrice}</ProductPrice>
                      <span style={{ paddingLeft: '1px', fontWeight: 300, fontSize: '16px', letterSpacing: '-1px' }}>원</span>
                    </Box>

                    {/* 수량 설정 박스 */}
                    {availableQuantity}
                  </Box>
                </Box>
              </Box>

              {/* 구매상품 추가 요청사항 */}
              <Box mt="12px">
                <FormControl
                  fullWidth
                  size="small"
                  sx={{ '& .MuiInputBase-root': { height: 42 }, '& .MuiTypography-root': { lineHeight: '42px' } }}
                >
                  <MuiTextField
                    hiddenLabel
                    fullWidth
                    autoComplete="off"
                    value={comment}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                    onBlur={(e) => {
                      setComment(e.target.value.trim());
                    }}
                    // placeholder={`추가요청사항: (ex. 선물포장 해주세요, 칠링해주세요) [${COMMENT_MAX_LEN}자]`}
                    // 2023.11.23 모젤 크리스마스 주문 시 선물포장이 많이 들어오는 이슈로 잠시 숨김처리
                    placeholder={`추가요청사항: (ex. 칠링해주세요) [${COMMENT_MAX_LEN}자]`}
                    inputProps={{ maxLength: COMMENT_MAX_LEN }}
                    sx={{
                      '& .MuiInputBase-input::-webkit-input-placeholder': {
                        fontSize: '12px',
                        fontWeight: 'bold',
                        color: '#140229'
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderRadius: '5px',
                        backgroundColor: 'transparent',
                        borderWidth: '1px !important'
                      }
                    }}
                  />
                </FormControl>
              </Box>
            </Box>
          </Box>
        </OrderSection>
        {/* 주문상품정보 영역 [END] */}

        {/* 픽업 정보 [START] */}
        <OrderSection>
          <TitleBox>
            <SpaceBetweenBox>
              <Typography variant="subtitle2" fontSize="14px" fontWeight="inherit">
                픽업 정보
              </Typography>
              <Box>
                <Typography component="span" fontSize={12}>
                  {pickupYmd ? moment(pickupYmd, 'YYYYMMDD').format('MM.DD (ddd)') : null}
                </Typography>
                <Typography component="span" fontSize={12}>
                  {pickupTime ? ` ${moment(pickupTime, 'HHmm').format('A hh:mm')}` : null}
                </Typography>
              </Box>
            </SpaceBetweenBox>
          </TitleBox>

          <Box
            sx={(theme) => ({
              height: 'auto',
              minHeight: 72,
              transition: theme.transitions.create('min-height')
            })}
          >
            {pickupAvailableDates.loaded ? (
              pickupAvailableDates.error === null ? (
                <Box py={1}>
                  <SpaceBetweenBox>
                    <Typography variant="subtitle2" fontSize="11px">
                      날짜 선택
                    </Typography>
                    {!pickupYmd && (
                      <Typography variant="caption" lineHeight={1} color="error.main">
                        *픽업날짜를 선택해주세요.
                      </Typography>
                    )}
                  </SpaceBetweenBox>

                  {/* 픽업날짜 선택버튼 표현[start] */}
                  <Box mt={1}>
                    <Grid container spacing={1}>
                      {Object.keys(pickupAvailableDates.data).map((date) => {
                        const selected = pickupYmd === date; // 픽업 날짜 선택 플래그

                        return (
                          <Grid item xs={3} key={`pickup-ymd-${date}`}>
                            <Button
                              fullWidth
                              size="small"
                              type="button"
                              color="brand"
                              variant={selected ? 'contained' : 'outlined'}
                              sx={{
                                p: '3px 0',
                                letterSpacing: '-0.5px',
                                fontWeight: selected && 'bold',
                                border: '1.5px solid #60546E1A',
                                '&:disabled': { backgroundColor: '#F1F0F2', color: '#140229' }
                              }}
                              value={date}
                              onClick={(e) => {
                                setPickupTime(null);
                                setPickupYmd(e.target.value);
                              }}
                            >
                              {moment(date, 'YYYYMMDD').format('M.D (ddd)')}
                            </Button>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                  {/* 픽업날짜 선택버튼 표현[end] */}

                  {/* 픽업시간 선택버튼 표현[start] */}
                  <Collapse in={pickupYmd != null && pickupTimeArray.length > 0}>
                    <Box pt={2} display="flex" justifyContent="space-between" alignItems="center">
                      <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle2" fontSize="11px">
                          시간 선택
                        </Typography>
                        {!pickupTime && (
                          <Typography variant="caption" lineHeight={1} color="error.main">
                            *픽업시간을 선택해주세요.
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    <Box mt={1}>
                      <Grid container spacing={1}>
                        {pickupTimeArray.map(([hour, available], i) => {
                          // 지나간 시간은 선택 못 하도록 ( buffer: 1분 )
                          const time = moment({ hour });
                          const timeFormat = time.format('HH:mm'); // 픽업 시간 포맷
                          const selected = pickupTime === timeFormat; // 픽업 시간 선택 플래그

                          return (
                            <Grid item xs={3} key={`pickup-time-${i}`}>
                              <Button
                                fullWidth
                                disabled={!available}
                                size="small"
                                type="button"
                                color="brand"
                                variant={selected ? 'contained' : 'outlined'}
                                sx={{
                                  p: '3px 0',
                                  letterSpacing: '-0.5px',
                                  fontWeight: selected && 'bold',
                                  border: '1.5px solid #60546E1A',
                                  '&:disabled': { backgroundColor: '#F1F0F2', color: '#140229' }
                                }}
                                value={timeFormat}
                                onClick={(e) => setPickupTime(e.target.value)}
                              >
                                {time.format(`A ${hour === 0 ? 'H' : 'h'}시`)}
                              </Button>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </Collapse>
                  {/* 픽업시간 선택버튼 표현[end] */}
                </Box>
              ) : (
                <Box component="section" height="100%" display="flex" justifyContent="center" alignItems="center">
                  <Box textAlign="center">
                    <Typography id="pickup-avail-dt-err" variant="caption" role="note">
                      픽업 가능일시 조회 중 오류가 발생했습니다.
                    </Typography>
                    <Box mt={1}>
                      <Button
                        variant="contained"
                        size="small"
                        color="brand"
                        startIcon={<ReplayOutlinedIcon />}
                        onClick={() => {
                          fetchPickupAvailableDates(todayOneBottle.data?.vendor_id);
                        }}
                      >
                        재시도
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )
            ) : (
              <PickupAvailDateLoading />
            )}
          </Box>
        </OrderSection>
        {/* 픽업 정보 [END] */}

        {/* 쿠폰 정보 [START] */}
        <OrderSection sx={{ mt: 2 }}>
          <TitleBox>
            <Typography variant="subtitle2" fontSize="14px" fontWeight="inherit">
              쿠폰
            </Typography>
          </TitleBox>

          {/* 현재 선택된 쿠폰 금액 */}
          {selectedCoupons.length > 0 && (
            <Box marginBottom="16px" marginTop="8px">
              {myCoupons.loaded ? (
                <Typography fontSize="14px" color="#9357E5">
                  {discountByCoupons.toLocaleString()}원 {couponMaxAmount === discountByCoupons ? '최대' : ''} 할인 적용 중
                </Typography>
              ) : (
                <Skeleton width="200px" sx={{ fontSize: '14px' }} />
              )}
            </Box>
          )}

          {/* 쿠폰 변경하기 버튼 */}
          <SpaceBetweenBox height="28px">
            <Typography>{couponSectionLabel}</Typography>
            {myCoupons.loaded && (
              <SmallButton
                onClick={() => {
                  setOpenCouponDialog(true);
                }}
                sx={{ borderColor: '#60546E26', color: '#140229' }}
              >
                {selectedCoupons.length === 0 ? `보유 쿠폰 ${myCoupons.coupons.length}개` : '쿠폰 변경하기'}
              </SmallButton>
            )}
          </SpaceBetweenBox>

          {/* 적용된 쿠폰의 이름과 금액 */}
          <Box pt="14px" color={(theme) => alpha(theme.palette.text.primary, 0.55)} width="100%">
            {selectedCoupons.length > 0 && myCoupons.loaded ? (
              selectedCoupons.map((selected, i) => {
                const { coupon } = selected;
                return (
                  <SpaceBetweenBox key={`selected-coupon-${i}`} height="28px" sx={{ pl: '1rem' }}>
                    <Typography noWrap sx={{ maxWidth: '50%' }}>
                      ㄴ&nbsp;{coupon.name}
                    </Typography>
                    <Box display="flex" justifyContent="flex-end" alignItems="center" marginRight="12px">
                      <Typography fontWeight="bold">{coupon.amount.toLocaleString()}</Typography>원
                    </Box>
                  </SpaceBetweenBox>
                );
              })
            ) : selectedCoupons.length === 0 ? null : (
              <SpaceBetweenBox sx={{ pl: '1rem' }}>
                <Skeleton width="120px" height="28px" />
                <Skeleton width="30px" height="28px" sx={{ marginRight: '12px' }} />
              </SpaceBetweenBox>
            )}
          </Box>
        </OrderSection>
        {/* 쿠폰 정보 [END] */}

        {/* 결제수단 [START] */}
        <OrderSection sx={{ mt: '32px' }}>
          <TitleBox>
            <Typography variant="subtitle2" fontSize="14px" fontWeight="inherit">
              결제수단
            </Typography>
          </TitleBox>
          <Box pt={1} pb={1.5}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="body2" fontSize="14px">
                신용/체크카드
              </Typography>
              {/* todo 다른 결제방법 기능작업 후 활성화
              <Button variant="outlined" size="small" sx={{ py: 0.25 }}>
                변경
              </Button>
               */}
            </Box>
          </Box>
        </OrderSection>
        {/* 결제수단 [END] */}

        {/* 결제 금액 정보 & 주문하기 [START] */}
        <Box sx={{ mx: '-20px', px: '20px', pt: 3.5, position: 'relative', bgcolor: '#f3f2f5' }}>
          <Box component="img" alt="" src={waveBorderImg} top="-3px" position="absolute" left={0} width="100%" height="4px" />
          {/* 결제 금액 [START] */}
          <OrderSection>
            <TitleBox>
              <Typography variant="subtitle2" fontSize="14px" fontWeight="inherit">
                결제 금액
              </Typography>
            </TitleBox>
            <Box pt="17px" pb="30px">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography>총 상품 금액</Typography>
                <Typography fontWeight={900}>{goodsTotalAmount.toLocaleString()}원</Typography>
              </Box>
              {/* 쿠폰 할인 */}
              <Collapse mountOnEnter unmountOnExit in={selectedCoupons.length > 0}>
                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 1.5 }}>
                  <Typography>쿠폰 사용</Typography>
                  {myCoupons.loaded ? (
                    <Typography color="#EA3D44" fontWeight={900}>
                      -{discountByCoupons.toLocaleString()}원
                    </Typography>
                  ) : (
                    <CircularProgress size={14} sx={{ color: '#EA3D44' }} />
                  )}
                </Box>
              </Collapse>
            </Box>
          </OrderSection>
          {/* 결제 금액 [END] */}

          {/* 총 결제 금액 [START] */}
          <Divider />
          <SpaceBetweenBox sx={{ py: 3 }}>
            <Typography variant="subtitle1" fontWeight={900} fontSize="14px">
              총 결제 금액
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1" fontSize="22px" color="#140229" fontWeight="bold" fontFamily="D-DIN-Bold,D-DIN">
                {myCoupons.loaded ? `${amount.toLocaleString()}원` : <CircularProgress size={22} sx={{ color: '#140229' }} />}
              </Typography>
            </Box>
          </SpaceBetweenBox>
          {/* 총 결제 금액 [END] */}

          {/* [PROD-143] 주문화면 내 (특가)세트상품 교환반품 불가 안내 문구 삽입 */}
          {/* 환불규정 */}
          <RefundInfo />

          {/* 주문내용 확인 및 약관동의 [START] */}
          <Box py={1} mt={2} display="flex" justifyContent="space-between" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={agree}
                  onChange={(e) => {
                    setAgree(e.target.checked);
                  }}
                  size="small"
                  inputProps={{ 'aria-labelledby': 'order-confirm-term' }}
                />
              }
              label={
                <Typography id="order-confirm-term" variant="caption" lineHeight={1} fontSize="14px">
                  주문내용 확인 및 약관동의
                </Typography>
              }
            />
            <Button variant="outlined" size="small" sx={{ py: 0.25 }} onClick={() => handleShowTerms(true)}>
              약관보기
            </Button>
          </Box>
          {/* 주문내용 확인 및 약관동의 [END] */}

          {/* 결제하기 버튼 [START] */}
          <Box pt="24px" pb="16px">
            <Button
              fullWidth
              type="button"
              disabled={!todayOneBottle.loaded || !myCoupons.loaded || pickupYmd == null || pickupTime == null || !agree}
              variant="contained"
              color="brand"
              size="x-large"
              onClick={startPurchase}
              sx={{ height: 46 }}
            >
              <Typography fontSize={17} fontWeight={800}>
                결제하기
              </Typography>
            </Button>
          </Box>
          {/* 결제하기 버튼 [END] */}
        </Box>
        {/* 결제 금액 정보 & 주문하기 [END] */}
      </Container>

      {/* 쿠폰선택 다이얼로그 */}
      {todayOneBottle.loaded && (
        <CouponDialog
          loaded={myCoupons.loaded}
          amount={todayOneBottle.data.price__original}
          open={openCouponDialog}
          onClose={() => setOpenCouponDialog(false)}
          onSelect={(coupons) => {
            console.debug('[주문하기] 선택된 쿠폰 정보. ', coupons);
            setSelectedCoupons(coupons);
            setOpenCouponDialog(false);
          }}
          coupons={myCoupons}
          selectedCoupons={selectedCoupons}
        />
      )}

      {/* 약관 표현영역 */}
      <Dialog fullScreen open={showTerms} onClose={() => handleShowTerms(false)} TransitionComponent={Transition}>
        <AppBar position="relative" elevation={0} sx={{ borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}` }}>
          <Toolbar color="text.primary">
            <IconButton edge="start" onClick={() => handleShowTerms(false)} aria-label="close">
              <CloseIcon color="text.primary" />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} color="text.primary" variant="h6" component="div">
              약관내용
            </Typography>

            <Button
              size="small"
              variant="outlined"
              disabled={agree}
              color={agree ? 'success' : 'primary'}
              onClick={() => {
                handleShowTerms(false, true);
              }}
            >
              {agree ? '동의완료' : '동의하기'}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ px: 0 }}>
          <Container maxWidth="xs" disableGutters>
            {termState.loaded && !termState.error && <Box dangerouslySetInnerHTML={{ __html: termState.term.content }} />}
            {!termState.loaded && <CircularProgress size={16} color="brand" />}
          </Container>
          {termState.error && (
            <Container>
              <Box py="72px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <ErrorIcon />
                <Typography sx={{ mt: '12px' }}>문서 조회 중 오류가 발생 했습니다</Typography>
                <Button
                  variant="contained"
                  color="brand"
                  startIcon={<RefreshOutlinedIcon />}
                  onClick={() => fetchTerm(termState.type)}
                  sx={{ mt: '7px' }}
                >
                  다시 시도
                </Button>
              </Box>
            </Container>
          )}
        </DialogContent>
      </Dialog>

      {/* 와인 상세정보 */}
      <PdataInfo show={showPdataInfo} pdataId={detailPdataId} />

      {/* 오늘의 한 병 판매 시간 종료 모달 */}
      <EndSaleModal open={todayOneBottle.loaded && endSaleModal} vendor_id={vendorId} />

      {/* 오늘의 한 병을 이미 주문한 경우의 모달 */}
      <EndOrderModal open={todayOneBottle.loaded && orderCompleteModal} vendor_id={vendorId} />

      {/* 오늘의 한 병 품절 모달 */}
      <SoldOutModal open={todayOneBottle.loaded && soldOutModal} vendor_id={vendorId} />

      {/* 남아있는 상품의 수량 보다 주문 상품의 수량이 많을 경우의 모달  */}
      <Dialog open={quantityOverModal.open} fullWidth maxWidth="xs">
        <DialogTitle sx={{ px: '24px', pb: '18px' }}>
          <Stack direction="row" alignItems="center" spacing="5px">
            <SoldOutIcon />
            <Typography sx={{ fontSize: '18px', fontWeight: 900, letterSpacing: '-0.18px', color: '#140229' }}>
              주문 재고 초과 안내
            </Typography>
          </Stack>
        </DialogTitle>

        <DialogContent sx={{ px: '24px', fontSize: '16px', letterSpacing: '-0.16px', color: '#140229' }}>
          아쉽게도 상품의 주문 재고 수량이 초과 되었습니다. (남은 수량 {quantityOverModal.quantity}개)
          <br />
          <br />
          상품 수량을 다시 설정해주세요.
        </DialogContent>

        <DialogActions sx={{ px: '24px', pt: '1px', pb: '25px' }}>
          <Button
            fullWidth
            variant="contained"
            color="brand"
            onClick={() => setQuantityOverModal({ ...quantityOverModal, open: false })}
            sx={{
              py: '16px',
              borderRadius: '26px',
              fontSize: 17,
              fontWeight: 800,
              lineHeight: 'normal',
              letterSpacing: '-0.17px'
            }}
          >
            수량 다시 설정하기
          </Button>
        </DialogActions>
      </Dialog>
    </OrderLayout>
  );
}

export default OrderTodayBottleConfirm;

// eslint-disable-next-line react/prop-types
const Transition = React.forwardRef(function Transition({ children, ...props }, ref) {
  return (
    <Slide direction="up" ref={ref} {...props}>
      {children}
    </Slide>
  );
});

const infoColor = alpha('#140229', 0.7);

const RefundInfoContent = React.memo(({ children }) => (
  <Box fontSize="12px" lineHeight="16px" width="100%" display="flex" color={infoColor}>
    <Box>
      <CheckIcon fontSize="12px" color={infoColor} />
    </Box>
    <Box pl="8px">{children}</Box>
  </Box>
));

RefundInfoContent.propTypes = {
  children: PropTypes.any
};

const PickupAvailDateLoading = React.memo(() => (
  <Box
    component="section"
    height="100%"
    display="flex"
    justifyContent="center"
    alignItems="center"
    role="progressbar"
    aria-labelledby="pickup-avail-dt-loadding"
  >
    <Box textAlign="center">
      <Box>
        <CircularProgress size="1.4rem" color="brand" />
      </Box>
      <Typography id="pickup-avail-dt-loadding" variant="caption" role="presentation" sx={{ mt: '4px' }}>
        픽업 가능시간을 확인 중 입니다.
      </Typography>
    </Box>
  </Box>
));
