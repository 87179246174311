import React from 'react';
import { useLocation, useNavigate, useNavigationType, useParams } from 'react-router-dom';
import moment from 'moment';
import { useLocationState } from 'react-router-use-location-state';
import { httpsCallable } from 'firebase/functions';

// import project
import BackHeader from 'layouts/Main/BackHeader';
import { getPromotion } from '../../../services/PromotionService/promotions';
import PromotionCoupon from './components/PromotionCoupon';
import { getFirebaseFunctions } from '../../../utils/firebase-tools';
import { CLO_CODE } from '../../../config';
import { WoAlert } from '../../../utils/kmwine-alerts';
import ProductCard from './components/ProductCard';
import { useScriptRef, useWineOne } from 'hooks';
import BottomButtonSheet from './components/BottomButtonSheet';
import { PdataInfo } from '../../../components/pdata';
import CouponDialog from './components/CouponDialog';
import CouponTerms from './components/CouponTerms';
import Timer from './components/Timer';

// material-ui
import { Box, ButtonBase, CircularProgress, Container, Typography } from '@mui/material';

/**
 * pdata 프로모션 페이지
 * @param event
 * @returns {JSX.Element}
 * @constructor 최효근<hkchoi@winone.io>
 */
function Promotion() {
  const scriptedRef = useScriptRef();
  const { promotionId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const navigationType = useNavigationType();

  const { refreshUserGeolocation } = useWineOne(); // wineone context

  React.useEffect(() => {
    refreshUserGeolocation(); // 사용자 현위치 최신화
  }, []);

  // 프로모션 정보
  const [promotionData, setPromotionData] = React.useState(null);

  // 상세보기할 pdata Id
  const [detailPdataId, setDetailPdataId] = useLocationState('promotion_pdataId', null);
  // pdata 상세보기
  const [showPdataInfo, setShowPdataInfo] = useLocationState('promotion_showPdataInfo', false);

  // 로딩
  const [loading, setLoading] = React.useState(false);

  // 쿠폰 로딩
  const [couponLoading, setCouponLoading] = React.useState(false);

  // 쿠폰정책 다이얼로그
  const [couponTerms, setCouponTerms] = React.useState(false);

  // 마지막주문상품
  const [lastOrderItemMap, setLastOrderItemMap] = useLocationState('promotion_last_order_item_map', null);

  // 시작한 프로모션 인지
  const isStart = React.useMemo(() => {
    if (promotionData) {
      return new Date() > new Date(promotionData.promotion.period.start_dt);
    }
  }, [promotionData]);

  // 주문상품(함께 주문) 초기값
  const initialOrderItemMap = React.useMemo(() => {
    let initVal = {};
    if (navigationType !== 'PUSH' && lastOrderItemMap) {
      initVal = { ...lastOrderItemMap };
    }
    return initVal;
  }, [location]);

  // 주문상품
  const [orderItemMap, setOrderItemMap] = React.useState(initialOrderItemMap);

  // 전체 주문 병 수
  const [totalBottleCount, setTotalBottleCount] = useLocationState('promotion_totalBottleCount', 0);
  // 전체 주문 병 수 계산
  const [totalPrice, setTotalPrice] = useLocationState('promotion_totalPrice', 0);

  // coupon list
  const [couponList, setCouponList] = React.useState([]);

  // coupon dialog
  const [couponDrawerOpen, setCouponDrawerOpen] = React.useState(false);

  // coupon dialog list
  const [couponDialogList, setCouponDialogList] = React.useState([]);

  const shareProp = React.useMemo(() => {
    return {
      linkTitle: '1KMWINE 프로모션',
      linkType: 'promotion',
      linkData: `${promotionId}`
    };
  }, [promotionId]);

  // 프로모션 정보 가지고오기
  const fetchPromotion = async () => {
    setLoading(true);
    const data = await getPromotion(promotionId).catch((error) => {
      httpsCallable(
        getFirebaseFunctions(),
        'call-cdm-clo-error'
      )({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `프로모션 조회 중 에러`,
        msg: ` ${JSON.stringify(error)}`,
        param: {
          error
        }
      })
        .then(console.log)
        .catch(console.error);

      WoAlert.fire(`프로모션 조회 중 오류가 발생하였습니다`, '', 'error').then(() => {
        navigate(-1);
      });
    });

    if (!scriptedRef.current) {
      console.warn('[PromotionSection][fetchPromotion] Unmounted component.');
      navigate(-1);
      return;
    }

    if (data.code === 1) {
      WoAlert.fire(`이미 종료된 프로모션입니다`, '', 'info').then(() => {
        navigate(-1);
      });
      return;
    }

    if (data.code === 0) {
      setPromotionData(data.data);
      setCouponList(data.data?.promotion_coupon ?? []);
      setLoading(false);
    } else {
      httpsCallable(
        getFirebaseFunctions(),
        'call-cdm-clo-error'
      )({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `프로모션 조회 중 에러`,
        msg: ` ${JSON.stringify(data.message)}`
      })
        .then(console.log)
        .catch(console.error);

      WoAlert.fire(`프로모션 조회 중 오류가 발생하였습니다`, '', 'error').then(() => {
        navigate(-1);
      });
    }
  };

  // 최초 호출
  React.useEffect(() => {
    fetchPromotion();
  }, [promotionId]);

  // 상품 추가/제외
  const handleProductItem = React.useCallback(
    (product) => {
      const productId = product.id ?? product._id;
      const _orderItemMap = { ...orderItemMap };

      const orderProductForm = {
        capacity: product.capacity.toString(),
        name: product.pdata.name,
        pdata: product.pdata,
        price: product.price,
        vintage: product.vintage,
        _id: productId
      };

      // 이미 주문상품에 추가되어있을 경우 -> 주문상품에서 제외
      if (_orderItemMap[productId]) {
        console.log(`[wineone] 주문상품 제외 (${product.pdata.name.ko})`);
        delete _orderItemMap[productId];
      }
      // 주문상품 추가
      else {
        console.log(`[wineone] 주문상품 추가 (${product.pdata.name.ko})`);
        _orderItemMap[productId] = {
          product: orderProductForm,
          quantity: 1
        };
      }

      setOrderItemMap(_orderItemMap);
    },
    [orderItemMap]
  );

  React.useEffect(() => {
    let totalCount = 0;
    let totalPrice = 0;
    const productIds = Object.keys(orderItemMap) ?? [];
    productIds.forEach((addedPrdId) => {
      const { quantity } = orderItemMap[addedPrdId];

      totalCount += quantity;
      totalPrice += orderItemMap[addedPrdId].product.price.original * quantity;
    });

    setTotalBottleCount(totalCount);
    setTotalPrice(totalPrice);
  }, [orderItemMap]);

  // 상품 수량변경
  const handleOrderItemQuantity = (orderItem, val) => {
    console.debug(`수량 변경됨. val=${val}`);
    const { product } = orderItem;
    const pid = product._id ?? product.id;
    const _orderItemMap = { ...orderItemMap };
    _orderItemMap[pid] = {
      product,
      quantity: val
    };
    setOrderItemMap(_orderItemMap);
  };

  // 와인 상세정보 보기
  const moveToWineInfo = async (_pdata) => {
    if (showPdataInfo) return false; // 이미 열려있을 때

    console.log('[1KMWINE] 와인 상세정보 보기. pdata=', _pdata);
    const pdataId = _pdata._id ?? _pdata.id;

    if (!pdataId) {
      console.error('[1KMWINE] 와인 상세정보 보기. pdataId가 없음.');
      return undefined;
    }

    setDetailPdataId(pdataId);
    setShowPdataInfo(true, { method: 'push' });
  };

  // 픽업샵 선택 클릭시
  const clickSelectPickUpShop = () => {
    if (!isStart) {
      notStartClick();
      return;
    }

    if (Object.keys(orderItemMap).length === 0) {
      WoAlert.fire('상품을 선택해주세요', '', 'info');
      return;
    }

    // 주문상품목록
    const orderItems = [];

    // 주문에 추가된 상품이 있을경우
    if (orderItemMap) {
      Object.keys(orderItemMap).forEach((productId) => {
        orderItems.push(orderItemMap[productId]);
      });
    }

    navigate(`/promotion/${promotionId}/map`, {
      state: {
        orderItems,
        vendor: promotionData.promotion_vendor,
        location: promotionData.promotion.location,
        location_level: promotionData.promotion.location_level
      }
    });
  };

  const notStartClick = () => {
    WoAlert.fire({
      title: '프로모션 기간 안내',
      html: `<div style="line-height: 1.5rem; text-align: left" >판매 대기중인 프로모션 입니다. </br> </br> 
              프로모션 시작일 </br> 
              <strong>${moment(promotionData?.promotion.period.start_dt).locale('ko').format('YYYY.MM.DD(ddd) a hh:mm ')}</strong>
              </br> 
              </br> 
              헤택/이벤트 알림 수신에 동의하시면 </br>
               프로모션 시작 알림을 받으실 수 있어요!
               </br>(마이 > 앱설정 > 알림 설정)</div>`,
      customClass: {
        confirmButton: 'max-100'
      },
      confirmButtonText: '확인'
    });
  };

  return (
    <BackHeader
      title={promotionData?.promotion.name}
      showFooter={false}
      showAlarms={false}
      showShare={true}
      showCart={false}
      showBottomNav={false}
      shareProp={shareProp}
    >
      {loading && (
        <Box width="100%" height="500px" display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
      {promotionData && !loading && (
        <>
          <Box width="100%" component="img" src={promotionData.promotion.promotion_img.origin} />
          {/* 프로모션 진행기간 */}
          <Container disableGutters maxWidth="xs" sx={{ bgcolor: 'background.paper', paddingY: '28px' }}>
            <Box width="100%" display="flex" justifyContent="center" flexDirection="column">
              <Typography textAlign="center" color="rgba(20, 2, 41, 0.40)" fontSize="12px" fontWeight="700">
                프로모션 진행기간
              </Typography>
              <Box mt="4px" display="flex" justifyContent="center">
                <Box display="flex" position="relative">
                  <Typography fontFamily="D-DIN" fontSize="16px" fontWeight="400" sx={{ zIndex: '10' }}>
                    {moment(promotionData?.promotion.period.start_dt).locale('ko').format('YYYY.MM.DD')}
                  </Typography>

                  <Typography fontFamily="D-DIN" fontSize="16px" fontWeight="400" sx={{ zIndex: '10' }}>
                    &nbsp;~&nbsp;
                  </Typography>
                  {promotionData.promotion.period.use && (
                    <Typography fontFamily="D-DIN" fontSize="16px" fontWeight="400" sx={{ zIndex: '10' }}>
                      {moment(promotionData?.promotion.period.end_dt).locale('ko').format('YYYY.MM.DD')}
                    </Typography>
                  )}

                  <Box width="100%" height="10px" bottom={2} sx={{ background: '#D4F788' }} position="absolute" />
                </Box>
              </Box>
            </Box>
            {!isStart && <Timer targetDate={promotionData.promotion.period.start_dt} isStart={isStart} />}

            {promotionData.promotion.pickup.state === 'none' && (
              <Box textAlign="center" mt="32px">
                <Typography color="rgba(20, 2, 41, 0.40)" fontSize="12px" fontWeight="700">
                  상품 입고 일정 및 픽업 기간
                </Typography>
                <Typography color="rgba(20, 2, 41, 0.70)" fontSize="14px" fontWeight="400" sx={{ marginTop: '8px' }}>
                  주문 마감 후 각 픽업샵에 상품 입고, <br /> 입고 후&nbsp;
                  <Typography
                    component="span"
                    color="rgba(20, 2, 41, 0.70)"
                    fontSize="14px"
                    fontWeight="400"
                    sx={{ textDecoration: 'underline' }}
                  >
                    픽업 일정 별도 안내
                  </Typography>
                </Typography>
              </Box>
            )}
          </Container>
          <Box width="100%" height="8px" sx={{ bgcolor: '#F3F2F5' }} />
          {couponList.length > 0 && (
            <>
              {/* 쿠폰 영역 */}
              <Container disableGutters maxWidth="xs" sx={{ bgcolor: 'background.paper', padding: '20px' }}>
                <Box display="flex" justifyContent="center" alignItems="center" position="relative">
                  <Typography color="rgba(20, 2, 41, 0.70)" fontSize="21px" fontWeight="800">
                    프로모션 쿠폰 🎉
                  </Typography>
                  <ButtonBase
                    onClick={() => {
                      setCouponTerms(true);
                    }}
                    sx={{
                      position: 'absolute',
                      right: 0,
                      width: '55px',
                      height: '18px',
                      borderRadius: '9px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      bgcolor: 'rgba(45, 21, 77, 0.08)'
                    }}
                  >
                    <Typography color="rgba(45, 21, 77, 0.50)" fontWeight="700" fontSize="10px">
                      쿠폰 정책
                    </Typography>
                  </ButtonBase>
                </Box>
                <Box mt="20px">
                  {(couponList ?? []).map((coupon) => (
                    <PromotionCoupon
                      on
                      coupon={coupon}
                      setCouponDrawerOpen={setCouponDrawerOpen}
                      setCouponList={setCouponDialogList}
                      key={coupon.id}
                      isStart={isStart}
                      notStartClick={notStartClick}
                    />
                  ))}
                </Box>
                <Box>
                  <Box mb="8px" display="flex">
                    <Typography color="rgba(20, 2, 41, 0.70)" fontSize="10px" fontWeight="400">
                      다운로드 및 유효 기간 :&nbsp;
                    </Typography>
                    <Typography color="rgba(20, 2, 41, 0.70)" fontSize="10px" fontWeight="400">
                      {moment(promotionData?.promotion.period.start_dt).locale('ko').format('YY년 MM월 DD일 (dd)')}
                    </Typography>

                    <Typography color="rgba(20, 2, 41, 0.70)" fontSize="10px" fontWeight="400">
                      &nbsp;~&nbsp;
                    </Typography>
                    {promotionData.promotion.period.use && (
                      <Typography color="rgba(20, 2, 41, 0.70)" fontSize="10px" fontWeight="400">
                        {moment(promotionData?.promotion.period.end_dt).locale('ko').format('YY년 MM월 DD일 (dd)')}
                      </Typography>
                    )}
                  </Box>
                  <Typography color="rgba(20, 2, 41, 0.70)" fontSize="10px" fontWeight="400">
                    일부 쿠폰의 다운로드 및 유효 기간이 다를 수 있습니다.
                    <br />
                    자세한 일정은 쿠폰 다운로드 화면에서 확인해주세요.
                  </Typography>
                </Box>
              </Container>
              <Box width="100%" height="8px" sx={{ bgcolor: '#F3F2F5' }} />
            </>
          )}

          {/* 상품 리스트 영역 */}
          <Container disableGutters maxWidth="xs" sx={{ position: 'relative', bgcolor: 'background.paper' }}>
            <Box
              position="relative"
              display="flex"
              flexDirection="column"
              sx={{ px: '20px', pt: '16px', pb: promotionData.promotion.pickup.state !== 'none' && '130px' }}
            >
              <Box>
                <Typography fontSize="14px" fontWeight="800" display="flex">
                  프로모션 상품&nbsp;
                  <Typography component="span" color="#9357E5" fontSize="14px" fontWeight="800">
                    {promotionData?.promotion_pdata?.length}
                  </Typography>
                </Typography>
              </Box>
              <Box mt="18px">
                {promotionData?.promotion_pdata?.map((product, index) => (
                  <ProductCard
                    key={`prd-${product._id}-${index}`}
                    index={index}
                    productId={product._id}
                    product={product}
                    orderItemMap={orderItemMap}
                    onClick={handleProductItem}
                    quantityOnClick={handleOrderItemQuantity}
                    imgOnClick={(pdata) => {
                      moveToWineInfo(pdata);
                    }}
                    isStart={isStart}
                    notStartClick={notStartClick}
                  />
                ))}
              </Box>
            </Box>
            {promotionData.promotion.pickup.state === 'none' && (
              <Box px="20px" py="25px" mt="16px" width="100%" mb="65px" height="106px" sx={{ backgroundColor: '#EBE7EF' }}>
                <Typography fontWeight="700" fontSize="12px" color="rgba(20, 2, 41, 0.40)">
                  ㆍ본 프로모션은 예약 주문 프로모션입니다.
                </Typography>
                <Typography fontWeight="700" fontSize="12px" color="rgba(20, 2, 41, 0.40)" sx={{ mt: '2px' }}>
                  ㆍ예약 주문 상품은 프로모션 종료 후 픽업샵으로 상품 배송이 시작되어 <br />
                  &nbsp; &nbsp; 주문 마감 후에는 취소/반품이 불가합니다.
                </Typography>
              </Box>
            )}

            {/* 장바구니 / 주문하기 영역 */}
            <BottomButtonSheet
              orderItemMap={orderItemMap}
              onOrderButtonClick={() => {
                clickSelectPickUpShop();
              }}
              onDeleteClick={handleProductItem}
              totalBottleCount={totalBottleCount}
              totalPrice={totalPrice}
            />
          </Container>

          {/* 쿠폰 다이얼로그 */}
          <CouponDialog
            couponDrawerOpen={couponDrawerOpen}
            setCouponDrawerOpen={setCouponDrawerOpen}
            couponList={couponList}
            couponDialogList={couponDialogList}
            setCouponDialogList={setCouponDialogList}
            setCouponList={setCouponList}
            couponLoading={couponLoading}
            setCouponLoading={setCouponLoading}
          />

          {/* 쿠폰 정책 다이얼로그 */}
          <CouponTerms couponTerms={couponTerms} setCouponTerms={setCouponTerms} />
        </>
      )}

      {/* pdata 상세정보 */}
      <PdataInfo show={showPdataInfo} pdataId={detailPdataId} />
    </BackHeader>
  );
}

export default Promotion;
