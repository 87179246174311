import axios from 'axios';
import { FIREBASE_FUNCITON_URL, NODE_URL } from './index';
import { httpsCallable } from 'firebase/functions';
import { getFirebaseFunctions } from '../../utils/firebase-tools';
import { CLO_CODE } from '../../config';

/**
 * 입점사(벤더)에서 다운로드 가능한 쿠폰 목록
 * @param {string} vendorId - 입점사 아이디
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getCouponList(vendorId) {
  return axios.post(FIREBASE_FUNCITON_URL.POST__VENDOR_COUPON_LIST, { id: vendorId });
}

/**
 * 입점샵 목록 조회
 * @param pinId
 * @param search
 * @param page
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getVendorList(pinId, search, page = { current: 1, size: 10 }) {
  return axios.post(NODE_URL.POST__GET_VENDOR_LIST, { pinId, search, page }).then(({ data }) => data);
}

export async function getRecentVendorList(search, page) {
  return axios.post(NODE_URL.POST__GET_RECENT_VENDOR_LIST, { search, page }).then(({ data }) => data);
}

export async function getFvrVendorList(search = '', page) {
  return axios.post(NODE_URL.POST__GET_FVR_VENDOR_LIST, { search, page }).then(({ data }) => data);
}

/**
 * 관심샵 등록
 *
 * @param {string} vendor_id 관심샵으로 등록할 입점샵 아이디
 * @authors 최효근<hkchoi@wineone.io>, 조현권<hkcho@wineone.io>
 */
export async function addFvrVendor(vendor_id) {
  return axios.post(NODE_URL.POST__ADD_FVR_VENDOR, { vendor_id }).then((res) => res);
}

/**
 * 관심샵 삭제
 * @param {string} vendor_id 관심샵에서 삭제할 입점샵 아이디
 * @authors 최효근<hkchoi@wineone.io>, 조현권<hkcho@wineone.io>
 */
export async function deleteFvrVendor(vendor_id) {
  return axios.delete(NODE_URL.DELETE__DELETE_FVR_VENDOR, { data: { vendor_id } });
}

/**
 * 입점샵 상세정보 조회
 *
 * @param {string} vendor_id 입점샵 아이디
 * @returns {Promise<AxiosResponse<any>>}
 * @authors 최효근<hkchoi@wineone.io>
 * @see https://api.1kmwine.kr/docs#/vendor/VendorController_getOneVendor
 */
export async function getOneVendor(vendor_id) {
  if (!vendor_id) throw new Error(`'vendor_id' cannot be null or empty.`);
  return axios.post(NODE_URL.POST__GET__ONE__VENDOR, { vendor_id });
}

/**
 * 입점샵 전체, 최근 픽업한 샵, 관심샵 개수조회
 * @param search
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getVendorsCnt(search = '') {
  return axios
    .post(NODE_URL.POST__GET__VENDORS_CNT, { search })
    .then((response) => {
      if (response.data.result.code !== 0) {
        throw new Error(`[${response.data.result.code}] ${response.data.result.msg}`);
      }
      return response.data;
    })
    .catch((error) => {
      if (error.message !== 'Network Error') {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `샵 리스트 카운트 조회 중 오류`,
          msg: error.message ?? 'Unexpected Error',
          which: `${window?.location?.href}`,
          param: { error }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 입점샵 코드로 입점샵 리스트 조회
 *
 * @param {string} query 검색할 코드
 * @returns {Promise<AxiosResponse<any>>}
 * @authors 조현권<hkcho@wineone.io>
 * @see https://api.1kmwine.kr/docs#/%EC%9E%85%EC%A0%90%EC%83%B5(Vendor)/VendorController_getVendorCodeList
 */
export async function getFindByCode(
  query
): Promise<{ result: { code: number | string, msg?: string }, data: { searchQuery: string, vendors: any[] } }> {
  return axios.post(NODE_URL.POST__GET_FIND_BY_CODE, { query }).then((res) => res.data);
}

/**
 * 입점샵 코드로 입점샵 상세정보 조회
 *
 * @param code
 * @returns {Promise<AxiosResponse<any>>}
 * @authors 조현권<hkcho@wineone.io>
 * @see
 */
export async function getOneByCode(code) {
  return axios.get(NODE_URL.GET__GET_ONE_BY_CODE, { params: { code } });
}

export async function findNearestPin({ longtitude, latitude }) {
  return axios.post(NODE_URL.POST__FIND_NEAREST_PIN, { longtitude, latitude }).then((res) => res.data);
}

/**
 * 현재 진행 중인 입점샵 셀프 프로모션 조회
 */
export async function getSelfPromotions(vendorId) {
  return axios.get(NODE_URL.GET__GET_PROM_VENDOR, { params: { id: vendorId } }).catch((error) => {
    httpsCallable(
      getFirebaseFunctions(),
      'call-cdm-clo-error'
    )({
      code: CLO_CODE.UNEXPECTED_ERROR,
      title: `입점샵[${vendorId}]에서 진행 중인 셀프 프로모션 조회 실패 [${error.name ?? 'Unexpceted'}]`,
      msg: `${JSON.stringify(error)}`,
      which: `getSelfPromotions`,
      param: { error, location: window.location.href }
    })
      .then(console.log)
      .catch(console.error);
    throw error;
  });
}

export async function postInviteVendor({ vendorId, uid, inviteVendorType }) {
  return axios.post(NODE_URL.POST__ADD_INVITE_VENDOR, { vendorId, uid, inviteVendorType }).then((res) => res.data);
}

/**
 * 지도에 위치한 입점샵 리스트 조회
 * @param {string} query 검색할 코드
 * @returns {Promise<AxiosResponse<any>>}
 * @authors 최효근<hkchoi@winone.io>
 */
export async function getVendorMapList(squarePoint) {
  return axios.post(NODE_URL.POST__VENDOR_MAP_LIST, { squarePoint }).then((res) => res.data);
}

/**
 * 지도에 위치한 핀 리스트 조회
 * @param {string} query 검색할 코드
 * @returns {Promise<AxiosResponse<any>>}
 * @authors 최효근<hkchoi@winone.io>
 */
export async function getPinMapList(squarePoint) {
  return axios.post(NODE_URL.POST__PIN_MAP_LIST, { squarePoint }).then((res) => res.data);
}

/**
 * 입점샵에서 판매 중인 상품목록 조회
 * @param vendorId 입점샵 아이디
 * @param query 검색어
 * @param options 검색옵션
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getProductList(vendorId: string, query, options) {
  return axios.post(NODE_URL.POST__GET_PRODUCT_LIST, { vendor_id: vendorId, query, ...options }).then(({ data }) => data);
}

// todo 작업 완료 후 삭제예정
// export async function getPickupAvailableDatesOld(vendorId: string, date?: string) {
//   const params = {};
//
//   // 기준일이 있을 경우
//   if (date) params.date = date;
//
//   return axios
//     .get(NODE_URL.GET__GET_PICKUP_AVAILABLE_DATES.replace(':vendor_id', vendorId), { params })
//     .then(({ data }) => data)
//     .catch((error) => {
//       console.error('[VendorService] 픽업 가능일시 조회 요청실패', error);
//       if (error.message !== 'Network Error') {
//         httpsCallable(
//           getFirebaseFunctions(),
//           'call-cdm-clo-error'
//         )({
//           code: CLO_CODE.UNEXPECTED_ERROR,
//           title: `픽업일시 조회 실패 [${error.message}:${error.code ?? 'Unexpected'}]`,
//           msg: `${JSON.stringify(error)}`,
//           which: `getPickupAvailableDates`,
//           param: { error, location: window.location.href }
//         })
//           .then(console.log)
//           .catch(console.error);
//       }
//       throw error;
//     });
// }

export async function getPickupAvailableDates(vendorId: string, date?: string, options?: { product_ids: string[] }) {
  return axios
    .post(NODE_URL.POST__GET_PICKUP_AVAILABLE_DATES, { vendor_id: vendorId, date, options })
    .then(({ data }) => data)
    .catch((error) => {
      console.error('[VendorService] 픽업 가능일시 조회 요청실패', error);
      if (error.message !== 'Network Error') {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `픽업일시 조회 실패 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `getPickupAvailableDates`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}
