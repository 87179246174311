import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { getAuth } from 'firebase/auth';

// project imports
import { useAuth, useScriptRef } from 'hooks';
import { WoAlert } from 'utils/kmwine-alerts';
import BackHeader from 'layouts/Main/BackHeader';
import { TextField } from 'components';

// material-ui
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { Alert, Box, Button, Container, Divider, FormControl, Grow, Slide, Snackbar, Stack, Typography } from '@mui/material';

/**
 * 이메일로 로그인
 *
 * @returns {JSX.Element}
 * @authors 조현권<hkcho@wineone.io>
 */
function SignInWithEmail() {
  const { signin } = useAuth();
  const scriptedRef = useScriptRef();
  const navigate = useNavigate();
  const { state } = useLocation();

  const auth = getAuth();
  React.useEffect(() => {
    try {
      auth.signOut();
    } catch (e) {
      /* DO NOTHING */
    }
  }, []);

  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: '로그인 실패'
  });

  const handleSnackbarClose = () => {
    setSnackbar({
      ...snackbar,
      message: '로그인 실패',
      open: false
    });
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const loginSnackBar = React.useMemo(
    () => (
      <Snackbar open={snackbar.open} onClose={handleSnackbarClose} TransitionComponent={Transition} key="login-error-snackbar">
        <Alert onClose={handleSnackbarClose} severity="warning" sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    ),
    [snackbar.open]
  );

  const onSubmit = (values, { setErrors, setStatus, setSubmitting }) => {
    console.log('[1kmwine] 이메일 아이디로 로그인을 시도합니다.');
    setSubmitting(true);

    signin(values.username, values.password, (err) => {
      if (scriptedRef.current) {
        // 로그인 실패시
        if (err) {
          console.warn('[1KMWINE] 이메일 로그인 실패 코드(firebase auth): ', err.code, err.message);

          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);

          let title = '로그인 실패';
          let message = '로그인 요청 중 오류가 발생했습니다.<br/>잠시 후 다시 시도해주세요.';

          if (
            err.code === 'auth/user-not-found' || // 존재하지 않는 이메일
            err.code === 'auth/wrong-password' // 잘못된 비밀번호
          ) {
            message = '아이디 또는 비밀번호가 올바르지 않습니다.';
          } else if (err.code === 'auth/user-disabled') {
            title = '사용 중지 안내';
            message = `관리자에 의해 사용이 중지된 계정입니다.<br/>관리자에게 문의해주세요.<br/>문의 ${process.env.REACT_APP_CS_EMAIL_ADDRESS}`;
          }

          WoAlert.fire({
            title,
            html: `<div style='line-height: 1.5rem; text-align: center'>${message}</div>`,
            customClass: {
              confirmButton: 'max-100'
            },
            confirmButtonText: '확인'
          });

          return err;
        }

        setStatus({ success: true });
        setSubmitting(false);
      }
    });
  };

  const initialValues = Object.freeze({
    username: state?.email ?? '', // 아이디 (이메일)
    password: '' // 비밀번호
  });

  // 계정정보 formik
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  });

  // console.log('### formik: ', formik);

  return (
    <BackHeader title={''} showFooter={false} showBottomNav={false} hideDivider ToolBarProps={{ sx: { px: '16px' } }}>
      <Helmet title={`${process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE} | 아이디 로그인`} />
      <form onSubmit={formik.handleSubmit}>
        <Container
          maxWidth="xs"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(var(--vh, 1vh) * 100 - 56px)',
            maxHeight: 'calc(var(--vh, 1vh) * 100 - 56px)',
            position: 'relative'
          }}
        >
          <Box pt="27px" pl="10px">
            <Typography fontSize="32px" lineHeight="36px" letterSpacing="-0.34px">
              이메일로 로그인
            </Typography>
          </Box>

          <Box mt="40px">
            <FormControl fullWidth>
              <TextField
                hiddenLabel
                fullWidth
                id="username"
                name="username"
                placeholder="아이디 (이메일)"
                type="email"
                value={formik.values.username}
                onChange={formik.handleChange}
                error={formik.touched.username && Boolean(formik.errors.username)}
                inputProps={{
                  autoComplete: 'email'
                }}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: '10px' }}>
              <TextField
                hiddenLabel
                fullWidth
                id="password"
                name="password"
                placeholder="비밀번호"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                inputProps={{
                  autoComplete: 'current-password'
                }}
              />
            </FormControl>
          </Box>

          <Box flexGrow={1} position="relative" overflow="hidden" minHeight="160px">
            <Box position="absolute" left={0} bottom="20px" width="100%">
              {/* 로그인 중에는 숨김 */}
              <Grow in={!formik.isSubmitting} appear={false}>
                <Box className="additional-action-area">
                  <Box mb="16px">
                    <Stack
                      direction="row"
                      divider={
                        <Box py="8px" display="flex">
                          <Divider orientation="vertical" flexItem />
                        </Box>
                      }
                      spacing={1}
                      justifyContent="center"
                    >
                      <AccountActionButton onClick={() => navigate('/auth/find-email')}>아이디 찾기</AccountActionButton>
                      <AccountActionButton
                        onClick={() => {
                          navigate('/auth/find-password');
                        }}
                      >
                        비밀번호 재설정
                      </AccountActionButton>
                    </Stack>
                  </Box>
                </Box>
              </Grow>

              {/* 로그인 버튼 */}
              <LoginButton loading={formik.isSubmitting} fullWidth type="submit" variant="contained" color="brand" size="large">
                로그인
              </LoginButton>
            </Box>
          </Box>
        </Container>
      </form>
      {loginSnackBar}
    </BackHeader>
  );
}

export default SignInWithEmail;

const validationSchema = yup.object({
  username: yup.string().trim().required('아이디(이메일)을 입력해주세요').email('올바른 이메일 주소가 아닙니다.'),
  password: yup.string().trim().required('비밀번호를 입력해주세요.')
});

const AccountActionButton = styled(Button)(() => ({
  fontSize: '13px',
  opacity: 0.6,
  fontWeight: 'normal',
  lineHeight: 'normal',
  letterSpacing: '-0.13px'
}));

const LoginButton = styled(LoadingButton)({
  position: 'sticky',
  bottom: 15,
  fontSize: '17px',
  fontWeight: 'bold',
  lineHeight: 'normal',
  padding: '15px 20px 17px'
});
