import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocationState } from 'react-router-use-location-state';
import * as _ from 'lodash';
import PropTypes from 'prop-types';

// project imports
import { useAuth, useLocalStorage, useScriptRef } from 'hooks';
import { SearchField } from 'components/search';
import OrderDialog from '../OrderDialog';
import ProductList from '../ProductList';
import SearchDialog from '../SearchDialog';
import FilterDialog, {
  COUNTRY_FILTER_NAMES,
  INITIAL_FILTER_STATE,
  PRODUCT_MAX_PRICE,
  PRODUCT_PRICE_STEP,
  VARIETY_FILTER_NAMES
} from '../../../../mall/MallIndex/components/FilterDialog';
import { SearchResultContainer } from '../../../../mall/MallIndex/components';

// material-ui
import { AppBar, Box, CircularProgress, Container, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PDATA_CATEGORY, WINE_TYPES } from '../../../../../config';
import { ProductInsideContainer } from './components';
import { getProductList } from '../../../../../services/VendorService';

// 상품검색 페이지 사이즈
const PAGE_SIZE = 12;

// 상품검색 페이지 네이트 기본정보
const initialPage = Object.freeze({
  current: 1,
  total_pages: 0,
  total_results: 0,
  size: PAGE_SIZE, // 한번에 호출할 상품 개수
  done: false
});

const initialSearchState = Object.freeze({
  initialized: false,
  searched: false,
  searching: false,
  error: false
});

const QUERY_HISTORY_MAX_LEN = 10;

// 와인 정렬옵션
export const vendorProductOrderOpts = Object.freeze([
  { label: '추천순', value: 'recommend' },
  { label: '낮은 가격순', value: 'lowp' },
  { label: '높은 가격순', value: 'highp' }
]);

const PRODUCT_TYPES = [];
Object.keys(WINE_TYPES).forEach((key) => {
  if (key !== 'TICKET') PRODUCT_TYPES.push(WINE_TYPES[key]);
});

/**
 * 입점샵 상세페이지 상품박스 (필터 & 상품목록)
 *
 * @constructor
 * @authors 이재일<leeji@wineone.io>, 조현권<hkcho@wineone.io>
 */
function ProductBox({ vendorId, mainProduct, orderItemMap, changeOrderItemMap, moveToWineInfo }) {
  const scriptedRef = useScriptRef();
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useAuth();

  const appBarRef = React.useRef(null);

  // 상품 목록 필터
  const initialAllSearchState = Object.freeze({
    orderDialogOpen: false,
    sort: vendorProductOrderOpts[0],
    filter: INITIAL_FILTER_STATE,
    filterUsed: false,
    filterOpen: false,
    searchOpen: false,
    searchState: initialSearchState,
    searchQuery: ''
  });

  // 상품 목록 검색 필터
  const [search, setSearch] = useLocationState('vp_search', initialAllSearchState);

  // 최근 검색어
  const [queryHistory, setQueryHistory] = useLocalStorage('wine-query-history', []);
  // '검색' 영역 활성화 여부
  const [searchOpen, setSearchOpen] = React.useState(false);

  const [list, setList] = useLocationState('vp_list', []);

  // '검색' 다이어로그 열기
  const openSearchArea = () => {
    setSearchOpen(() => true);
  };
  // '검색' 다이어로그 닫기
  const closeSearchArea = () => {
    setSearchOpen(() => false);
  };

  // 뒤로가기
  const pageBackFunc = React.useCallback(() => {
    console.debug(`검색 화면에서 '뒤로가기' 버튼이 감지되었습니다.`);
    // 상품검색 영역이 열려있었을 경우
    if (searchOpen) {
      closeSearchArea();
    } else if (window.history.length > 0) {
      navigate(-1);
    } else {
      navigate('/', { replace: true });
    }
  }, [location, searchOpen]);

  // product 검색
  const searchProduct = (_searchQuery = '') => {
    // 최근 검색어에 추가
    if (_searchQuery.trim().length > 0) {
      try {
        let _oldQueryHistory = [...queryHistory];
        if (_oldQueryHistory.indexOf(_searchQuery) >= 0) {
          console.log('[1KMWINE] 이미 검색한 적 있는 검색어: ', _searchQuery);
          _oldQueryHistory.splice(_oldQueryHistory.indexOf(_searchQuery), 1);
        }

        // 기록하고 있을 최대 개수를 넘어갈 경우
        if (_oldQueryHistory.length > QUERY_HISTORY_MAX_LEN - 1) {
          _oldQueryHistory = _oldQueryHistory.splice(0, QUERY_HISTORY_MAX_LEN - 1);
        }

        setQueryHistory(() => [_searchQuery, ..._oldQueryHistory]);
      } catch (e) {
        /* DO NOTHING */
      }
    }
    fetchProductList(initialPage, {
      ...search,
      searchQuery: _searchQuery.trim(),
      searchOpen: false,
      filter: {
        ...search.filter,
        tabIndex: 'all'
      }
    });

    closeSearchArea(); // 검색 Dialog 닫는 역할
  };

  // 상품 종류 탭
  const productTabList = React.useMemo(() => {
    return PRODUCT_TYPES.map((wineType) => (
      <Tab
        key={`product-tab-key-${wineType.value.toLowerCase()}`}
        label={wineType.label.ko}
        id={`product-tab-${wineType.value.toLowerCase()}`}
        value={wineType.value}
      />
    ));
  }, []);

  // 상품 카테고리 탭 변경
  const handleTabNavChange = (event, newTabIndex) => {
    // console.log('---------------------> 탭 선택됨');
    if (appBarRef.current) {
      console.debug('스크롤 조정', window.scrollY, appBarRef.current.offsetTop);
      if (window.scrollY > appBarRef.current.offsetTop) {
        window.scrollTo(0, appBarRef.current.offsetTop - 54);
      }
    }

    fetchProductList(initialPage, { ...search, filter: { ...search.filter, tabIndex: newTabIndex } });
  };

  /** 툴팁 노출지연 ms */
  const TOOLTIP_SHOW_AFTER = 1200;
  /** 툴팁 노출시간 ms */
  const TOOLTIP_EXPOSURE_MILLIS = 3000;
  /** 툴팁 노출 최대횟수 */
  const TOOLTIP_EXPOSURE_COUNT_MAX = 3;
  // 툴팁 보이기 타임아웃 ID
  let tooltipShowTimeout = null;
  // 툴팁 감추기 타임아웃 ID
  let tooltipHideTimeout = null;
  // 툴팁 노출 횟수
  const [tooltipShowCount, setTooltipShowCount] = useLocalStorage('filter-tooltip', 0);

  // 와인 상세보기 안내 툴팁 보이기
  const [showTooltip, setShowTooltip] = React.useState(false);

  // 툴팁 관련
  React.useEffect(() => {
    if (showTooltip) {
      console.debug('[1KMWINE] 필터버튼 안내 툴팁을 보여줍니다.');
      setTooltipShowCount((tooltipShowCount) => {
        console.debug(`[1KMWINE] 필터버튼 툴팁 노출횟수. ${tooltipShowCount + 1}/${TOOLTIP_EXPOSURE_COUNT_MAX}`);
        return ++tooltipShowCount;
      });

      // #{TOOLTIP_EXPOSURE_MILLIS} millisecond 후 툴팁 감춤
      tooltipHideTimeout = setTimeout(() => {
        if (scriptedRef.current) {
          setShowTooltip(false);
        }
      }, TOOLTIP_EXPOSURE_MILLIS);
    }
  }, [showTooltip]);

  // 툴팁 자동 보이기/감추기
  React.useEffect(() => {
    if (tooltipShowCount >= TOOLTIP_EXPOSURE_COUNT_MAX) {
      console.info(`[1KMWINE] 이미 툴팁 노출횟수를 달성했습니다. [TOOLTIP_EXPOSURE_COUNT_MAX = ${TOOLTIP_EXPOSURE_COUNT_MAX}]`);
      return undefined;
    }

    // #{TOOLTIP_SHOW_AFTER} millisecond 후 툴팁 표현
    tooltipShowTimeout = setTimeout(() => {
      if (scriptedRef.current) setShowTooltip(true);
    }, TOOLTIP_SHOW_AFTER);

    return () => {
      hideTooltip();
    };
  }, []);

  // 툴팁 감추기
  const hideTooltip = () => {
    clearTimeout(tooltipShowTimeout);
    clearTimeout(tooltipHideTimeout);

    if (scriptedRef.current) {
      setShowTooltip(false);
    }
  };
  /* 필터버튼 안내 툴팁 [END] */

  // 검색어를 통해 상품을 검색
  const searchNearbyProduct = (_searchQuery = '') => {
    // 최근 검색어에 추가
    if (_searchQuery.trim().length > 0) {
      try {
        let _oldQueryHistory = [...queryHistory];
        if (_oldQueryHistory.indexOf(_searchQuery) >= 0) {
          _oldQueryHistory.splice(_oldQueryHistory.indexOf(_searchQuery), 1);
        }

        // 기록하고 있을 최대 개수를 넘어갈 경우
        if (_oldQueryHistory.length > QUERY_HISTORY_MAX_LEN - 1) {
          _oldQueryHistory = _oldQueryHistory.splice(0, QUERY_HISTORY_MAX_LEN - 1);
        }

        setQueryHistory(() => [_searchQuery, ..._oldQueryHistory]);
      } catch (e) {
        /* DO NOTHING */
      }
    }
    fetchProductList(initialPage, {
      ...search,
      searchQuery: _searchQuery.trim(),
      searchOpen: false,
      filter: {
        ...search.filter,
        tabIndex: 'all'
      }
    });
  };

  const [filterItemSequence, setFilterItemSequence] = useLocationState('mall_fis', []);

  /** 필터 적용 */
  const applySheetFilter = (filter, _filterItemSequence) => {
    // '스타일', '국가', '품종'의 경우
    if (_filterItemSequence) {
      setFilterItemSequence(_filterItemSequence);
    }

    fetchProductList(initialPage, {
      ...search,
      filter: { ...search.filter, ...filter },
      filterUsed: !_.isEqual(INITIAL_FILTER_STATE, filter),
      filterOpen: false
    });
  };

  const [page, setPage] = useLocationState('vp_page', initialPage);

  // 상품(product) 검색결과 있음
  const hasResult = React.useMemo(() => {
    return search.searchState.searching || (search.searchState.searched && page.total_results > 0);
  }, [search.searchState.searching, search.searchState.searched, page.total_results]);

  /** 상품정렬 dialog 열기 */
  const handleOrderDialogOpen = () => {
    console.log('상품 정렬 dialog 열기', { ...search });
    setSearch({ ...search, orderDialogOpen: true });
  };

  /**
   * 상품정렬 순서변경 콜백
   */
  const handleOrderDialogChange = (value) => {
    // 정렬 순서가 변경되었을 경우
    if (value) {
      const { label } = vendorProductOrderOpts.find(({ value: _value }) => _value === value);

      fetchProductList(initialPage, { ...search, sort: { ...search.sort, label, value }, orderDialogOpen: false });
    }
    // 변경하지 않고 닫을 경우
    else fetchProductList(initialPage, { ...search, orderDialogOpen: false });
  };

  /**
   * 필터 삭제 콜백
   */
  const deleteFilter = (name, value) => {
    // 스타일 필터 삭제
    if (name === 'taste_acidity' || name === 'taste_body' || name === 'taste_sweet' || name === 'taste_tannin') {
      const remainSequence = _.reject(filterItemSequence, { item: name });

      if (name === 'taste_acidity') applySheetFilter({ ...search, taste_acidity: value }, remainSequence);
      else if (name === 'taste_body') applySheetFilter({ ...search, taste_body: value }, remainSequence);
      else if (name === 'taste_sweet') applySheetFilter({ ...search, taste_sweet: value }, remainSequence);
      else if (name === 'taste_tannin') applySheetFilter({ ...search, taste_tannin: value }, remainSequence);
    }
    // 국가, 품종 픽터 삭제
    else if (name === 'country' || name === 'variety') {
      const { country = [], variety = [] } = search.filter;
      const remainSequence = _.reject(filterItemSequence, { item: name, value });

      if (name === 'country') applySheetFilter({ ...search, country: country.filter((item) => item !== value) }, remainSequence);
      else if (name === 'variety') applySheetFilter({ ...search, variety: variety.filter((item) => item !== value) }, remainSequence);
    }
  };

  React.useEffect(() => {
    if (!search.searchState.initialized) {
      fetchProductList(initialPage, initialAllSearchState);
    }
  }, []);

  // 판매상품(product) 목록 호출
  const fetchProductList = async (_page, allSearchState) => {
    if (_page.current !== 1 && search.searchState.searching) {
      console.warn('[1KMWINE] 이미 판매상품(product) 목록을 요청 중 입니다.');
      return false;
    }

    // 1page 조회일 경우
    if (_page.current === 1) {
      // 기존 검색값 모두 초기화
      setPage(initialPage);
      setSearch({ ...allSearchState, searchState: initialSearchState });
    } else {
      // 검색상태로 변경
      setSearch({ ...allSearchState, searchState: { ...allSearchState.searchState, searching: true, error: false } });
    }

    // 판매상품 필터
    const filters = {
      all: [
        { vendor_id: vendorId }, // 해당 입점샵의 상품만
        { available: 'true' }, // 판매중인 상품만
        { remove: 'false' } // 삭제되지 않은 상품000000000
      ],
      none: [
        { category: [PDATA_CATEGORY.TICKET.value] }, // 티켓 제외
        { quantity: { to: 1 } }, // 재고가 1개 이상
        { promo_state: ['WAIT', 'ING'] } // 셀프 프로모션 상태가 '대기', '진행중'인 상품은 검색 제외
      ]
    };

    // 메인 상품이 있을 경우 해당 상품은 제외하고 조회
    if (mainProduct.show && mainProduct._id) {
      filters.none.push({ id: `${mainProduct.id}` });
    }

    let searchWineOnly = false;

    // 와인 검색 카테고리가 [전체]일 경우 ->
    if (allSearchState.filter.tabIndex === 'all') {
      // DO NOTHING
      console.log('[ProductBox] 모든 카테고리 검색');
    }
    // 와인 외의 카테고리 검색일 경우
    else if (allSearchState.filter.tabIndex === 'others') {
      // 위스키, 일본주, 기획상품에서만 검색
      // filters.all.push({ category: [PDATA_CATEGORY.WHISKEY.value, PDATA_CATEGORY.NIHONSHU.value, PDATA_CATEGORY.OFFER.value] });
      filters.none.push({ category: [PDATA_CATEGORY.WINE.value, PDATA_CATEGORY.TICKET.value] });
    }
    // 레드, 화이트, 스파클링, 로제, 기타 - 와인 종류일때만 검색
    else {
      searchWineOnly = true; // 와인만 검색
      filters.all.push({ pdata_type: [allSearchState.filter.tabIndex] });
    }
    if (allSearchState.filter.tabIndex === 'all' || searchWineOnly) {
      // 필터에서 VIVINO 4.0 이상 평점 필터링
      if (allSearchState.filter.vivino) {
        filters.all.push({ pdata_score_vivino: { from: 4.0 } });
      }

      // 설정한 필터 중 '당도'가 0 이상인 경우
      if (allSearchState.filter.taste_sweet > 0) {
        let scores = [];
        for (let i = 0; i < 3; i++) {
          if (allSearchState.filter.taste_sweet & (2 ** i)) scores = [...new Set([...scores, i + 1, i + 2, i + 3])];
        }
        filters.all.push({ pdata_taste_sweet: scores });
      }

      // 설정한 필터 중 '산도'가 0 이상인 경우
      if (allSearchState.filter.taste_acidity > 0) {
        let scores = [];
        for (let i = 0; i < 3; i++) {
          if (allSearchState.filter.taste_acidity & (2 ** i)) scores = [...new Set([...scores, i + 1, i + 2, i + 3])];
        }
        filters.all.push({ pdata_taste_acidity: scores });
      }

      // 설정한 필터 중 '바디감'이 0 이상인 경우
      if (allSearchState.filter.taste_body > 0) {
        let scores = [];
        for (let i = 0; i < 3; i++) {
          if (allSearchState.filter.taste_body & (2 ** i)) scores = [...new Set([...scores, i + 1, i + 2, i + 3])];
        }
        filters.all.push({ pdata_taste_body: scores });
      }

      // 설정한 필터 중 '탄닌'이 0 이상인 경우
      if (allSearchState.filter.taste_tannin > 0) {
        let scores = [];
        for (let i = 0; i < 3; i++) {
          if (allSearchState.filter.taste_tannin & (2 ** i)) scores = [...new Set([...scores, i + 1, i + 2, i + 3])];
        }
        filters.all.push({ pdata_taste_tannin: scores });
      }

      // filter - Grape variety
      if (allSearchState.filter.variety.length) {
        // 'etc'가 끼어있을 경우
        if (allSearchState.filter.variety.indexOf('etc') >= 0) {
          // 그 외에 선택된 품종은 검색되도록
          const notIn = VARIETY_FILTER_NAMES.filter((v) => allSearchState.filter.variety.indexOf(v) < 0);
          console.debug('[filter] 검색에서 제외할 품종: ', notIn);
          if (!filters.none) filters.none = [];
          filters.none.push({
            pdata_variety_id: notIn
          });
        }
        // 선택된 포도품종 필터링
        else {
          console.debug('[filter] 검색할 품종: ', allSearchState.filter.variety);
          filters.all.push({
            pdata_variety_id: [...allSearchState.filter.variety]
          });
        }
      }
    }

    // 필터에서 금액범위를 직접 입력했을 경우
    if (_.difference(allSearchState.filter.price_range_custom, INITIAL_FILTER_STATE.price_range_custom).length) {
      // 직접 설정한 금액범위
      const [priceMin, priceMax] = allSearchState.filter.price_range_custom;

      // 금액 슬라이더가 최소, 최대범위일 경우 모든 금액을 계산합니다.
      if (priceMin === 0 && priceMax === '') {
        console.debug('모든 가격의 상품을 조회합니다.');
      } else {
        // 직접 설정한 금액범위가 있을 경우
        const option = {};
        if (priceMin > 0) {
          option.from = priceMin;
        }

        if (priceMax > 0 && priceMax !== '') {
          option.to = priceMax;
        }

        filters.all.push({ price_original: option });
      }
    }
    // 필터에서 금액범위를 직접 설정 경우
    // else if (search.filter.price_range !== INITIAL_FILTER_STATE.price_range) {
    else if (_.difference(allSearchState.filter.price_range, INITIAL_FILTER_STATE.price_range).length) {
      // Slide로 설정한 금액범위
      const [priceMin, priceMax] = allSearchState.filter.price_range;

      // 금액 슬라이더가 최소, 최대범위일 경우 모든 금액을 계산합니다.
      if (priceMin === 0 && priceMax === PRODUCT_MAX_PRICE + PRODUCT_PRICE_STEP) {
        console.debug('모든 가격의 상품을 조회합니다.');
      } else {
        const option = {};
        if (priceMin > 0) {
          option.from = priceMin;
        }

        if (priceMax < PRODUCT_MAX_PRICE + PRODUCT_PRICE_STEP) {
          option.to = priceMax;
        }

        filters.all.push({ price_original: option });
      }
    }
    // 금액범위 기본
    else {
      // 10원 이상 - v1.0.9
      filters.all.push({ price_original: { from: 10 } });
    }

    // filter - Country
    if (allSearchState.filter.country.length) {
      // 'etc'가 끼어있을 경우
      if (allSearchState.filter.country.indexOf('etc') >= 0) {
        // 그 외에 선택된 국가는 검색되도록
        const notIn = COUNTRY_FILTER_NAMES.filter((c) => allSearchState.filter.country.indexOf(c) < 0);
        console.debug('[filter] 검색에서 제외할 국가: ', notIn);
        if (!filters.none) filters.none = [];
        filters.none.push({
          pdata_country_id: notIn
        });
      }
      // 선택된 국가 필터링
      else {
        console.debug('[filter] 검색할 국가: ', allSearchState.filter.country);
        filters.all.push({
          pdata_country_id: [...allSearchState.filter.country]
        });
      }
    }

    // 와인만 검색되어야함
    if (searchWineOnly) {
      filters.all.push({ category: [PDATA_CATEGORY.WINE.value] });
    }

    // 절.대.사.용.자 - v1.0.9
    const { god = false } = user;

    if (god) {
      /* Firestore: /member/{uid} -> god: true */
      console.info('[ProductBox] `god`사용자는 테스트 입점샵도 조회합니다.');
    } else {
      // 테스트 입점샵은 검색이 안되도록
      if (!filters.none) filters.none = [];
      filters.none.push({ vendor_test: 'true' });
    }

    console.groupCollapsed('[1KMWINE] 상품(product) 조회조건');
    console.log('필터: ', filters);
    console.log('정렬: ', allSearchState.sort);
    console.groupEnd();

    // 판매상품 정렬
    const _sort = [];

    // 추천순
    if (allSearchState.sort.value === 'recommend') {
      _sort.push({ recommend_use: 'desc' });
      _sort.push({ recommend_order: 'asc' });
    }
    // 가격 낮은순
    else if (allSearchState.sort.value === 'lowp') {
      _sort.push({ lowp: 'asc' });
    }
    // 가격 높은순
    else if (allSearchState.sort.value === 'highp') {
      _sort.push({ highp: 'desc' });
    }

    // Elastic App Search option
    // reference: https://www.elastic.co/guide/en/app-search/8.2/search.html#search-api-request-body
    const options = {
      page: { size: _page.size, current: _page.current },
      search_fields: {
        name_ko: { weight: 5 },
        name_en: { weight: 5 }
      },

      // 판매상품 필터
      filters,
      // 판매상품 정렬
      sort: _sort,

      analytics: {
        tags: ['1kmwine', 'wine', 'search']
      }
    };

    // 상품 검색
    const result = await getProductList(vendorId, allSearchState.searchQuery, options);
    console.log('입점샵 상품목록 조회결과: ', result);

    if (!scriptedRef.current) {
      console.warn('[ProductBox][fetchProductList] Unmounted component.');
      return;
    }

    const { page, products } = result;

    products.forEach((product) => {
      product.id = product._id;
    });

    // 1페이지 요청 시 리스트 초기화
    const productList = page.current === 1 ? [...products] : [...list, ...products];

    // 판매상품(product) 조회 완료, 조회결과가 없거나 모든 결과를 조회했을 때
    const done = page.total_results === 0 || page.total_pages === 0 || page.total_pages === page.current;

    // 검색상태
    setSearch((prev) => ({
      ...prev,
      ...allSearchState,
      searchState: {
        ...allSearchState.searchState,
        initialized: true,
        searched: true,
        searching: false
      },
      filterUsed: !deepEqual(INITIAL_FILTER_STATE, allSearchState.filter)
    }));

    setPage((prev) => ({
      ...prev,
      ...page,
      done
    }));

    setList(productList);
  };

  // ---------------------------------------- 상품 선택하기 [START]

  // 상품 추가/제외
  const handleProductItem = React.useCallback(
    (product) => {
      const { id } = product;
      const _orderItemMap = { ...orderItemMap };

      // 이미 주문상품에 추가되어있을 경우 -> 주문상품에서 제외
      if (_orderItemMap[id]) {
        // console.debug(`[wineone] 주문상품 제외 (${product.pdata.name.ko})`);
        console.log(`[wineone] 주문상품 제외 (${product.pdata.name.ko})`);
        delete _orderItemMap[id];
      }
      // 주문상품 추가
      else {
        // console.debug(`[wineone] 주문상품 추가 (${product.pdata.name.ko})`);
        console.log(`[wineone] 주문상품 추가 (${product.pdata.name.ko})`);
        _orderItemMap[id] = {
          product,
          quantity: 1
        };
      }

      changeOrderItemMap(_orderItemMap);
    },
    [orderItemMap]
  );

  // 상품 수량변경
  const handleOrderItemQuantity = (orderItem, val) => {
    console.debug(`수량 변경됨. val=${val}`);
    const { product } = orderItem;
    const _orderItemMap = { ...orderItemMap };
    _orderItemMap[product._id] = {
      product,
      quantity: val
    };
    changeOrderItemMap(_orderItemMap);
  };
  // ---------------------------------------- 상품 선택하기 [END]

  // 조회된 상품목록
  const productListComponent = React.useMemo(() => {
    return search.searchState.initialized || search.searchState.searched ? (
      <ProductList
        productList={list}
        onProductClick={(pdata) => moveToWineInfo(pdata)}
        onToggleProduct={handleProductItem}
        orderItemMap={orderItemMap}
        onQuantityChange={handleOrderItemQuantity}
      />
    ) : (
      <Box height="80vh" display="flex" justifyContent="center" pt={2}>
        <CircularProgress color="brand" size={24} />
      </Box>
    );
  }, [list, search.sort]);

  // 스크롤 디렉션
  const [y, setY] = React.useState(window.scrollY);

  const handleScrollEvent = (e) => {
    const window = e.currentTarget;
    // 스크롤이 아래방향으로 ( trigger 쓰지말고 y로 판단 )
    if (y < window.scrollY) {
      // 스크롤이 바닥에 거의(-180) 닿았을 때
      if (window.scrollY + window.innerHeight >= document.body.offsetHeight - 180) {
        if (!search.searchState.searched) return; // 최초 조회시 중복요청 제외
        if (search.searchState.searching) return; // 판매상품(product) 페이지 요청 중일 경우 pass

        // 다음 페이지 호출
        if (!page.done) {
          console.debug(`[wineone] 다음 페이지 호출 (${page.current + 1})`);
          fetchProductList({ ...page, current: page.current + 1 }, search);
        }
      }
    }
    setY(window.scrollY);
  };

  // 스크롤 감시
  React.useEffect(() => {
    setY(window.scrollY);
    window.addEventListener('scroll', handleScrollEvent);

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, [handleScrollEvent]);

  const onFilterBtnClick = React.useCallback(() => {
    setSearch((search) => ({ ...search, filterOpen: true }));
  }, [setSearch]);

  const pdata_category = React.useMemo(() => {
    let cat;
    switch (search.filter.tabIndex) {
      case WINE_TYPES.RED.value:
      case WINE_TYPES.WHITE.value:
      case WINE_TYPES.SPARKLING.value:
      case WINE_TYPES.ROSE.value:
        cat = 'wine';
        break;
      default:
        cat = 'all';
    }
    return cat;
  }, [search.filter.tabIndex]);

  // render
  return (
    <Container ref={appBarRef} maxWidth="xs" sx={{ pb: '100px', px: [0, 0, 0, 0] }}>
      <AppBar
        id="order-sticky-header"
        position="sticky"
        role="searchbox"
        aria-roledescription="상품 검색박스"
        sx={{ top: 54, minHeight: '0px', marginTop: '12px', px: '20px' }}
      >
        {/* 상품명 검색 */}
        <SearchField readOnly value={search.searchQuery} onClick={openSearchArea} placeholder="와인샵 내 상품 검색" />

        {/* 상품 타입 탭 (전체, 레드, 화이트, 스파클링, 로제, 기타) */}
        <CategoryTabs
          value={search.filter.tabIndex}
          onChange={handleTabNavChange}
          variant="scrollable"
          indicatorColor="primary"
          textColor="inherit"
          aria-label="상품구분 탭"
        >
          <Tab label="전체" id="product-tab-all" value="all" />
          {productTabList}
          <Tab label="그외 주류" id="product-tab-others" value="others" />
        </CategoryTabs>
      </AppBar>

      <SearchResultContainer sx={{ minHeight: hasResult ? `calc(var(--vh, 1vh) * 100)` : 'auto' }}>
        {/* 핀 위치내 상품 표현 영역 */}
        <ProductInsideContainer
          totalCount={page.total_results}
          sort={{ label: search.sort.label, value: search.sort.value }}
          onFilterBtnClick={onFilterBtnClick}
          onSortOpen={handleOrderDialogOpen}
          searchState={{ ...search.searchState, page }}
          filter={{ ...search.filter, pdata_category }}
          filterItemSequence={filterItemSequence}
          onFilterItemClick={(name, value) => {
            if (search.searchState.initialized) deleteFilter(name, value);
          }}
          onVivinoScoreClick={(vivino) => {
            console.log(search.searchState.initialized);
            if (search.searchState.initialized) applySheetFilter({ ...search.filter, vivino });
          }}
          onPriceRangeClick={(price_range_custom, price_all) => {
            if (search.searchState.initialized) applySheetFilter({ ...search.filter, price_range_custom, price_all });
          }}
        >
          {productListComponent}
        </ProductInsideContainer>
      </SearchResultContainer>

      {/* 검색 다이얼로그 */}
      <SearchDialog
        fullScreen
        keepMounted
        open={searchOpen}
        onClose={() => {
          pageBackFunc();
        }}
        onSearch={(query) => {
          searchProduct(query);
        }}
        value={search.searchQuery}
      />

      {/* 검색영역 다이얼로그 */}
      <SearchDialog
        fullScreen
        keepMounted
        open={search.searchOpen}
        onClose={() => {
          pageBackFunc();
        }}
        onSearch={searchNearbyProduct}
        queryHistory={queryHistory}
        value={search.searchQuery}
      />

      {/* Product filter dialog */}
      <FilterDialog
        open={search.filterOpen}
        filterState={search.filter}
        filterItemSequence={filterItemSequence}
        onClose={() => {
          setSearch({ ...search, filterOpen: false });
        }}
        onConfirm={applySheetFilter}
      />

      {/* 상품 정렬옵션 다이얼로그 */}
      <OrderDialog
        id="vendor-product-order-menu"
        keepMounted
        open={search.orderDialogOpen}
        onClose={handleOrderDialogChange}
        value={search.sort.value}
      />
    </Container>
  );
}

export default ProductBox;

ProductBox.propTypes = {
  vendorId: PropTypes.string.isRequired,
  mainProduct: PropTypes.object.isRequired,
  orderItemMap: PropTypes.object.isRequired,
  changeOrderItemMap: PropTypes.func.isRequired,
  moveToWineInfo: PropTypes.func.isRequired
};

// 와인 카테고리 탭 (레드, 화이트, 스파클링, 로제, 기타)
const CategoryTabs = styled(Tabs)(
  ({ theme }) => `
  height: auto;
  & .MuiTab-root {
    height: 54px;
    min-width: 42px;
    line-height: normal;
    font-size: 14px;    
    color: ${theme.palette.text.primary};
    opacity: 1;
    transition: all ${theme.transitions.duration.short} ${theme.transitions.easing.easeInOut};
    padding-left: ${theme.spacing(1)};
    padding-right: ${theme.spacing(1)};
    
    &.Mui-selected {
      font-weight: 800;
    }
    &.Mui-disabled {
      opacity: 0.35;
    }
  }
  
  & .MuiTabs-indicator {
    transition: none;
    background-color: #2d154d;
  }
`
);

function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }
  return true;
}

function isObject(object) {
  return object != null && typeof object === 'object';
}
