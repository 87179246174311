import React from 'react';
import { useLocationState } from 'react-router-use-location-state';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate, useNavigationType, useParams } from 'react-router-dom';
import { getFirebaseFunctions, getFirestore } from 'utils/firebase-tools';
import { doc, getDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

// material-ui
import { Box, ButtonBase, Card, Container, ListItem, ListItemText, Skeleton, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';

// project imports
import { dispatch, useSelector } from 'store';
import { fetchCodes } from 'store/slices/wo-constants';
import { addProduct } from 'store/slices/cart';
import { openSnackbar } from 'store/slices/snackbar';
import { useAuth, useScriptRef, useWineOne } from 'hooks';
import { WoAlert } from 'utils/kmwine-alerts';
import { CLO_CODE } from 'config';
import { addFvrVendor, deleteFvrVendor, getOneVendor } from 'services/VendorService';
import { Calculator } from 'utils/bourgogne-tools';
import RefundDialog from './components/RefundDialog';
import BottomButtonSheet from './components/BottomButtonSheet';
import VendorDetailDialog from './components/VendorDetailDialog';
import CartSnackbar from './components/CartSnackbar';
import { BackHeader } from './components/BackHeader';
import SelfPromotion from './components/Promotion/SelfPromotion';
import { ProductImage, ProductName, ProductPrice, SpaceBetweenBox, WishButton } from '../../../components';
import { QuantityBox } from '../../pages/order/components';
import { VendorQrDialog } from '../../promotion';
import { VendorInfoSection } from './components/VendorInfoSection';
import { PdataInfo } from 'components/pdata';

// assets imports
import wineDefaultImg from 'assets/images/default_wine.webp';
import ProductBox from './components/ProductBox/ProductBox';

const IMG_WIDTH = 107; // 상품 썸네일 이미지 너비
const IMG_HEIGHT = 154; // 상품 썸네일 이미지 높이

// 한번에 호출할 페이지 개수
const PAGE_SIZE = 15;

// 와인 정렬옵션
export const vendorProductOrderOpts = Object.freeze([
  { label: '추천순', value: 'recommend' },
  { label: '낮은 가격순', value: 'lowp' },
  { label: '높은 가격순', value: 'highp' }
]);

/**
 * 매장 상세
 *
 * @authors 최효근<hkchoi@wineone.io>, 이재일<leeji@wineone.io>, 조현권<hkcho@wineone.io>
 */
function VendorDetailIndex() {
  const navigate = useNavigate();
  const location = useLocation();
  const scriptedRef = useScriptRef();
  const navigationType = useNavigationType();

  // 오늘의 한 병 페이지에서 '관심샵 등록하고 샵 둘러보기' 버튼을 클릭해서 온 경우
  React.useLayoutEffect(() => {
    if (!location.state?.requestFvr) return;

    const { requestFvr } = location.state;
    if (requestFvr) toggleVendorFvr(!requestFvr);
  }, [location.state?.requestFvr]);

  const globalDispatch = useDispatch();
  const sendCloError = httpsCallable(getFirebaseFunctions(), 'call-cdm-clo-error');

  // 서비스 상수, 사용자 핀위치
  const { woConstants } = useSelector((state) => state);

  React.useLayoutEffect(() => {
    dispatch(fetchCodes(['wine_type']));
  }, [woConstants]);

  // 장바구니 로딩
  const [cartLoading, setCartLoading] = React.useState(false);

  // 입점샵(vendor) ID
  const { vendorId } = useParams(); // 입점샵 아이디

  // 모바일에서 뒤로가기 눌렀을시
  const { onNativeBackPressed } = useWineOne();

  // 로그인 사용자 정보
  const { user } = useAuth();

  // 마지막으로 저장된 상태값
  const [lastState, setLastState] = useLocationState('lastState', null);

  const initialProductListValue = React.useMemo(
    () =>
      Object.freeze({
        loaded: false,
        error: false,
        items: []
      }),
    []
  );

  // 매장에서 판매중인 상품 초기값
  const initialProductList = React.useMemo(() => {
    let initVal = { ...initialProductListValue };
    if (navigationType !== 'PUSH' && lastState?.productList) {
      initVal = { ...lastState.productList };
    }
    return initVal;
  }, []);

  // 입점샵의 상품목록
  const [productList, setProductList] = React.useState(initialProductList);

  const initialPageValue = React.useMemo(
    () =>
      Object.freeze({
        current: 1,
        total_pages: 0,
        total_results: 0,
        size: PAGE_SIZE // 한번에 호출할 상품 개수
      }),
    []
  );
  // 페이징 정보 초기값
  const initialPage = React.useMemo(() => {
    let initVal = { ...initialPageValue };
    if (navigationType !== 'PUSH' && lastState?.page) {
      initVal = { ...lastState.page };
    }
    // console.debug(' - 페이징 정보 초기값: ', initVal);
    return initVal;
  }, [location]);

  // 상품 페이징 정보
  const [page, setPage] = React.useState(initialPage);

  const initialSearchStateValue = React.useMemo(
    () =>
      Object.freeze({
        initialized: false,
        searched: false,
        searching: false,
        error: false
      }),
    []
  );

  // 검색상태 기본정보 초기값
  const initialSearchState = React.useMemo(() => {
    let initVal = { ...initialSearchStateValue };
    if (navigationType !== 'PUSH' && lastState?.searchState) {
      initVal = { ...lastState.searchState };
    }
    return initVal;
  }, []);

  // 검색상태
  const [searchState, setSearchState] = React.useState(initialSearchState);

  // 뒤로가기
  const pageBackFunc = React.useCallback(() => {
    console.debug(`검색 화면에서 '뒤로가기' 버튼이 감지되었습니다.`);
    // 상품검색 영역이 열려있었을 경우
    if (window.history.length > 0) {
      navigate(-1);
    } else {
      navigate('/', { replace: true });
    }
  }, [location]);

  React.useEffect(() => {
    // 기기에서 뒤로가기 버튼을 눌렀을 때
    onNativeBackPressed(pageBackFunc);
  }, [pageBackFunc]);

  // 카트 스낵바 상태
  const [cartSnackbar, setCartSnackbar] = React.useState(false);

  // 장바구니 버튼 클릭 이벤트
  const cartButtonClickEvent = async () => {
    // 선택한 상품이 없을 시
    if (totalBottleCount === 0) {
      globalDispatch(
        openSnackbar({
          open: true,
          message: `상품을 선택해주세요.`,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: true,
          autoHideDuration: 4000
        })
      );
    }
    // 선택한 상품이 있을 시
    else {
      setCartLoading(true);
      const body = [];
      const productIds = Object.keys(orderItemMap) ?? [];

      productIds.forEach((product) => {
        const { quantity } = orderItemMap[product];
        body.push({ product_id: product, cart_quantity: quantity });
      });

      const { code } = await globalDispatch(addProduct(body)).finally(() => setCartLoading(false));
      setCartSnackbar(code === 0);
    }
  };

  // 스마트 오더 화면으로 이동
  const moveToOrderConfirmPage = () => {
    if (totalBottleCount === 0) {
      globalDispatch(
        openSnackbar({
          open: true,
          message: `상품을 선택해주세요.`,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: true,
          autoHideDuration: 4000
        })
      );
      return;
    }

    // const _mainProduct = { product: mainProduct, quantity };

    // 주문상품목록
    // const orderItems = mainProduct.show ? [_mainProduct] : [];
    const orderItems = [];

    // 주문에 추가된 상품이 있을경우
    if (orderItemMap) {
      Object.keys(orderItemMap).forEach((productId) => {
        orderItems.push(orderItemMap[productId]);
      });
    }

    const lastState = {
      page,
      vendor,
      searchState,
      productList,
      mainProduct,
      quantity,
      orderItemMap
    };

    try {
      setLastState(lastState);
    } catch (e) {
      console.warn('[매장 상세]location.state에 마지막 state저장 실패', e);
    } finally {
      // todo hkchoi 영업중지 제한

      // 픽업주문 화면으로 이동
      navigate(`/order/confirm`, {
        state: {
          // mainProduct: mainProduct.show ? _mainProduct : { ...orderItems[0] },
          mainProduct: { ...orderItems[0] },
          orderItems,
          vendor: { ...vendor, id: vendor._id }
        }
      });
    }
  };

  // default vendor datas from location.state
  const initialVendor = React.useMemo(() => {
    if (location?.state?.vendor?._id === vendorId) {
      let vendor_imgs = [];
      if (location.state.vendor.vendor_img) {
        if (Array.isArray(location.state.vendor.vendor_img)) {
          vendor_imgs = location.state.vendor.vendor_img;
        } else {
          vendor_imgs = [location.state.vendor.vendor_img];
        }
      }

      return { ...location.state.vendor, vendor_imgs };
    }
    return {};
  }, [location?.state?.vendor?._id, vendorId]);

  // 샵정보
  const [vendor, setVendor] = React.useState({ loaded: false, _id: vendorId, ...initialVendor });

  // 관심샵 여부
  const isMyFavorite = React.useMemo(() => {
    // 관심샵 조회 중
    if (!vendor.loaded) return false;
    // 관심샵 여부 확인
    return vendor.my_favorite;
  }, [vendor]);

  // 입점샵 정보 불러오기
  React.useLayoutEffect(() => {
    // 입점샵이 변경됨
    if (vendor.loaded && vendorId !== vendor._id) {
      setLastState(null);
      setPage(initialPageValue);
      setSearchState(initialSearchStateValue);
      setProductList(initialProductListValue);
      setVendor({ loaded: false, _id: vendorId });
      // fetchVendor();
    } else if (!vendor.loaded) {
      fetchVendor();
    }
  }, [vendor.loaded, vendorId]);

  /**
   * 입점샵 상세정보 조회
   * @returns {Promise<undefined>}
   */
  const fetchVendor: Promise<void> = async () => {
    try {
      const {
        data: { result, data: vendor }
      } = await getOneVendor(vendorId).catch((error) => ({ error }));

      // console.log('------- vendor: ', vendor);

      if (!scriptedRef.current) {
        console.warn('[VendorDetailIndex][fetchVendor] Unmounted component.');
        return undefined;
      }

      // 조회 실패
      if (result.code !== 0) throw Error(result.msg);

      // 조회된 입점샵이 없을 경우
      if (!vendor) {
        WoAlert.fire(`영업 중인 샵이 아니에요.`, '[vendor_not_exists]', 'error').then(() => {
          if (window.history.length > 0) {
            navigate(-1);
          } else {
            navigate('/home', { replace: true });
          }
        });
        return undefined;
      }

      // 입점샵 이미지 데이터 재정의 (대표이미지 1장 + 서브이미지)
      const { vendor_img = [], sub_img = [] } = vendor;
      const result_img = [];

      // 입점샵 대표이미지가 있으면 첫번째 이미지로 재배열
      if (vendor_img.length > 0 && vendor_img[0]?.origin !== '') {
        result_img.push(vendor_img[0]);
      }
      // 서브이미지가 있으면 sort순서로 재배열
      if (sub_img.length > 0) {
        const sortBySubImg = sub_img.sort((a, b) => a.sort - b.sort);
        result_img.push(...sortBySubImg);
      }

      vendor.vendor_imgs = result_img;

      // 입점샵 정보
      setVendor({ ...vendor, loaded: true });
    } catch (e) {
      await WoAlert.fire(`샵정보 조회 중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.\n[Not available]`, '', 'error').then(() => {
        navigate(-1);
      });
      return undefined;
    }
  };

  // 다이나믹 링크로 들어왔을시 대비
  React.useEffect(() => {
    if (!vendor.loaded) return false;

    // 입점 후보 또는 영업 중지 또는 삭제 또는 올바르지 않은 상태의 입점샵일 경우
    if (vendor.candidate || vendor.closed || vendor.deleted || vendor.state !== 'REQ_OK') {
      WoAlert.fire(`영업 중인 샵이 아니에요.`, '', 'error').then(() => {
        if (window.history.length > 0) {
          navigate(-1);
        } else {
          navigate('/home', { replace: true });
        }
      });
    }
  }, [vendor]);

  // 매장 주소
  const vendorAddr = React.useMemo(() => {
    let addr;
    if (!vendor.biz) return null;

    if (vendor?.biz?.address1) {
      addr = vendor.biz.address1;
      if (vendor.biz.address2) addr += `\r\n${vendor.biz.address2}`;
    }
    return addr;
  }, [vendor?.biz]);

  // 영업시간 표현 [end]

  // 메인 상품정보 초기값
  const initialMainProduct = React.useMemo(() => {
    let initVal = { loaded: false };
    if (navigationType !== 'PUSH' && lastState?.mainProduct) {
      initVal = { ...lastState.mainProduct };
    } else {
      initVal = location?.state?.product
        ? { loaded: false, ...location.state.product, show: true, noPromotion: false }
        : { loaded: false, show: false, noPromotion: false };
    }
    return initVal;
  }, [location]);

  // 메인 상품 정보 - 선택해서 진입한 상품
  const [mainProduct, setMainProduct] = React.useState(initialMainProduct);

  React.useLayoutEffect(() => {
    if (mainProduct.show && !mainProduct.loaded) {
      fetchMainProduct();
    } else if (!location?.state?.product) {
      setMainProduct((mainProduct) => ({ ...mainProduct, loaded: true }));
    }
  }, [mainProduct.show]);

  // 메인 상품 정보 조회
  const fetchMainProduct = React.useCallback(async () => {
    const productSnapshot = await getDoc(doc(getFirestore(), 'product', mainProduct._id));
    if (!scriptedRef.current) {
      console.warn('[VendorDetailIndex][fetchMainProduct] Unmounted component.');
      return;
    }
    const mainProduct_ = productSnapshot.data();
    setMainProduct((mainProduct) => ({ ...mainProduct, ...mainProduct_, loaded: true, show: true }));
  }, [mainProduct._id]);

  // 메인상품 주문수량 초기값
  const initialQuantity = React.useMemo(() => {
    let initVal = 1;
    if (navigationType !== 'PUSH' && lastState?.mainProduct && lastState?.orderItemMap) {
      const _mainProduct = lastState.orderItemMap[lastState.mainProduct._id];
      initVal = _mainProduct?.quantity ?? 1;
    } else if (navigationType !== 'PUSH' && lastState?.quantity) {
      initVal = lastState.quantity;
    }
    return initVal;
  }, [location]);

  // 메인상품 주문수량
  const [quantity, setQuantity] = React.useState(initialQuantity);

  // 주문상품(함께 주문) 초기값
  const initialOrderItemMap = React.useMemo(() => {
    let initVal = {};
    if (navigationType !== 'PUSH' && lastState?.orderItemMap) {
      initVal = { ...lastState.orderItemMap };
    }
    return initVal;
  }, [location]);

  // 주문상품(함께 주문)
  const [orderItemMap, setOrderItemMap] = React.useState(initialOrderItemMap);

  React.useLayoutEffect(() => {
    // 메인상품과 동기화
    if (navigationType === 'POP' && mainProduct.loaded && mainProduct?._id && !orderItemMap[mainProduct._id]) {
      setMainProduct({ loaded: true, show: false, noPromotion: false });
    }
  }, [mainProduct.loaded && orderItemMap]);

  // 상품 추가/제외
  const handleProductItem = React.useCallback(
    (product) => {
      const { _id: id } = product;
      const _orderItemMap = { ...orderItemMap };

      if (product.show) {
        setQuantity(0);
        delete _orderItemMap[id];
        setOrderItemMap(_orderItemMap);
        setMainProduct({ ...mainProduct, show: false });
        return;
      }

      // 이미 주문상품에 추가되어있을 경우 -> 주문상품에서 제외
      if (_orderItemMap[id]) {
        console.debug(`[wineone] 주문상품 제외 (${product.pdata.name.ko})`);
        delete _orderItemMap[id];
      }
      // 주문상품 추가
      else {
        console.debug(`[wineone] 주문상품 추가 (${product.pdata.name.ko})`);
        _orderItemMap[id] = {
          product,
          quantity: 1
        };
      }

      setOrderItemMap(() => {
        return _orderItemMap;
      });
    },
    [orderItemMap, quantity]
  );

  // 메인 상품 수량 변경 이벤트
  const handleOrderItemQuantity = (product, val) => {
    console.debug(`수량 변경됨. val=${val}`);
    const _orderItemMap = { ...orderItemMap };
    _orderItemMap[product._id] = {
      product,
      quantity: val
    };
    setOrderItemMap(_orderItemMap);
  };

  // 전체 주문 병 수
  const [totalBottleCount, setTotalBottleCount] = useLocationState('totalBottleCount', 0);
  // 전체 주문 병 수 계산
  const [totalPrice, setTotalPrice] = useLocationState('totalPrice', 0);

  React.useEffect(() => {
    let totalCount = 0;
    let totalPrice = 0;
    const productIds = Object.keys(orderItemMap) ?? [];
    productIds.forEach((addedPrdId) => {
      const { quantity } = orderItemMap[addedPrdId];

      totalCount += quantity;
      totalPrice += orderItemMap[addedPrdId].product.price.original * quantity;
    });

    setTotalBottleCount(totalCount);
    setTotalPrice(totalPrice);
  }, [orderItemMap, quantity, mainProduct]);

  // 샵상세정보열고닫기
  const [openVendorDetailDialog, setOpenVendorDetailDialog] = useLocationState('ovdd', false);

  const [vendorClickState, setVendorClickState] = React.useState('name');

  // 샵상세정보로가기(샵이름클릭)
  const onVendorNameClick = React.useCallback(() => {
    setVendorClickState('name');
    setOpenVendorDetailDialog(true, { method: 'push' });
  }, [setOpenVendorDetailDialog]);

  // 샵상세정보로가기(영업시간클릭)
  const onVendorTimeClick = React.useCallback(() => {
    setVendorClickState('time');
    setOpenVendorDetailDialog(true, { method: 'push' });
  }, [setOpenVendorDetailDialog]);

  // 반품/교환/환불 정보 안내 Dialog
  const [openCancelDialog, setOpenCancelDialog] = useLocationState('ocd', false);

  const onRefundInfoClick = React.useCallback(() => {
    setOpenCancelDialog(true, { method: 'push' });
  }, [setOpenCancelDialog]);

  // 상품원본정보
  const { pdata } = mainProduct;

  // 상세보기할 pdata id
  const [detailPdataId, setDetailPdataId] = useLocationState('vd_detail_pdata_id', null);
  // pdata 상세보기
  const [showPdataInfo, setShowPdataInfo] = useLocationState('vd_detail_showPdataInfo', false);

  // 와인 상세정보 보기
  const moveToWineInfo = async (_pdata) => {
    if (showPdataInfo) return false; // 이미 열려있을 때

    console.debug('[1KMWINE] 와인 상세정보 보기. pdata=', _pdata);
    const pdataId = _pdata._id ?? _pdata.id;

    if (!pdataId) {
      console.error('[1KMWINE] 와인 상세정보 보기. pdataId가 없음.');
      return undefined;
    }

    setDetailPdataId(pdataId);
    setShowPdataInfo(true, { method: 'push' });
  };

  // v1.1.3: 입점샵 QR 쿠폰 발급안내 레이어
  const [showVendorQrCouponDialog, setShowVendorQrCouponDialog] = React.useState(false);
  const [vendorQrCouponId, setVendorQrCouponId] = React.useState();

  // 관심샵 추가,제거
  const toggleVendorFvr = async (fvr) => {
    let result;
    if (fvr) {
      result = await deleteFvrVendor(vendor._id).catch((error) => ({ error })); // 관심샵 삭제
    } else {
      result = await addFvrVendor(vendor._id).catch((error) => ({ error })); // 관심샵 추가
    }

    // 관심샵 추가/제외 중 오류가 발생
    if (result.error) {
      let errMsg = null;
      if (result.error.response && result.error.response.data) {
        const { data } = result.error.response;
        errMsg = data?.message ?? null;
      }

      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `관심샵 변경 중 오류`,
        msg: `[uid=${user._id}] ${JSON.stringify(result.error)}`,
        which: `${location.pathname}${location.search}`,
        param: { uid: user._id, vendor_id: vendor._id }
      })
        .then(console.log)
        .catch(console.error);

      if (scriptedRef.current) {
        await WoAlert.fire(`관심샵 변경 중 오류가 발생했어요.`, errMsg ? `[${errMsg}]` : '', 'warning');
      }
      return undefined;
    }

    console.debug(`##--> 관심샵 ${fvr ? '제외' : '추가'} 결과.`, result.data.result);

    if (result.data.result.code === 0) {
      await fetchVendor(); // 관심샵 카운트 갱신

      setVendor((prev) => ({ ...prev, my_favorite: !isMyFavorite })); // 별표 반대로 표현

      // VENDOR_QR 쿠폰 수령시 레이어로 띄울 것
      if (!isMyFavorite && result.data?.VENDOR_QR) {
        const vendorQrCouponResult = result.data.VENDOR_QR;

        // 쿠폰발급 성공
        if (vendorQrCouponResult.success) {
          setShowVendorQrCouponDialog(true);
          setVendorQrCouponId(vendorQrCouponResult.coupon_id);
          return undefined;
        }

        // 쿠폰 발급 중 오류
        if (vendorQrCouponResult.error) {
          await WoAlert.fire(
            `이벤트 쿠폰오류`,
            '쿠폰 발급 중에 오류가 발생했어요.<br/>관심샵 해제 후 다시 한번 관심샵으로 설정해주세요',
            ''
          );
          return undefined;
        }

        // 기타 미발급 사유
        console.warn(`입점샵 쿠폰 미발급 사유: ${result.data.VENDOR_QR.message}`);
      }

      globalDispatch(
        openSnackbar({
          open: true,
          message: `관심샵이 ${fvr ? '제외' : '추가'}되었습니다.`,
          variant: 'alert',
          alert: { color: 'success' },
          close: true,
          autoHideDuration: 2800
        })
      );
    }
    // 이미 관심샵으로 등록되어있음
    else if (!fvr && result.data.result.code === 1) {
      if (scriptedRef.current) {
        globalDispatch(
          openSnackbar({
            open: true,
            message: `이미 관심샵으로 등록이 되어있어요`,
            variant: 'alert',
            alert: { color: 'success' },
            close: true,
            autoHideDuration: 2000
          })
        );
      } else {
        /* DO NOTHING */
      }
    }
    // 이미 관심샵 제거된 호출
    else if (fvr && result.data.result.code === 1) {
      await fetchVendor(); // 관심샵 카운트 갱신
    } else {
      console.error('관심샵 변경 중 오류#974', result.data.result);

      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `관심샵 ${fvr ? '제외' : '추가'} 중 오류`,
        msg: `[uid=${user._id}] ${JSON.stringify(result.data.result)}`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user._id
        }
      })
        .then(console.log)
        .catch(console.error);

      if (scriptedRef.current) {
        await WoAlert.fire(`관심샵 변경 중 오류가 발생했어요.`, '', 'warning');
      }
    }
  };

  // ------------------------------------------------------------------------ 입점샵 상품 필터 [START]
  // ------------------------------------------------------------------------ 입점샵 상품 필터 [END]

  // render
  return (
    <Box>
      {/* 장바구니 상품 추가 성공 스낵바 */}
      {cartSnackbar && (
        <CartSnackbar
          open={cartSnackbar}
          close={() => setCartSnackbar(false)}
          vendor={vendor}
          mainProduct={mainProduct}
          mainProductQuantity={quantity}
          selectProducts={orderItemMap}
        />
      )}
      <BackHeader
        vendor={vendor}
        showBottomNav={false}
        imgs={vendor?.vendor_imgs}
        fvr={vendor?.my_favorite}
        fetchVendor={fetchVendor}
        toggleVendorFvr={toggleVendorFvr}
      >
        <Helmet title={`${process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE} | 매장 상세`} />
        <Container maxWidth="xs" sx={{ px: [0, 0], pt: vendor?.vendor_imgs?.length === 0 ? '54px' : 0 }}>
          {/* 매장 상세정보 */}
          <MemoizedVendorInfoSection
            vendor={vendor}
            onVendorNameClick={onVendorNameClick}
            onVendorTimeClick={onVendorTimeClick}
            onRefundInfoClick={onRefundInfoClick}
          />

          {/* 메인 상품이 있을시 > 프로모션 상품인 경우에는 표시되면 안됨 */}
          {mainProduct.show && mainProduct.loaded && mainProduct.noPromotion && orderItemMap[mainProduct?._id] !== undefined ? (
            <>
              {/* empty area */}
              <Box maxWidth height="8px" bgcolor="#F3F2F5" />
              <Card elevation={0} sx={{ py: 1, my: 1, px: '20px' }}>
                <ListItem disablePadding>
                  {/* 메인 상품 이미지  */}
                  <Box sx={{ mt: 0, mr: 1.5 }} minWidth={IMG_WIDTH} width={IMG_WIDTH} height={IMG_HEIGHT}>
                    <ButtonBase
                      sx={{ width: '100%', height: '100%' }}
                      className="pdata-detail-button"
                      onClick={() => {
                        if (mainProduct.loaded) {
                          moveToWineInfo(pdata);
                        }
                      }}
                    >
                      <ProductImage
                        width="100%"
                        height="100%"
                        visibleByDefault
                        src={mainProduct.pdata?.bottle_img?.thumb ?? wineDefaultImg}
                        alt="와인 이미지"
                      />
                    </ButtonBase>
                  </Box>
                  <ListItemText
                    disableTypography
                    sx={{
                      m: 0,
                      minHeight: 112,
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexFlow: 'column'
                    }}
                    primary={
                      <Box>
                        <SpaceBetweenBox sx={{ height: 20 }}>
                          <Typography variant="caption" fontSize={11} fontWeight={800} letterSpacing="-0.11px" pt="7px">
                            {mainProduct.pdata?.producer?.ko ? mainProduct.pdata.producer.ko : <span>1KMWINE</span>}
                          </Typography>
                          {/* Wish 포함여부 */}
                          {mainProduct.pdata && (
                            <WishButton
                              pdataId={mainProduct.pdata.id ?? mainProduct.pdata._id}
                              pdataName={mainProduct.pdata.name.ko}
                              onWishChange={(isWish) => {
                                console.debug('찜하기 여부: ', isWish);
                              }}
                            />
                          )}
                        </SpaceBetweenBox>
                        {/* 상품명[start] */}
                        <ProductName sx={{ mt: '7px' }}>
                          {mainProduct.pdata?.name?.ko ? (
                            `${mainProduct.pdata.name.ko}`
                          ) : (
                            <>
                              <Skeleton />
                              <Skeleton />
                            </>
                          )}
                        </ProductName>
                        {/* 상품명[end] */}

                        {/* 빈티지 / 용량 */}
                        <Box>
                          <Stack
                            direction="row"
                            spacing="6px"
                            divider={
                              <Box display="flex" alignItems="center" pb="1px">
                                <Box height={'9px'} width={'1px'} bgcolor={(theme) => alpha(theme.palette.text.primary, 0.1)} />
                              </Box>
                            }
                            sx={{ fontSize: 11, fontWeight: 300, color: 'text.secondary' }}
                          >
                            {mainProduct.vintage && mainProduct.vintage !== 'NV' && (
                              <Typography variant="caption">{`${mainProduct.vintage}년`}</Typography>
                            )}
                            <Typography variant="caption">{Calculator.formatCapacity(mainProduct.capacity)}</Typography>
                          </Stack>
                        </Box>
                      </Box>
                    }
                    secondary={
                      <Box pb="4px">
                        {/* 상품가격 */}
                        <Box py="10px" display="flex" justifyContent="flex-start" alignItems="center" height={22}>
                          {!mainProduct.price || mainProduct.price.original === null ? (
                            <Skeleton width={'120px'} />
                          ) : (
                            <>
                              <ProductPrice>
                                {/* t odo styling */}
                                <span>{mainProduct.price.original.toLocaleString()}</span>
                              </ProductPrice>
                              <span style={{ paddingLeft: '1px', fontWeight: 300, fontSize: '16px', letterSpacing: '-1px' }}>원</span>
                            </>
                          )}
                        </Box>
                        <Box mt="6px">
                          <QuantityBox
                            max={mainProduct.quantity >> 0}
                            value={quantity}
                            onChange={(newVal) => handleOrderItemQuantity(mainProduct, newVal)}
                          />
                        </Box>
                      </Box>
                    }
                  />
                </ListItem>
              </Card>
            </>
          ) : null}
        </Container>

        {/* 프로모션 상품 목록 */}
        {vendor.loaded && mainProduct.loaded && (
          <SelfPromotion
            vendorId={vendorId}
            mainProduct={mainProduct}
            homePromotionProduct={location?.state?.promotionProduct}
            changeMainProduct={(newVal) => setMainProduct(newVal)}
            orderItemMap={orderItemMap}
            changeOrderItemMap={(newVal) => setOrderItemMap(newVal)}
            moveToWineInfo={(pdata) => moveToWineInfo(pdata)}
          />
        )}

        {/* 매장 상품 & 필터 영역 */}
        <ProductBox
          vendorId={vendor?._id}
          mainProduct={mainProduct}
          orderItemMap={orderItemMap}
          changeOrderItemMap={(newVal) => setOrderItemMap(newVal)}
          moveToWineInfo={(pdata) => moveToWineInfo(pdata)}
        />

        {/* 장바구니 / 주문하기 영역 */}
        <BottomButtonSheet
          product={mainProduct}
          orderItemMap={orderItemMap}
          wish={true}
          onCartButtonClick={cartButtonClickEvent}
          onOrderButtonClick={moveToOrderConfirmPage}
          onDeleteClick={handleProductItem}
          totalBottleCount={totalBottleCount}
          totalPrice={totalPrice}
          cartLoading={cartLoading}
        />
        {/* 반품,교환,안내 다이얼로그 */}
        <RefundDialog openCancelDialog={openCancelDialog} setOpenCancelDialog={setOpenCancelDialog} />

        {/* pdata 상세정보 */}
        <PdataInfo show={showPdataInfo} pdataId={detailPdataId} />

        {/* 매장상세정보 */}
        {vendor.loaded && (
          <VendorDetailDialog
            openVendorDetailDialog={openVendorDetailDialog}
            setOpenVendorDetailDialog={setOpenVendorDetailDialog}
            vendor={vendor}
            vendorClickState={vendorClickState}
            imgs={vendor?.vendor_imgs ?? []}
            vendorAddr={vendorAddr}
            toggleVendorFvr={toggleVendorFvr}
          />
        )}
      </BackHeader>

      {/* v1.1.3: 입점샵 QR 프로모션 - 쿠폰발급 안내 레이어 */}
      {vendor.loaded && (
        <VendorQrDialog
          vendorId={vendorId}
          vendorName={vendor.biz.name}
          couponId={vendorQrCouponId}
          open={showVendorQrCouponDialog}
          onConfirm={() => {
            setShowVendorQrCouponDialog(false);
          }}
          onClose={() => {
            setShowVendorQrCouponDialog(false);
          }}
        />
      )}
    </Box>
  );
}

export default VendorDetailIndex;

// eslint-disable-next-line react/prop-types
const MemoizedVendorInfoSection = React.memo(({ vendor, onVendorNameClick, onVendorTimeClick, onRefundInfoClick }) => (
  <VendorInfoSection
    vendor={vendor}
    onVendorNameClick={onVendorNameClick}
    onVendorTimeClick={onVendorTimeClick}
    onRefundInfoClick={onRefundInfoClick}
  />
));
