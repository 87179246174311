import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// project imports
import { useWineOne } from 'hooks';
import { openSnackbar } from 'store/slices/snackbar';
import { APP_TYPE } from '../../store/slices/wine-one';

// assets
import { ButtonBase } from '@mui/material';
import { ShareIcon } from '../icons';

/**
 * 공유하기 버튼
 */
/* eslint-disable react/prop-types */
function ShareButton(shareData) {
  const globalDispatch = useDispatch();
  const { linkType, linkData, linkTitle = '페이지 공유' } = shareData;

  // 공유기능 사용가능여부
  const [canShare, setCanShare] = useState(typeof window.navigator?.share !== 'undefined');

  const {
    appType // 접근 앱 타입
  } = useWineOne();

  // 이벤트 링크 복사
  const copyInviteLink = async () => {
    if (!linkType || !linkData) return false;

    try {
      const content = `https://api.1kmwine.kr/link/user?type=${linkType}&data=${linkData}`;

      // 안드로이드의 경우
      if (appType === APP_TYPE.AOS) {
        window.kmwine.copyToClipboard('event_content', content);
        // copyToClipboard
        globalDispatch(
          openSnackbar({
            open: true,
            message: '초대링크가 클립보드에 저장되었습니다.',
            variant: 'alert',
            alert: { color: 'success' },
            close: true,
            autoHideDuration: 4000
          })
        );
      } else {
        const copyResult = await navigator.clipboard.writeText(content);
        console.log('copyResult: ', copyResult);

        globalDispatch(
          openSnackbar({
            open: true,
            message: '초대링크가 클립보드에 저장되었습니다.',
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true,
            autoHideDuration: 4000
          })
        );
      }
    } catch (e) {
      console.error('window.navigator.clipboard를 지원하지 않는 기기 입니다.', e);
    }
  };

  // 이벤트 공유
  const shareEvent = async () => {
    if (!linkType || !linkData) return false;

    try {
      if (canShare) {
        await copyInviteLink();

        const shareOption = {
          title: '1KMWINE',
          text: linkTitle,
          url: `https://api.1kmwine.kr/link/user?type=${linkType}&data=${linkData}`
        };
        console.debug('Share contenet: ', shareOption);
        const result = await window.navigator.share(shareOption).catch((error) => {
          console.error('공유하기 실패!!!', error);
        });

        console.log('Share result: ', result);
      } else {
        console.error('window.navigator.share를 지원하지 않는 기기 입니다.');
        await copyInviteLink();
      }
    } catch (e) {
      console.error('window.navigator.share를 지원하지 않는 기기 입니다.', e);
    }
  };

  return (
    <ButtonBase aria-label="공유하기" onClick={shareEvent} sx={{ height: 34, width: 34, justifyContent: 'flex-end' }}>
      <ShareIcon sx={{ width: '34px', height: '34px' }} />
    </ButtonBase>
  );
}

export default ShareButton;

ShareButton.propTypes = {
  linkType: PropTypes.string,
  linkData: PropTypes.string
};
